import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { formatCurrency, createTooltip } from "app/main/utils/tableUtils";
import { isRoleExternal } from "app/services/LoginService";
import { Link, List, ListItem, ListItemText, Tooltip } from "@material-ui/core";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { createTypeView } from "app/main/search/searchUtils";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import TripStopPanel from "./TripStopPanel";
import LoadSearchSummaryCarrierView from "app/main/search/result/LoadSearchSummaryCarrierView";
import { tripHelpers } from "app/main/profile/trips/tools/helpers";
import TripLogisticsCostsComponent from "./TripLogisticsCostsComponent";
import TripFilesView from "./TripFilesView";
import { formatDate } from "app/main/utils/dateUtils";
import { INVOICE_STATUSES_LABELS, INVOICE_STATUSES_SP_LABELS_ADMIN } from "app/main/consts/tripInvoiceConsts";
import moment from "moment";
import { getWarningMsg } from "app/main/utils/tripsUtils";

function formatText(value) {
	const v = value || value === 0 ? value.toString() : value;
	return v && v !== "" ? v : "-";
}

/**
 * Returns a formatted fee from a load, if fee is flat, then it will add extra info ("flat fee")
 * otherwise it will add the fee percentage
 * @param {*} load Object that contains load/trip information
 * @returns formatted fee
 */
function formatFeeFromLoad(load) {
	let formattedFee;
	if (load?.fee) {
		formattedFee = formatCurrency(load?.fee);
		formattedFee += load?.isFlatFeeApplied ? " (Flat Fee)" : ` (${load?.feePercentage}%)`;
	}
	return formattedFee;
}

function TripDescription({
	load,
	invoice,
	classes,
	isPublicView = false,
	priceRestricted = false,
	showTruckInfo = true,
}) {
	const dispatch = useDispatch();

	const subaccount = useSelector(({ auth }) => auth.account.subAccount);
	const isMainHandler = subaccount?.list?.length > 0;

	const isExternal = useMemo(() => isRoleExternal(), []);
	const status = !isExternal ? load?.status : load?.status !== "Pending" ? load?.status : "Pending Ratecon";
	const active =
		status !== "Pending Ratecon" &&
		status !== "Booked" &&
		status !== "Delivered" &&
		status !== "Missing Data" &&
		status !== "Not Booked";

	const hidePrice = useMemo(
		() => priceRestricted || load?.hidePrice || load?.isRateRestricted,
		[priceRestricted, load]
	);

	const statusConfig = useMemo(() => {
		let config = { value: formatText(status), label: "Status" };
		const warn = load?.warnings?.map?.((type) => getWarningMsg(type))?.filter((msg) => msg);
		if (!warn?.length) return config;

		return {
			...config,
			tooltip: {
				value: {
					message: `Required missing fields: ${warn?.join?.(", ")}`,
					icon: "warning",
				},
			},
		};
	}, [load?.warnings, status]);

	const formatLoad = [
		[
			statusConfig,
			...(active
				? [
						{ value: formatText(load?.last_location), label: "Last Location" },
						...(load?.eta ? [{ value: formatText(moment(load?.eta).format("MMM Do YYYY, h:mma")), label: "ETA" }] : []),
						...(load?.miles_left > 0 ? [{ value: formatText(load?.miles_left), label: "Miles Left" }] : []),
						...(load?.next_stop ? [{ value: load?.next_stop, label: "Next Stop", newLine: true }] : []),
				  ]
				: []),
			...(status === "Delivered"
				? [
						{
							value: formatDate(load.marked_delivered_date),
							label: "Updated",
							tooltip: { value: { message: "Date and time when this trip was marked as deliveted", icon: "info" } },
						},
				  ]
				: []),
		],
	];

	const invoiceStatus = INVOICE_STATUSES_LABELS[invoice?.status] ?? INVOICE_STATUSES_SP_LABELS_ADMIN[invoice?.status];
	const formatInvoice = [
		[
			{ value: formatText(invoiceStatus), label: "Status" },
			{ value: formatCurrency(invoice?.amount), label: "Total" },
		],
	];

	const formatBroker = [
		[{ value: formatText(load?.broker__view?.label), label: "Name" }],
		[{ value: formatText(load?.phone_broker), label: "Phone" }],
		[{ value: formatText(load?.mail_broker), label: "Email" }],
		load?.broker_contact ? [{ value: formatText(load?.broker_contact), label: "Contact" }] : [],
		load?.customerId ? [{ value: formatText(load?.customerId), label: "EIN" }] : [],
	];

	const formatDetails = [
		[
			{
				value: load?.miles_for_state ? (
					<Tooltip
						classes={{ tooltip: "text-12 font-normal" }}
						title={
							<>
								<Typography className="text-12 ml-2 font-semibold">Miles per state</Typography>
								<List className="w-full m-0 p-0" style={{ textAlign: "left" }}>
									{load?.miles_for_state?.map((value) => (
										<ListItem key={value} className=" m-0 p-0 ">
											<ListItemText
												primary={
													<Typography color="inherit" className="text-12 m-0 p-0 font-light">
														{`${value.StCntry}: ${Math.round(value.Total)}`}
													</Typography>
												}
											/>
										</ListItem>
									))}
								</List>
							</>
						}
					>
						<div className="flex justify-between flex-row items-center ml-4 pt-1">
							<Typography className="text-12 mr-4 font-semibold">{formatText(load?.emptymiles)}</Typography>
							<Typography className="text-11 mr-4 text-grey">empty</Typography>
							<Typography className="text-12 mr-4 font-semibold"> + </Typography>
							<Typography className="text-12 mr-4 font-semibold">{formatText(load?.miles)}</Typography>
							<Typography className="text-11 mr-4 text-grey">loaded</Typography>
							<Typography className="text-12 mr-4 font-semibold"> = </Typography>
							<Typography className="text-12 mr-4 font-semibold">{formatText(load?.total_miles)}</Typography>
							<Icon className="text-16">info</Icon>
						</div>
					</Tooltip>
				) : (
					`${formatText(load?.emptymiles)} (Empty) + ${formatText(load?.miles)} (Loaded) = ${formatText(
						load?.total_miles
					)}`
				),
				label: "Miles",
			},
			{ value: hidePrice ? "-" : formatCurrency(load?.rate), label: "Rate" },
			...(!load?.isDefaultPlan
				? [
						{
							value: formatFeeFromLoad(load) ?? "-",
							label: "Fee",
						},
				  ]
				: []),
			{ value: hidePrice ? "-" : formatCurrency(load?.rpm), label: "RPM" },
			{ value: hidePrice ? "-" : formatCurrency(load?.profit), label: "Est Profit" },
			...(load?.cargo_type ? [{ value: formatText(load?.cargo_type), label: "Cargo Type" }] : []),
			{ value: formatText(load?.weight), label: "Weight" },
			...(load?.pallets ? [{ value: formatText(load?.pallets), label: "Pallets" }] : []),
			...(load?.temperature && load?.temperature !== "None"
				? [{ value: formatText(load?.temperature), label: "Temperature" }]
				: []),
		],
	];

	const renderInfo = (info) => {
		const value = info.dialogBuilder ? (
			<Link onClick={() => dispatch(openFormDialog(info.dialogBuilder))}>{info.value}</Link>
		) : (
			info.value
		);

		return (
			<div className="w-full">
				{renderDataItem(info.label, value, undefined, { vertical: info.newLine, tooltip: info.tooltip })}
			</div>
		);
	};

	const logisticCost = tripHelpers.mapLogisticsCosts(load?.logistics_costs);
	const extraContacts = tripHelpers.mapExtraContact(load?.extraContacts);

	const renderComponent = (titleSection = "", array = [], text = "", labels = [], actions = []) => {
		return (
			<DisplaySection
				classes={{ root: "mx-0 mt-0 mb-20", divider: "mb-0" }}
				title={titleSection}
				labels={labels}
				actions={actions}
			>
				{array.map
					? array.map((data, index) => (
							<div key={index} className={"w-full flex flex-col"}>
								{data.map((dataValue, index2) => (
									<Fragment key={index2}>{renderInfo(dataValue)}</Fragment>
								))}
							</div>
					  ))
					: array}
				{array.length === 0 &&
					(text.split("\n").length > 0 ? (
						<div className="flex flex-col w-full pt-6">
							{text.split("\n").map((paragraph, i) => {
								return paragraph?.trim?.()?.length > 0 ? (
									<Typography key={"paragraph-" + i} className="text-12 md:text-13 break-words pr-12">
										{paragraph?.trim?.()}
									</Typography>
								) : null;
							})}
						</div>
					) : (
						<Typography className="text-12 md:text-13 break-words pr-12">{text?.trim?.() ?? "-"}</Typography>
					))}
			</DisplaySection>
		);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-16">
			<div className={` ${classes?.root} w-full md:w-6/12 w-full `}>
				<div className="flex flex-col md:flex-row md:space-x-16">
					<div className="flex flex-col w-full">
						{showTruckInfo &&
							load?.carrier__view?.value &&
							renderComponent(
								"Truck & Driver",
								<LoadSearchSummaryCarrierView
									isPublicView={isPublicView}
									carrier={{
										id: load?.carrier__view?.value,
										name: load?.carrier__view?.label,
										hidden: isExternal && !isMainHandler,
									}}
									truck={{ id: load?.truck__view?.value, name: load?.truck__view?.label }}
									trailer={{ id: load?.truck_trailer__view?.value, name: load?.truck_trailer__view?.label }}
									primaryDriver={{
										id: load?.driver__view?.value,
										name: load?.driver__view?.label,
										phone: load?.profitData?.data?.driver?.phone,
									}}
									investor={{ id: load?.investor__view?.metadata?.userId, name: load?.investor__view?.label }}
								/>
							)}
						{renderComponent(load?.load_id ? `Load #${load?.load_id}` : `Load`, formatDetails, "", [
							...(load?.equipment ? [{ label: createTypeView(load?.equipment), classes: { label: " pl-0 " } }] : []),
							...(load?.size && load?.size !== "Not Specified" && load?.size !== "Unknown"
								? [{ label: createTypeView(load?.size + "'"), classes: { label: " pl-0 " } }]
								: []),
						])}
						{renderComponent(load?.mcnumber ? `Broker MC ${formatText(load?.mcnumber)}` : `Broker`, formatBroker, "", [
							...(load?.smartpay_eligible
								? [
										{
											label: createTooltip(
												<div className="flex flex-row">
													<Typography className="text-primary text-13 font-bold">Smart</Typography>
													<Typography className="text-secondary text-13 font-bold">Pay</Typography>
													<Icon className={`text-15 ml-2 mt-2 text-secondary`}>info</Icon>
												</div>,
												"This trip is eligible for SmartPay"
											),
										},
								  ]
								: []),
						])}
						{extraContacts?.length > 0 && (
							<DisplaySection classes={{ root: "mx-0" }} title="Extra Contacts">
								{extraContacts.map((data, index) => (
									<div key={index} className={"w-full flex flex-row pb-4 pt-0 " + (index === 0 ? " -mt-8 " : "")}>
										{extraContacts.length > 1 && (
											<Typography className="text-12 md:text-13 font-semibold pr-3 mr-3 pt-10">
												{index + 1 + ")"}
											</Typography>
										)}
										<div className={"w-full flex flex-col "}>
											{data.map((elem, index2) => (
												<div key={"contact" + index2}>
													{renderDataItem(elem.label, elem.value, null, { vertical: elem.vertical })}
												</div>
											))}
										</div>
									</div>
								))}
							</DisplaySection>
						)}
						{load?.tonu && (
							<div className="flex">
								<Typography className="text-13 md:text-14 text-gray-600 pr-2 mr-3 pt-6">TONU</Typography>
								<Icon className={`text-24 mt-3 text-red-800`}>error_outline</Icon>
							</div>
						)}
						<TripLogisticsCostsComponent
							logisticsCosts={logisticCost}
							title={"Additions and Deductions"}
							styleClasses={{ root: "mx-0 mt-0", divider: "mb-4" }}
						/>
						{renderComponent("Notes", [], load?.comment?.length > 0 ? load?.comment : "No information")}
						{renderComponent(
							"Files",
							!load?.rate_con_file__flag && !load?.pod_files?.length ? (
								[]
							) : (
								<TripFilesView trip={load} isPublicView={isPublicView} priceRestricted={priceRestricted} />
							),
							!load?.rate_con_file__flag && !load?.pod_files?.length ? "No attachments" : ""
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full md:w-6/12">
				{renderComponent(`Trip`, formatLoad)}
				{invoice && renderComponent(`Invoice ${invoice?.carrierRefLabel}`, formatInvoice)}
				<DisplaySection classes={{ root: "mx-0 mt-0", divider: "mb-0" }} title="Route">
					<TripStopPanel classes={{ root: "w-full" }} load={load} />
				</DisplaySection>
			</div>
		</div>
	);
}

export default TripDescription;
