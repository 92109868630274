// Utils
import { formatPaymentMethodErrorReason } from "app/main/utils/tableUtils";

const paymentErrorsList = {
	urlGET: "billing/payment-methods/errors",
	idKey: "source_id",
	listenEvent: "billingRevision",
	segmentKey: "ADMIN_PAYMENT_METHOD_ERRORS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
		},
		table: {
			variant: "skinny",
			orderBy: ["created_at"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		filters: {
			search: true,
			items: [
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					translate: "",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						params: {
							sortOrder: "asc",
							sortBy: "carrierid",
						},
					},
				},
				{
					key: "start_date",
					type: "picker",
					label: "Start Date",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
				{
					key: "end_date",
					type: "picker",
					label: "End Date",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					picker: {
						format: "MMMM do yyyy",
						disableFuture: true,
						notClearable: false,
						type: "date",
						classes: {},
					},
				},
			],
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		items: [
			{
				key: "_id",
				type: "id",
				label: "ID",
				grid: { headerTitle: true, valuePefix: "ID " },
				group: "internal",
			},
			{
				key: "source_id",
				type: "id",
				label: "Source ID",
				grid: { headerSubtitle: true, valuePefix: "Source ID " },
				group: "internal",
			},
			{ key: "carrier_id", type: "id", label: "Carrier ID", group: "internal" },
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
					showCarrierAccountType: true,
				},
				grid: { headerTitle: true, valuePefix: "Carrier " },
			},
			{
				type: "text",
				key: "last4",
				label: "Last 4",
				builder: (item) => (item.type === "card" ? item.last4_card : item.last4),
			},
			{
				type: "text",
				key: "reason",
				label: "Reason",
				maxLengthStart: 80,
				builder: (item) => formatPaymentMethodErrorReason(item.reason),
			},
			{
				type: "date",
				label: "Date",
				key: "created_at",
			},
		],
	},
};

export default paymentErrorsList;
