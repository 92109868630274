const statesOptions = [
	{ state: "AL", label: "Alabama", value: "AL" },
	{ state: "AK", label: "Alaska", value: "AK" },
	{ state: "AZ", label: "Arizona", value: "AZ" },
	{ state: "AR", label: "Arkansas", value: "AR" },
	{ state: "CA", label: "California", value: "CA" },
	{ state: "CO", label: "Colorado", value: "CO" },
	{ state: "CT", label: "Connecticut", value: "CT" },
	{ state: "DE", label: "Delaware", value: "DE" },
	{ state: "FL", label: "Florida", value: "FL" },
	{ state: "GA", label: "Georgia", value: "GA" },
	{ state: "HI", label: "Hawaii", value: "HI" },
	{ state: "ID", label: "Idaho", value: "ID" },
	{ state: "IL", label: "Illinois", value: "IL" },
	{ state: "IN", label: "Indiana", value: "IN" },
	{ state: "IA", label: "Iowa", value: "IA" },
	{ state: "KS", label: "Kansas", value: "KS" },
	{ state: "KY", label: "Kentucky", value: "KY" },
	{ state: "LA", label: "Louisiana", value: "LA" },
	{ state: "ME", label: "Maine", value: "ME" },
	{ state: "MD", label: "Maryland", value: "MD" },
	{ state: "MA", label: "Massachusetts", value: "MA" },
	{ state: "MI", label: "Michigan", value: "MI" },
	{ state: "MN", label: "Minnesota", value: "MN" },
	{ state: "MS", label: "Mississippi", value: "MS" },
	{ state: "MO", label: "Missouri", value: "MO" },
	{ state: "MT", label: "Montana", value: "MT" },
	{ state: "NE", label: "Nebraska", value: "NE" },
	{ state: "NV", label: "Nevada", value: "NV" },
	{ state: "NH", label: "New Hampshire", value: "NH" },
	{ state: "NJ", label: "New Jersey", value: "NJ" },
	{ state: "NM", label: "New Mexico", value: "NM" },
	{ state: "NY", label: "New York", value: "NY" },
	{ state: "NC", label: "North Carolina", value: "NC" },
	{ state: "ND", label: "North Dakota", value: "ND" },
	{ state: "OH", label: "Ohio", value: "OH" },
	{ state: "OK", label: "Oklahoma", value: "OK" },
	{ state: "OR", label: "Oregon", value: "OR" },
	{ state: "PA", label: "Pennsylvania", value: "PA" },
	{ state: "RI", label: "Rhode Island", value: "RI" },
	{ state: "SC", label: "South Carolina", value: "SC" },
	{ state: "SD", label: "South Dakota", value: "SD" },
	{ state: "TN", label: "Tennessee", value: "TN" },
	{ state: "TX", label: "Texas", value: "TX" },
	{ state: "UT", label: "Utah", value: "UT" },
	{ state: "VT", label: "Vermont", value: "VT" },
	{ state: "VA", label: "Virginia", value: "VA" },
	{ state: "WA", label: "Washington", value: "WA" },
	{ state: "WV", label: "West Virginia", value: "WV" },
	{ state: "WI", label: "Wisconsin", value: "WI" },
	{ state: "WY", label: "Wyoming", value: "WY" },
];

const toStateViews = (stateIds) => {
	let states__view = [];
	stateIds?.forEach((stateId) => {
		let stateFound = statesOptions.find((state) => state.value === stateId);
		if (!stateFound) return;
		states__view.push({ value: stateId, label: stateFound.label, description: stateFound.state });
	});
	return states__view;
};

const toShortStateViews = (longState) => {
	let stateFound = statesOptions.find((state) => state.label === longState);
	return stateFound?.state;
};

const getAllStates = () => {
	return statesOptions.map((state) => ({ state: state.state, label: state.label }));
};

const toBlackListCities = ["Crane, IN, USA"];

export { toStateViews, toShortStateViews, toBlackListCities, getAllStates };
