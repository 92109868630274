import { Link } from "@material-ui/core";

import { InvoiceTutorialDescription, InvoiceTutorialSidebar } from "./resourceComponents/invoiceTutorialComponents";
import { PayrollTutorialDescription, PayrollTutorialSidebar } from "./resourceComponents/payrollTutorialComponents";
import invoiceImg from "assets/images/onboarding/invoice_onboarding.png";

/** 
  if you want to put a information in column in smarthop list you need to put as a key the following combination
  segmentKey + "_" + key. if you want to provide a resource in the widget just use as a key the name of the widget 
  for example WIDGET_315ce2ce
 */

export const resourcesKeys = {
	INITIAL_ONBOARDING: {
		link: "https://youtu.be/fwypitbkDsk",
	},
	PAYROLL_ONBOARDING: {
		link: "https://youtu.be/j2eA7UgffCs",
	},
	DISPATCHING_ONBOARDING: {
		link: "https://youtu.be/5Gf7PAe5s4s",
	},
	SEARCH_ONBOARDING: {
		link: "https://youtu.be/GL3g-sbh-9U",
	},
	PROFILE_MANAGEMENT_ONBOARDING: {
		link: "https://youtu.be/_-Q8O8bz8Xw",
	},
	CARRIER_COMPANY_ONBOARDING: {
		link: "https://youtu.be/ou0OCEYn3gU",
	},
	DRIVERS_ONBOARDING: {
		link: "https://youtube.com/watch?ab_channel=SmartHop&v=ERz3vyAXuxs",
	},
	BROKER_ONBOARDING: {
		link: "https://youtu.be/mWu7ljtCvxI",
	},
	COST_ONBOARDING: {
		link: "https://youtu.be/OTRODeOUuM8",
	},
	TRUCKS_ONBOARDING: {
		link: "https://youtu.be/dYboGREDazM",
	},
	HOMEPAGE_TUTORIAL: {
		link: "https://youtu.be/LcpYRRMH0u0",
		description: "Hi! Welcome to SmartHop. Let me show you around!",
	},
	SEARCH_TUTORIAL: {
		link: "https://youtu.be/iegRbK8I5RI",
		description: "The SmartHop search experience is unique and expansive. Let me show you around!",
	},
	LOAD_SCOUT_TUTORIAL: {
		link: "https://www.youtube.com/watch?v=SO32x8tKrXk",
		description: "Load Scout",
	},
	DISPATCHING_TUTORIAL: {
		link: "https://youtu.be/6_Rs8orXu7U",
	},
	DRIVERS_TUTORIAL: {
		link: "https://youtu.be/ErA2Pqv5u1U",
		description: "Customize your SmartHop experience. Create your first driver now!",
	},
	TRUCKS_NOTICE: {
		description: "Woah, Woah, Woah, every truck needs a driver! Create your driver first",
	},
	TRUCKS_TUTORIAL: {
		link: "https://youtu.be/QpRXfU3ab4c",
		description: "Every driver needs a truck! Create your first truck",
	},
	SEARCH_RESULT_DISPATCHER_profit_trip_carrier: {
		link: "https://youtu.be/hRFEeFQQlEk",
		description: "Profit is based on your own numbers and cost structures.",
	},
	SEARCH_RESULT_DISPATCHER_rank_mci: {
		link: "https://youtu.be/Vorcab1nkUs",
		description: "MPI scores let you know how profitable a market currently is.",
	},
	SEARCH_RESULT_DISPATCHER_ppd: {
		description: "Profit per Day.",
	},
	SEARCH_RESULT_DISPATCHER_smartpayElegible: {
		link: "https://www.smarthop.com/smartpay-factoring",
		description: "Factoring provided by SmartHop with industry-leading rates",
	},
	SEARCH_EXPIRED_POPUP: {
		description:
			"Our system continously self refresh after launching a search. After 10 minutes we need a little help, just hit search again.",
	},
	SEARCH_BOOK_POPUP: {
		link: "https://youtu.be/hsCCyMM3CCs",
		description: "Book now loads dont require a phone call, book these loads digitally right NOW!",
	},
	SEARCH_TRUCK_POPUP: {
		description: "Customize your search experience with driver and truck profiles.",
	},
	DRIVER_FORM_DRIVER_TYPE_POPUP: {
		description: "Profit is displayed differently based on your selection.",
	},
	DRIVER_FORM_PAYMENT_TYPE_POPUP: {
		description: "Payment type is connected to payroll and profit amounts.",
	},
	DRIVER_FORM_EXTRA_DROP_PAYMENT_POPUP: {
		description: "This number is connected to payroll and profit numbers you see.",
	},
	PAYROLL_TIMEZONE_START_OF_WEEK: {
		description:
			"Applies to carriers with weekly or bi-weekly pay periods. Controls when the system will auto close the pay period if defined on the user profile.",
	},
	SEARCH_BROKERS_POPUP: {
		description: "You can search by broker's name or MC number",
	},
	PAYROLL_TUTORIAL: {
		title: "Payroll Info",
		video: "https://youtu.be/j2eA7UgffCs",
		format: "COMPLETE",
		description: <PayrollTutorialDescription />,
		sidebar: <PayrollTutorialSidebar />,
	},
	INVOICE_TUTORIAL: {
		title: "Invoice Info",
		image: invoiceImg,
		format: "COMPLETE",
		description: <InvoiceTutorialDescription />,
		sidebar: <InvoiceTutorialSidebar />,
	},
	EMAIL_ALIAS_INFO: {
		description: (
			<>
				<div className="mb-12">
					Adding an email alias will allow this individual to email RateCons directly to{" "}
					<a href="mailto:ratecon@smarthop.co">ratecon@smarthop.co</a> on your behalf.
				</div>
				<div className="mb-12">
					It will NOT provide access to the SmartHop application. If you would like to provide application access to
					this individual, <Link onClick={() => (window.location = "/profile/management")}>click here.</Link>
				</div>
				<div>
					<Link onClick={() => window.open("https://www.youtube.com/watch?v=BDxKDFdKPcI")} className="font-medium">
						Learn more about this feature
					</Link>
				</div>
			</>
		),
	},
	ELIGIBILITY_CONDITION: {
		description:
			"Add a setting to enable payroll eligibility based on option selected. This setting will be used to determine if the trip is eligible for payroll.",
	},
};
