import CellFirstRow from "./CellFirstRow";
import CellSecondRow from "./CellSecondRow";
import CellEmptyRow from "./CellEmptyRow";
import CellContainerRow from "./CellContainerRow";

import { DISTRIBUTION_PRIORITY, PAYROLL_PAYMENT_TYPE_LABEL } from "app/main/consts";

const PAYEE_CATEGORIES_LABELS = {
	COMPANY_DRIVER: "Company Driver",
	OWNER_OPERATOR: "Owner Operator",
	DISPATCHER: "Dispatcher",
	INVESTOR: "Investor",
	MANAGER: "Manager",
	UNKNOWN: "Unknown",
};

function PayeeCellView(props) {
	const mobile = props.mobile;
	const variant = props.variant;
	const config = props.config;
	const phone = props.view?.metadata?.phone;
	const email = props.view?.metadata?.email;

	const paymentType =
		props.view?.metadata?.payment_type ?? props.view?.metadata?.payroll_settings?.settings?.payment_type;

	const distributionPriority = props.view?.metadata?.payroll_settings?.settings?.distribution_priority;
	const distributionPriorityValue = props.view?.metadata?.payroll_settings?.settings?.distribution_priority_value;

	const carrier = props.view?.metadata?.carrier ?? props.item?.payee?.carrier;
	const active = !props.view?.metadata?.deleted;
	const payeeType = props.view?.metadata?.payee_type;

	const payeeCategory = props.view?.metadata?.payee_category;

	if (!props.view?.value) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}

	const dialog = {
		viewId: "USER_DETAILS_VIEW",
		dataIds: {
			carrierId: carrier,
			...(payeeType === "DRIVER" ? { driverId: props.view?.value } : {}),
			...(payeeType === "USER" ? { userId: props.view?.value } : {}),
		},
	};

	return (
		<CellContainerRow
			mobile={mobile}
			variant={variant}
			first={
				<div className="flex flex-wrap">
					<CellFirstRow mobile={mobile} view={props.view} variant={variant} dialog={dialog} />
					{config?.showStatus__top && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700" : " text-red-600"}
							variant={variant}
							classes={{ label: "ml-4" }}
						/>
					)}
				</div>
			}
			second={
				<div className="flex flex-wrap">
					{config?.showPayeeType && (
						<CellSecondRow
							mobile={mobile}
							icon={"account_box"}
							label={PAYEE_CATEGORIES_LABELS[payeeCategory] ?? "Unknown"}
							variant={variant}
						/>
					)}
					{config?.showPhone && (
						<CellSecondRow mobile={mobile} icon={"local_phone"} label={phone ?? "No Phone"} variant={variant} />
					)}
					{config?.showEmail && (
						<CellSecondRow mobile={mobile} icon={"mail"} label={email ?? "No Email"} variant={variant} />
					)}
					{config?.showStatus && !active && (
						<CellSecondRow
							mobile={mobile}
							icon={active ? "done" : "close"}
							label={active ? "Active" : "Inactive"}
							color={active ? " text-green-700" : " text-red-600"}
							variant={variant}
						/>
					)}
					{config?.showPaymentType && payeeType === "DRIVER" && (
						// TODO: Refactor to avoid making distinctions by payee type, info must come from the backend
						<CellSecondRow
							mobile={mobile}
							icon={PAYROLL_PAYMENT_TYPE_LABEL[paymentType] ? "attach_money" : "money_off"}
							label={PAYROLL_PAYMENT_TYPE_LABEL[paymentType] ?? "No Payment Type"}
							variant={variant}
							color={PAYROLL_PAYMENT_TYPE_LABEL[paymentType] ? " text-blue-700" : " text-red-600"}
						/>
					)}

					{config?.showPaymentType && payeeType === "USER" && (
						// TODO: Refactor to avoid making distinctions by payee type, info must come from the backend
						<CellSecondRow
							mobile={mobile}
							icon={PAYROLL_PAYMENT_TYPE_LABEL["PAY_BY_PERCENTAGE"] ? "attach_money" : "money_off"}
							label={distributionPriorityValue ? DISTRIBUTION_PRIORITY[distributionPriority] : "No Payment Type"}
							variant={variant}
							color={distributionPriorityValue ? " text-blue-700" : " text-red-600"}
						/>
					)}
				</div>
			}
		/>
	);
}

export default PayeeCellView;
