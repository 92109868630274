import { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Icon, Paper, Typography } from "@material-ui/core";
import useMobileDetect from "@smarthop/hooks/useMobileDetect";
import { SmarthopConfirmDialog } from "@smarthop/form";

import { cancelFile, deleteOneFile } from "app/store/upload/filesSlice";
import { MULTIFILE_STATUS } from "app/main/multi-file-upload/MultiFileUploadView";

// Services
import { getUserTier } from "app/services/LoginService";

// Components
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

function UploadStatus({ uploadedFile, onCancel, onApply }) {
	const isNotReady = uploadedFile?.status !== "ready" && uploadedFile?.status !== "ready_convert_bol";

	return (
		<Paper elevation={6} className="p-20">
			{isNotReady ? (
				<>
					<div className={`flex flex-row items-center border-b-1 pb-12 mb-16`}>
						<Typography className="text-16">Transcribing RC</Typography>
						<div className="w-1 h-16 bg-grey-400 mx-12" />
						<div className={`flex flex-row text-secondary animate-pulse`}>
							<Icon className={`text-20 mb-2 mr-3`}>{MULTIFILE_STATUS[uploadedFile?.status]?.icon}</Icon>
						</div>
						<Typography className={`text-16 text-secondary`}>
							{MULTIFILE_STATUS[uploadedFile?.status]?.label ?? "Oops..."}
						</Typography>
					</div>
					<Box className="my-8">
						<Typography className="hidden lg:flex">
							<Icon className="mr-8 mt-2" style={{ verticalAlign: "middle", display: "inline-block" }}>
								close_fullscreen
							</Icon>
							Use the minimize icon to keep the process running and come back to it later.
						</Typography>
						<Typography className="lg:hidden">
							Press cancel to <b>KEEP THE RC</b> and transcribe the document manually.
						</Typography>
					</Box>
				</>
			) : (
				<>
					<Typography variant="h6">
						{uploadedFile?.status === "ready" ? "Your RC has been transcribed" : "We detected this file is not an RC"}
					</Typography>
					<Box className="leading-8 mt-8 mb-16">
						<Typography>
							{uploadedFile?.status === "ready"
								? "Choose apply, to fill the form with information extracted from your RC."
								: "Choose add to trip, to upload the file as an additional document in the trip."}
						</Typography>
					</Box>
				</>
			)}
			<Box className="flex gap-5 justify-end mt-8">
				<Button onClick={onCancel} variant="contained">
					Cancel
				</Button>
				<Button
					onClick={() => onApply(uploadedFile?.status)}
					variant="contained"
					color="secondary"
					disabled={isNotReady}
				>
					{uploadedFile?.status === "ready_convert_bol" ? "Add to Trip" : "Apply"}
				</Button>
			</Box>
		</Paper>
	);
}

const ParsingPopup = forwardRef(
	({ uploadedFile, handleApplyParsing, handleUploadDocument, showParsingPopUp, dataIds }, ref) => {
		const [applying, setApplying] = useState(false);
		const [transcribe, setTranscribe] = useState(false);
		const [dialogOpen, setDialogOpen] = useState(false);
		const [paywallDialog, setPaywallDialog] = useState({});

		useEffect(() => {
			if (uploadedFile?.status === "uploading") {
				setTranscribe(false);
				setDialogOpen(true);
			}
			if (dataIds?.continueAutoTranscribe) {
				handleClose();
			}
			// eslint-disable-next-line
		}, [uploadedFile?.status, dataIds?.continueAutoTranscribe]);

		const dispatch = useDispatch();
		const isMobile = useMobileDetect();

		const onCancel = async () => {
			setApplying(true);
			dispatch(cancelFile({ fileId: uploadedFile?.fileId, uploadId: uploadedFile?.metadata?.uploadId }));
			dispatch(
				deleteOneFile({
					uploadId: uploadedFile?.metadata?.uploadId,
					fileId: uploadedFile?.fileId,
					carrierId: uploadedFile?.carrierId,
				})
			);
			setApplying(false);
			setTranscribe(false);
		};

		const handleClose = (_, reason) => {
			if (getUserTier() === "TIER_STARTER") {
				setPaywallDialog({
					open: true,
					dataIds: {
						type: "rc_transcription_restriction",
						data: {
							callbackKey: "TRIP_EDIT_VIEW",
							dataIdsInherited: {
								...dataIds,
								rate_con_file: [uploadedFile.fileId],
								rate_con_file__flag: true,
								uploadId: uploadedFile?.metadata?.uploadId,
								continueAutoTranscribe: true,
							},
						},
					},
				});
				return;
			}
			if (reason !== "backdropClick") {
				setTranscribe(true);
				setDialogOpen(false);
				setApplying(false);
				setTimeout(() => {
					ref?.current?.scrollIntoView();
				}, 1000);
			}
		};

		const handleCancel = () => {
			onCancel();
			setDialogOpen(false);
		};

		const onApply = async (status) => {
			setApplying(true);
			if (status === "ready") {
				await handleApplyParsing();
			} else {
				await handleUploadDocument();
			}
			setApplying(false);
		};

		return (
			<>
				{showParsingPopUp && !dialogOpen && transcribe ? (
					<Box
						className={`absolute ${!isMobile ? "inset-1/3 w-1/3" : "w-full"} ${
							applying ? "pointer-events-none opacity-60" : ""
						}`}
						zIndex={100}
						ref={ref}
					>
						<UploadStatus uploadedFile={uploadedFile} isMobile={isMobile} onCancel={onCancel} onApply={onApply} />
					</Box>
				) : null}

				<SmarthopConfirmDialog
					open={dialogOpen && !!uploadedFile?.fileId}
					title={"RC Uploaded Successfully!"}
					handleClose={handleClose}
					handleAccept={handleCancel}
					closeMsg={"Auto Transcribe"}
					acceptMsg={"Proceed Manually"}
				>
					<Typography className="text-16">
						Would you like to auto transcribe the RC or continue editing the trip manually?
					</Typography>
				</SmarthopConfirmDialog>

				<PaywallDialog
					data={paywallDialog}
					onHandlerPaywall={() => {
						setDialogOpen(false);
						setPaywallDialog({});
					}}
					onHandlerClosePaywall={() => setPaywallDialog({})}
				/>
			</>
		);
	}
);

export default ParsingPopup;
