import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import PageTabWrapper from "app/main/common/PageTabWrapper";
import { openFormDialog, refreshDialog } from "app/store/tools/formDialogSlice";

// Services
import { getUserDetails, getUserDriverDetails, getUserInternalDetails } from "app/services/usersServices";
import { getCarrierId, getUserId } from "app/services/LoginService";
import {
	getCarrierPayrollSettings,
	getCarrierFuelSettings,
	getCarrierInvoiceSettings,
} from "app/services/carrierServices";

// Nested Components
import CarrierUsersEditView from "app/main/profile/carriers/CarrierUsersEditView";
import EnterpriseUsersPayrollPage from "app/main/profile/users/EnterpriseUsersPayrollPage";
import DriverTrucksPage from "app/main/profile/trucks/DriverTrucksPage";
import UserDetailsPage from "app/main/profile/users/UserDetailsPage";

const UserDetailsView = forwardRef((props, ref) => {
	const dispatch = useDispatch();

	const nativeMobile = props.nativeMobile;

	useEffect(() => {
		props?.setTitle?.("User Information");
		// eslint-disable-next-line
	}, []);

	const dataRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);
	const user = useSelector(({ auth }) => auth.user);

	const [carrierId, setCarrierId] = useState(props.carrierId ?? props?.dataIds?.carrierId ?? getCarrierId());
	const [driverId, setDriverId] = useState(props.userId ?? props?.dataIds?.driverId);
	const [userId, setUserId] = useState(props.userId ?? props?.dataIds?.userId);

	const [loading, setLoading] = useState(true);
	const [loadingError, setLoadingError] = useState(null);
	const [data, setData] = useState(null);

	// FIXME investor flag should not be passed into componets of payroll,
	// component should determine how to render UI based on ID
	const isOwner = data?.userData?.user?.role === "CARRIER_OWNER";
	const isDriver = data?.userData?.user?.role === "CARRIER_DRIVER";
	const isCarrierDispatcher = data?.userData?.user?.role === "CARRIER_DISPATCHER";
	const isInvestor = data?.userData?.permissions.permission_investor_on;
	const isCarrierPayrollEnabled = data?.payrollSettings?.payroll_module_enabled;
	const isUserPayrollEnabled = !!data?.userData?.payroll?.enabled;
	const hasParent = data?.userData?.user?.parent;
	const isUserRoleSupportsPayroll =
		isInvestor || ["CARRIER_DRIVER", "CARRIER_DISPATCHER", "CARRIER_OWNER"].includes(data?.userData?.user?.role);

	const viewerIsHimself = getUserId() === userId;
	const viewerCanEdit =
		(user.roleType === "INTERNAL" && !hasParent) ||
		// Can edit himself (ONLY CARRIER OWNER)
		(user.roleType === "EXTERNAL" && ["CARRIER_OWNER"].includes(user.role) && !hasParent && viewerIsHimself) ||
		// Can edit all user
		(user.roleType === "EXTERNAL" &&
			["CARRIER_OWNER", "CARRIER_MANAGER"].includes(user.role) &&
			!hasParent &&
			!viewerIsHimself &&
			!isOwner) ||
		// Can edit driver users only
		(user.roleType === "EXTERNAL" && ["CARRIER_DISPATCHER"].includes(user.role) && isDriver) ||
		// Can edit dispatcher users only
		(user.roleType === "EXTERNAL" &&
			["CARRIER_OWNER"].includes(user.role) &&
			isCarrierDispatcher &&
			carrierId === user.carrier);

	useImperativeHandle(
		ref,
		() => {
			const editIntent = { viewId: "CARRIER_USER_EDIT_VIEW", dataIds: { carrierId, driverId, userId, mode: "EDIT" } };
			dispatch(refreshDialog());
			return {
				edit:
					!loading && viewerCanEdit && carrierId
						? { onClick: () => dispatch(openFormDialog(editIntent)), title: "Edit" }
						: null,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[carrierId, driverId, userId, viewerCanEdit, loading]
	);

	useEffect(() => {
		setLoading(true);
		(async () => {
			try {
				const userData = await (driverId
					? getUserDriverDetails(driverId, { version: 2 })
					: carrierId
					? getUserDetails(userId, carrierId, { version: 2 })
					: getUserInternalDetails(userId, { version: 2 }));

				if (!userId) setUserId(userData.user._id);
				if (!carrierId && userData.user.roleType === "EXTERNAL") setCarrierId(userData.user.carrier);
				if (!driverId && userData.user.roleType === "EXTERNAL") setDriverId(userData.user.driver);

				const [payrollSettings, fuelSettings, invoiceSettings] =
					userData.user.roleType === "EXTERNAL" && userData.user.carrier
						? await Promise.all([
								// TODO: aggregate carrier settings into a single endpoint
								getCarrierPayrollSettings(userData.user.carrier),
								getCarrierFuelSettings(userData.user.carrier),
								getCarrierInvoiceSettings(userData.user.carrier),
						  ])
						: [null, null];

				setData({ userData, payrollSettings, fuelSettings, invoiceSettings });
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
			setLoading(false);
		})();
		// eslint-disable-next-line
	}, [dataRevision]);

	if (loadingError || loading) {
		return (
			<div className={"flex w-full flex-col items-center justify-center h-512 "}>
				{loadingError ? (
					<Typography color="error" className="text-red text-13 font-light mx-20 py-6 md:pb-48">
						{loadingError}
					</Typography>
				) : loading ? (
					<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
						Loading...
					</Typography>
				) : null}
			</div>
		);
	}

	return (
		<div style={{ minHeight: "75vh" }}>
			<PageTabWrapper
				isView
				tabs={[
					...(carrierId
						? [
								{
									label: "Details",
									component: (
										<CarrierUsersEditView
											isView
											carrierId={carrierId}
											userId={userId}
											driverId={driverId}
											dataIds={{ mode: "VIEW" }}
											nativeMobile={nativeMobile}
											// To avoid loading user data multiple times passing
											// preloaded data as cache param
											cache={data}
										/>
									),
								},
						  ]
						: []),
					...(carrierId && !nativeMobile && (isInvestor || isCarrierDispatcher || driverId)
						? [
								{
									label: "Trucks",
									component: (
										<DriverTrucksPage
											isView
											params={{
												carrierId,
												// FIXME we need to pass only user id to get assigned
												// trucks to a user / driver
												userId: isInvestor || isCarrierDispatcher ? userId : null,
												driverId: !isInvestor && !isCarrierDispatcher ? driverId : null,
												allowActions: true,
												isCarrierDispatcher: isCarrierDispatcher,
											}}
										/>
									),
									auth: ["administrator", "carrier", "carrier_manager"],
								},
						  ]
						: []),
					...(viewerCanEdit &&
					carrierId &&
					isCarrierPayrollEnabled &&
					isUserPayrollEnabled &&
					isUserRoleSupportsPayroll &&
					!nativeMobile
						? [
								{
									label: "Payroll",
									component: (
										<EnterpriseUsersPayrollPage isView params={{ carrierId, userId, driverId, isDriver, isInvestor }} />
									),
									auth: ["administrator", "carrier", "carrier_manager"],
								},
						  ]
						: []),
					...(user.roleType === "INTERNAL"
						? [
								{
									label: "Internal",
									component: <UserDetailsPage params={{ carrierId, userId, driverId }} isView />,
									auth: ["administrator"],
								},
						  ]
						: []),
				]}
			/>
		</div>
	);
});

export default UserDetailsView;
