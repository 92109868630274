import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { isEnabled } from "app/services/featureStorageService";
import FormSection from "app/main/tools/FormSection";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

import { getLogoConfig, getLogoImage } from "app/main/utils/brokerUtils";
import { createRequest, throwResErrors } from "app/services/requestUtil";
import { showSnackbar } from "app/main/utils/snackbarUtil";

import { GLOBAL_FORM } from "@smarthop/form/configs/integrationsGeneralForm";

function BrokersView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();

	const carrierId = props.carrierId;
	const enableUberOauth = false;

	const logoDAT = getLogoImage(getLogoConfig("dat"));
	const logoNewtrul = getLogoImage(getLogoConfig("newtrul"));
	const logoEcho = getLogoImage(getLogoConfig("echo"));
	const logoUber = getLogoImage(getLogoConfig("uber"));
	const logoCoyote = getLogoImage(getLogoConfig("coyote"));
	const logoCHR = getLogoImage(getLogoConfig("chrobinson"));
	const logoLoadsmart = getLogoImage(getLogoConfig("loadsmart"));
	const logoEmerge = getLogoImage(getLogoConfig("emerge"));

	const isGenericCredentialsEnabled = isEnabled("QA_ADD_GENERIC_CREDENTIAL");
	const isEmergeIntegrationEnabled = isEnabled("EMERGE_INTEGRATION");

	const ADD_GENERIC_CREDENTIAL = (carrierId, broker) => {
		return {
			action: {
				onClick: async () => {
					try {
						await createRequest()
							.post(`api/profile/carriers/${carrierId}/integrations/brokers/${broker}/generic`)
							.catch((error) => {
								throwResErrors(error);
							});
						dispatch(incrementDataRevision({ event: "profileRevision" }));
					} catch (error) {
						showSnackbar(snackbar, error.errors[0]?.message ?? "Error checking trial credential", "error", {
							persist: true,
						});
					}
				},
			},
			label: "Set trial credential",
			auth: ["administrator"],
		};
	};
	const ACTION_CREATE = {
		action: "create",
		mode: "CREATE",
		label: "Set Credentials",
		auth: ["administrator", "dispatcher"],
	};
	const ACTION_CREATE_PREFILL = {
		action: "edit",
		mode: "EDIT",
		label: "Set Credentials",
		auth: ["administrator", "dispatcher", "ops support"],
	};
	const ACTION_DELETE = {
		action: "delete",
		mode: "DELETE",
		label: "Clear Credentials",
		auth: ["administrator", "dispatcher"],
	};
	const ACTION_ADD_GENERIC = (brokerId) => {
		return isGenericCredentialsEnabled ? [ADD_GENERIC_CREDENTIAL(carrierId, brokerId)] : [];
	};
	const ACTION_REQUEST_CREDENTIAL = {
		action: "create",
		mode: "EDIT",
		label: "Request integration",
		auth: ["administrator", "dispatcher"],
	};
	const ACTION_CHECK_STATUS_LOADSMART = {
		action: {
			onClick: () => {
				dispatch(
					openLoadedFormDialog({
						formId: "INTEGRATIONS_LOADSMART_CHECK_CREDENTIALS",
						mode: "VIEW",
						dataIds: { carrierId },
					})
				);
			},
		},
		label: "Check Status",
		auth: [],
	};
	//By now only for uber, in future could be generic
	const ACTION_OAUTH_REQUEST = {
		action: {
			onClick: () => {
				const url = `/profile/carriers/oauth/uber?carrier=${carrierId}`;
				if (props.isView) window.open(url, "_blank");
				else history.push(url);
			},
		},
		label: "Set oauth credentials",
		auth: [],
		url: "/profile/carriers/oauth/uber",
	};
	const ACTION_DISABLE_CREDENTIAL = (carrierId, broker) => {
		return {
			action: {
				onClick: async () => {
					try {
						await createRequest()
							.delete(`api/profile/carriers/${carrierId}/integrations/brokers/${broker}/disable`)
							.catch((error) => {
								throwResErrors(error);
							});
						dispatch(incrementDataRevision({ event: "profileRevision" }));
					} catch (error) {
						showSnackbar(snackbar, error.errors[0]?.message ?? "Error disabling credential", "error", {
							persist: true,
						});
					}
				},
			},
			label: "Disable credential",
			auth: ["administrator"],
		};
	};

	const ACTION_UPDATE_STATUS = (carrierId, brokerId) => {
		return {
			action: {
				onClick: () => {
					dispatch(
						openLoadedFormDialog({
							formId: "INTEGRATION_UPDATE_STATUS_FORM",
							mode: "EDIT",
							dataIds: { carrierId, brokerId },
						})
					);
				},
			},
			mode: "EDIT",
			label: "Update Status",
			auth: ["administrator"],
		};
	};

	return (
		<>
			<div className="flex flex-col w-full">
				<FormSection
					titleView={<div className="py-5 bg-white px-10 rounded-lg">{logoDAT}</div>}
					title="DAT Integration"
					form={GLOBAL_FORM("dat", "DAT Credential")}
					dataIds={{ carrierId, brokerId: "dat" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("dat"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "dat"),
						ACTION_UPDATE_STATUS(carrierId, "dat"),
					]}
				/>
				<FormSection
					titleView={<div className="py-2	bg-white px-20 rounded-lg">{logoNewtrul}</div>}
					title="Newtrul Integration"
					form={GLOBAL_FORM("newtrul", "Newtrul Credential")}
					dataIds={{ carrierId, brokerId: "newtrul" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("newtrul"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "newtrul"),
						ACTION_UPDATE_STATUS(carrierId, "newtrul"),
					]}
				/>
				<FormSection
					titleView={<div className="py-8 bg-white px-10 rounded-lg">{logoEcho}</div>}
					title="Echo Integration"
					form={GLOBAL_FORM("echo", "ECHO Credential")}
					dataIds={{ carrierId, brokerId: "echo" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("echo"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "echo"),
						ACTION_UPDATE_STATUS(carrierId, "echo"),
					]}
				/>
				{isEmergeIntegrationEnabled && (
					<FormSection
						titleView={<div className="py-6 bg-white px-16 rounded-lg">{logoEmerge}</div>}
						title="Emerge Integration"
						form={GLOBAL_FORM("emerge", "Emerge Credential")}
						dataIds={{ carrierId, brokerId: "emerge" }}
						actions={[
							ACTION_REQUEST_CREDENTIAL,
							ACTION_DELETE,
							ACTION_DISABLE_CREDENTIAL(carrierId, "emerge"),
							ACTION_UPDATE_STATUS(carrierId, "emerge"),
						]}
					/>
				)}
			</div>
			<div className="flex flex-col w-full">
				<FormSection
					titleView={<div className="py-8 bg-white px-6 rounded-lg">{logoCoyote}</div>}
					title="Coyote Integration"
					form={GLOBAL_FORM("coyote", "Coyote Credential")}
					dataIds={{ carrierId, brokerId: "coyote" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("coyote"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "coyote"),
						ACTION_UPDATE_STATUS(carrierId, "coyote"),
					]}
				/>
				<FormSection
					titleView={<div className="py-7 bg-white px-8 rounded-lg">{logoLoadsmart}</div>}
					title="Loadsmart Integration"
					form={GLOBAL_FORM("loadsmart", "Loadsmart Credential")}
					dataIds={{ carrierId, brokerId: "loadsmart" }}
					actions={[
						ACTION_CREATE_PREFILL,
						ACTION_CHECK_STATUS_LOADSMART,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("loadsmart"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "loadsmart"),
						ACTION_UPDATE_STATUS(carrierId, "loadsmart"),
					]}
				/>
				<FormSection
					titleView={<div className="py-7 bg-white px-10 rounded-lg">{logoCHR}</div>}
					title="CH Robinson Integration"
					form={GLOBAL_FORM("chrobinson", "CH Robinson Credential")}
					dataIds={{ carrierId, brokerId: "chrobinson" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						...ACTION_ADD_GENERIC("chrobinson"),
						ACTION_UPDATE_STATUS(carrierId, "chrobinson"),
						ACTION_DISABLE_CREDENTIAL(carrierId, "chrobinson"),
					]}
				/>
				<FormSection
					titleView={<div className="py-6 bg-white px-16 rounded-lg">{logoUber}</div>}
					title="Uber Integration for Fleets"
					form={GLOBAL_FORM("uber-fleet", "Uber fleet Credential")}
					dataIds={{ carrierId, brokerId: "uber-fleet" }}
					actions={[
						ACTION_CREATE,
						ACTION_DELETE,
						ACTION_DISABLE_CREDENTIAL(carrierId, "uber-fleet"),
						ACTION_UPDATE_STATUS(carrierId, "uber-fleet"),
					]}
					auth={["administrator"]}
				/>
				{/* Uber removed meanwhile they allow us to use oauth process*/}
				{enableUberOauth && (
					<FormSection
						titleView={<div className="py-6 bg-white px-16 rounded-lg">{logoUber}</div>}
						title="Uber Integration"
						form={GLOBAL_FORM("uber", "Uber Credential")}
						dataIds={{ carrierId, brokerId: "uber" }}
						actions={[ACTION_OAUTH_REQUEST, ACTION_DELETE, ACTION_UPDATE_STATUS(carrierId, "uber")]}
					/>
				)}
			</div>
		</>
	);
}

export default BrokersView;
