import { SmarthopTextField } from "@smarthop/form";

export default function ChatListFilter({ filter, setFilter }) {
	return (
		<div className="-mb-20 -mr-4 -mt-4 -ml-4">
			<SmarthopTextField
				style={{ borderRadius: 0 }}
				startIcon="search"
				placeholder="Filter Channels"
				//inputRef={inputRef}
				field={{ variant: "outlined" }}
				showClearIcon={true}
				onChangeCommitted={(key, value) => {
					setFilter(value);
				}}
			/>
		</div>
	);
}
