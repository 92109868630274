import { useEffect, useState } from "react";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// View
import PageWrapper from "../../common/PageWrapper";
import TripFilesForm from "app/main/profile/trips/TripFilesForm";

// Services
import { getTripUploadFilesInfoPublic } from "app/services/publicServices";

// Utils
import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

const PublicTripUploadFiles = () => {
	const params = convertURLParamsToModel(readURLParameters(), {}, { notJson: true });
	const token = params.token;

	const [userId, setUserId] = useState(null);
	const [trip, setTrip] = useState(null);
	const [showFilesAnalysis, setShowFilesAnalysis] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setLoading(true);
		if (!token) {
			setLoading(false);
			setError("Oops, something went wrong...");
			return;
		}

		getTripUploadFilesInfoPublic(token).then(
			(response) => {
				setTrip(response.trip);
				setUserId(response.userId);
				setShowFilesAnalysis(response.showFilesAnalysis);
				setLoading(false);
			},
			(error) => {
				console.error("[PublicTripUploadFiles] Failed to load data", error);
				setLoading(false);
				setError(error.message ?? error.errors?.[0]?.message ?? "Oops, something went wrong...");
			}
		);
	}, [token]);

	const CardView = (props) => {
		return (
			<div className="flex flex-col h-full flex-auto items-center justify-center">
				<Card className={"max-w-510 flex flex-col w-full items-center justify-center sm:text-center py-24 "}>
					<CardContent className="w-full flex flex-col  items-center justify-center px-32">
						{props.children}
					</CardContent>
				</Card>
			</div>
		);
	};

	if (loading) {
		return (
			<CardView>
				<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
				<Typography className="pt-28 text-14 font-light" color="textSecondary">
					Loading...
				</Typography>
			</CardView>
		);
	}

	if (error) {
		return (
			<CardView>
				<img className="logo-icon w-200 px-20" src="assets/images/logoSmartHop.png" alt="logo" />
				<Typography className="pt-28 text-14 text-red font-light">{error}</Typography>
			</CardView>
		);
	}

	return (
		<PageWrapper title={`Upload Files`}>
			<div className="flex flex-col mb-12 md:mb-0 md:flex-row ml-5 w-full -mt-12 md:mt-0">
				<div className="flex flex-col w-full md:w-2/6 lg:w-1/5">
					<Typography variant="h6" className="text-14" component={"div"}>
						{trip?.data?.broker}
					</Typography>
					<Typography variant="subtitle1" className="text-14 -mt-5" component={"div"}>
						Load ID: {trip?.data?.load_id}
					</Typography>
				</div>
				<div className="flex flex-col w-full md:w-2/6 lg:w-1/5 hidden md:block">
					<Typography variant="h6" className="text-14" component={"div"}>
						Pickup: {trip?.data?.pickup_shipper}
					</Typography>
					<Typography variant="subtitle1" className="text-14 -mt-5" component={"div"}>
						{trip?.data?.pickup_address} - {moment.utc(trip?.data?.pickup_date).format("MM/DD/YYYY")} @
						{moment.utc(trip?.data?.pickup_date).format("hh:mm A")}
					</Typography>
				</div>
				<div className="flex flex-col w-full md:w-2/6 lg:w-1/5 hidden md:block">
					<Typography variant="h6" className="text-14" component={"div"}>
						Delivery: {trip?.data?.delivery_shipper}
					</Typography>
					<Typography variant="subtitle1" className="text-14 -mt-5" component={"div"}>
						{trip?.data?.delivery_address} - {moment.utc(trip?.data?.delivery_date).format("MM/DD/YYYY")} @
						{moment.utc(trip?.data?.delivery_date).format("hh:mm A")}
					</Typography>
				</div>
			</div>

			<div className=" p-0 md:py-10 md:pr-16 md:pl-5 m-0 md:my-12 border-1 bg-white rounded-lg">
				<TripFilesForm
					dataIds={{ userId, carrierId: trip?.carrier, tripId: trip?._id, showFilesAnalysis, public: true }}
				/>
			</div>
		</PageWrapper>
	);
};

export default PublicTripUploadFiles;
