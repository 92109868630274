import { makeStyles } from "@material-ui/core";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { getUserId, isRoleHasFullCarrierAccess, isRoleExternal } from "app/services/LoginService";
import { getSearchStatus, saveViewedSearchVersions } from "app/services/searchServices";
import { getFlexibleReasonName, createBadgeView } from "./searchUtils";
import { useDispatch } from "react-redux";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { selectChats } from "app/store/messenger/chatSlice";
import SearchOrderByFilter from "./SearchOrderByfilter";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";

const useStyles = makeStyles({
	"@keyframes flicker": {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.2,
		},
	},
	flicker: {
		animationName: "$flicker",
		animationDuration: "1000ms",
		animationIterationCount: "infinite",
		animationDirection: "alternate",
		animationTimingFunction: "ease-in-out",
	},
	withAnimation: ({ disabled }) => ({
		animationPlayState: disabled ? "paused" : "running",
	}),
});

const RELOAD_TIMEOUT = 15;

function CounterFilter({ content, field, updateFilters, enabled }) {
	const onClickCounterFilter = () => {
		updateFilters(field, !enabled);
	};

	return (
		<div
			className={`cursor-pointer ${
				enabled ? `border-b-2 border-orange-300 ${field === "system_hide_all_flexible" ? "opacity-40" : ""}` : ""
			}`}
			onClick={onClickCounterFilter}
		>
			{content}
		</div>
	);
}

function SearchStatusPanel(props) {
	const { flicker, withAnimation } = useStyles({ disabled: false });
	const snackbar = useSnackbar();

	const userId = useMemo(() => getUserId(), []);
	const viewerIsCustomer = useMemo(() => isRoleExternal(), []);
	const viewerHasFullCarrierAccess = useMemo(() => isRoleHasFullCarrierAccess(), []);

	const dispatch = useDispatch();
	const viewOnly = props.viewOnly;
	const contentHidden = props.contentHidden;
	const nativeMobile = useSelector(({ auth }) => auth.user.nativeMobile);
	const onSearchStatusChanged = props.onSearchStatusChanged;
	const onReloadClick = props.onReloadClick;
	const onReloadRequested = props.onReloadRequested;
	const filters = props.filters;
	const searchId = props.searchId;
	const orderBy = props.orderBy;
	const onChangeOrderBy = props.onChangeOrderBy;
	const mobile = props.mobile;
	const smallScreen = props.smallScreen;

	const tableCacheKey = "search_result_" + searchId;
	const tableUrl = `api/search/users/${userId}/requests/${searchId}/results`;
	const tableUrlGeneric = `api/search/requests/${searchId}/details`;

	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const searchRevision = useSelector(({ tools }) => tools.revision.searchRevision);
	const subscriberMeta = useSelector(({ tools }) => tools.table[tableCacheKey]);

	const [state, setState] = useState(null);
	const [filtersClicked, setFiltersClicked] = useState([]);
	const [warnings, setWarnings] = useState(null);
	const [errors, setErrors] = useState(null);
	const [checkAttempt, setCheckAttempt] = useState(0);
	const [refreshData, setRefreshData] = useState(null);
	const chats = useSelector(selectChats);
	const unreadCount = useMemo(() => {
		let data = {};
		chats?.forEach((chat) => {
			if (chat.truck === filters?.truck) {
				data[chat.type] = chat?.metadata?.status?.unread;
			}
		});
		return data;
	}, [chats, filters?.truck]);

	useEffect(() => {
		if (!searchId) {
			return;
		}

		let timer;
		let stopped = false;
		getSearchStatus(userId, searchId).then(
			(data) => {
				if (stopped) return;
				processStatus(data);
				if (data.state !== "EXPIRED" && data.state !== "FAILED") {
					timer = setTimeout(() => setCheckAttempt(checkAttempt + 1), RELOAD_TIMEOUT * 1000);
				}
			},
			(errors) => {
				if (stopped) return;
				console.error(`[SearchStatusPanel] GET: failed to check status`, errors);
				timer = setTimeout(() => setCheckAttempt(checkAttempt + 1), RELOAD_TIMEOUT * 1000);
			}
		);

		return () => {
			stopped = true;
			clearTimeout(timer);
		};
		// eslint-disable-next-line
	}, [searchId, searchRevision, checkAttempt, isAuthenticated, subscriberMeta?.result?.metadata?.stats]);

	const onAckVersionClick = () => {
		if (refreshData?.new?.totalNewCount > 0) {
			saveViewedSearchVersions(userId, searchId, refreshData.last).then(
				(data) => {
					onReloadRequested?.();
					processStatus(data);
				},
				(errors) => {
					console.error(`[SearchStatusPanel] POST: failed to acknoledged viewed loads`, errors);
				}
			);
		}
	};

	const stats =
		subscriberMeta?.url === tableUrl || subscriberMeta?.url === tableUrlGeneric
			? subscriberMeta?.result?.metadata?.stats
			: null;

	const stillFetching = subscriberMeta?.result?.metadata?.status?.state === "FINISHED_PRELOADED";
	const stillFinishing = subscriberMeta?.result?.metadata?.status?.state === "FINISHED_MANDATORY";

	const processStatus = (data) => {
		let warnings = [];
		let errors = [];
		let hasDAT = false;
		let hasCOYOTE = false;
		let hasNEWTRUL = false;
		let hasECHO = false;
		let hasCHRobinson = false;

		data?.pipelines?.forEach((pipeline) => {
			pipeline?.meta?.warnings?.forEach((warning) => {
				warnings.push(pipeline.type + ": " + warning);
			});

			if (pipeline.state === "FAILED") {
				errors.push(pipeline.type + ": " + (pipeline?.meta?.errors[0]?.message ?? "critical pipeline failure"));
			} else if (pipeline.state === "FAILED_STUCK") {
				errors.push(pipeline.type + ": search request stuck");
			}

			if (pipeline.type === "DAT") {
				hasDAT = true;
			}
			if (pipeline.type === "COYOTE") {
				hasCOYOTE = true;
			}
			if (pipeline.type === "NEWTRUL") {
				hasNEWTRUL = true;
			}
			if (pipeline.type === "ECHO") {
				hasECHO = true;
			}
			if (pipeline.type === "CHROBINSON") {
				hasCHRobinson = true;
			}
		});

		if (!hasCOYOTE) {
			warnings = ["COYOTE: disabled, credentials not provided by carrier", ...warnings];
		}
		if (!hasDAT) {
			warnings = ["DAT: disabled, credentials not provided by carrier", ...warnings];
		}
		if (!hasNEWTRUL) {
			warnings = ["NEWTRUL: disabled, credentials not provided by carrier", ...warnings];
		}
		if (!hasECHO) {
			warnings = ["ECHO: disabled, credentials not provided by carrier", ...warnings];
		}
		if (!hasCHRobinson) {
			warnings = ["CHROBINSON: disabled, credentials not provided by carrier", ...warnings];
		}

		const refreshNewCount = data?.versions?.new?.totalNewCount ?? 0;
		const prevNewCount = refreshData?.new?.totalNewCount ?? 0;
		const prevCountChanged = prevNewCount !== refreshNewCount;
		if (prevCountChanged) {
			onReloadRequested?.();
			// Showing snackbar on mobile only
			if (!contentHidden && prevNewCount < refreshNewCount && !mobile && !nativeMobile) {
				showSnackbar(snackbar, `Found ${refreshNewCount - prevNewCount} new load(s)!`, "info", 6000);
			}
		}

		let jobExpired = data?.state === "EXPIRED" || data?.refresh?.state === "EXPIRED";
		let jobFailed =
			data?.state === "FAILED" ||
			data?.state === "FAILED_STUCK" ||
			data?.refresh?.state === "FAILED" ||
			data?.refresh?.state === "FAILED_STUCK";
		const prevJobStateChanged =
			state?.main !== data?.state || state.jobExpired !== jobExpired || state.jobFailed !== jobFailed;

		if (prevCountChanged || prevJobStateChanged) {
			const state = !data?.state ? undefined : jobExpired ? "EXPIRED" : jobFailed ? "FAILED" : "ACTIVE";
			onSearchStatusChanged?.(state, { new: refreshNewCount });
		}

		setState({ main: data?.state, refresh: data?.refresh?.state, jobExpired, jobFailed });
		setRefreshData(data?.versions);
		setWarnings(warnings);
		setErrors(errors);
	};

	const getStatValue = (label, count, hiddenCount, digitalCount) => {
		let color =
			label === "Best"
				? "text-primary "
				: label === "Good"
				? "text-blue-700 text-white"
				: label === "Flexible"
				? "text-orange-800 text-white"
				: label === "Book Now"
				? "text-purple-800 text-white"
				: label === "Match"
				? "text-blue-900 text-white"
				: label === "Strategic"
				? "text-red-900 text-white"
				: "text-black";

		const labelStyle = smallScreen ? " text-11 " : " text-12 ";
		const iconStyle = smallScreen ? " text-12 mt-2 " : " text-13 mt-3 ";
		const textStyle = smallScreen ? " text-11 " : " text-12 ";

		const getValue = () => {
			return (
				<Typography
					className={"flex flex-row font-semibold whitespace-nowrap items-center justify-center " + color + textStyle}
					component={"div"}
				>
					{label === "New" && <div className={"w-6 h-6 rounded-full mr-4 bg-cyan-700"} />}
					{hiddenCount > 0 ? "Hidden" : count?.length > 0 ? count : count >= 0 ? count : "-"}
				</Typography>
			);
		};

		return (
			<div className={"flex flex-col " + (smallScreen ? " -ml-4 " : " mr-4 ")}>
				<Typography className={"font-normal whitespace-nowrap text-gray-800 " + labelStyle}>{label}</Typography>
				<div className={"flex flex-row " + (smallScreen ? " -mt-2 " : " ")}>
					<div className="flex flex-row whitespace-nowrap">
						{label === "Book Now" && (
							<ResourceView
								type={"custom"}
								variant={"hover"}
								description={getResourceByKey("SEARCH_BOOK_POPUP")?.description}
								link={getResourceByKey("SEARCH_BOOK_POPUP")?.link}
								customIcon={
									<div className="flex flex-row whitespace-nowrap">
										{createBadgeView(null, "STATUS", "BOOK")}
										{getValue()}
									</div>
								}
							/>
						)}
						{label === "Best" && createBadgeView(null, "STATUS", "MATCH_STATUS")}
						{label !== "Book Now" && getValue()}
					</div>
					{!hiddenCount && digitalCount > 0 && (
						<div className="flex flex-row whitespace-nowrap">
							<Typography className={"font-semibold whitespace-nowrap text-purple-800 ml-4 " + textStyle}>(</Typography>
							<Icon className={" text-purple-800 -ml-2 -mr-2 " + iconStyle}>flash_on</Icon>
							<Typography className={"font-semibold whitespace-nowrap text-purple-800 " + textStyle}>
								{digitalCount}
								{")"}
							</Typography>
						</div>
					)}
				</div>
			</div>
		);
	};

	const getTooltip = (component, title) => {
		return (
			<Tooltip classes={{ tooltip: "drop-shadow-md" }} title={title}>
				{component}
			</Tooltip>
		);
	};

	const getReasonsTooltip = (description, component, reasonsCounts, hiddenCount) => {
		if (hiddenCount > 0) {
			return getTooltip(
				component,
				<>
					<Typography color="inherit" variant="body2" className="p-6">
						{description}
					</Typography>
					<Typography color="inherit" variant="body2" className="p-6 font-light">
						{hiddenCount} loads hidden, enable visibility in filters
					</Typography>
				</>
			);
		}

		let keys = Object.keys(reasonsCounts ?? {});
		if (!keys?.length) {
			return component;
		}

		return getTooltip(
			component,
			<>
				<Typography color="inherit" variant="body2" className="p-6">
					{description}
				</Typography>
				{keys.map((reason, index) => {
					return (
						<Typography key={index} color="inherit" variant="body2" className="p-6 font-light">
							{reasonsCounts[reason]} because of '{getFlexibleReasonName(reason)}'
						</Typography>
					);
				})}
			</>
		);
	};

	const getWarningsTooltip = (errors, warnings) => {
		if (!warnings?.length && !errors?.length) {
			return null;
		}

		return getTooltip(
			<Icon
				className={
					(errors?.length > 0 ? " text-red-400 " : " text-orange-700 ") +
					(smallScreen ? " mr-20 ml-4 " : " mr-28 ml-4 ") +
					" text-20 "
				}
			>
				{errors?.length > 0 ? "warning" : "report_problem"}
			</Icon>,
			<>
				{errors?.map((error, index) => {
					return (
						<Typography key={index} color="inherit" variant="body2" className="p-6 font-semibold">
							{error}
						</Typography>
					);
				})}
				{warnings?.map((warning, index) => {
					return (
						<Typography key={index} color="inherit" variant="body2" className="p-6 font-light">
							{warning}
						</Typography>
					);
				})}
			</>
		);
	};

	const getIconButton = (icon, text, disabled, className, badge, showText, callback) => {
		if (!callback) {
			return null;
		}

		let button = showText ? (
			<div disabled={!!disabled} className={"flex flex-row cursor-pointer"} onClick={() => callback()}>
				<Icon className={"text-20 text-primary"}>{icon}</Icon>
				<div className="flex flex-col">
					{text?.split(" ")?.map((line) => {
						return <Typography className="ml-6 text-10 ml:text-11 leading-none text-primary">{line}</Typography>;
					})}
				</div>
			</div>
		) : (
			<IconButton
				disabled={!!disabled}
				className={(smallScreen ? " mr-16 p-3 ml-4 " : " mr-24 p-3 ml-4 ") + className}
				aria-label="Clear"
				color="secondary"
				onClick={() => callback()}
			>
				<Badge badgeContent={badge} color="secondary">
					<Icon className={"text-20"}>{icon}</Icon>
				</Badge>
			</IconButton>
		);

		if (disabled) {
			return button;
		}

		return getTooltip(
			button,
			<Typography className={"font-semibold text-12 text-white p-6"} color="primary">
				{text}
			</Typography>
		);
	};

	let stateList = filters?.destinationStates__view?.map((state) => state.description);
	let location = filters?.location_origin?.replace(", USA", "");
	let locationDest = filters?.location_destination?.replace(", USA", "");
	let locationMultiDest = stateList?.length > 0 ? stateList?.join(", ") : undefined;

	if (location && locationMultiDest) {
		location += " - " + locationMultiDest;
	} else if (location && locationDest) {
		location += " - " + locationDest;
	}

	const hasNetwort = isAuthenticated || nativeMobile;
	const searchStatus = filters && (
		<>
			{state?.main && !nativeMobile && !smallScreen && !mobile && (
				<Typography color="primary" className="p-4 flex">
					{!hasNetwort && state?.main
						? "Offline"
						: state?.jobFailed
						? "Search Failed"
						: state?.jobExpired
						? "Search Inactive"
						: "Waiting for New"}
				</Typography>
			)}
			{state?.main && !mobile && (
				<div
					className={
						((!hasNetwort && state) || state?.jobExpired || state?.jobFailed
							? ` text-red `
							: `${flicker} ${withAnimation} text-green `) + ` pt-4`
					}
				>
					<Icon className={"ml-10 ml:ml-2 text-18"}>
						{!hasNetwort && state?.main
							? "cloud_off"
							: state?.jobExpired || state?.jobFailed
							? "stop_circle"
							: "cached"}
					</Icon>
				</div>
			)}
		</>
	);

	const updateFilters = (field, enabled) => {
		props?.updateFilters?.({ [field]: enabled });
		if (!filtersClicked.includes(field)) {
			setFiltersClicked((val) => {
				val.push(field);
				return val;
			});
		}
	};

	const countersIcons = (filters || viewOnly) && (
		<div className={"flex flex-row items-center justify-start " + (smallScreen ? "" : " flex-1 flex-wrap ")}>
			<div className="px-10 ml:px-16 min-w-64">
				{!stillFetching && !stillFinishing ? (
					getStatValue("Total", stats?.total?.count, 0)
				) : (
					<div className={`${flicker} ${withAnimation} text-green`}>
						<Icon className={"ml-2 pt-2 text-18"}>search</Icon>
					</div>
				)}
			</div>
			<div className="pl-8 ml:pl-12 min-w-64">
				{!stillFetching && !stillFinishing ? (
					getReasonsTooltip(
						`Some loads are not visible because of the selected search preferences.`,
						getStatValue("Filtered", stats?.filtered?.count, 0),
						stats?.removed
					)
				) : (
					<div className={`${flicker} ${withAnimation} text-green`}>
						<Icon className={"ml-2 pt-2 text-18"}>filter_list</Icon>
					</div>
				)}
			</div>
			{!viewOnly && (
				<div className="flex flex-row items-center">
					{getIconButton(
						"replay",
						"Reset Filters",
						stats?.total?.count === stats?.filtered?.count,
						null,
						null,
						null,
						onReloadClick
					)}
					<div className="w-1 h-20 bg-grey-300 mr-16 ml-8 ml:-ml-2" />
				</div>
			)}
			{!mobile && !viewOnly && (
				<div className="pl-8 ml:pl-12">
					<CounterFilter
						content={getStatValue("New", stats?.filtered?.new ?? 0, 0)}
						field="system_show_new_loads_only"
						updateFilters={updateFilters}
						enabled={!!filters?.system_show_new_loads_only}
					/>
				</div>
			)}
			{!mobile && !viewOnly && (
				<div className="flex flex-row items-center">
					{getIconButton(
						"done",
						"Acknowledge New",
						!refreshData?.new?.totalNewCount,
						"flex",
						null,
						null,
						onAckVersionClick
					)}
					<div className="w-1 h-20 bg-grey-300 mr-8 ml:mr-12 -ml-4" />
				</div>
			)}
			{!viewOnly && !nativeMobile && (
				<div className="flex flex-row items-center">
					{getIconButton(
						"event_note",
						"Truck Plan",
						filters?.truck?.length !== 24,
						"text-grey-900",
						null,
						false,
						() => {
							dispatch(
								openFormDialog({
									viewId: "TRUCK_PLAN_VIEW",
									dataIds: {
										truckId: filters?.truck,
										carrierId: filters?.carrier,
									},
								})
							);
						}
					)}
					<div className="w-1 h-20 bg-grey-300 mr-8 ml:mr-12 ml-6 ml:-ml-8" />
				</div>
			)}
			{!nativeMobile && !mobile && (
				<div className="flex flex-row items-center">
					{getIconButton("assessment", "Market Analytics", false, "text-grey-900", null, false, () => {
						dispatch(
							openFormDialog({
								viewId: "LOADS_SEARCH_MARKET_ANALYSIS_VIEW",
								dataIds: { searchId },
							})
						);
					})}
					<div className="w-1 h-20 bg-grey-300 mr-12 -ml-8" />
				</div>
			)}
			{!nativeMobile &&
				!viewOnly &&
				!mobile &&
				(!viewerIsCustomer || viewerHasFullCarrierAccess) &&
				!filters?.truck?.includes("ANY_") && (
					<div className="flex flex-row items-center">
						{getIconButton(
							"textsms",
							"Dispatching Channel",
							false,
							"text-primary",
							unreadCount["TRUCK_EXTERNAL"] ? "1+" : null,
							false,
							() => {
								dispatch(
									openFormDialog({
										viewId: "CHAT_APP_PREVIEW_TRUCK_EXTERNAL",
										dataIds: { truckId: filters?.truck, carrierId: filters?.carrier },
									})
								);
							}
						)}
					</div>
				)}
		</div>
	);

	const actionButtons = filters && (
		<div className={"flex flex-row items-center md:flex-1 justify-end pr-16 "}>
			{!nativeMobile && !viewerIsCustomer && !mobile && getWarningsTooltip(errors, warnings)}
			{searchStatus}
		</div>
	);

	if (!stats) {
		return null;
	}

	return (
		<div className="flex bg-white flex-col w-full divide-y">
			{mobile && (
				<SearchOrderByFilter
					onChange={(value) => {
						onChangeOrderBy(value);
					}}
					value={orderBy}
					mobile={mobile}
				/>
			)}
			<div className={"flex flex-row w-full px-16 ml:px-6" + (mobile ? " h-60 " : smallScreen ? " h-40 " : " h-44 ")}>
				{countersIcons}
				{actionButtons}
			</div>
			<div className="flex w-full h-1"></div>
		</div>
	);
}

export default SearchStatusPanel;
