import moment from "moment";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, IconButton, Icon, Tooltip, withStyles } from "@material-ui/core";
import { getUserId, getRoleLabel } from "app/services/LoginService";
import { buildMessageAvatar, buildSenderName, buildMessageView } from "../utils/chatUtils";
import { formatMessageDate } from "app/main/utils/dateUtils";
import { authRoles } from "app/main/consts";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import { isEnabled } from "app/services/featureStorageService";

const timeGroup = 60 * 5; // 5 min

const TextDivider = ({ children }) => {
	return (
		<div className="flex items-center justify-center flex-grow-1 w-full">
			<div
				className="border-b-1 w-full"
				style={{
					borderImage: "linear-gradient(to right, #FFFFFF 0%, rgba(0, 0, 0, 0.12) 40%, rgba(0, 0, 0, 0.12) 100%)",
					borderImageSlice: 1,
				}}
			/>
			<span className="px-10 flex-shrink-0">{children}</span>
			<div
				className="border-b-1 w-full"
				style={{
					borderImage: "linear-gradient(to left, #FFFFFF 0%, rgba(0, 0, 0, 0.12) 40%, rgba(0, 0, 0, 0.12) 100%)",
					borderImageSlice: 1,
				}}
			/>
		</div>
	);
};

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "black",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

function MessageListItem(props) {
	const userId = getUserId();
	const userRole = getRoleLabel();
	const dispatch = useDispatch();
	const [showContent, setShowContent] = useState(false);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 965);

	const nativeMobile = props.nativeMobile;
	const rowClasses = props.classes?.row;
	const prevMessage = props.prevMessage;
	const message = props.currentMessage;
	const nextMessage = props.nextMessage;
	const isLastMessage = props.isLastMessage;
	const viewerIsOwner = props.viewerIsOwner;
	const locationPermission = props.locationPermission;

	const isNewUIEnabledMessages = isEnabled("CHAT_NEW_UI_MESSAGES");

	const isFirstMessageOfGroup =
		!prevMessage ||
		(prevMessage && (prevMessage.user !== message.user || message.sentUnix - prevMessage.sentUnix > timeGroup));
	const isLastMessageOfGroup =
		!nextMessage ||
		(nextMessage && (nextMessage.user !== message.user || nextMessage.sentUnix - message.sentUnix > timeGroup));
	const shouldShowContactAvatar = !isNewUIEnabledMessages
		? message.user !== userId &&
		  ((nextMessage && (nextMessage.user !== message.user || nextMessage.sentUnix - message.sentUnix > timeGroup)) ||
				!nextMessage)
		: isFirstMessageOfGroup;

	const senderUser = message?.metadata?.user;

	const senderRoleLable =
		senderUser?.roleLabel === "carrier"
			? "Owner"
			: senderUser?.roleLabel === "driver"
			? "Driver"
			: senderUser?.roleLabel === "carrier_manager"
			? "Manager"
			: senderUser?.roleLabel === "carrier_dispatcher"
			? "Dispatcher"
			: senderUser?.roleLabel === "carrier_generic"
			? "User"
			: "";

	const senderExternal = authRoles.externalUsers?.includes(senderUser?.roleLabel);
	let user = senderUser;
	if (viewerIsOwner && !senderExternal) {
		user = { ...senderUser, first_name: "SmartHop", last_name: "Team" };
	}
	const incoming = message.user !== userId;
	const nextIncoming = nextMessage && nextMessage.user !== userId;

	const isToday = moment.unix(message?.sentUnix).isSame(moment(), "day");
	const isSameDay =
		prevMessage?.sentUnix > 0
			? moment.unix(message?.sentUnix).isSame(moment.unix(prevMessage?.sentUnix), "day")
			: false;

	// handle screen size
	useEffect(() => {
		if (nativeMobile) return;

		function handleResize() {
			if (window.innerWidth <= 965 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 965 && smallScreen) {
				setSmallScreen(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<>
			{!isSameDay && (
				<div
					key={message._id + "_divider"}
					className={clsx(
						"flex flex-col flex-grow-0 flex-shrink-0 items-center justify-end relative",
						!prevMessage ? "  mb-20 " : "mt-20 mb-20 "
					)}
				>
					{!isNewUIEnabledMessages ? (
						<div className="flex flex-row flex-full items-center justify-center ">
							<Typography className="mx-10 pr-48 font-bold text-13 md:text-14" color="textSecondary">
								{isToday ? "Today" : moment.unix(message?.sentUnix).format("MMM DD")}
							</Typography>
						</div>
					) : (
						<div
							className={`flex flex-row flex-full items-center justify-center w-full ${
								!smallScreen ? "pl-0 pr-0 ml:pl-28 ml:pr-28" : ""
							}`}
						>
							<TextDivider>
								<Typography className="font-bold text-13 md:text-14 border-1 rounded-20 px-20" color="textSecondary">
									{isToday ? "Today" : moment.unix(message?.sentUnix).format("MMM DD")}
								</Typography>
							</TextDivider>
						</div>
					)}
				</div>
			)}

			<div
				key={message._id}
				className={clsx(
					rowClasses,
					"flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative -ml-6 md:ml-0 -mr-6 md:mr-0 pl-0 md:pl-16 pr-2 md:pr-8 pb-4",
					!isNewUIEnabledMessages &&
						(((nextIncoming && incoming) || (!nextIncoming && !incoming)) && isLastMessageOfGroup ? " pb-40 " : ""),
					!isNewUIEnabledMessages ? { me: !incoming } : {},
					{ contact: incoming },
					{ "first-of-group": isFirstMessageOfGroup },
					!isNewUIEnabledMessages ? { "last-of-group": isLastMessageOfGroup } : {},
					isLastMessage && "pb-64",
					!message._id && "opacity-50"
				)}
				style={{ maxWidth: "100%" }}
			>
				{isFirstMessageOfGroup && (
					<>
						{!isNewUIEnabledMessages && message.user !== userId && (
							<Typography className="pl-10 pb-3 font-medium text-11 md:text-12" color="textSecondary">
								{buildSenderName({ user, message })}
							</Typography>
						)}
						{isNewUIEnabledMessages && (
							<>
								<div className="flex flex-row items-center mt-5 mb-5">
									<Typography className="pl-10 pb-3 font-bold text-12 md:text-14" color="textSecondary">
										{buildSenderName({ user, role: senderRoleLable, message })}
									</Typography>
									<Typography className="pl-10 font-normal pb-3 opacity-70 text-10 md:text-11" color="textSecondary">
										{formatMessageDate(message?.sentUnix * 1000, { noTimeFromNow: true })}
									</Typography>
								</div>
								<Typography
									className="pl-10 -mt-8 mb-2 font-normal opacity-70 text-10 md:text-11"
									color="textSecondary"
								>
									{senderRoleLable}
								</Typography>
							</>
						)}
						{!isNewUIEnabledMessages && !viewerIsOwner && senderExternal && (
							<Typography className="pl-10 -mt-8 mb-2 font-normal opacity-70 text-10 md:text-11" color="textSecondary">
								{senderRoleLable ?? "Customer"}
							</Typography>
						)}
					</>
				)}
				{shouldShowContactAvatar &&
					buildMessageAvatar({ message, user, senderExternal, sameUser: message.user === userId }, viewerIsOwner, {
						classes: {
							avatar: `avatar absolute ${
								isNewUIEnabledMessages ? " top-9 " : ""
							} ltr:left-0 rtl:right-0 m-0 -mx-40 md:-mx-32 h-28 w-28 md:h-40 md:w-40`,
						},
					})}
				<div
					className={`flex relative items-center justify-center  ${
						!isNewUIEnabledMessages
							? "bubble p-10 md:p-12 shadow"
							: "px-10 py-5 md:py-6 md:px-12 w-full hover:bg-gray-200"
					} `}
				>
					<div className={`leading-tight whitespace-pre-wrap ${isNewUIEnabledMessages ? "w-full" : ""}`}>
						{buildMessageView({ message, incoming, nativeMobile }, viewerIsOwner, {
							userId,
							dispatch,
							locationPermission,
						})}
					</div>
					{!isNewUIEnabledMessages && (
						<Typography
							className="time absolute hidden w-full text-11 md:text-12 mt-8 -mb-20 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
							color="textSecondary"
						>
							{formatMessageDate(message?.sentUnix * 1000)}
						</Typography>
					)}

					{userRole === "administrator" && (
						<>
							<div className="absolute -mr-20 right-0">
								<IconButton className="relative w-14 h-14 opacity-20" onClick={() => setShowContent(true)}>
									<Icon className="text-14">settings</Icon>
								</IconButton>
							</div>
							<SmarthopConfirmDialog
								open={!!showContent}
								title={"Message Content"}
								handleClose={() => setShowContent(false)}
							>
								<Typography component="pre" className="text-13 text-grey-800">
									{JSON.stringify(message, null, 8)}
								</Typography>
							</SmarthopConfirmDialog>
						</>
					)}
					{isLastMessage && props?.failedSend?.length > 0 && (
						<TooltipStyle
							title={props?.failedSend?.map(
								(f) => `Failed to reach user ${f?.user?.full_name} VIA SMS ${f.userPhone} \n`
							)}
						>
							<Icon color="error">warning</Icon>
						</TooltipStyle>
					)}
				</div>
			</div>
		</>
	);
}

export default MessageListItem;
