import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";
import SmarthopFormView from "./SmarthopFormView";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

// Services
import { createHeaders, global } from "app/services/requestUtil";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Utils
import { OTP_ERROR_MESSAGE_REQUIRED, WALLET_ACCESS } from "app/main/utils/financeUtils";

import axios from "axios";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";

function SmarthopForm(props) {
	const form = props.form;
	const onDone = props.onDone;
	const onLoading = props.onLoading;
	const onLoaded = props.onLoaded;
	const onProcessing = props.onProcessing;
	const nativeMobile = props.nativeMobile;
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [dataIds, setDataIds] = useState(props.dataIds ?? {});
	const [mode, setMode] = useState(props.mode ?? null);
	const [currentDataOverrides, setCurrentDataOverrides] = useState(props.dataOverrides);
	const [currentData, setCurrentData] = useState(
		props.data || props.dataOverrides ? { ...(props.data ?? {}), ...(props.dataOverrides ?? {}) } : null
	);

	const [executed, setExecuted] = useState(false);
	const [updatedData, setUpdatedData] = useState(null);
	const [loadingError, setLoadingError] = useState(null);
	const [errors, setErrors] = useState(null);
	const [openWarningDialog, setOpenWarningDialog] = useState(null);
	const history = useHistory();

	const loadinInitValue = mode !== "CREATE" && !props.data;
	const [loading, setLoading] = useState(loadinInitValue);
	const [processing, setProcessing] = useState(false);

	// updatde view on data change only in case on VIEW mode, in other mode listening
	// to the change event can cause unpredicted behaviour
	const dataRevision =
		useSelector(({ tools }) => {
			// If there are multiple listen events, they are all added together.
			if (Array.isArray(form?.listenEvent)) {
				const eventRevisions = form?.listenEvent?.map((listenEvent) => tools.revision[listenEvent] ?? 0);
				return eventRevisions.reduce((a, b) => a + b, 0);
			} else {
				return tools.revision[form?.listenEvent ?? "none"];
			}
		}) ?? 0;
	const currentRevision = mode === "VIEW" || form.listenEventReloadAllStates || mode === "COMPARE" ? dataRevision : 0;
	const [fetchedRevision, setFetchedRevision] = useState(currentRevision);

	const actionURL =
		mode === "CREATE" ? form.urlPOST : mode === "EDIT" ? form.urlPUT : mode === "DELETE" ? form.urlDELETE : "";
	const getURL = form.urlGET;

	const modeKey = mode.toLowerCase();
	const content = form.content[modeKey]?.sameAs ? form.content[form.content[modeKey]?.sameAs] : form.content[modeKey];

	// snackbar messages
	const messages = form?.messages?.[modeKey]?.sameAs
		? form?.messages?.[form?.messages?.[modeKey]?.sameAs]
		: form?.messages?.[modeKey];
	const disableMessages = messages?.disabled ?? false;

	// * Smarthop Wallet: OTP (2FA verification)
	const otp = Array.isArray(form.otp) ? form.otp : [];
	const hasSmarthopWalletToken = useSelector(({ wallet }) => !!wallet.smarthopWallet?.token);

	let queryMissingParams = "";
	actionURL?.split("/")?.forEach((section) => {
		if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
			if (queryMissingParams) queryMissingParams += ",";
			queryMissingParams += section.substring(1);
		}
	});

	useDebouncedEffect(
		async () => {
			if (mode === "CREATE" || queryMissingParams) {
				return;
			}
			if (currentData && fetchedRevision === currentRevision) {
				return;
			}
			setFetchedRevision(currentRevision);

			const headers = createHeaders();

			let url = getURL;
			Object.keys(dataIds).forEach((key) => {
				url = url.replace(":" + key, dataIds[key]);
			});

			// * Smarthop Wallet: OTP (2FA verification)
			if (otp?.includes(WALLET_ACCESS) && !hasSmarthopWalletToken) {
				return errorHandler(OTP_ERROR_MESSAGE_REQUIRED);
			}

			try {
				if (onLoading) onLoading(true);
				setLoading(!currentData && mode !== "CREATE");
				setErrors(null);
				setLoadingError(false);

				const res = await axios.create({ baseURL: global.SERVER_NAME, headers }).get(url, { headers });
				console.log(`[SmarthopForm] GET: data loaded`);
				if (onLoading) onLoading(false);
				if (onLoaded) onLoaded(res.data);
				setLoading(false);
				setCurrentData(res.data ? { ...res.data, ...(currentDataOverrides ?? {}) } : null);
			} catch (error) {
				errorHandler(error);
			}
			// eslint-disable-next-line
		},
		[dataIds, currentRevision, hasSmarthopWalletToken],
		1000
	);

	const errorHandler = (error) => {
		let response = error?.response;
		let errors = response?.data?.errors?.map
			? response?.data?.errors
			: [{ type: "generic", message: "Something went wrong, please try again later" }];
		console.error(`[SmarthopForm] GET: failed to load data=${response?.status}, data=`, response?.data);
		if (onLoading) onLoading(false);
		setErrors(errors);
		setLoading(false);
		setProcessing(false);
		setLoadingError(true);
	};

	useEffect(() => {
		if (!updatedData) {
			return;
		}
		if (executed && JSON.stringify(currentData) === JSON.stringify(updatedData) && !currentDataOverrides) {
			console.log(`[SmartHopForm] no changes to update`);
			return;
		}
		if (currentDataOverrides) {
			setCurrentDataOverrides(null);
		}

		const headers = createHeaders();
		setProcessing(true);
		let axiosCore = axios.create({
			baseURL: global.SERVER_NAME,
			headers,
		});

		let url = actionURL;
		Object.keys(dataIds).forEach((key) => {
			url = url.replace(":" + key, dataIds[key]);
		});
		const cleanUpdatedData = cleanData(updatedData);
		const params = { ignoreWarnings: cleanUpdatedData.ignoreWarnings ?? false };
		let axiosRequest =
			mode === "CREATE"
				? axiosCore.post(url, { headers, data: cleanUpdatedData }, { headers, params })
				: mode === "EDIT"
				? axiosCore.put(url, { headers, data: cleanUpdatedData }, { headers, params })
				: mode === "DELETE"
				? axiosCore.delete(url, { headers, data: cleanUpdatedData }, { headers, params })
				: null;

		if (!axiosRequest) {
			console.error(`[SmartHopForm] failer to create a request for mode '${mode}'`);
			return;
		}

		const onFinished = form.content[mode.toLowerCase()]?.onFinished;
		const onError = form.content[mode.toLowerCase()]?.onError;
		if (onProcessing) onProcessing(true);
		axiosRequest
			.then((res) => {
				setExecuted(true);
				if (mode === "CREATE" && form?.content?.create?.multipleAllowed) {
					// Adding random value to unblock save button for unchanged values
					console.log(`[SmarthopForm] POST: keeping dialog open, multiple saves allowed`);
					return;
				}
				setCurrentData(updatedData);
				if (form?.event) {
					createTrackOrPage(form?.event, cleanUpdatedData, "track");
				}
				if (form.triggerEvent) {
					if (Array.isArray(form.triggerEvent)) {
						form.triggerEvent?.forEach((event) => {
							dispatch(incrementDataRevision({ event }));
						});
					} else {
						dispatch(incrementDataRevision({ event: form.triggerEvent }));
					}
				}
				setProcessing(false);
				if (onDone) onDone(res.data);
				if (onProcessing) onProcessing(false);
				if (mode === "CREATE") {
					console.log(`[SmarthopForm] POST: saved data, id=${res.data.id}`, onFinished);
					if (form.callbacks?.onCreate) form.callbacks?.onCreate(res.data, dispatch, history);
					setDataIds({ ...dataIds, ...res.data });
					setMode("EDIT");
					if (onFinished) onFinished(res.data, dispatch, history);
				} else if (mode === "EDIT") {
					console.log(`[SmarthopForm] PUT: edited data, id=${JSON.stringify(dataIds)}`);
					if (form.callbacks?.onEdit) form.callbacks?.onEdit(res.data, dispatch, history);
					if (onFinished) onFinished(res.data, dispatch, history);
				} else if (mode === "DELETE") {
					console.log(`[SmarthopForm] DELETE: deleted data, id=${JSON.stringify(dataIds)}`);
					if (form.callbacks?.onDelete) form.callbacks?.onDelete(res.data, dispatch, history);
					if (onFinished) onFinished(res.data, dispatch, history);
				}

				if (!disableMessages) {
					const message = _.isFunction(messages?.success)
						? messages?.success?.(res?.data)
						: messages?.success ?? "Done!";
					showSnackbar(snackbar, message, "success");
				}
			})
			.catch((error) => {
				setProcessing(false);
				let response = error?.response;
				let errors = response?.data?.errors?.map
					? response?.data?.errors
					: [{ type: "generic", message: "Something went wrong, please try again later" }];

				const warnings = errors?.[0]?.metadata?.warnings;
				if (!warnings?.length) {
					console.error(
						`[SmarthopForm] POST/PUT: failed with status=${response?.status}, data=`,
						response?.data,
						!response?.status ? error : ""
					);
					if (onProcessing) onProcessing(false);
					setErrors(errors);
					if (onError) onError(errors, dispatch, snackbar, history);
				} else {
					setOpenWarningDialog({
						warnings: warnings,
					});
					if (onFinished) onFinished(updatedData, dispatch, history);
				}
			});
		// eslint-disable-next-line
	}, [form, dataIds, updatedData, currentData, onDone, mode, actionURL, dispatch]);

	const handleOnSubmit = (model, key) => {
		if (form?.onSubmitOverride) {
			form.onSubmitOverride(model, dataIds, onDone, dispatch, snackbar, history, key);
			return;
		}

		if (executed && JSON.stringify(currentData) === JSON.stringify(model) && !currentDataOverrides) {
			console.log(`[SmartHopForm] no changes to submit`);
			showSnackbar(snackbar, "No New Changes, Nothing to Update...", "info");
			return;
		}

		setUpdatedData(model);
	};

	const cleanData = (data) => {
		const newData = { ...data };
		for (const [key, value] of Object.entries(data)) {
			newData[key] = value && typeof value === "string" ? value.trim() : value;
		}
		return newData;
	};

	const handleOnDelete = (formModel) => {
		let entityModel = JSON.parse(JSON.stringify(currentData));
		let model = { ...(entityModel ?? {}), ...(formModel ?? {}), delete: true };
		setUpdatedData(model);
	};

	const handleOnCancel = () => {
		if (onDone) onDone();
	};

	const onAcceptWarnings = () => {
		setUpdatedData((data) => ({ ...data, ignoreWarnings: true }));
		setOpenWarningDialog(null);
	};

	const onDeclineWarnings = () => {
		if (props?.onDeclineWarnings) props?.onDeclineWarnings();
		setOpenWarningDialog(null);
	};

	if (queryMissingParams) {
		return (
			<Typography color="primary" className="m-10">
				Form mode '{mode}' action url '{actionURL}' required mandatory params [{queryMissingParams}] which were not
				provided in 'dataIds' props
			</Typography>
		);
	}

	if (!errors && !currentData && (mode === "EDIT" || mode === "DELETE")) {
		return (
			<SmarthopFormView
				key={"loading_form"}
				content={content}
				data={{}}
				mode={mode}
				nativeMobile={nativeMobile}
				loading={true}
			/>
		);
	}

	return (
		<>
			<WarningConfirmDialog
				open={!!openWarningDialog}
				warnings={openWarningDialog?.warnings}
				onAccept={onAcceptWarnings}
				onClose={onDeclineWarnings}
			/>
			<SmarthopFormView
				key={"data_form"}
				content={content}
				data={currentData}
				dataIds={dataIds}
				mode={mode}
				errors={errors}
				nativeMobile={nativeMobile}
				loading={loading}
				processing={processing}
				loadingError={loadingError}
				trackChangedFields={content?.submitOnChange ? ["ALL"] : content?.form?.trackChangedFields ?? null}
				onChangeCommitted={
					content?.submitOnChange
						? (model, key, valid) => {
								if (valid) handleOnSubmit(model, key);
						  }
						: null
				}
				onSubmit={handleOnSubmit}
				onCancel={handleOnCancel}
				onDelete={handleOnDelete}
			/>
		</>
	);
}

export default SmarthopForm;
