// Utils
import { formatCurrency } from "app/main/utils/tableUtils";
import { stripeCustomerLink } from "app/main/utils/billingUtils";

const invoices = {
	urlGET: "billing/invoices",
	idKey: "_id",
	listenEvent: "invoiceRevision",
	segmentKey: "ADMIN_INVOICES",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["created_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_date",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "carrier",
					type: "autocomplete",
					label: "Carrier",
					translate: "",
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/carriers",
						params: {
							sortOrder: "asc",
							sortBy: "carrierid",
							filters: { customer_id: "__NOT_EMPTY__" },
						},
					},
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
				{
					key: "detailed_information",
					type: "checkbox",
					label: "Show Detailed Information",
				},
			],
		},
		items: [
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "invoice_id", type: "id", label: "Invoice ID", grid: { headerSubtitle: true }, group: "internal" },
			{
				key: "invoice__view",
				type: "invoice__view",
				label: "Invoice",
				viewConfig: {
					showStatus: true,
				},
				grid: { headerTitle: true, valuePefix: "Invoice " },
			},
			{ key: "carrier", type: "id", label: "Carrier ID", group: "internal" },
			{
				key: "carrier__view",
				type: "carrier__view",
				label: "Carrier",
				viewConfig: {
					showMcNumber: true,
					showTestCarrier: true,
					showCarrierAccountType: true,
				},
			},
			{
				key: "customer",
				type: "component",
				label: "Stripe Customer",
				translate: "",
				group: "detailed_information",
				builder: (item) => stripeCustomerLink(item?.customer),
			},
			{ key: "type", type: "text", label: "Type", translate: "" },
			{ key: "amount", type: "text", label: "Amount", translate: "", builder: (item) => formatCurrency(item.amount) },
			{ key: "created_date", type: "date", label: "Creation Date", translate: "" },
			{
				key: "menu",
				type: "menu",
				label: "Actions",
				items: [
					{
						key: "charge",
						label: "Charge",
						translate: "",
						auth: ["administrator"],
						isVisible: (item) =>
							["fuel", "dispatching"].includes(item?.type) && ["draft", "open"].includes(item?.status),
						dialogBuilder: (item) => ({
							viewId: "INVOICE_CHARGE_VIEW",
							dataIds: { number_invoice: item?.number_invoice, type: item?.type },
						}),
					},
				],
			},
		],
	},
};

export default invoices;
