import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const DATE_OVERRIDE_ENABLED_JOBS_WITH_USERS = ["GENERATE_ENTRIES_PAYROLL_RULES"];

const DATE_OVERRIDE_ENABLED_JOBS_WITH_DRIVERS = [
	"PAYROLL_RECURRING_DEDUCTIONS",
	"SCHEDULE_MANAGEMENT_RECURRING_DEDUCTIONS",
	"PAYROLL_PERIOD_CLOSE",
];

const DATE_OVERRIDE_ENABLED_JOBS = [
	"PLATFORM_DELETION_AND_GRACE_PERIOD",
	"SYNC_FUEL_TRANSACTIONS",
	"DAILY_SYNC_FUEL_TRANSACTIONS",
	"SYNC_STRIPE_CUSTOMERS_DATA",
	"POST_TRIAL_SUBSCRIPTION_AND_REMOVE_DEMO_EXPIRED_TIERS",
	"AUTOMATIC_PAYROLL_CLOSING",
];

const CARRIER_OVERRIDE_ENABLED_JOBS = ["SYNC_FUEL_CARRIERS", "INJECT_TEST_FUEL_TRANSACTIONS"];

const WARNING_MESSAGE = {
	type: "message",
	builder: () => (
		<div className="flex flex-row justify-center space-x-10">
			<ErrorOutlineIcon />
			<span className="font-bold">Date Override allows us to do a simulation and not a full run</span>
		</div>
	),
};

/**
 * Displays the carrier field depending on the job chosen
 */
const overrideCarrierConfig = (values) => ({
	values,
	showItems: [
		{
			type: "group",
			content: {
				items: [
					{
						key: "carrier",
						type: "autocomplete",
						label: "Carrier",
						field: {
							noErrorMessage: true,
							classes: {},
						},
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: {
								options_active_only: true,
								options_my_assigned: false,
							},
						},
					},
				],
			},
		},
	],
});

/**
 * Displays the date field depending on the job chosen
 */
const overrideDateConfig = (values, showWarning = true) => {
	let config = overrideCarrierConfig(values, showWarning);
	config.showItems?.unshift({
		type: "group",
		content: {
			items: [
				{
					key: "startDate",
					type: "picker",
					label: "Date Override",
					picker: {
						type: "datetime",
						classes: {},
						format: "dd/MM/yyyy",
					},
				},
				{
					key: "endDate",
					type: "picker",
					label: "End Date Override",
					picker: {
						type: "datetime",
						classes: {},
						format: "dd/MM/yyyy",
					},
				},
			],
		},
	});

	if (showWarning) {
		config.showItems.push(WARNING_MESSAGE);
	}
	return config;
};

/**
 * Displays the driver field depending on the job chosen
 */
const overrideDateWithDriverConfig = (values) => {
	const showWarning = false;
	let config = overrideDateConfig(values, showWarning);
	config.showItems.push(
		{
			type: "group",
			content: {
				items: [
					{
						key: "driver",
						type: "autocomplete",
						label: "Driver",
						translate: "",
						field: {
							noErrorMessage: true,
							classes: {},
							variant: undefined,
						},
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/drivers",
							params: { options_active_only: true },
							preloadDefault: true,
							preloadImmedately: true,
							listenEvent: "profileRevision",
						},
						rules: {
							params: {
								valueRef: "carrier",
								paramKey: "options_carrier_id_search",
							},
						},
					},
				],
			},
		},
		WARNING_MESSAGE
	);
	return config;
};

/**
 * Displays the user field depending on the job chosen
 */
const overrideDateWithUserConfig = (values) => {
	const showWarning = false;
	let config = overrideCarrierConfig(values, showWarning);
	config.showItems.push({
		type: "group",
		content: {
			items: [
				{
					key: "user",
					type: "autocomplete",
					label: "User",
					translate: "",
					field: {
						noErrorMessage: true,
						classes: {},
						variant: undefined,
					},
					autocomplete: {
						provider: "smarthop",
						url: "api/profile/users",
						params: { options_active_only: true },
						preloadDefault: true,
						preloadImmedately: true,
						listenEvent: "profileRevision",
					},
				},
			],
		},
	});
	return config;
};

const createCronJob = {
	urlPOST: "api/cron/users/:userId/runs",
	idKey: "_id",
	triggerEvent: "cronJobsRevision",
	listenEvent: "cronJobsRevision",
	header: {
		create: { label: "Start Job" },
	},
	content: {
		create: {
			items: [
				{
					key: "jobKey",
					type: "autocomplete",
					label: "Cron Job Name",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "api/cron/jobs",
						preloadDefault: true,
					},
					options: [
						overrideCarrierConfig(CARRIER_OVERRIDE_ENABLED_JOBS),
						overrideDateConfig(DATE_OVERRIDE_ENABLED_JOBS),
						overrideDateWithDriverConfig(DATE_OVERRIDE_ENABLED_JOBS_WITH_DRIVERS),
						overrideDateWithUserConfig(DATE_OVERRIDE_ENABLED_JOBS_WITH_USERS),
					],
				},
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: (fields) => fields.jobKey__view.options?.[0]?.metadata?.sensitive,
						typedConfirmation: "Accept",
						message: "This is a sensitive job, please make sure you understand what you are doing.",
					},
				},
			],
		},
	},
};

export default createCronJob;
