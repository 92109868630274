import { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import uniqid from "uniqid";
import { useSnackbar } from "notistack";

// state
import { useDispatch, useSelector } from "react-redux";
import { addDummyFile, cancelFile, deleteOneFile, fetchFiles, updateFile } from "app/store/upload/filesSlice";
import { openFormDialog } from "app/store/tools/formDialogSlice";

// material
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

// components
import { SmarthopConfirmDialog } from "@smarthop/form";
import SmarthopAutocompleteField from "@smarthop/form/fields/SmarthopAutocompleteField";
import DropZone from "./DropZone";
import PaywallDialog from "app/main/billing/paywall/PaywallDialog.js";

// services
import { global } from "app/services/requestUtil";
import { getParsedRC, retryFileUpload, startQueueFiles, saveFileOnTrip } from "app/services/multiUploadServices";
import { getCarrierInformation } from "app/services/carrierServices";
import { buildLink } from "app/services/fileServices";
import { getUserTier } from "app/services/LoginService";

// utils
import { showSnackbar } from "../utils/snackbarUtil";
import { Tooltip } from "@material-ui/core";

const STATUS = {
	queueing: { label: "Queueing...", icon: "queue", type: "QUEUE" },
	uploading: { label: "Uploading...", icon: "backup", type: "IN_PROGRESS" },
	uploaded: { label: "Uploaded", icon: "cloud_done", type: "IN_PROGRESS" },
	ml_rc: { label: "Scanning...", icon: "scanner", type: "IN_PROGRESS" },
	ml_bol: { label: "Scanning...", icon: "scanner", type: "IN_PROGRESS" },
	rc: { label: "Reading...", icon: "search", type: "IN_PROGRESS" },
	bol: { label: "Reading...", icon: "search", type: "IN_PROGRESS" },
	ocr: { label: "Scanning...", icon: "scanner", type: "IN_PROGRESS" },
	gpt: { label: "Reading...", icon: "search", type: "IN_PROGRESS" },
	ready: { label: "Ready", icon: "done", type: "SUCCESS_READY" },
	ready_convert_bol: { label: "Ready", icon: "done", type: "SUCCESS_READY_CONVERT_BOL" },
	created: { label: "Created", icon: "done_all", type: "SUCCESS_SAVED" },
	updated: { label: "Updated", icon: "done_all", type: "SUCCESS_UPDATED" },
	error: { label: "Failure", icon: "close", type: "FAILURE" },
	error_mimetype: { label: "Failure", icon: "close", type: "FAILURE_MIMETYPE" },
	canceled: { label: "Canceled", icon: "close", type: "CANCELED" },
};
const _QUEUE_STATUS = [STATUS.queueing.type];
const _IN_PROGRESS_STATUS = [
	STATUS.uploading.type,
	STATUS.uploaded.type,
	STATUS.ml_rc.type,
	STATUS.ml_bol.type,
	STATUS.rc.type,
	STATUS.bol.type,
	STATUS.ocr.type,
	STATUS.gpt.type,
];
const _FAILURE_STATUS = [STATUS.error.type];
const _FAILURE_MIMETYPE_STATUS = [STATUS.error_mimetype.type];
const _CANCELED_STATUS = [STATUS.canceled.type];
const _SUCCESS_READY_STATUS = [STATUS.ready.type];
const _SUCCESS_READY_CONVERT_BOL_STATUS = [STATUS.ready_convert_bol.type];
const _SUCCESS_SAVED_STATUS = [STATUS.created.type];
const _SUCCESS_UPDATED_STATUS = [STATUS.updated.type];

const progressBoxStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "absolute",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const FileRowNew = ({
	file,
	goToCreateTrip,
	handleCreateTrip,
	handlePreview,
	handleDeleteFile,
	handleRetry,
	setOpenConfirmDialog,
	user,
	openUserDialog,
	hasSubaccounts,
}) => {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [formData, setFormData] = useState({});
	const [processing, setProcessing] = useState(false);

	const finishCreated = file?.status === "created";
	const finishUpdated = file?.status === "updated" && !file?.metadata?.created?.updatedBolFiles;
	const finishUpdatedBol = file?.status === "updated" && file?.metadata?.created?.updatedBolFiles;
	const hasError = file?.status === "error" || file?.status === "error_mimetype";
	const isCanceled = file?.status === "canceled";
	const isReady = file?.status === "ready";
	const isReadyBol = file?.status === "ready_convert_bol";
	const isUploaded = !!file?.fileId;
	const notProcessing =
		finishCreated || finishUpdated || finishUpdatedBol || hasError || isCanceled || isReady || isReadyBol;

	const handleTripDetailView = (file) => {
		dispatch(
			openFormDialog({
				viewId: "TRIP_VIEW",
				dataIds: {
					carrierId: file?.metadata?.created?.carrierId,
					tripId: file?.metadata?.created?.tripId,
					openChat: true,
				},
			})
		);
	};

	const getLabel = (file) => {
		let label;
		let icon;

		const type = STATUS[file?.status]?.type;
		if (_QUEUE_STATUS.includes(type)) {
			label = "Waiting to process...";
		} else if (_IN_PROGRESS_STATUS.includes(type)) {
			label = "Processing...";
		} else if (_FAILURE_STATUS.includes(type)) {
			label = (
				<>
					Failed to process file. Click retry or add a trip manually by clicking
					<Link onClick={() => goToCreateTrip(file)}>
						<span className="mx-3 text-blue-800">here.</span>
					</Link>
				</>
			);
		} else if (_FAILURE_MIMETYPE_STATUS.includes(type)) {
			label =
				"Failed to process file. Unsupported file-type, please upload a PDF document or image with text with load information.";
		} else if (_CANCELED_STATUS.includes(type)) {
			label = (
				<>
					File processing canceled. Click retry or add a trip manually by clicking
					<Link onClick={() => goToCreateTrip(file)}>
						<span className="mx-3 text-blue-800">here.</span>
					</Link>
				</>
			);
		} else if (_SUCCESS_READY_STATUS.includes(type)) {
			icon = "done";
			label = (
				<>
					We were unable to identify some mandatory information in the upload RC file, please provide it
					<Link onClick={() => handleCreateTrip(file?.fileId, null, null, file?.metadata?.uploadId)}>
						<span className="mx-3 text-blue-800">here.</span>
					</Link>
				</>
			);
		} else if (_SUCCESS_READY_CONVERT_BOL_STATUS.includes(type)) {
			const type = file.metadata?.openAiDoc?.metadata[0]?.document_type;
			icon = "image_search";
			label = "We have analyzed the file and detected that it is not RateCon";
			if (type) label = `We have analyzed the file and detected that it is a [${type}].`;
		} else if (_SUCCESS_SAVED_STATUS.includes(type)) {
			icon = "done_all";
			label = (
				<>
					Trip was created successfully. More details by clicking
					<Link onClick={() => handleTripDetailView(file)}>
						<span className="mx-3">{file.metadata.created.loadId}.</span>
					</Link>
				</>
			);
		} else if (_SUCCESS_UPDATED_STATUS.includes(type)) {
			if (file.metadata.created.updatedBolFiles) {
				icon = "done_all";
				label = (
					<>
						The file was successfully uploaded on a trip. More details by clicking
						<Link onClick={() => handleTripDetailView(file)}>
							<span className="mx-3">{file.metadata.created.loadId}.</span>
						</Link>
					</>
				);
			} else {
				label = (
					<>
						It seems there is already another load with the same id
						<Link onClick={() => handleTripDetailView(file)}>
							<span className="mx-3">{file.metadata.created.loadId},</span>
						</Link>
						please upload RC file to that trip instead.
					</>
				);
			}
		}

		return { label, icon };
	};

	const handleCancel = async () => {
		dispatch(cancelFile({ fileId: file?.fileId, uploadId: file?.metadata?.uploadId }));
	};

	const handlerSelectTrip = async () => {
		setProcessing(true);

		await saveFileOnTrip(file?.fileId, formData.value)
			.then(() => {})
			.catch((error) => {
				setProcessing(false);
				const message = error?.errors?.[0]?.message ?? `There was an error saving the file on the trip`;
				showSnackbar(snackbar, message, "error", 5000);
			});
	};

	return (
		<div className="flex flex-row border-b-1 items-center min-h-68">
			<div className="flex h-40 w-40 items-center justify-center pr-10">
				{hasError || isCanceled ? (
					<IconButton
						size="small"
						className="bg-red-400 hover:bg-red-200"
						onClick={() => handleRetry({ fileId: file?.fileId, uploadId: file?.metadata?.uploadId })}
					>
						<Icon className="text-24 text-white font-light">refresh</Icon>
					</IconButton>
				) : isReady || finishUpdated ? (
					<Icon className="text-32 font-light" color="secondary">
						warning
					</Icon>
				) : notProcessing ? (
					<Icon className="text-28">{getLabel(file)?.icon ?? "done"}</Icon>
				) : (
					<Tooltip title="Cancel">
						<IconButton onClick={() => handleCancel()} disabled={!isUploaded}>
							<Box sx={{ display: "inline-flex" }}>
								<CircularProgress size={27} />
								<Box sx={progressBoxStyle}>
									<Icon className="opacity-60 hover:opacity-100" fontSize="0.7rem" color="error">
										close
									</Icon>
								</Box>
							</Box>
						</IconButton>
					</Tooltip>
				)}
			</div>

			<div className={"flex flex-col"}>
				<div className="flex">
					<Link onClick={() => handlePreview(file?.fileId)}>{file?.metadata?.name}</Link>
					{file.userId && user?._id !== file.userId && (
						<>
							<span className="mx-3 text-grey-700 ">by </span>
							<Link onClick={() => openUserDialog(file.userId, file.carrierId)}>{`${
								(file?.userName ?? "") + (file?.carrierName && hasSubaccounts ? ` (${file?.carrierName})` : "")
							}`}</Link>
						</>
					)}
					{file?.metadata?.isFromEmail && <span className="mx-3 text-grey-700 "> via email</span>}
				</div>

				<Typography className={"text-grey-700 text-12 -mt-2 mr-10"}>
					{STATUS[file?.status]?.type?.includes("FAILURE") && (
						<Icon className="text-16 mr-3 -mb-3 text-red">warning</Icon>
					)}
					{getLabel(file)?.label ?? "Oops..."}
				</Typography>
			</div>

			<div className="flex flex-col flex-1" />

			{isReadyBol && (
				<div className="flex flex-row items-center justify-end w-4/12 mr-10">
					<div className="flex flex-row w-9/12">
						<SmarthopAutocompleteField
							name="trip"
							inputValue={""}
							optionsValue={[formData ?? null]}
							disabled={processing}
							autocomplete={{
								provider: "smarthop",
								url: "api/booking/trip_search",
								listenEvent: "profileRevision",
								preloadDefault: true,
								params: {
									filters: {
										trip_status: "__ACTIVE_DELIVERED__",
										createdAt: "__DATE_DAYS_GT__15",
									},
									options_show_carrier_description: true,
								},
								placeholder: "Select Active Trip...",
							}}
							field={{ noErrorMessage: true, variant: "standard" }}
							input={{ classes: { input: "text-12 my-5" } }}
							onSelected={(optionsValues) => setFormData(optionsValues[0])}
						/>
					</div>
					{!processing ? (
						<IconButton
							size="small"
							className="bg-grey-300 hover:bg-grey-200 p-7 mt-1 ml-8"
							onClick={() => handlerSelectTrip()}
						>
							<Icon className="text-20 text-black font-light">check</Icon>
						</IconButton>
					) : (
						<Box className="mt-8 ml-10">
							<CircularProgress size={30} className="text-secondary" />
						</Box>
					)}
				</div>
			)}

			{notProcessing ? (
				<div className="flex flex-row items-center justify-center">
					<IconButton
						size="small"
						className="bg-grey-300 hover:bg-grey-200 mr-0 md:mr-16 p-7"
						onClick={() => {
							if (file.status === "created") {
								handleDeleteFile({
									fileId: file?.fileId,
									uploadId: file?.metadata?.uploadId,
									carrierId: file?.carrierId,
								});
							} else {
								setOpenConfirmDialog({
									msg: (
										<>
											Are you sure you want to remove this file from the upload list?
											<Typography color="secondary">{file?.metadata?.name}</Typography>
										</>
									),
									cb: handleDeleteFile,
									args: { fileId: file?.fileId, uploadId: file?.metadata?.uploadId, carrierId: file?.carrierId },
								});
							}
						}}
					>
						<Icon className="text-20 text-black font-light">close</Icon>
					</IconButton>
				</div>
			) : (
				<div className="flex flex-row min-w-150 animate-pulse">
					<Icon className="text-20 mr-6">{STATUS[file?.status]?.icon ?? "close"}</Icon>
					<div className="flex flex-row">
						<Typography className="text-14">{STATUS[file?.status]?.label ?? "Oops..."}</Typography>
					</div>
				</div>
			)}
		</div>
	);
};

const useFilePolling = (carrierId) => {
	const _MAX_PROCESSING = 5;

	const userTier = getUserTier();

	const mounted = useRef();
	const dispatch = useDispatch();

	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const visibleForm = formStack.length > 0 ? formStack[formStack.length - 1] : null;

	let files = useSelector(({ upload }) => upload?.files?.list ?? []);
	if (userTier === "TIER_STARTER") files = [];

	const fileStatusCheck = async () => {
		try {
			if (mounted.current) {
				dispatch(fetchFiles());
			}
		} catch (err) {
			console.error("Error: ", err);
		}
	};

	const calculateProcessingFiles = (files) => {
		const countError = files.reduce((prev, file) => {
			let count = file.status === "error" ? prev + 1 : prev;
			return count;
		}, 0);
		const countReady = files.reduce((prev, file) => {
			let count = file.status === "ready" ? prev + 1 : prev;
			return count;
		}, 0);
		const countOCR = files.reduce((prev, file) => {
			let count = file.status === "ocr" ? prev + 1 : prev;
			return count;
		}, 0);
		const countGPS = files.reduce((prev, file) => {
			let count = file.status === "gps" ? prev + 1 : prev;
			return count;
		}, 0);
		const countQueueing = files.reduce((prev, file) => {
			let count = file.status === "queueing" ? prev + 1 : prev;
			return count;
		}, 0);

		return {
			processed: countError + countReady,
			processing: countOCR + countGPS,
			queueing: countQueueing,
		};
	};

	const { processed, processing, queueing } = calculateProcessingFiles(files);
	if (processed > 0 && processing < _MAX_PROCESSING && queueing > 0) {
		startQueueFiles();
	}

	useEffect(() => {
		mounted.current = true;
		fileStatusCheck();
		return () => (mounted.current = false);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		mounted.current = visibleForm?.viewId === "MULTI_FILE_UPLOAD_VIEW";
	}, [visibleForm]);

	return files;
};

export const uploadFiles = async (files, dispatch, existingUploadId, isPriority, carrierId, ignoreCreateTrip) => {
	if (!files.length) return;

	let headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	let data = new FormData();
	files.forEach((file) => {
		const uploadId = existingUploadId ?? uniqid();
		data.append("files", file);
		data.append("uploadIds", uploadId);
		// The fileId doesn't exist at this point where the file is dragged in the dropzone,
		// adding a random placeholder here until all these dummy records get replaced
		dispatch(
			addDummyFile({
				status: "uploading",
				dialogId: uploadId,
				metadata: { name: file.name, type: "RC", uploadId },
			})
		);
	});
	data.append("type", "rate_con_file");
	data.append("document_type", "trip");
	data.append("field_name", "rate_confirmation");
	isPriority && data.append("isPriority", isPriority);
	carrierId && data.append("carrierId", carrierId);
	ignoreCreateTrip && data.append("ignoreCreateTrip", ignoreCreateTrip);

	let url = "api/multi-upload/upload";

	console.log(`[MultiFileUploadView] uploading files, files=`, files);
	return axios
		.create({ baseURL: global.SERVER_NAME, headers: headers })
		.post(url, data, headers)
		.then((res) => {
			console.log(`[MultiFileUploadView] POST, uploaded files, data=`, res.data);
			return res.data;
		})
		.catch((res) => {
			console.error(`[MultiFileUploadView] error, status=${res?.status}`);
			return res;
		});
};

const MultiFileUploadView = ({ dataIds, setTitle, setSize, onDone }) => {
	useEffect(() => {
		setTitle?.("Rate Confirmation Upload");
		setSize?.("max-w-2xl");
		// eslint-disable-next-line
	}, []);
	const { carrierId, customMsg } = dataIds;

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();

	const files = useFilePolling(carrierId);
	const filesParsing = files.filter((item) => item.status !== "created");
	const filesCreated = files.filter((item) => item.status === "created");

	const [carrierInfo, setCarrierInfo] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [paywallDialog, setPaywallDialog] = useState({});
	const [notificationSettings, setNotificationSettings] = useState(null);

	const fileInputRef = useRef();

	const location = useLocation();
	const path = location.pathname;

	const user = useSelector(({ auth }) => auth.user);
	const role = user.role;

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const filesIds = files.map((file) => file.metadata.uploadId);
	localStorage.setItem("filesIds", JSON.stringify(filesIds));

	const getCarrierView = (carrierInfo) => ({
		description: `Carrier #${carrierInfo.carrierid}`,
		label: carrierInfo.carrier_company_name,
		value: carrierInfo._id,
		metadata: {
			notificationDispatched: notificationSettings?.dispatchedNotificationBroker,
			notificationAtPickup: notificationSettings?.atPickedUpNotificationBroker,
			notificationAtDelivery: notificationSettings?.atDeliveryNotificationBroker,
			hasSmartPayProgram: carrierInfo?.hasSmartPayProgram,
		},
	});

	useEffect(() => {
		const getCarrierInfo = async (carrierId) => {
			const carrierInfo = await getCarrierInformation(carrierId);
			setNotificationSettings(carrierInfo?.notificationSettings);
			if (!hasSubaccounts) {
				setCarrierInfo(carrierInfo);
			} else {
				setCarrierInfo(null);
			}
		};

		getCarrierInfo(carrierId);
		return;
		//eslint-disable-next-line
	}, []);

	const handleCreateTrip = async (fileId, tripId, carrierView, uploadId) => {
		try {
			const { parsedData } = await getParsedRC(fileId);

			const carrier = hasSubaccounts || parsedData.carrier ? parsedData.carrier : carrierId;
			const carrier__view =
				hasSubaccounts || parsedData.carrier ? parsedData.carrier__view : getCarrierView(carrierInfo);

			dispatch(
				openFormDialog({
					viewId: "TRIP_EDIT_VIEW",
					mode: tripId ? "EDIT" : "CREATE",
					dataIds: {
						carrierId: carrier,
						uploadId,
						rcDataEditable: {
							...parsedData,
							rate_con_file: [fileId],
							rate_con_file__flag: true,
							carrier: carrier,
							carrier__view: carrier__view,
							...(!tripId && {
								creator__view: {
									value: user?._id,
									label: user?.first_name + " " + user?.last_name,
									description: user?.email,
								},
							}),
						},
						mode: tripId ? "EDIT" : "CREATE",
						tripId,
						metadata: {
							rcUpload: {
								isApplied: true,
							},
						},
					},
				})
			);
		} catch (error) {
			showSnackbar(snackbar, `There was an error while creating the trip`, "error", 5000);
		}
	};

	const handleDeleteFile = useCallback(async ({ fileId, uploadId, carrierId }) => {
		try {
			dispatch(deleteOneFile({ fileId, uploadId, carrierId, isAdmin: role === "ADMIN" }));
			console.log("[MultiFileUploadView] File deleted");
		} catch (err) {
			console.error("[MultiFileUploadView] Error: ", err);
		}
		// eslint-disable-next-line
	}, []);

	const handleRetry = useCallback(async ({ fileId, uploadId }) => {
		try {
			dispatch(updateFile({ uploadId, data: { status: "uploading" } }));
			await retryFileUpload(fileId);
			dispatch(fetchFiles());
		} catch (err) {
			console.error("[MultiFileUploadView] Error: ", err);
		}
		// eslint-disable-next-line
	}, []);

	const handleAccept = useCallback(() => {
		const callback = openConfirmDialog.cb;
		const args = openConfirmDialog?.args;
		callback(args);
		setOpenConfirmDialog(false);
	}, [openConfirmDialog]);

	const handleDialogClose = useCallback(() => {
		setOpenConfirmDialog(false);
	}, []);

	const handlePreview = (value) => {
		let viewUrl = buildLink(value);
		dispatch(
			openFormDialog({
				viewId: "FILE_VISUALIZER_VIEW",
				dataIds: {
					urlFilePreview: viewUrl,
				},
			})
		);
	};

	const openUserDialog = (userId, carrierId) => {
		dispatch(
			openFormDialog({
				viewId: "USER_DETAILS_VIEW",
				dataIds: { userId, carrierId },
			})
		);
	};

	const handleFileChange = (ev) => {
		if (getUserTier() === "TIER_STARTER") {
			setPaywallDialog({
				open: true,
				dataIds: {
					type: "rc_transcription_restriction",
					data: { callback: handleFileChange },
					extraData: { dataSend: ev },
				},
			});
			return;
		}
		uploadFiles(Array.from(ev.target.files), dispatch).then(() => dataIds?.onUploadFile?.());
		ev.target.value = null;
	};

	const goToCreateTrip = (file) => {
		dispatch(
			openFormDialog({
				viewId: "TRIP_EDIT_VIEW",
				mode: "CREATE",
				dataIds: {
					carrierId: carrierInfo ? carrierId : null,
					rcDataEditable: {
						carrier: carrierInfo ? carrierId : null,
						carrier__view: carrierInfo ? getCarrierView(carrierInfo) : null,
						creator__view: {
							value: user?._id,
							label: user?.first_name + " " + user?.last_name,
							description: user?.email,
						},
						rate_con_file: file ? [file?.fileId] : null,
						rate_con_file__flag: file ? true : false,
						send_broker_dispatched: notificationSettings?.dispatchedNotificationBroker,
						send_broker_at_pick_up: notificationSettings?.atPickedUpNotificationBroker,
						send_broker_at_delivery: notificationSettings?.atDeliveryNotificationBroker,
					},
					mode: "CREATE",
				},
			})
		);
	};

	return (
		<div className="relative">
			<Box className="p-14">
				{customMsg}
				{filesParsing?.length || filesCreated?.length ? (
					<div>
						<UploadHeader fileInputRef={fileInputRef} setOpenConfirmDialog={setOpenConfirmDialog} files={files} />
						{filesParsing?.map((file) => (
							<div key={"new_" + file?.metadata?.uploadId}>
								<FileRowNew
									file={file}
									goToCreateTrip={goToCreateTrip}
									handleCreateTrip={handleCreateTrip}
									handleDeleteFile={handleDeleteFile}
									handlePreview={handlePreview}
									handleRetry={handleRetry}
									setOpenConfirmDialog={setOpenConfirmDialog}
									user={user}
									hasSubaccounts={hasSubaccounts}
									openUserDialog={openUserDialog}
								/>
							</div>
						))}

						{filesCreated.length > 0 && (
							<>
								<TripCreatedHeader />
								{filesCreated?.map((file) => (
									<div key={"new_" + file?.metadata?.uploadId}>
										<FileRowNew
											file={file}
											goToCreateTrip={goToCreateTrip}
											handleCreateTrip={handleCreateTrip}
											handleDeleteFile={handleDeleteFile}
											handlePreview={handlePreview}
											handleRetry={handleRetry}
											setOpenConfirmDialog={setOpenConfirmDialog}
											user={user}
											hasSubaccounts={hasSubaccounts}
											openUserDialog={openUserDialog}
										/>
									</div>
								))}
							</>
						)}
					</div>
				) : (
					<Alert icon={false} severity="error">
						<Button
							style={{
								borderRadius: 5,
								marginRight: 10,
								fontSize: 12,
								marginBottom: 1,
								color: "#611a15",
								borderColor: "#611a15",
							}}
							variant="outlined"
							onClick={() => fileInputRef.current.click()}
						>
							Upload
						</Button>
						or drag and drop multiple RateCon files.
						<br />
					</Alert>
				)}
			</Box>
			<div className="flex flex-row px-12 py-16 mt-8 justify-between items-center rounded-8 bg-grey-100 bottom-0 right-0 w-full">
				<Typography className="mr-10">
					Don't have an RateCon?
					<Link onClick={() => goToCreateTrip()}>
						<span className="underline mx-5 text-blue-800">Click Here</span>
					</Link>
					to start a trip without one (you can add a RateCon anytime)
				</Typography>
				<Button
					style={{
						borderRadius: 5,
						color: "white",
						height: "35px",
						marginTop: "2px",
						marginLeft: "8px",
					}}
					className="w-3/6 md:w-1/6"
					color="secondary"
					variant="contained"
					onClick={() => {
						onDone();
						history.push(user.roleType === "EXTERNAL" ? "/dispatching?tab=0" : "/profile/load/dispatching");
					}}
				>
					Manage Trips
				</Button>
			</div>
			<input type="file" multiple style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
			{(path !== "/home" || role === "ADMIN") && <DropZone />}

			<SmarthopConfirmDialog
				open={!!openConfirmDialog}
				message={openConfirmDialog?.msg}
				handleClose={handleDialogClose}
				handleAccept={handleAccept}
				closeMsg={"No"}
				acceptMsg={"Yes"}
			/>
			<PaywallDialog data={paywallDialog} onHandlerPaywall={() => setPaywallDialog({})} />
		</div>
	);
};

export const MULTIFILE_STATUS = STATUS;

export default MultiFileUploadView;

function UploadHeader({ fileInputRef, setOpenConfirmDialog, handleClear, files }) {
	return (
		<Box className="top-0 bg-white w-full z-50">
			<div className="flex flex-row py-8 border-b-1">
				<div className="flex flex-row flex-1 mb-5">
					<Typography className="text-14 font-semibold">Drag and drop RateCons or use the upload button</Typography>
					<Button
						style={{
							marginTop: -5,
							borderRadius: 5,
							marginLeft: 10,
							fontSize: 12,
							marginBottom: 1,
						}}
						variant="outlined"
						onClick={() => fileInputRef.current.click()}
						color="secondary"
					>
						Upload
					</Button>
				</div>
			</div>
		</Box>
	);
}

function TripCreatedHeader() {
	return (
		<Box className="top-0 bg-white w-full z-50">
			<div className="flex flex-row py-20 border-b-1">
				<Icon className="text-28 mr-8 text-green-600">check_circle_outline</Icon>
				<Typography className="text-14 font-semibold mt-3 text-green-600">Recently created trips</Typography>
			</div>
		</Box>
	);
}
