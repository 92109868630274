import axios from "axios";
import MobileDetect from "mobile-detect";
import { Typography, makeStyles } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { global } from "app/services/requestUtil";
import * as fileService from "app/services/fileServices";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";

const useStyles = makeStyles((theme) => {
	return {
		dropzoneActive: {
			animation: "$progress 2s linear infinite !important",
			// eslint-disable-next-line max-len
			backgroundImage: "repeating-linear-gradient(-45deg, "
				.concat(theme.palette.secondary.light, ", ")
				.concat(theme.palette.secondary.light, " 25px, ")
				.concat(theme.palette.background.default, " 25px, ")
				.concat(theme.palette.background.default, " 50px)"),
			backgroundSize: "150% 100%",
			border: "solid",
			borderWidth: "1px",
			borderColor: "#D29C89",
			pointerEvents: "none",
		},
	};
});

const FilesUploadView = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const snackbar = useSnackbar();
	const { fileInputRef, userId, tripId, carrierId, data, isPublicView, native, setData, setLoading } = props;

	const mobileDetect = new MobileDetect(window.navigator.userAgent);

	const [files, setFiles] = useState(data);
	const [newFile, setNewFile] = useState(null);
	const [warnings, setWarnings] = useState();
	const [isWarningDialogOpen, setIsWarningOpen] = useState(false);
	const [ignoreWarnings, setIgnoreWarnings] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);
	const [callActionOnce, setCallActionOnce] = useState(null);

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
	};

	useEffect(() => {
		if (ignoreWarnings) {
			handleUploadFile(newFile);
		}
		//eslint-disable-next-line
	}, [ignoreWarnings]);

	useEffect(() => {
		setFiles(data);
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	});

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "ScannedDocument":
					return OnScannedDocument(dataParsed.data);
				default:
					break;
			}
		} catch (e) {}
	};

	function dataURItoBlob(dataURI, dataTYPE) {
		var binary = atob(dataURI.split(",")[1]),
			array = [];
		for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
		return new Blob([new Uint8Array(array)], { type: dataTYPE });
	}

	const dataURLtoFile = async (url, filename, mime) => {
		let res = await fetch(url);
		let resBlob = await res.blob();
		let file = await new File([resBlob], filename, { type: mime });
		return file;
	};

	const OnScannedDocument = async (msg) => {
		if (native) setLoading(true);
		const genFilename = `${Date.now()}.${msg?.contentType.split("/")[1]}`;
		let content = msg?.imgData;
		let file;
		if (msg?.imageList) {
			content = await fileService.convert(msg.imageList);
			const blob = dataURItoBlob(content, msg?.contentType);
			file = await new File([blob], genFilename, { type: msg?.contentType });
		} else {
			file = await dataURLtoFile(content, genFilename, msg?.contentType);
		}
		handleOnDrop([file]);
	};

	const handleFileChange = (ev) => {
		setError(null);
		if (!ev?.target?.files) return setError("An error occurred while uploading the file");
		handleOnDrop(Array.from(ev.target.files));
		ev.target.value = null;
	};

	const handleOnDrop = (newFiles) => {
		setError(null);
		if (!newFiles?.length) return;

		setUploading(true);
		if (native) setLoading(true);

		const uploadConfig = {
			url: isPublicView ? `api/public/url/carriers/${carrierId}/files` : `api/booking/carriers/${carrierId}/files`,
			documentType: "trip",
			fileName: "file_id",
			type: "file_id",
			params: {
				...(isPublicView ? { options_public_user_context: userId } : {}),
			},
		};

		let data = new FormData();
		data.append("file", newFiles[0]);
		data.append("type", uploadConfig?.type);
		data.append("document_type", uploadConfig?.documentType);
		data.append("field_name", uploadConfig?.fileName);

		const params = uploadConfig?.params;
		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.post(uploadConfig?.url, data, { headers, params })
			.then((res) => {
				setUploading(false);
				if (native) setLoading(false);

				const file = { fileType: "other", file: { ...res, name: newFiles[0]?.name, type: newFiles[0]?.type } };
				setNewFile(file);
				handleUploadFile(file);
			})
			.catch((res) => {
				setUploading(false);
				if (native) setLoading(false);
				setError("An error occurred while uploading the file");
			});
	};

	const handleUploadFile = async (file) => {
		setLoading(true);

		const splitFile = file?.file?.name?.split(".");
		const data = [
			...files,
			{
				file_id: [file?.file?.data?.fileId],
				type: file.fileType,
				description: file.file?.name,
				typeFile: splitFile ? splitFile[splitFile?.length - 1] : "pdf",
				analize: { status: "PROGRESS", metadata: {} },
				new: true,
			},
		];
		await onSubmit(data);
	};

	const onSubmit = async (data) => {
		setIgnoreWarnings(false);
		setIsWarningOpen(false);

		const url = isPublicView
			? `api/public/url/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`
			: `api/booking/carrier/${carrierId}/trips/${tripId}/files/type/pod_files`;

		const dataSend = { data: { pod_files: data, ignoreWarnings: ignoreWarnings, action: "ADD" } };
		const params = isPublicView ? { options_public_user_context: userId } : {};

		axios
			.create({ baseURL: global.SERVER_NAME, headers: headers })
			.put(url, dataSend, { headers, params })
			.then((res) => {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "filesRevision" }));
				if (carrierId && !callActionOnce) {
					setCallActionOnce(true);
					dispatch(fetchActions({ carrierId }));
					if (native) {
						window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRIP_LIST", data: carrierId }));
					}
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				}

				setData(data);
				setLoading(false);
			})
			.catch((error) => {
				const response = error?.response;
				const err = { status: response?.status, errors: response?.data?.errors };
				setLoading(false);

				const warnings = err?.errors?.[0]?.metadata?.warnings;
				setWarnings(warnings);
				if (!warnings?.length) {
					showSnackbar(snackbar, err?.errors?.[0]?.message ?? "Error updating", "error");
				} else {
					setIsWarningOpen(true);
				}
			});
	};

	return (
		<div className={"h-full w-full " + (uploading ? "pointer-events-none" : "")}>
			<div className={!native ? "md:mb-12 h-full w-full" : ""}>
				{!native && (
					<DropzoneArea
						ref={props.innerRef}
						fileObjects={[]}
						maxFileSize={50 * 1024 * 1024}
						showPreviews={false}
						showPreviewsInDropzone={false}
						showFileNamesInPreview={false}
						showFileNames={false}
						filesLimit={1}
						dropzoneClass={
							"min-h-120 md:h-full w-full flex justify-center items-center " + (uploading ? classes.dropzoneActive : "")
						}
						dropzoneParagraphClass={"text-13 h-full md:-mt-32 " + (error ? "text-red" : "")}
						Icon={"b"}
						dropzoneText={uploading ? "Uploading file..." : error ? error : "Drag and Drop File"}
						showAlerts={false}
						onChange={(e) => handleOnDrop(e)}
						acceptedFiles={[".pdf", ".jpg", ".jpeg", ".png"]}
					/>
				)}
			</div>
			{!files?.length && native && (
				<div className="h-full w-full items-center justify-center align-center flex">
					<Typography className="text-16 text-grey-700 text-center">
						{"Scanner is recommended to simplify uploading proccess of multipage documents"}
					</Typography>
				</div>
			)}

			<input
				type="file"
				style={{ display: "none" }}
				ref={fileInputRef}
				onChange={(e) => handleFileChange(e)}
				accept={".jpg, .jpeg, .png, .pdf"}
			/>

			{warnings?.length && (
				<WarningConfirmDialog
					open={isWarningDialogOpen}
					warnings={warnings}
					onAccept={() => {
						setWarnings();
						setIgnoreWarnings(true);
					}}
					onClose={() => setWarnings()}
				/>
			)}
		</div>
	);
};

export default FilesUploadView;
