import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { truckFormConfig, TRUCK_DEFAULTS } from "@smarthop/form/configs/truckForm";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Service
import { isCarrier, signInWithToken } from "app/services/LoginService";
import { getTruck, createTruck, updateTruck } from "app/services/truckServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { setUserData } from "app/store/auth/userSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";

// Conts
const _TITLE_UPDATED_TRAILER = (trailerid) => `Do you want to assign the trailer ${trailerid} to this truck?`;
const _MESSAGE_UPDATED_TRAILER = `Currently the trailer is assigned. If you continue, this trailer will be transferred to this truck.`;

function TruckView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const native = window?.ReactNativeWebView ?? false;

	const dataIds = props?.dataIds;
	const onDone = props?.onDone;

	const mode = dataIds?.mode;
	const truckId = dataIds?.truckId;
	const historyData = dataIds?.historyData;
	const fromCalendar = dataIds?.fromCalendar;
	const carrierOption = hasSubaccounts && dataIds?.carrierOption;
	const isCreate = mode === "CREATE";

	const [data, setData] = useState(null);
	const [carrierId, setCarrierId] = useState(carrierOption ? null : dataIds?.carrierId);
	const [trailer, setTrailer] = useState(null);
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);
	const [openDialog, setOpenDialog] = useState({ flag: false, title: null, message: null, action: null });

	useEffect(() => {
		props?.setTitle?.(historyData ? "View Truck" : isCreate ? "Add New Truck" : "Edit Truck");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (historyData) {
			setData(historyData);
			setTrailer(historyData.equipment ?? "PO");
			return;
		}

		if (!carrierId || !truckId || isCreate) {
			return;
		}

		setLoading(true);

		getTruck(carrierId, truckId).then(
			(response) => {
				setLoading(false);
				setTrailer(response.equipment);
				setData(response);
			},
			(err) => {
				setLoading(false);
				setError(err.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);

		// eslint-disable-next-line
	}, [carrierId, truckId, isCreate]);

	const form = useMemo(
		() => truckFormConfig(isCreate, { carrierOption, carrierId }, trailer ?? data?.trailer, data?.subtype),
		[data, trailer, carrierOption, carrierId, isCreate]
	);

	const closeDialog = () => {
		setOpenDialog({ flag: false, message: null, title: null, action: null });
	};

	const handledSave = (action = null) => {
		setProcessing(true);
		closeDialog();

		if (action) data.action = action;

		//Logic for special equipments
		const equipment = data?.equipment?.includes("SPECIAL") ? "SPECIAL" : data?.equipment;
		const subtype = equipment === "SPECIAL" ? data?.equipment.replace("SPECIAL_", "") : undefined;
		const dataToSend = { ...data, equipment, subtype };

		const promise = isCreate ? createTruck(carrierId, dataToSend) : updateTruck(carrierId, truckId, dataToSend);

		promise.then(
			async () => {
				if (isCreate) {
					const userData = await signInWithToken();
					if (userData) dispatch(setUserData(userData, { skip_settings_set: true }));
				}

				setProcessing(false);
				showSnackbar(snackbar, "Successfully Saved Information", "success");

				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "dataRevision" }));
				if (isCarrier()) {
					dispatch(fetchActions({ carrierId }));
				}
				if (fromCalendar) dispatch(incrementDataRevision({ event: "dashboardRevision" }));
				if (native) window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRUCK_LIST" }));
				onDone?.();
			},
			(err) => {
				setProcessing(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<>
			<SmarthopFormView
				key={"truck-view"}
				mode={historyData ? "COMPARE" : mode}
				content={form}
				data={data}
				noInitValidation={true}
				dataIds={{ ...dataIds, carrierId }}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					if (key === "carrier") {
						setCarrierId(model.carrier);
						if (model.carrier) {
							setOpenDialog({
								flag: true,
								title: "",
								message: "",
								action: "WARNING_CARRIER",
							});
						}
					}
					if (key === "equipment") {
						const subtype = model?.equipment.split("_")?.[1] ?? model?.equipment;
						if (data.subtype !== subtype)
							setData({
								...data,
								equipment: model?.equipment,
								subtype,
								...TRUCK_DEFAULTS[subtype],
							});
						return;
					}
					if (key === "truck_trailer") {
						// Update Info
						const trailer = model?.truck_trailer__view?.metadata;
						const equipment =
							model?.truck_trailer === "CREATE_NEW_TRAILER"
								? "VAN"
								: trailer?.equipment?.includes("SPECIAL")
								? "SPECIAL"
								: trailer?.equipment;
						const subtype = equipment === "SPECIAL" ? trailer.equipment.replace("SPECIAL_", "") : undefined;
						const trailerid = model?.truck_trailer === "CREATE_NEW_TRAILER" ? null : trailer?.trailerid;
						setData({
							...data,
							...model,
							trailerid: trailerid,
							equipment: equipment,
							max_weight: trailer?.subtype === "Cargo Van" ? 5000 : trailer?.max_weight,
							trailer: trailer?.subtype === "Cargo Van" ? 15 : trailer?.trailer,
							parial_loads: trailer?.subtype === "Cargo Van" ? true : false,
							trailer_year: trailer?.trailer_year,
							doors: trailer?.doors,
							trailer_strap: trailer?.trailer_strap,
							trailer_food_grade: trailer?.trailer_food_grade,
							trailer_registration: trailer?.trailer_registration,
							load_type: trailer?.subtype === "CV" ? "BOTH" : "FULL",
							subtype,
						});

						// Set trailer
						if (model?.truck_trailer === "CREATE_NEW_TRAILER") {
							setTrailer(model?.truck_trailer);
						} else {
							setTrailer(equipment);
						}
						return;
					}

					setData({ ...data, ...model });
				}}
				onSubmit={() => {
					// Handle logic for updated traile
					const trailer = data?.truck_trailer__view?.metadata;
					if (trailer?.trucks?.length > 0) {
						const isAssignedEdit = !isCreate && trailer.trucks.find((item) => item._id + "" !== truckId + "");
						const isAssignedCreate = isCreate && trailer.assigned;

						if (isAssignedEdit || isAssignedCreate) {
							setOpenDialog({
								flag: true,
								title: _TITLE_UPDATED_TRAILER(trailer.trailerid),
								message: _MESSAGE_UPDATED_TRAILER,
								action: "CHANGE_TRAILER",
							});
							return;
						}
					}

					handledSave();
				}}
				processing={processing}
			/>
			<SmarthopConfirmDialog
				open={!!openDialog.flag}
				title={openDialog?.title}
				message={openDialog?.message}
				mode={openDialog.action === "WARNING_CARRIER" ? "WARNING" : null}
				onClose={() => closeDialog()}
				onAccept={() => (openDialog.action === "WARNING_CARRIER" ? closeDialog() : handledSave(openDialog.action))}
				acceptMsg={"Continue"}
				closeMsg={openDialog.action === "WARNING_CARRIER" ? "Confirm" : "Close"}
			>
				{openDialog.action === "WARNING_CARRIER" && (
					<>
						<Typography className={"text-14"}>
							<span className="text-red font-bold">*WARNING: </span>You will{" "}
							<span className="underline font-bold">not </span>
							be able to edit carrier once you create this truck.
						</Typography>
						<Typography className="text-14 mt-6 mb-6">
							{"Please confirm that this truck belongs to carrier: "}
							<span style={{ color: "#E7683D", textDecoration: "underline" }}>{data?.carrier__view?.label ?? ""}</span>
						</Typography>
					</>
				)}
			</SmarthopConfirmDialog>
		</>
	);
}

export default TruckView;
