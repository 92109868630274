import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from "react";
import { Icon, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Store
import { openLoadedFormDialog, openFormDialog } from "app/store/tools/formDialogSlice";

//Utils
import { createMciIconView, createSourceView } from "app/main/utils/brokerUtils";
import { descriptionLoadId } from "app/main/search/searchUtils";

// Services
import {
	getCarrierId,
	isActiveCarrierStatus,
	isHelpEnabled,
	isTestCarrier,
	getUserTier,
} from "app/services/LoginService";
import { getLoadForDispatcherRefreshed } from "app/services/searchServices";
import { getBrokerSearch } from "app/services/brokersServices";
import { getTruckSearchData } from "app/services/searchServices";

// Utils
import { showSnackbar, showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { DisplaySection } from "app/main/utils/uiUtils";
import { locationLatLong } from "app/main/utils/tripsUtils";
import { checkValidURL } from "app/main/search/searchUtils";

// Components
import LoadMarketConditionMapsNew from "../load/LoadMarketConditionMapsNew";
import CostsBreakdownPage from "app/main/profile/cost-structure/calculator/CostsBreakdownPage";
import LoadSearchAvailability from "./LoadSearchViewAvailability";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog.js";
import LoadSearchBidBookModalView from "./LoadSearchBidBookModalView";

// Const
import LoadSearchSummaryBrokerView from "./LoadSearchSummaryBrokerView";
import LoadSearchSummaryDetailsView from "./LoadSearchSummaryDetailsView";
import LoadSearchSummaryRateView from "./LoadSearchSummaryRateView";
import LoadSearchSummaryCarrierView from "./LoadSearchSummaryCarrierView";
import LoadSearchViewLocationsEdit from "./LoadSearchViewLocationsEdit";
import { calculateProfit } from "app/main/profile/cost-structure/calculator/CostStructureCalculatorSettings";
import { openDialogTitle, openDialogMessage } from "../config/suggestUtils";
import { getButtons, runAction, updateLoad, getProfitData, onLocationChange } from "./LoadSearchViewHelper";
import MarketAnalysisWidget from "app/main/widgets/custom/MarketAnalysisWidget";
import LoadSearchViewLocationsView from "./LoadSearchViewLocationsView";
import { selectLoadsById } from "app/store/loads/loadsSlice";
import LoadingDialog from "app/main/tools/LoadingDialog";
import { useRef } from "react";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import { isEnabled } from "app/services/featureStorageService";
import { createBadgeView } from "app/main/search/searchUtils";

const LoadSearchView = forwardRef(({ dataIds, onDone, onMinimize, onProgress, setTitle, children }, ref) => {
	useEffect(() => {
		setTitle?.("Load Details");
		// eslint-disable-next-line
	}, []);
	const nativeMobile = window?.ReactNativeWebView ? true : false;
	const snackbar = useSnackbar();
	const dispatch = useDispatch();
	const locationsRef = useRef(null);

	const variant = "skinny";
	const openExternalBook = dataIds?.openExternalBook;

	const [load, setLoad] = useState(
		nativeMobile && dataIds?.load && typeof dataIds?.load === "string"
			? JSON.parse(dataIds?.load)
			: dataIds?.load ?? null
	);
	const editedLoad = useSelector((state) => selectLoadsById(state.loads, dataIds.loadId));
	const user = useSelector(({ auth }) => auth.user);

	const [progress, setProgress] = useState({ flag: false, message: null });
	const [locationsEditOpen, setLocationsEditOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [loading, setLoading] = useState(!dataIds?.load?.fullLoadRecord);
	const [processing, setProcessing] = useState(false);
	const [truckData, setTruckData] = useState(dataIds?.truckData);
	const [error, setError] = useState(false);

	// Trip warnings
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
	const [ignoreWarnings, setIgnoreInvoiceWarnings] = useState(false);
	const [tripInvoiceWarnings, setTripInvoiceWarnings] = useState();

	const [smallScreen, setSmallScreen] = useState(dataIds?.nativeMobile || window.innerWidth <= 600);
	const [shortScreen, setShortScreen] = useState(dataIds?.nativeMobile || window.innerHeight <= 1080);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 50) * 50);
	const [openConfirmDialog, setOpenConfirmDialog] = useState({
		title: null,
		previousTitle: null,
		flag: false,
		typeAction: null,
		isWarning: false,
	});
	const [openBidDialog, setOpenBidDialog] = useState(dataIds?.openBidDialog ?? false);

	const isPublicView = useMemo(() => !!dataIds?.public, [dataIds]);
	const mergedData = useMemo(() => {
		const l = load ? load : {};
		const el = editedLoad ? editedLoad : {};

		const loadToUse = { ...(l ?? {}), ...(el ?? {}) };
		loadToUse.profitData = {};

		const pdata = getProfitData(loadToUse, truckData);
		const res = calculateProfit(pdata);

		loadToUse.profitData = { data: pdata, ...res };
		loadToUse.total_miles = res.totalMiles;
		loadToUse.duration = res.duration;
		loadToUse.stopsNumber = 2 + (loadToUse.locations?.length ?? 0);
		loadToUse.truckOriginObj = truckData?.truckOriginObj ?? l.truckOriginObj;
		return loadToUse;
	}, [load, editedLoad, truckData]);
	const restrictions = useMemo(() => {
		return {
			bookCarrierActive: isActiveCarrierStatus() && truckData?.truck?.active,
		};
	}, [truckData]);

	const locations = useMemo(() => locationLatLong(mergedData), [mergedData]);

	const permission = useMemo(() => {
		const hasBookPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
			? hasRequiredGateKeepers(user, { permission_book_on: true })
			: true;
		return {
			book: hasBookPermission,
			help: isHelpEnabled(),
			price: !dataIds?.hidePrice,
		};
	}, [user, dataIds]);

	const isUserTestCarrier = useMemo(() => isTestCarrier(), []);
	const showBid = useMemo(
		() => (mergedData?.bidnow || (mergedData?.booknow && !mergedData?.external)) && permission?.book,
		[mergedData, permission]
	);
	const showBookExternal = useMemo(
		() =>
			!mergedData?.bidnow &&
			mergedData?.booknow &&
			mergedData.external &&
			permission?.book &&
			checkValidURL(mergedData.url),
		[mergedData, permission]
	);

	useEffect(() => {
		onProgress?.(showProgress || saving);
	}, [showProgress, onProgress, saving]);

	// get updated load
	useEffect(() => {
		const getLoad = async () => {
			setLoading(true);
			setLoad((l) => ({ ...(l ?? {}), ...{ refreshing: true } }));
			const options = { tollDiscouraged: false, forceEstimatedPriceRecalculate: true };
			try {
				const theLoad = await getLoadForDispatcherRefreshed(dataIds.userId, dataIds.searchId, dataIds.loadId, options);
				setLoad({ ...theLoad, fullLoadRecord: true });
				if (theLoad.availabilityError)
					console.log(`[Load check availability] Error ${JSON.stringify(load.availabilityError)}`);
			} catch (e) {
				if (e?.errors?.[0]?.message) {
					setError(e?.errors?.[0]?.message);
				}
				console.log(`[Load check availability] Error ${e.message}`);
			}
			setLoad((l) => ({ ...(l ?? {}), ...{ refreshing: false } }));
			setLoading(false);
		};

		async function fetchTruckSearchData() {
			let equipment = dataIds.truckId?.includes("ANY_")
				? dataIds.truckId.replace("ANY_", "")
				: dataIds.truck__view?.metadata?.equipment ?? "VAN";

			await getTruckSearchData(dataIds.carrierId, dataIds.truckId, equipment, {
				location: dataIds?.filters?.location_origin,
				dh: dataIds?.filters?.dh_origin,
			}).then(
				(data) => {
					setTruckData(data);
				},
				(err) => {
					console.log("[LoadSearchResult]: Error getting profit  status");
				}
			);
		}

		if (dataIds?.public) {
			setLoading(false);
			return;
		}

		if (!truckData) {
			fetchTruckSearchData();
		}

		if (!dataIds?.load?.fullLoadRecord && !load?.fullLoadRecord) {
			getLoad();
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [dataIds]);

	// handle screen size
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 960 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 960 && smallScreen) {
				setSmallScreen(false);
			}
			if (window.innerHeight <= 1080 && !shortScreen) {
				setShortScreen(true);
			} else if (window.innerHeight > 1080 && shortScreen) {
				setShortScreen(false);
			}
			if (parseInt(window.innerHeight / 50) * 50 !== screenHeight) {
				setScreenHeight(parseInt(window.innerHeight / 50) * 50);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		if (ignoreWarnings) {
			executeAction("TRIP");
		}
		if (openExternalBook) {
			executeAction("EXTERNAL_BOOK");
		}
		// eslint-disable-next-line
	}, [ignoreWarnings, openExternalBook]);

	const onLocationsChanged = (update) => {
		setLocationsEditOpen(false);
		onLocationChange(update, mergedData, dataIds, dispatch);
	};

	const onRateChange = (newRate, loadId) => {
		updateLoad(dataIds.searchId, dataIds?.loadId, { price: newRate, originalPrice: mergedData.price });
	};

	const snackbarMsg = useCallback(
		(msg, type) => {
			showSnackbar(snackbar, msg, type);
		},
		[snackbar]
	);

	const onAcceptWarningChanges = () => {
		cleanTripInvoiceWarnings();
		setIgnoreInvoiceWarnings(true);
	};

	const cleanTripInvoiceWarnings = () => {
		setTripInvoiceWarnings();
	};

	const executeAction = useCallback(
		async (action, data) => {
			setProcessing(true);
			setOpenBidDialog(false);
			try {
				const response = await runAction(
					action,
					setOpenConfirmDialog,
					dispatch,
					setProgress,
					{ ...mergedData, ...data },
					dataIds,
					snackbar,
					setSaving,
					ignoreWarnings
				);
				setIgnoreInvoiceWarnings(false);
				setProcessing(false);
				return response;
			} catch (err) {
				const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
				setTripInvoiceWarnings(tripWarnings);
				if (tripWarnings?.length) {
					setIsWarningDialogOpen(true);
				}
			}
			setProcessing(false);
		},
		[dataIds, mergedData, dispatch, snackbar, setSaving, ignoreWarnings]
	);

	const setDialog = useCallback(
		(type) => {
			if (type === "BID") {
				setOpenBidDialog(true);
				return;
			}
			const title = openDialogTitle(type);
			const message = openDialogMessage(type);
			let confirmDialog = { title: title, message: message, flag: true, typeAction: type };

			if (["RESTRICTION_BOOK_CARRIER_HAS_MCNUMBER", "RESTRICTION_BOOK_CARRIER_STATUS"].includes(type)) {
				confirmDialog.isWarning = true;
			}

			setOpenConfirmDialog(confirmDialog);
		},
		// eslint-disable-next-line
		[mergedData]
	);

	useImperativeHandle(
		ref,
		() => {
			if (dataIds?.historicalView || isPublicView || loading) return {};
			const btns = getButtons(dataIds, mergedData, snackbarMsg, dispatch, smallScreen, setDialog, executeAction, user);
			dispatch(incrementDataRevision({ event: "dialogBtnsRevision" }));
			return btns;
		},
		// eslint-disable-next-line
		[dataIds, mergedData, smallScreen, dispatch, snackbarMsg, setDialog, executeAction, isPublicView, loading]
	);

	const showBrokerSummary = async () => {
		try {
			setShowProgress(true);
			const { mcnumber, carrier } = mergedData;
			const broker = await getBrokerSearch(mcnumber, carrier);
			if (!broker) {
				showErrorSnackbar(snackbar, "Broker Not Found");
			}
			const dataIds = { brokerId: broker._id, carrierId: carrier, cache: broker };

			dispatch(
				openLoadedFormDialog({
					viewId: "BROKER_INFO_VIEW",
					dataIds: dataIds,
					mode: "VIEW",
				})
			);
			setShowProgress(false);
		} catch (error) {
			setShowProgress(false);
			showErrorSnackbar(snackbar, error);
		}
	};

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="text-red">{error}</Typography>
			</div>
		);
	}

	if (!load) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<SmarthopDialogViewContainer
			tabComponents={[
				{
					key: "DETAILS",
					title: "Details",
					component: (
						<div className={"flex flex-col w-full"}>
							<div className={"flex w-full flex-col md:flex-row mb-20 md:mb-14"}>
								{mergedData?.deleted && !mergedData?.covered_by_trip ? (
									<div className={"flex flex-row rounded-lg py-4 items-center w-full bg-red"}>
										<Icon className="text-20 text-white mr-4 ml-14">warning</Icon>
										<Typography className="text-12 text-white p-5">
											This load has been covered and no longer available
										</Typography>
									</div>
								) : (
									<div className={"flex w-full flex-col md:flex-row"}>
										<div className={"flex flex-1"}>
											<LoadSearchAvailability
												load={mergedData}
												loading={loading}
												hasError={!!error || !!mergedData.availabilityError}
											/>
										</div>
										<div className={"flex flex-row mt-10 md:mt-2"}>
											{createBadgeView(load, "MOBILE_CARD", null, "extra-skiny")}
										</div>
									</div>
								)}
							</div>
							<div className={"flex flex-col w-full md:flex-row space-y-12 md:space-y-0 space-x-0 md:space-x-16"}>
								<div className="flex flex-col w-full md:w-6/12">
									<DisplaySection
										title={"Broker" + (mergedData?.mcnumber ? " MC " + mergedData?.mcnumber : "")}
										classes={{ root: "mb-0 mt-0 mx-0", labelsContainer: "items-center" }}
										labels={[{ label: createSourceView(mergedData), classes: { label: " pl-0 " } }]}
									>
										<LoadSearchSummaryBrokerView
											variant={variant}
											permission={permission}
											load={mergedData}
											onShowBrokerSummary={showBrokerSummary}
											isPublicView={isPublicView}
										/>
									</DisplaySection>
									<DisplaySection title={descriptionLoadId(mergedData)} classes={{ root: "mt-0 mx-0 md:mt-6 mb-0" }}>
										<LoadSearchSummaryDetailsView
											variant={variant}
											permission={permission}
											load={mergedData}
											loading={saving}
											onLocationChange={onLocationChange}
											hideLocation={true}
											hideNotes={true}
										/>
									</DisplaySection>
									<DisplaySection classes={{ root: "mx-0 mt-20 md:mt-24 mb-0", divider: "hidden" }}>
										<LoadSearchSummaryRateView
											permission={permission}
											load={mergedData}
											loading={loading}
											onUpdateRate={onRateChange}
											loadId={dataIds?.loadId}
											isPublicView={isPublicView}
										/>
									</DisplaySection>
									<DisplaySection title={"Notes"} classes={{ root: "mx-0 mt-20 md:mt-24 mb-10" }}>
										<Typography className={"flex text-12 ml:text-13 break-normal mt-3 text-primary-700 "}>
											{!!load?.specinfo?.trim()?.length ? load?.specinfo?.trim() : "-"}
										</Typography>
									</DisplaySection>
								</div>
								<div className="flex flex-col w-full md:w-6/12">
									<DisplaySection
										title={"Carrier" + (load?.carrierMcNumber ? " MC " + load?.carrierMcNumber : "")}
										classes={{ root: "mx-0 mt-0 mb-0 hidden md:flex", divider: "mb-0" }}
									>
										<LoadSearchSummaryCarrierView
											carrier={{ id: load?.carrier, name: load?.carrierName, hidden: user.roleType !== "INTERNAL" }}
											truck={{ id: load?.truck, name: load?.truckName }}
											trailer={{ id: load?.trailer, name: load?.trailerid }}
											primaryDriver={{ id: load?.primaryDriver, name: load?.primaryDriverName }}
											isPublicView={isPublicView}
										/>
									</DisplaySection>
									<DisplaySection
										classes={{ root: "mx-0" }}
										title={
											<div className="w-full flex  flex-row content-center justify-between items-center space-x-16">
												<div>Route</div>
												{!mergedData?.deleted && !dataIds?.historicalView && !isPublicView && (
													<div className="space-x-8 float-right -mt-8 -mb-20">
														<IconButton
															aria-label="edit"
															className="-mt-20"
															size="medium"
															color="inherit"
															onClick={() => setLocationsEditOpen(true)}
															title="Edit Locations"
														>
															<Icon className={"text-20 text-black font-500"}>{"edit"}</Icon>
														</IconButton>
													</div>
												)}
											</div>
										}
									>
										<LoadSearchViewLocationsView
											load={mergedData}
											onEditRoute={() => setLocationsEditOpen(true)}
											hideEdit={true}
											showAllStops={true}
										/>
									</DisplaySection>
								</div>
							</div>
						</div>
					),
				},
				{
					key: "DESTINATION",
					title: "Destination",
					hideOnDesktop: isPublicView,
					hideOnMobile: true,
					component: (
						<div className={`flex w-full flex-col md:flex-row`}>
							<div className={`flex w-full flex-col`}>
								<DisplaySection
									title="Destination Market"
									views={[
										<div className="flex flex-row">
											<Typography className={"text-13 mr-4 font-semibold"}>MPI</Typography>
											{createMciIconView((mergedData?.rank_mci * 100)?.toFixed(2))}
										</div>,
										<div className="flex flex-row ml-4 md:ml-14">
											<Typography className={"text-13 mr-8 font-semibold"}>MVI</Typography>
											<Typography className={"text-13 mr-4"}>{(mergedData?.rank_mvi * 100)?.toFixed(2)}</Typography>
										</div>,
									]}
								>
									<MarketAnalysisWidget
										mode="FULL"
										config={{
											height: smallScreen
												? screenHeight * 0.3 < 300
													? 300
													: screenHeight * 0.3
												: shortScreen
												? screenHeight * 0.45 < 400
													? 400
													: screenHeight * 0.45
												: 550,
											params: {
												carrier: dataIds?.carrierId,
												truck: dataIds?.truckId,
												driver: dataIds?.driverId,
												equipment: mergedData?.equipment,
												origin: mergedData?.destination,
												pickupDs: mergedData?.dropready,
											},
										}}
									/>
								</DisplaySection>
							</div>
						</div>
					),
				},
				{
					key: "PROFIT",
					title: "Profit",
					hideOnDesktop: isPublicView,
					hideOnMobile: isPublicView,
					component: (
						<div className="flex flex-col w-full">
							<CostsBreakdownPage data={mergedData} />
						</div>
					),
				},
				{
					key: "MAP",
					title: "Map",
					hideOnDesktop: true,
					hideOnMobile: isPublicView,
					component: (
						<LoadMarketConditionMapsNew zoom={3} data={mergedData} showRouteTolls={false} showHeatMap={true} />
					),
				},
			]}
			sideComponents={[
				{
					key: "TRIP_MAP_" + mergedData?.load_id,
					type: "MAP",
					tabKeys: ["DETAILS", "DESTINATION"],
					hideOnMobile: true,
					params: {
						locations: locations,
						showFuelLocationsButton: isEnabled("SHOW_FUEL_LOCATIONS_BUTTON"),
						loadId: mergedData?.load_id,
						carrierId: truckData?.carrier?._id,
						plan: truckData?.plan,
						truckLocation: mergedData?.truckOriginObj,
					},
				},
			]}
			footerActions={[
				{
					key: "VIEW_TRIP",
					title: "View Trip",
					hideOnDesktop: !mergedData?.covered_by_trip || isPublicView,
					hideOnMobile: !mergedData?.covered_by_trip || isPublicView,
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "TRIP_VIEW",
								dataIds: { tripId: mergedData?.covered_by_trip, carrierId: truckData?.carrier?._id ?? getCarrierId() },
							})
						);
					},
					style: { icon: "local_offer", align: "LEFT", notCollapsible: true },
				},
				{
					key: "CREATE_TRIP",
					title: "Create Trip",
					disabled: loading,
					hideOnDesktop: mergedData?.deleted || isPublicView,
					hideOnMobile: mergedData?.deleted || isPublicView,
					onClick: () => setDialog("TRIP"),
					style: {
						icon: "local_offer",
						primary: true,
						align: "RIGHT",
						notCollapsible: true,
					},
				},
				{
					key: "EXTERNAL_BOOK",
					title: "Book Online (Ext.)",
					disabled: loading,
					hideOnDesktop: !showBookExternal || mergedData?.deleted || isPublicView,
					hideOnMobile: !showBookExternal || mergedData?.deleted || isPublicView,
					onClick: () => {
						if (getUserTier() === "TIER_STARTER") {
							dispatch(
								openFormDialog({
									viewId: "PAYWALL_VIEW",
									dataIds: {
										type: "book_externally_restricted",
										data: {
											dataIdsInherited: { ...dataIds, openExternalBook: true },
											callbackKey: "LOAD_SUGGEST_VIEW",
										},
									},
								})
							);
							return;
						}
						if (!restrictions?.bookCarrierActive) {
							return setDialog("RESTRICTION_BOOK_CARRIER_STATUS");
						}
						executeAction("EXTERNAL_BOOK");
					},
					style: {
						icon: "flash_on",
						classes: "bg-purple-800 text-white",
						align: "RIGHT",
						notCollapsible: true,
					},
				},
				{
					key: "BID",
					title: `${mergedData?.bidnow ? "Bid Online" : "Book Online"} ${isUserTestCarrier ? "(Test)" : ""} `,
					disabled: loading,
					hideOnDesktop: !showBid || mergedData?.deleted || isPublicView,
					hideOnMobile: !showBid || mergedData?.deleted || isPublicView,
					onClick: () => {
						if (getUserTier() === "TIER_STARTER") {
							dispatch(
								openFormDialog({
									viewId: "PAYWALL_VIEW",
									dataIds: {
										type: "digital_booking_restricted",
										data: { dataIdsInherited: { ...dataIds, openBidDialog: true }, callbackKey: "LOAD_SUGGEST_VIEW" },
									},
								})
							);
							return;
						}
						if (!restrictions?.bookCarrierActive) {
							return setDialog("RESTRICTION_BOOK_CARRIER_STATUS");
						}
						setDialog("BID");
					},
					style: {
						icon: "flash_on",
						classes: "bg-purple-800 text-white",
						align: "RIGHT",
						notCollapsible: true,
					},
				},
			]}
			nativeMobile={nativeMobile}
			processing={processing}
		>
			<SmarthopConfirmDialog
				title={`Route`}
				open={locationsEditOpen}
				maxWidth="lg"
				onClose={() => {
					setLocationsEditOpen(false);
				}}
				onAccept={() => {
					const data = locationsRef.current?.getValues();
					const { price, rpm, profitData, ...dataToUpdate } = data;
					if (dataToUpdate) onLocationsChanged(dataToUpdate);
				}}
			>
				<LoadSearchViewLocationsEdit data={mergedData} innerRef={locationsRef} />
			</SmarthopConfirmDialog>
			<LoadingDialog open={progress.flag} message={progress.message} />
			<SmarthopConfirmDialog
				open={!!openConfirmDialog.flag}
				title={openConfirmDialog?.previousTitle ?? openConfirmDialog.title}
				message={openConfirmDialog.message}
				handleClose={() => setOpenConfirmDialog({ title: null, flag: false, typeAction: null, isWarning: false })}
				handleAccept={() => {
					if (!openConfirmDialog?.previousTitle) {
						executeAction(openConfirmDialog?.typeAction);
						setOpenConfirmDialog({ title: null, flag: false, typeAction: null });
					} else {
						setOpenConfirmDialog({ ...openConfirmDialog, previousTitle: null, isWarning: false });
					}
				}}
				mode={openConfirmDialog?.isWarning ? "WARNING" : "NORMAL"}
				acceptMsg={openConfirmDialog?.buttonTitle}
			/>
			<LoadSearchBidBookModalView
				open={openBidDialog}
				price={!mergedData?.bidnow && mergedData.originalPrice ? mergedData.originalPrice : mergedData?.price}
				estBidPrice={mergedData?.est_bid_price}
				bookNow={mergedData?.booknow}
				bidNow={mergedData?.bidnow}
				onClose={() => setOpenBidDialog(false)}
				onAccept={(action, data) => executeAction(action, data)}
				loadId={mergedData?.tripid}
				bidOffline={mergedData?.owner === "echo"}
				brokerBlacklisted={mergedData?.brokerBlacklisted}
			/>
			{tripInvoiceWarnings?.length && (
				<WarningConfirmDialog
					open={isWarningDialogOpen}
					warnings={tripInvoiceWarnings}
					onAccept={onAcceptWarningChanges}
					onClose={cleanTripInvoiceWarnings}
				/>
			)}
		</SmarthopDialogViewContainer>
	);
});

export default LoadSearchView;
