const userAxleForm = {
	urlGET: "api/profile/carriers/:carrierId/drivers/:driverId/details",
	idKey: "_id",
	triggerEvent: "profileRevision",
	listenEvent: "profileRevision",
	content: {
		view: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "axleId",
								type: "text",
								label: "Axle ID",
							},
							{
								key: "axleName",
								type: "text",
								label: "Axle Name",
							},
							{
								key: "axleImported",
								type: "text",
								label: "Axle Imported",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "hoursOfService.off_duty_seconds",
								type: "number",
								label: "Off Duty Seconds",
							},
							{
								key: "hoursOfService.on_duty_seconds",
								type: "number",
								label: "On Duty Seconds",
							},
							{
								key: "hoursOfService.sleeper_seconds",
								type: "number",
								label: "Sleeper Seconds",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "hoursOfService.driving_seconds",
								type: "number",
								label: "Driving Seconds",
							},
							{
								key: "hoursOfService.waiting_seconds",
								type: "number",
								label: "Waiting Seconds",
							},
							{
								key: "hoursOfService.active_seconds",
								type: "number",
								label: "Active Seconds",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "hoursOfService.yard_move_seconds",
								type: "number",
								label: "Yard Move Seconds",
							},
							{
								key: "hoursOfService.break",
								type: "text",
								label: "Break",
							},
							{
								key: "hoursOfService.cycle",
								type: "text",
								label: "Cycle",
							},
						],
					},
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "hoursOfService.jurisdiction",
								type: "text",
								label: "Jurisdiction",
							},
							{
								key: "hoursOfService.restart",
								type: "text",
								label: "Restart",
							},
							{
								key: "hoursOfService.shift",
								type: "text",
								label: "Shift",
							},
						],
					},
				},
			],
		},
	},
};

export default userAxleForm;
