import { Children, cloneElement, useState } from "react";

const LegendList = ({ children }) => {
	const [itemExpanded, setItemExpanded] = useState(null);

	const childChange = (childId) => (ev, expanded) => {
		setItemExpanded(expanded ? childId : null);
	};

	return Children.map(children, (child) =>
		cloneElement(child, {
			...child.props,
			parentChange: childChange(child.props.label),
			expandFromParent: itemExpanded === child.props.label,
		})
	);
};

export default LegendList;
