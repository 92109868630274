import SmarthopCheckboxField from "@smarthop/form/fields/SmarthopCheckboxField";

const carrierDispatchingAccessForm = ({ onSetData }) => {
	return {
		urlGET: "api/profile/carriers/:carrierId/dispatching/details/:dispatchingType",
		idKey: "_id",
		triggerEvent: "carrierDispatchingRevision",
		listenEvent: "carrierDispatchingRevision",
		header: {
			view: { label: "Account Information" },
		},
		content: {
			view: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "company",
									type: "text",
									label: "Company Name",
									builder: (item) => {
										return item?.company ?? "-";
									},
									hidden: (item) => !item?.access,
								},
								{
									key: "access",
									type: "text",
									label: "Access Level",
									builder: (item) => {
										const includeSubaccount = item?.isMainAccount && item?.dispatcherIncludeSubaccounts;
										return item?.access === "OWNER"
											? "Full"
											: item?.access === "DISPATCHING"
											? "Partial" + (includeSubaccount ? " (Include Sub-accounts)" : "")
											: item?.access?.length > 0
											? item?.access
											: "No External Access Allowed";
									},
								},
								{
									key: "includeSubaccounts",
									type: "view",
									label: "",
									builder: (item) => {
										return (
											<SmarthopCheckboxField
												key={"checkbox_include_subaccounts"}
												name={"Include Sub-accounts"}
												errors={[]}
												label={"Include Sub-accounts"}
												value={localStorage.getItem("include_subaccounts") === "true"}
												onChangeCommitted={(model, value) => onSetData(value)}
												checkbox={{ noErrorMessage: true, variant: "row" }}
											/>
										);
									},
									hidden: (item) => !item?.isMainAccount || item?.access,
								},
							],
						},
					},
					{
						type: "message",
						builder: (item) => {
							if (item?.access === "OWNER") {
								return `'${item?.company}' users have full controll of your account and can edit your assets, create trips, manage invoicing and payroll. `;
							} else if (item?.access === "DISPATCHING") {
								return `'${item?.company}' users have partial controll of your account and can edit your assets, create trips, manage invoices and payroll (everything except billing). You can remove dispatcher access at any time by clicking 'Disconnect Dispatcher' in the options menu.`;
							} else if (item?.access?.length > 0) {
								return null;
							} else {
								return 'To allow an external company to control your account, generate an "Access Code" by clicking "Generate Access Code" in options menu. Provide it to the dispatching company to get connected.';
							}
						},
					},
				],
			},
		},
	};
};

export default carrierDispatchingAccessForm;
