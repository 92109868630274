import clsx from "clsx";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MobileDetect from "mobile-detect";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import SmarthopMenuContainer from "@smarthop/form/fields/SmarthopMenuContainer";

import withReducer from "app/store/withReducer";
import MessageList from "./MessageList";
import ChatList from "./ChatList";
import ChatNotSelectedView from "./ChatNotSelectedView";
import ChatNotSelectedTruckView from "./ChatNotSelectedTruckView";
import ParticipantList from "./ParticipantList";

import reducer from "app/store/messenger";
import { fetchChats, markAllChatsAsRead, setLoading, setVisible, handleChatMute } from "app/store/messenger/chatSlice";
import {
	fetchMessages,
	fetchTruckMessagesPreview,
	setActiveChat,
	removeAllMessage,
	showLoadingMessage,
} from "app/store/messenger/messageSlice";
import { getUserId, isRoleExternal, getUserTier } from "app/services/LoginService";
import { isEnabled } from "app/services/featureStorageService";
import { typeWarning, showWarning } from "app/main/utils/warningUtils";
import { replaceFormDialog } from "app/store/tools/formDialogSlice";

// Services
import { getTrucksAnalytics } from "app/services/truckServices";

import { makeChatAppStyles } from "./rowStyles";
import { buildChatTitle } from "../utils/chatUtils";
import { Button, Menu, MenuItem, Chip } from "@material-ui/core";
import ChatListFilter from "./ChatListFilter";

const useStyles = makeStyles((theme) => makeChatAppStyles(theme));
const mobileDetect = new MobileDetect(window.navigator.userAgent);

function ChatApp(props) {
	// Native fields
	const nativeMobile = props.nativeMobile;
	const onEvent = props.onEvent;
	const onDialogOpen = props.onDialogOpen;

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles(props);

	const user = useSelector(({ auth }) => auth.user);
	const chat = useSelector(({ messenger }) => messenger.messages.activeChat);
	const loadingMessages = useSelector(({ messenger }) => messenger.messages.loading);
	const errorsMessages = useSelector(({ messenger }) => messenger.messages.errors);
	const initializedMessages = useSelector(({ messenger }) => messenger.messages.initialized);

	const userId = getUserId();
	const userTier = getUserTier();
	const viewerIsOwner = isRoleExternal();

	const onDone = props.onDone;
	const onClose = props.onClose;
	const type = props.type;
	const dataIds = props.dataIds;
	const truckId = dataIds?.truckId;
	const carrierId = dataIds?.carrierId;

	const previewMode = type === "TRUCK_EXTERNAL" || type === "TRUCK_DELIVERY";

	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const chatTruckView = props.chatTruckView;
	const isNewUIEnabledMessages = isEnabled("CHAT_NEW_UI_MESSAGES");

	const [mobile, setMobile] = useState(window.innerWidth <= 1280);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorMute, setAnchorMute] = useState(null);
	const [markAllAsRead, setMarkAllAsRead] = useState(false);
	const [truckAnalitics, setTruckAnalitics] = useState(null);
	const [muteChat, setMuteChat] = useState(chat?.mute);
	const [filter, setFilter] = useState("");
	const open = Boolean(anchorEl);
	const openMute = Boolean(anchorMute);

	useEffect(() => {
		if (userTier === "TIER_STARTER") {
			return dispatch(
				replaceFormDialog({
					viewId: "PAYWALL_VIEW",
					dataIds: {
						type: "chat_restricted",
						data: { dataIdsInherited: props.dataIds },
						extraData: { type },
					},
				})
			);
		}
		// eslint-disable-next-line
	}, [userTier, props.dataIds, type]);

	useEffect(() => {
		//As the activechat selector is async, we need to make sure to set
		//the mute status correctly
		if (muteChat !== chat?.mute) setMuteChat(chat?.mute);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat]);

	useEffect(() => {
		if (muteChat !== chat?.mute) {
			dispatch(handleChatMute({ userId, chatId: chat._id, scope: muteChat }));
			dispatch(setActiveChat({ ...chat, ...{ mute: muteChat } }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, muteChat]);

	useEffect(() => {
		if (markAllAsRead) dispatch(markAllChatsAsRead({ userId }));
		return () => {
			setMarkAllAsRead(false);
		};
	}, [userId, dispatch, markAllAsRead]);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 1280 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 1280 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
		// eslint-disable-next-line
	}, []);

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "setChannelMuteLevel":
					dispatch(handleChatMute({ userId, chatId: chat._id, scope: dataParsed.data.scope }));
					dispatch(setActiveChat({ ...chat, ...{ mute: dataParsed.data.scope } }));
					return;
				default:
					break;
			}
		} catch (e) {}
	};

	useEffect(() => {
		if (chat?.previewOnly && !truckId) {
			// Remove previously selected preview only chat
			dispatch(removeAllMessage());
		}

		if (isAuthenticated && truckId && carrierId) {
			// Fetch messages for preview only chats
			if (chat?.truck !== truckId || chat?.type !== type) {
				dispatch(removeAllMessage());
			}
			dispatch(showLoadingMessage(true));
			dispatch(fetchTruckMessagesPreview({ userId, truckId, type, carrierId }));
		}
		// eslint-disable-next-line
	}, [dispatch, userId, truckId, type, isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(setLoading(true));
			dispatch(fetchChats({ userId }));
		}
	}, [dispatch, userId, isAuthenticated]);

	useEffect(() => {
		dispatch(setVisible(true));
		return () => {
			dispatch(setVisible(false));
			if (mobile) {
				dispatch(setActiveChat(null));
			}
		};
	}, [dispatch, mobile]);

	useEffect(() => {
		if (chat?.metadata?.truck?.carrier && chat?.truck) {
			getTrucksAnalytics(chat?.metadata?.truck?.carrier, chat?.truck)
				.then((response) => {
					setTruckAnalitics(response);
				})
				.catch((e) => {
					setTruckAnalitics(null);
					console.error("[ChatApp] Failed to load truck analytics", e);
				});
		}
	}, [chat?.truck, chat?.metadata?.truck?.carrier]);

	useEffect(() => {
		if (chat?.truck !== truckAnalitics?.truck) {
			setTruckAnalitics(null);
		}
	}, [truckAnalitics, chat?.truck]);

	useEffect(() => {
		if (chat && !chat.previewOnly && isAuthenticated) {
			dispatch(fetchMessages({ userId, chatId: chat._id }));
		}
	}, [dispatch, userId, chat, isAuthenticated]);

	const muteMenu = useMemo(() => {
		return (
			<>
				<Button
					id="options-chat"
					aria-controls="positioned-menu"
					aria-haspopup="true"
					aria-expanded={openMute ? "true" : undefined}
					onClick={(event) => {
						setAnchorMute(event.currentTarget);
					}}
				>
					<Icon>more_vert</Icon>
				</Button>
				<Menu
					id="mute-menu"
					aria-labelledby="options-chat"
					anchorEl={anchorMute}
					open={openMute}
					onClose={() => {
						setAnchorMute(null);
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					getContentAnchorEl={null}
				>
					{muteChat !== "ALL" && (
						<MenuItem
							onClick={() => {
								setAnchorMute(null);
								setMuteChat("ALL");
							}}
						>
							Mute All Messages
						</MenuItem>
					)}
					{muteChat !== "AUTOMATED" && (
						<MenuItem
							onClick={() => {
								setAnchorMute(null);
								setMuteChat("AUTOMATED");
							}}
						>
							Mute Automated Messages
						</MenuItem>
					)}
					{muteChat !== "NONE" && (
						<MenuItem
							onClick={() => {
								setAnchorMute(null);
								setMuteChat("NONE");
							}}
						>
							Unmute
						</MenuItem>
					)}
				</Menu>
			</>
		);
	}, [muteChat, openMute, anchorMute]);

	let warning = typeWarning(["ONBOARDING"], user);
	if (warning) return showWarning(warning, props.title, props.classes);

	return (
		<div
			className={clsx(
				isNewUIEnabledMessages ? classes.messageList : classes.contentCard,
				classes.contentHeight,
				"shadow flex flex-1 rounded-none"
			)}
		>
			<div
				className={clsx(
					"h-full bg-white w-full min-w-0 lg:w-min lg:min-w-480 xl:min-w-570",
					(mobile && chat) || previewMode ? "hidden" : ""
				)}
			>
				<>
					{!nativeMobile && (
						<AppBar color={"inherit"} className="w-full border-r-1" elevation={0} position="static">
							<Toolbar className="px-16">
								{mobile && !chatTruckView && (
									<IconButton
										className="p-4 m-0 -ml-5"
										onClick={() => {
											if (onDone) onDone();
										}}
									>
										<Icon className="text-24" color="action">
											arrow_back
										</Icon>
									</IconButton>
								)}
								<div className="flex flex-row flex-1">
									<div className="flex flex-1 flex-col">
										<div className="flex">
											<Typography className="text-14 lg:text-16 font-semibold px-4 pt-2" color="primary">
												Your Inbox
											</Typography>
										</div>
										<div className="flex">
											<Typography
												className={
													"text-11 lg:text-12 font-normal px-4 pt-2 -mt-4" +
													(isAuthenticated ? " text-green-700 " : " text-red-700 ")
												}
											>
												{isAuthenticated ? "Online" : "Offline"}
											</Typography>
										</div>
									</div>
									<div className="self-end">
										{!chatTruckView && (
											<Button
												id="options-chat"
												onClick={(event) => {
													history.push("chat/trucks");
													onDone?.();
												}}
											>
												<Icon>open_in_full</Icon>
											</Button>
										)}
										<Button
											id="options-chat"
											aria-controls="positioned-menu"
											aria-haspopup="true"
											aria-expanded={open ? "true" : undefined}
											onClick={(event) => {
												setAnchorEl(event.currentTarget);
											}}
										>
											<Icon>more_vert</Icon>
										</Button>
										<Menu
											id="positioned-menu"
											aria-labelledby="options-chat"
											anchorEl={anchorEl}
											open={open}
											onClose={() => {
												setAnchorEl(null);
											}}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
											getContentAnchorEl={null}
										>
											<MenuItem
												onClick={() => {
													setAnchorEl(null);
													setMarkAllAsRead(true);
												}}
											>
												Mark all as read
											</MenuItem>
										</Menu>
										{mobile && (
											<IconButton color="inherit" onClick={onClose}>
												<Icon>close</Icon>
											</IconButton>
										)}
									</div>
								</div>
							</Toolbar>
							<div style={{ height: 0 }} className="w-full  border-b-1 border-grey-300"></div>
						</AppBar>
					)}
					<ChatListFilter query={filter} setFilter={setFilter} />
					<ChatList filter={filter} nativeMobile={nativeMobile && mobile} chatTruckView={chatTruckView} />
				</>
			</div>

			{!mobile || chat ? (
				<main className={clsx(classes.contentWrapper, "divide-y divide-grey-300")}>
					{!chat ? (
						<ChatNotSelectedView
							loading={previewMode && !errorsMessages}
							onClose={onClose}
							chatTruckView={chatTruckView}
						/>
					) : !truckId && previewMode ? (
						<ChatNotSelectedTruckView />
					) : (
						<>
							{!nativeMobile && (
								<AppBar color={"inherit"} className="w-full" elevation={0} position="static">
									<Toolbar className="px-16">
										{mobile && !previewMode && (
											<IconButton
												className="p-4 m-0 -ml-5"
												onClick={() => {
													dispatch(setActiveChat(null));
												}}
											>
												<Icon className="text-24" color="action">
													arrow_back
												</Icon>
											</IconButton>
										)}
										<div className="flex flex-1 flex-row">
											<div className="flex flex-1 flex-col">
												<div className="flex">
													<Typography color="inherit" className="text-14 lg:text-15 font-medium px-4 pt-2">
														{buildChatTitle({ chat: chat, truck: chat?.metadata?.truck }, viewerIsOwner)}
													</Typography>
												</div>
												<div className="flex">
													{truckAnalitics && !viewerIsOwner && (
														<div className="flex-row mb-2">
															<Chip className="ml-6 h-24" label={`${truckAnalitics?.numberTrips} trips`} />
														</div>
													)}
													{chat?._id && (
														<div>
															<SmarthopMenuContainer
																key={"key"}
																label={"Participants"}
																chip={true}
																button={{
																	color: "secondary",
																	classes: "ml-6 h-24 text-11 text-white hover: text-white",
																}}
																renderOnce={true}
															>
																<ParticipantList chatId={chat?._id} />
															</SmarthopMenuContainer>
														</div>
													)}
												</div>
											</div>
											{!mobile && loadingMessages && initializedMessages && <CircularProgress color="secondary" />}
											<div className="flex">{muteMenu}</div>
											{!chatTruckView && (
												<IconButton color="inherit" onClick={onClose}>
													<Icon>close</Icon>
												</IconButton>
											)}
										</div>
									</Toolbar>
								</AppBar>
							)}
							<div className="flex flex-1 flex-row min-h-0">
								<div className={"flex flex-col min-h-0 w-full align-items-start"}>
									<MessageList
										onEvent={onEvent}
										onDialogOpen={onDialogOpen}
										nativeMobile={nativeMobile}
										chatTruckView={chatTruckView}
									/>
								</div>
							</div>
						</>
					)}
				</main>
			) : (
				!mobile && <ChatNotSelectedTruckView />
			)}
		</div>
	);
}
ChatApp.defaultProps = {
	chatTruckView: false,
};
export default withReducer("chat", reducer)(ChatApp);
