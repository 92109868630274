import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";

import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import OnboardStepsNew from "../onboarding/OnboardStepsNew";
import config from "./signupStepsConfig";

function SignupPage() {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const signupRevision = useSelector(({ tools }) => tools.revision["signupRevision"]);

	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		// Load initial data or check email verification status
		const loadData = async () => {
			try {
				// Here you would typically fetch any existing signup data
				// For now we'll just set some initial state
				setData({
					email: null,
					emailVerified: false,
					billing_plan_subscription_complete: false,
				});
			} catch (error) {
				setError(`Failed to load signup information (${error.message ?? "Unknown"})`);
			}
		};

		loadData();
	}, [signupRevision]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}

	if (!data) {
		return <FuseSplashScreen />;
	}

	return <OnboardStepsNew config={config} data={data} props={{ signupRevision }} />;
}

export default SignupPage;
