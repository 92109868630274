const carrierDispatchingGenerateCodeForm = () => {
	return {
		urlGET:
			"api/profile/carriers/:carrierId/dispatching/get-code/include-subaccount/" +
			localStorage.getItem("include_subaccounts"),
		idKey: "_id",
		header: {
			view: { label: "Access Code" },
		},
		size: "max-w-s",
		content: {
			view: {
				items: [
					{
						type: "message",
						builder: (item) => {
							return <div className="flex justify-center m-16 font-medium text-32">{item.code}</div>;
						},
					},
					{
						type: "message",
						builder: (item) => {
							return (
								<div className="flex justify-center m-5 text-grey-700">
									Please provide this access code to the dispatching company to get connected
								</div>
							);
						},
					},
				],
			},
		},
	};
};

export default carrierDispatchingGenerateCodeForm;
