import { formatDate } from "app/main/utils/dateUtils";

const payrollTripsList = ({ payPeriod, isInvestor, payrollStatus, showPeriodFilter }) => ({
	urlGET: "billing/v3/payroll/:payrollId/details/trips",
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "PAYROLL_TRIPS",
	cache: { disableInit: true },
	content: {
		table: {
			variant: "skinny",
			orderBy: ["trip_data.data.delivery_date", "trip_data.data.pickup_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "trip_data.data.delivery_date",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "trip_data.data.delivery_date",
					type: "dateRange",
					label: "Date Range",
				},
				...(payrollStatus === "open" && payPeriod && payPeriod !== "MANUAL" && showPeriodFilter
					? [
							{
								key: "period",
								type: "autocomplete",
								label: "Periods",
								description: "Select the period to filter the trips",
								translate: "",
								field: {
									classes: {},
									variant: undefined,
								},
								autocomplete: {
									provider: "smarthop",
									url: "billing/v3/payroll/carriers/:carrierId/payee/:userId/periods",
									preloadDefault: true,
									preloadImmedately: true,
									listenEvent: "profileRevision",
								},
							},
					  ]
					: []),
				...(isInvestor
					? [
							{
								key: "truck",
								type: "autocomplete",
								label: "Truck",
								translate: "",
								field: {
									classes: {},
									variant: undefined,
								},
								autocomplete: {
									provider: "smarthop",
									url: "api/profile/carriers/:carrierId/investor/:userId/trucks",
									params: {
										params: { options_investor: true },
									},
									preloadDefault: true,
									listenEvent: "profileRevision",
								},
							},
					  ]
					: []),
			],
		},
		items: [
			{
				key: "trip__view",
				type: "trip__view",
				label: "Trip",
			},
			{
				key: "pickup_date",
				builder: (item) =>
					formatDate(
						item.data?.pickup_date ?? item.pickup_date,
						item.data?.pickup_window_date ?? item.pickup_window_date
					),
				type: "text",
				label: "Pickup Date",
			},
			{
				key: "delivery_date",
				builder: (item) =>
					formatDate(
						item.data?.delivery_date ?? item.delivery_date,
						item.data?.delivery_window_date ?? item.delivery_window_date
					),
				type: "text",
				label: "Delivery Date",
			},
			{ key: "trip_pay", type: "currency", label: "Trip Pay" },
			{ key: "trip_additions", type: "currency", label: "Trip Additions" },
			{ key: "trip_deductions", type: "currency", label: "Trip Deductions" },
			{
				key: "driver_pay",
				type: "currency",
				label: "Payee Pay",
				viewConfig: {
					colorPositive: "font-medium text-black",
					colorNegative: "text-orange-800",
					colorZero: "text-grey",
				},
			},
		],
	},
});

export default payrollTripsList;
