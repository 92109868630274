import { Typography } from "@material-ui/core";
import { registy, SmarthopForm } from "@smarthop/form";
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import RegisterForm from "../auth/register/RegisterForm";

const stepConfig = {
	path: "/signup",
	sidebarHeader: "Sign Up",
	sidebarClass: "bg-[#E6E8EA]",
	list: [
		{
			title: "Create Account",
			subtitle: "Enter your account details",
			pageTitle: "Create Your Account",
			pageSubtitle: "Please enter your email and create a password",
			itemClass: "",
			isComplete: (data) => !!data?.email && !!data?.password,
			render: ({ dispatch, data }) => {
				return (
					<div className="w-full md:w-10/12">
						<RegisterForm viewType="register" />
					</div>
				);
			},
		},
		{
			title: "Email Verification",
			subtitle: "Verify your email address",
			pageTitle: "Verify Your Email",
			pageSubtitle: "Please check your inbox and verify your email address",
			itemClass: "",
			isComplete: (data) => !!data?.emailVerified,
			isAllowed: (data) => !!data?.email,
			render: ({ data }) => {
				return (
					<div className="flex flex-col items-center justify-center w-full md:w-10/12">
						<Typography variant="h6" className="mb-16">
							We've sent a verification email to: {data?.email}
						</Typography>
						<Typography>Please check your inbox and click the verification link to continue</Typography>
					</div>
				);
			},
		},
		{
			title: "Select Plan",
			subtitle: "Choose your subscription plan",
			pageTitle: "Select Your Plan",
			pageSubtitle: ["Select the plan that best fits your needs.", "Cancel anytime. No hidden fees ever."],
			itemClass: "",
			isComplete: (data) => !!data?.billing_plan_subscription_complete,
			isAllowed: (data) => !!data?.user,
			render: ({ carrierId }) => {
				return (
					<div className="flex flex-col w-full items-center justify-center -mt-14">
						<PlatformAvailablePlansView
							showPromoCodeBar
							carrierId={carrierId}
							allowToPreselectPlan={true}
							mode="SIGNUP"
						/>
					</div>
				);
			},
			classes: {
				contentContainer: "md:w-full mx-20",
			},
		},
	],
};

export default stepConfig;
