import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { buildChatTitle } from "../utils/chatUtils";
import { Button, Tooltip } from "@material-ui/core";
import { isEnabled } from "app/services/featureStorageService";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectChats } from "app/store/messenger/chatSlice";
import { RiSteering2Line } from "react-icons/ri";

function ChatListItemByType(props) {
	const message = props.message;
	const truck = message?.metadata?.truck;

	const messageIcon = message.type === "TRUCK_EXTERNAL" ? "support_agent" : "place";
	const viewerIsOwner = props.viewerIsOwner;

	const unread = message?.metadata?.status?.unread;
	const silent = message?.metadata?.status?.silent;
	const selectedStyle = "bg-grey-300 hover:bg-grey-300";
	const tabbedChatEnabled = isEnabled("TABBED_CHAT");
	const chats = useSelector(selectChats);

	const otherChat = useMemo(() => {
		const truck = message.truck;
		const type = message.type === "TRUCK_EXTERNAL" ? "TRUCK_DELIVERY" : "TRUCK_EXTERNAL";
		return chats.find((chat) => chat.truck === truck && chat.type === type);
	}, [chats, message]);
	const selected = props.activeChatId === message._id || (tabbedChatEnabled && props.activeChatId === otherChat._id);

	return (
		<div className="flex flex-col  w-full">
			<ListItem
				key={message._id}
				button
				disableTouchRipple={true}
				className={`pr-12 md:pr-16 h-44 w-full ${selected ? selectedStyle : ""}`}
				onClick={() => {
					props.onClick(message);
				}}
			>
				<Icon className={"text-20 mr-8 text-bold text-gray-700"}>{messageIcon}</Icon>
				{message._id && (
					<div className="flex flex-col justify-center items-end">
						{message.mute === "ALL" && <Icon className={"text-20 text-gray-700 mr-8"}>volume_off</Icon>}
						{unread && !selected && !silent && (
							<div className="flex mr-8 items-center justify-center w-6 h-6 md:h-8 md:w-8 rounded-full font-medium text-center bg-red-400"></div>
						)}
					</div>
				)}
				<div className="flex w-full flex-col pl-4">
					<Typography
						className={
							(selected
								? " font-medium text-grey-900 "
								: unread
								? " font-bold text-grey-800 "
								: " font-medium text-grey-700 ") + " whitespace-nowrap truncate pr-40"
						}
					>
						{buildChatTitle({ chat: message, truck }, viewerIsOwner, { variant: "short" })}
					</Typography>
				</div>
				{tabbedChatEnabled && (
					<Tooltip title="Driver Channel">
						<Button
							onClick={(e) => {
								e.stopPropagation();
								props.onClick(otherChat);
							}}
							variant="contained"
						>
							<RiSteering2Line style={{ fontSize: "2rem" }} />
						</Button>
					</Tooltip>
				)}
			</ListItem>
		</div>
	);
}

export default ChatListItemByType;
