import moment from "moment";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Typography,
	Divider,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Button,
	Icon,
	Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import SmarthopMenuContainer from "@smarthop/form/fields/SmarthopMenuContainer";
import SmarthopCheckboxField from "@smarthop/form/fields/SmarthopCheckboxField";
import SmarthopTextField from "@smarthop/form/fields/SmarthopTextField";
import SmarthopSliderField from "@smarthop/form/fields/SmarthopSliderField";
import SmarthopAutocompleteField from "@smarthop/form/fields/SmarthopAutocompleteField";
import SmarthopPickerField from "@smarthop/form/fields/SmarthopPickerField";

import { isEnabled } from "app/services/featureStorageService";
import { isRoleExternal } from "app/services/LoginService";
import { replaceFormDialog } from "app/store/tools/formDialogSlice";
import { createTooltip } from "app/main/utils/tableUtils";
import { filtersChanged, DEFAULT_SOFT_FILTERS_VALUES, DEFAULT_HARD_FILTERS_VALUES } from "../utils/filtersUtils";

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "13px",
		color: "black",
		backgroundColor: "#f1f1f1",
		maxWidth: "none",
		border: "solid thin black",
	},
}))(Tooltip);

function SectionHeader({ label, actions }) {
	const getBtn = (action) => {
		return (
			<Button
				key={"actions_button_" + action.label}
				className={`tex-11 ml:tex-12 py-1 ml-4 rounded-4 mb-6 ${action.className}`}
				variant={action.variant ?? "contained"}
				color={action.color ?? "secondary"}
				onClick={action.onClick}
			>
				{action.icon ? <Icon className="text-15 ml:text-16">{action.icon}</Icon> : action.label}
				{action.description &&
					!action.onClick &&
					createTooltip(
						<Icon className="text-13 ml-4 pt-1" key="info_icon">
							info
						</Icon>,
						action.description
					)}
				{action.description && action.onClick && (
					<Icon className="text-14 ml-4 pt-1 -mr-4" key="info_icon">
						info
					</Icon>
				)}
			</Button>
		);
	};

	const actionsView = actions ? (
		<div className="flex flex-1 flex-row -mt-8 item-end justify-end">
			{actions.map((action, index) => (
				<div className="flex" key={"container_button_" + index}>
					{action.description && action.onClick && (
						<TooltipStyle
							key={"description_" + index}
							index={action.description + "_" + index}
							title={action.description}
						>
							{getBtn(action)}
						</TooltipStyle>
					)}
					{(!action.description || !action.onClick) && getBtn(action)}
				</div>
			))}
		</div>
	) : null;

	return (
		<div className="flex flex-col">
			<div className="flex flex-row">
				<Typography className="text-14 ml:text-15  font-semibold">{label}</Typography>
				{actionsView}
			</div>
			<Divider className="mb-20" />
		</div>
	);
}

function CheckBoxFilter({ name, label, isHardFilter, onEnable, value, description }) {
	return (
		<SmarthopCheckboxField
			key={"checkbox_" + name}
			name={name}
			errors={[]}
			label={label}
			value={value}
			onChangeCommitted={(model, value) => {
				onEnable({ [model]: value }, isHardFilter);
			}}
			description={description}
			checkbox={{ noErrorMessage: true }}
		/>
	);
}

function TextFilter({ name, label, description, value, onChange, isHardFilter }) {
	return (
		<SmarthopTextField
			key={"textFilter_" + name}
			errors={[]}
			name={name}
			value={value}
			label={label}
			description={description}
			onChangeCommitted={(model, value) => {
				onChange({ [model]: value }, isHardFilter);
			}}
		/>
	);
}

function SliderFilter({ name, label, description, value, onChange, slider, disabled, isHardFilter }) {
	return (
		<SmarthopSliderField
			key={name}
			errors={[]}
			type="slider"
			name={name}
			label={label}
			slider={slider}
			disabled={disabled}
			value={value}
			onChangeCommitted={(model, value) => {
				onChange({ [model]: value }, isHardFilter);
			}}
			onChange={(value) => {
				onChange({ [name]: value }, isHardFilter);
			}}
		/>
	);
}

function DateFilter({ value, name, label, onChange, isHardFilter }) {
	return (
		<SmarthopPickerField
			key={"search_" + name}
			type="date"
			name={name}
			label={label}
			disabled={false}
			value={value}
			onChange={(value) => {
				onChange({ [name]: value }, isHardFilter);
			}}
			field={{ noErrorMessage: true }}
			picker={{
				disablePast: true,
				notClearable: false,
				type: "date",
				classes: {},
			}}
		/>
	);
}

function AutocompleteFilter({ view, label, name, isHardFilter, onChange, autocomplete, disabled }) {
	return (
		<SmarthopAutocompleteField
			name={name}
			label={label}
			disabled={disabled}
			inputValue={!autocomplete.multiple && view?.label ? view?.label : ""}
			optionsValue={autocomplete.multiple && view ? view : view?.value ? [view] : []}
			onSelected={(optionsValues) => {
				const viewValue = autocomplete.multiple ? optionsValues : optionsValues?.[0];
				const singleValue = autocomplete.multiple
					? optionsValues?.map((value) => value.value)?.join(",")
					: optionsValues?.[0]?.value;
				onChange({ [name + "__view"]: viewValue, [name]: singleValue }, isHardFilter);
			}}
			field={{ noErrorMessage: true }}
			autocomplete={autocomplete}
		/>
	);
}

function SelectFilter({ options, label, onChange, value, name, isHardFilter }) {
	const classes = value ? { select: "py-14" } : {};
	return (
		<FormControl className="w-full px-4 mb-4 mt-4" fullWidth variant="outlined">
			<InputLabel>{label}</InputLabel>
			<Select
				key={name}
				label={label}
				classes={classes}
				className={"w-full pr-0 pl-0"}
				value={value}
				name={name}
				variant="outlined"
				onChange={(event) => {
					onChange({ [event.target.name]: event.target.value }, isHardFilter);
				}}
			>
				{options.map((option) => {
					return (
						<MenuItem value={option.value} key={name + "_" + option.value}>
							<Typography display="inline">{option.label}</Typography>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

function SearchFilters(props) {
	const dispatch = useDispatch();
	const updateFilter = props.onFilterChanged;
	const currentHardFilters = props.hardFilters;
	const currentSoftFilters = props.softFilters;
	const onSearch = props.onSearch;
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const viewerIsCustomer = useMemo(() => isRoleExternal(), []);
	const currentSearch = searches[currentSearchId];
	const flicker = props.flicker;
	const withAnimation = props.withAnimation;
	const isMobile = props.isMobile;
	const carrierField = props.carrierField;
	const truckField = props.truckField;

	const searchButton = useMemo(() => {
		const softFiltersChanged = filtersChanged(currentSoftFilters, currentSearch?.softFilters);
		const hardFiltersChanged = filtersChanged(currentHardFilters, currentSearch?.filters);
		const disabled =
			!currentHardFilters?.carrier ||
			!currentHardFilters?.truck__view ||
			!currentHardFilters?.location_origin__view ||
			!currentHardFilters?.pickup_ds__after;
		return {
			type: "action",
			action: "SUBMIT",
			label: "Search",
			click: onSearch,
			button: {
				alwaysEnabled: !disabled,
				classes: {
					root: softFiltersChanged || hardFiltersChanged ? `${flicker} ${withAnimation} rounded-4 ` : "rounded-4 ",
				},
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSoftFilters, currentHardFilters, currentSearch?.softFilters]);

	const onSaveTruckPlan = () => {
		const allData = { ...currentHardFilters, ...currentSoftFilters };
		const dataToSend = {
			rate_per_mile_preferred: allData?.rate_per_mile_preferred,
			miles_preferred__max: allData?.miles_preferred[1],
			miles_preferred__min: allData?.miles_preferred[0],
			rate_total_preferred: allData?.rate_total_preferred,
			extra_stops_preferred: allData?.extra_stops_preferred,
			dh_origin_preferred: allData?.dh_origin,
			profit_preferred: allData?.profit_preferred,
			min_rank_mci: allData?.min_rank_mci,
			age_mins_max_preferred: allData?.age_mins_max_preferred,
			min_credit_score: allData?.min_credit_score,
			carrier: allData?.carrier,
			truck: allData?.truck,
		};

		if (allData?.destinationStates?.length) {
			dataToSend.current_destination = null;
			dataToSend.destinationStates = allData.destinationStates;
			dataToSend.destinationStates__view = allData.destinationStates__view;
		} else if (allData?.location_destination) {
			dataToSend.current_destination = allData.location_destination;
			dataToSend.current_destination__view = allData.location_destination__view;
		}

		if (allData?.location_origin) {
			dataToSend.current_location = allData?.location_origin;
			dataToSend.current_location__view = allData?.location_origin__view;
			dataToSend.current_location_coords = allData?.location_origin__view?.metadata?.coords;
		}
		if (allData?.pickup_ds__after) {
			dataToSend.current_available_date = moment
				.parseZone(allData?.pickup_ds__after)
				.utc(true)
				.add(12, "hours")
				.format("YYYY-MM-DD HH:mm");
		}

		dispatch(
			replaceFormDialog({
				viewId: "TRUCK_PLAN",
				mode: "EDIT",
				dataIds: {
					carrierId: allData?.carrier,
					truckId: allData?.truck,
					overridesDataPlan: dataToSend,
					openSection: "search",
					fromSearch: true,
				},
			})
		);
	};

	const resetFilters = () => {
		//Reset all soft filters to defaults
		updateFilter(DEFAULT_SOFT_FILTERS_VALUES, false);

		//Only reset some hard filters without affect main search fields
		const newHardFilters = {
			...DEFAULT_HARD_FILTERS_VALUES,
			pickup_ds__before: undefined,
			destinationStates__view: undefined,
			location_destination__view: undefined,
			blacklisted_states_ids__view: undefined,
		};
		updateFilter(newHardFilters, true);
	};

	const preferencesItems = () => {
		let actions = [];
		if (currentHardFilters?.truck?.length === 24 && currentHardFilters?.location_origin__view?.value) {
			actions.push({
				label: "Update Plan",
				onClick: onSaveTruckPlan,
				description: "Save search preferences to Truck Plan",
			});
		}

		actions.push({
			icon: "replay",
			onClick: resetFilters,
		});
		let milesPreferred = currentSoftFilters?.miles_preferred
			? [...currentSoftFilters?.miles_preferred]
			: DEFAULT_SOFT_FILTERS_VALUES.miles_preferred;
		return (
			<div className="mt-20" key="container_pref">
				<div key="carrier_truck" className="mt-20 mb-20">
					{isMobile && carrierField}
					{isMobile && truckField}
				</div>
				<SectionHeader key="preferences_header" label="Preferences" actions={actions} dispatch={dispatch} />
				<SliderFilter
					name="rate_per_mile_preferred"
					label="Rate Per Mile (Minimum)"
					value={currentSoftFilters?.rate_per_mile_preferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						inverted: true,
						type: "input",
						prefix: "$",
						postfix: "+",
						step: 0.1,
						min: 0,
						max: 5,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="rate_total_preferred"
					label="Rate Total (Minimum)"
					value={currentSoftFilters?.rate_total_preferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						inverted: true,
						type: "input",
						prefix: "$",
						postfix: "+",
						step: 5,
						min: 0,
						max: 5000,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="miles_preferred"
					label="Trip Miles + DH (Range)"
					value={milesPreferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						type: "range",
						postfix: " mi",
						min: 0,
						max: 4000,
						step: 50,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="age_mins_max_preferred"
					label="Age (Maximum)"
					value={currentSoftFilters?.age_mins_max_preferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						type: "input",
						postfix: " min",
						builder: (value) => {
							if (value === DEFAULT_SOFT_FILTERS_VALUES.age_mins_max_preferred) return { label: "2h+" };
							if (value < 10) return { label: value + "m" };
							let mins = parseInt(value % 60);
							let hourse = parseInt((value / 60) % 24);
							let days = parseInt(value / 60 / 24);
							let label =
								(days > 0 ? days + "d:" : "") +
								(hourse > 0 || days > 0 ? hourse + "h:" : "") +
								(mins < 10 ? "0" : "") +
								mins +
								"m";
							return { label };
						},
						min: 1,
						max: DEFAULT_SOFT_FILTERS_VALUES.age_mins_max_preferred,
						step: 1,
						scale: (x) => Math.exp(x),
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="profit_preferred"
					label="Profit (Minimum)"
					value={currentSoftFilters?.profit_preferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						builder: (value) => {
							if (value === -1000) {
								return { label: "Any" };
							} else if (value < 0) {
								return { label: "-$" + value * -1, classes: "text-red-600" };
							} else {
								return { label: "$" + value + "+", classes: "text-green-600" };
							}
						},
						inverted: true,
						type: "input",
						prefix: "$",
						step: 25,
						min: -1000,
						max: 1000,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="min_rank_mci"
					label="MPI (Minimum)"
					value={currentSoftFilters?.min_rank_mci}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						inverted: true,
						type: "input",
						prefix: "",
						postfix: "+",
						step: 5,
						min: 0,
						max: 100,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
					}}
				/>
				<SliderFilter
					name="min_credit_score"
					label="Broker Credit Score (Minimum)"
					value={currentSoftFilters?.min_credit_score}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						inverted: true,
						type: "input",
						prefix: "",
						postfix: "+",
						step: 1,
						min: 0,
						max: 100,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
						builder: (value) => {
							if (value === 0) {
								return { label: "Any" };
							} else if (value < 67) {
								return { label: value, value: value, classes: "text-red-600" };
							} else if (value < 87) {
								return { label: value, value: value, classes: "text-orange-600" };
							} else {
								return { label: value, value: value, classes: "text-green-600" };
							}
						},
					}}
				/>
				<SliderFilter
					name="extra_stops_preferred"
					label="Extra Stops (Maximum)"
					value={currentSoftFilters?.extra_stops_preferred}
					onChange={updateFilter}
					isHardFilter={false}
					slider={{
						inverted: false,
						type: "input",
						step: 1,
						min: 0,
						max: 5,
						noErrorMessage: true,
						classes: { root: "-mt-2" },
						variant: undefined,
						builder: (value) => {
							if (value === 5) {
								return { label: "5+" };
							} else {
								return { label: value, value: value };
							}
						},
					}}
				/>
			</div>
		);
	};

	const loadRouteItems = (
		<div className="mt-20">
			<SectionHeader label="Trailer" />
			<SelectFilter
				name="load_type"
				label="Capacity"
				value={currentHardFilters?.load_type}
				isHardFilter={true}
				onChange={updateFilter}
				options={[
					{ label: "Only Full", value: "FULL" },
					{ label: "Only Partial", value: "PARTIAL" },
					{ label: "Partial and Full", value: "BOTH" },
				]}
			/>
			<SelectFilter
				name="equipment"
				label="Equipment"
				value={currentHardFilters?.equipment}
				isHardFilter={true}
				onChange={updateFilter}
				options={[
					{ label: "Van", value: "VAN" },
					{ label: "Reefer", value: "REEFER" },
					{ label: "Flatbed", value: "FLATBED" },
					{ label: "Power Only", value: "PO" },
					...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS")
						? [
								{
									value: "SPECIAL_Box Truck",
									label: "Box Truck",
								},
								{
									value: "SPECIAL_Cargo Van",
									label: "Cargo Van",
								},
								{
									value: "SPECIAL_Conestoga",
									label: "Conestoga",
								},
								{
									value: "SPECIAL_Deck",
									label: "Deck",
								},
								{
									value: "SPECIAL_HotShot",
									label: "HotShot",
								},
								{
									value: "SPECIAL_Tanker",
									label: "Tanker",
								},
						  ]
						: []),
				]}
			/>
			{currentHardFilters?.equipment !== "PO" ? (
				<div className="flex flex-row justify-center">
					<SelectFilter
						name="trailer"
						label="Length"
						value={currentHardFilters?.trailer}
						isHardFilter={true}
						onChange={updateFilter}
						options={[
							{ label: "53'", value: 53 },
							{ label: "48'", value: 48 },
							{ label: "45'", value: 45 },
							{ label: "28'", value: 28 },
							{ label: "26'", value: 26 },
							{ label: "15'", value: 15 },
						]}
					/>
					<TextFilter
						name="max_weight"
						label="Max Weight"
						isHardFilter={true}
						value={currentHardFilters?.max_weight}
						onChange={updateFilter}
					/>
				</div>
			) : null}
			<SectionHeader label="Route" />
			<DateFilter
				value={currentHardFilters?.pickup_ds__before ? moment(currentHardFilters?.pickup_ds__before).utc() : undefined}
				name="pickup_ds__before"
				label="Latest Pick Up Date"
				onChange={updateFilter}
				isHardFilter={true}
			/>
			<SliderFilter
				name="dh_origin"
				label="DH-Origin (Maximum)"
				value={currentHardFilters?.dh_origin}
				onChange={updateFilter}
				isHardFilter={true}
				slider={{
					type: "input",
					prefix: "",
					postfix: " mi",
					step: 10,
					min: 1,
					max: 500,
					noErrorMessage: true,
					classes: { root: "-mt-2" },
					variant: undefined,
				}}
			/>
			<AutocompleteFilter
				view={currentHardFilters?.destinationStates__view}
				isHardFilter={true}
				onChange={updateFilter}
				label="Destination States"
				name="destinationStates"
				disabled={!!currentHardFilters?.location_destination__view}
				autocomplete={{
					provider: "smarthop",
					url: "api/consts/states",
					types: [],
					multiple: true,
					anyValue: false,
					preloadDefault: true,
					icon: "location",
					maxValues: 7,
				}}
			/>
			<AutocompleteFilter
				view={currentHardFilters?.location_destination__view}
				isHardFilter={true}
				onChange={updateFilter}
				label="Destination"
				name="location_destination"
				disabled={currentHardFilters?.destinationStates__view?.length > 0}
				autocomplete={{
					provider: "trimblemaps",
					formatST: true,
					params: {
						componentRestrictions: { country: "us", includeOnly: "city" },
						types: ["(cities)"],
					},
					multiple: false,
					anyValue: false,
					icon: "location",
				}}
			/>
			<SliderFilter
				name="dh_destination"
				label="DH-Destination (Maximum)"
				value={currentHardFilters?.dh_destination}
				onChange={updateFilter}
				disabled={!currentHardFilters?.location_destination__view}
				isHardFilter={true}
				slider={{
					type: "input",
					prefix: "",
					postfix: " mi",
					step: 10,
					min: 1,
					max: 500,
					noErrorMessage: true,
					classes: { root: "-mt-2" },
					variant: undefined,
				}}
			/>
			<AutocompleteFilter
				view={currentHardFilters?.blacklisted_states_ids__view}
				isHardFilter={true}
				onChange={updateFilter}
				label="Blacklist States"
				name="blacklisted_states_ids"
				autocomplete={{
					provider: "smarthop",
					url: "api/consts/states",
					types: [],
					multiple: true,
					anyValue: false,
					preloadDefault: true,
					icon: "location",
				}}
			/>
		</div>
	);

	const filtersItems = (
		<div className="mt-20">
			<SectionHeader label="Brokers" />
			<TextFilter
				name="searchBroker"
				label="Search Broker"
				description="description"
				value={currentSoftFilters?.searchBroker}
				onChange={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyPreffered"
				value={currentSoftFilters?.onlyPreffered}
				label="Show only loads from my preferred brokers"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<SectionHeader label="Filters" />
			<CheckBoxFilter
				name="onlyNew"
				value={currentSoftFilters?.onlyNew}
				label="Show only new loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlySmartpay"
				value={currentSoftFilters?.onlySmartpay}
				label="Show only factorable loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyBook"
				value={currentSoftFilters?.onlyBook}
				label="Book/Bid Online - Show loads that can be booked digitally"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyTruckPlan"
				value={currentSoftFilters?.onlyTruckPlan}
				label="Show only loads that match my current truck plan"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyNoFlex"
				value={currentSoftFilters?.onlyNoFlex}
				label="Hide all flexible loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyNoInter"
				value={currentSoftFilters?.onlyNoInter}
				label="Hide International loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyTeam"
				value={currentSoftFilters?.onlyTeam}
				label="Show only team loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyWithRate"
				value={currentSoftFilters?.onlyWithRate}
				label="Show only loads with rates"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="onlyHazmat"
				value={currentSoftFilters?.onlyHazmat}
				label="Show only hazmat loads"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			<CheckBoxFilter
				name="hideCovered"
				value={currentSoftFilters?.hideCovered}
				label="Hide covered loads"
				description="Hide loads covered more than 2 minutes ago"
				isHardFilter={false}
				onEnable={updateFilter}
			/>
			{!viewerIsCustomer && (
				<>
					<SectionHeader label="System" />
					<AutocompleteFilter
						view={currentSoftFilters?.onlyOwnerLoads__view}
						isHardFilter={false}
						onChange={updateFilter}
						label="Loads Source"
						name="onlyOwnerLoads"
						autocomplete={{
							provider: "smarthop",
							url: "api/consts/owners",
							types: [],
							multiple: true,
							anyValue: false,
							preloadDefault: true,
						}}
					/>
					<CheckBoxFilter
						name="onlyCovered"
						value={currentSoftFilters?.onlyCovered}
						label="Show only covered loads"
						isHardFilter={false}
						onEnable={updateFilter}
					/>
					<CheckBoxFilter
						name="onlyTest"
						value={currentSoftFilters?.onlyTest}
						label="Show only test loads"
						isHardFilter={false}
						onEnable={updateFilter}
					/>
				</>
			)}
		</div>
	);

	return (
		<div className="mb-4 mt-4 pt-4 pb-4" key="filter_menu_container">
			<SmarthopMenuContainer
				key={"filter_button"}
				label={""}
				icon={"tune"}
				button={{
					color: "primary",
				}}
				renderOnce={true}
				groups={!isMobile ? [preferencesItems(), loadRouteItems, filtersItems] : undefined}
				actions={[searchButton]}
				menu={{
					classes: {
						container: "pb-16 flex flex-col md:flex-row justify-between md:pl-10 md:pr-10",
					},
				}}
			>
				{[preferencesItems(), loadRouteItems, filtersItems]}
			</SmarthopMenuContainer>
		</div>
	);
}

export default SearchFilters;
