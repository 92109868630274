import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import MessageBasic from "app/main/chat/messages/MessageBasic";
import MessageBasicSms from "app/main/chat/messages/MessageBasicSms";
import MessageViewRequestLocation from "app/main/chat/messages/MessageViewLoadRequestLocation";
import MessageGeoFence from "app/main/chat/messages/MessageGeoFence";
import MessageViewMarketAnalysisWarning from "app/main/chat/messages/MessageViewMarketAnalysisWarning";
import MessageViewLoadShared from "app/main/chat/messages/MessageViewLoadShared";
import TransactionBidBookView from "app/main/profile/transactionsState/components/TransactionBidBookView";
import MessageViewCommandHelp from "../chat/messages/MessageViewCommandHelp";

import { authRoles } from "app/main/consts";

// Services
import { getRoleLabel } from "app/services/LoginService";
import MessageViewTruckReminder from "../chat/messages/MessageViewTruckReminder";
import { Tooltip, withStyles } from "@material-ui/core";

const TooltipStyle = withStyles((theme) => ({
	tooltip: {
		fontSize: "14px",
	},
}))(Tooltip);

const buildChatWarningAvatar = () => {
	return (
		<Avatar src={null} alt={"Warning"} className={"bg-orange-400"}>
			<Icon className="text-24 text-orange-100" color="action">
				warning
			</Icon>
		</Avatar>
	);
};

const buildChatAvatar = ({ chat, truck, user }, viewerIsOwner, options) => {
	return chat?.type === "TRUCK_EXTERNAL" ? (
		<Avatar
			src={null}
			alt={chat?.metadata?.truck?.company}
			className={"bg-blue-500 " + (options?.classes?.avatar ?? "")}
		>
			<Icon className={"text-blue-100 " + (options?.classes?.icon ?? " text-24 ")} color="action">
				search
			</Icon>
		</Avatar>
	) : chat?.type === "TRUCK_DELIVERY" ? (
		<Avatar
			src={null}
			alt={chat?.metadata?.truck?.company}
			className={"bg-green-500 " + (options?.classes?.avatar ?? "")}
		>
			<Icon className={"text-green-100 " + (options?.classes?.icon ?? " text-24 ")} color="action">
				local_shipping
			</Icon>
		</Avatar>
	) : (
		<Avatar src={null} alt={chat?.metadata?.truck?.company} className={options?.classes?.avatar ?? ""}></Avatar>
	);
};

const buildChatTypeTitle = ({ chat }, viewerIsOwner, options) => {
	return chat?.type === "TRUCK_EXTERNAL"
		? "Dispatcher Channel"
		: chat?.type === "TRUCK_DELIVERY"
		? "Driver Channel"
		: "Unknown Type";
};

const buildChatTitle = ({ chat, truck }, viewerIsOwner, options) => {
	let variant = options?.variant ?? "default";
	let driverName = truck?.driverName ?? "No driver";
	let carrierName = truck?.company ?? "Carrier #" + truck?.carrierid;
	const equipmentLabel = truck?.subtype ?? truck?.equipment;

	if (carrierName.length > 15 && variant === "notification") {
		carrierName = carrierName.substring(0, 13) + "...";
	}
	if (driverName.length > 15 && variant === "notification") {
		driverName = driverName.substring(0, 13) + "...";
	}

	if (viewerIsOwner && truck) {
		return variant === "short" || variant === "notification"
			? equipmentLabel + " #" + truck?.truckid + " - " + driverName
			: (chat.type === "TRUCK_EXTERNAL" ? "Dispatch Channel " : "Driver Channel ") +
					" for Driver " +
					driverName +
					" - " +
					equipmentLabel +
					" #" +
					truck?.truckid;
	} else if (!viewerIsOwner && truck) {
		return "[" + carrierName + "] " + equipmentLabel + " #" + truck?.truckid + " - " + driverName;
	} else if (chat.name) {
		return chat.name;
	} else {
		return "Unnamed Channel";
	}
};

const buildChatSubtitle = ({ chat, truck }, viewerIsOwner, options) => {
	if (viewerIsOwner && truck) {
		return null;
	} else if (!viewerIsOwner && truck) {
		return chat.type === "TRUCK_EXTERNAL"
			? "Carreir Dispatching Channel for '" + truck?.company + "'"
			: "Channel for Carrier '" + truck?.company + "'";
	} else {
		return null;
	}
};

const buildChatLabels = ({ chat }, viewerIsOwner, options) => {
	if (viewerIsOwner || !chat?.type) {
		return null;
	}

	return (
		<div
			key="1"
			className={
				"flex py-14 px-12 mb-2 items-center justify-center " +
				(chat.type === "TRUCK_EXTERNAL" ? "bg-blue" : chat.type === "TRUCK_DELIVERY" ? "bg-green" : "bg-blue")
			}
		>
			<Typography className={"text-white text-12 font-medium"}>
				{chat.type === "TRUCK_EXTERNAL"
					? "Public Dispatching Chat Visible to Specific Carrier's Users"
					: chat.type === "TRUCK_DELIVERY"
					? "Public Delivery Chat Visible to All Carrier's Users"
					: chat.type}
			</Typography>
		</div>
	);
};

const buildChatPreview = ({ chat, user, viewerUserId }, viewerIsOwner, options) => {
	const lastMessage = () => {
		if (!chat?.lastUser) {
			return "No Messages";
		} else if (user?.first_name && chat?.lastPreview && chat?.lastType === "TEXT") {
			const senderExternal = authRoles.externalUsers?.includes(chat?.metadata?.lastUser?.roleLabel);
			if (chat?.lastUser === viewerUserId) {
				return "You: " + chat?.lastPreview;
			} else if (!viewerIsOwner || senderExternal) {
				return user?.first_name + ": " + chat?.lastPreview;
			} else {
				return "SmartHop Team: " + chat?.lastPreview;
			}
		} else if (chat?.lastPreview) {
			return chat?.lastPreview;
		} else {
			return "Message";
		}
	};

	const classesMessage = options?.classes?.message;
	return (
		<Typography
			key={`${chat?._id}_last_message`}
			component={"span"}
			variant={"body2"}
			color={"textPrimary"}
			className={classesMessage ?? ""}
		>
			{lastMessage()}
		</Typography>
	);
};

const buildMessageAvatar = ({ message, user, senderExternal, sameUser }, viewerIsOwner, options) => {
	let letters =
		(user?.first_name?.length ? user?.first_name.substring(0, 1) : "") +
		(user?.last_name?.length ? user?.last_name.substring(0, 1) : "");

	if (letters) {
		return (
			<Avatar
				src={null}
				alt={""}
				className={(options?.classes?.avatar ?? "") + (!senderExternal || sameUser ? " bg-indigo-900 " : "")}
			>
				{!senderExternal && viewerIsOwner ? (
					<img className="pl-2 pr-5 w-44" src="assets/images/logos/smarthop.png" alt="logo" />
				) : (
					<Typography className="font-medium text-12 md:text-16">{letters}</Typography>
				)}
			</Avatar>
		);
	} else {
		return <Avatar src={null} alt={""} className={options?.classes?.avatar ?? ""}></Avatar>;
	}
};

const buildSenderName = ({ user, message }, viewerIsOwner, options) => {
	if (!user) {
		return null;
	}
	if (user?.first_name && user?.last_name) {
		const u = user?.first_name + " " + user?.last_name + " ";
		const iconName = user.sms_notify_opt_out ? "phone_disabled" : "phone";
		const icon = message?.phone ? (
			<TooltipStyle
				className="ml-4"
				title={`VIA SMS: ${message?.phone}${user.sms_notify_opt_out ? " (Unsubsribed from SMS)" : ""}`}
			>
				<Icon className="text-14">{iconName}</Icon>
			</TooltipStyle>
		) : null;

		return (
			<div className="flex flex-row items-center">
				{u}
				{icon}
			</div>
		);
	} else {
		return null;
	}
};

const buildMessageContentPreview = ({ message, incoming }, viewerIsOwner, options) => {
	if (message?.type === "TEXT") {
		let preview = message?.content?.text;
		if (!preview && message?.content?.files?.length > 0) {
			preview = `${message.content.files.length} Files`;
		}
		return preview;
	} else if (message?.type === "REQUEST_GPS_TRACKING") {
		return "Location GPS request";
	} else if (message?.type === "BID_BOOK_TRANSACTION") {
		return "New bid/book transaction";
	} else if (message?.type === "LOAD_SHARED") {
		return "Load has been shared";
	} else if (message?.type === "MARKET_ANALYTICS_WARNING") {
		return "Load Scout";
	} else if (message?.type === "TRUCK_REMINDER") {
		return "Truck Reminder";
	} else if (message?.type === "COMMAND_HELP") {
		return "Help command";
	} else if (message?.type === "DRIVER_ITINERARY") {
		return "Sent Itinerary";
	} else {
		return "New Message";
	}
};

const buildMessagePreview = ({ message, user, incoming }, viewerIsOwner, options) => {
	if (authRoles.internal?.includes(user?.roleLabel)) {
		return "SmartHop Team: " + buildMessageContentPreview({ message }, viewerIsOwner, options);
	} else if (user?.first_name && user?.first_name !== "SmartHop" && user?.first_name !== "Smarthop") {
		return user?.first_name + ": " + buildMessageContentPreview({ message }, viewerIsOwner, options);
	} else {
		return buildMessageContentPreview({ message, incoming }, viewerIsOwner, options);
	}
};

const buildMessageView = ({ message, incoming, nativeMobile }, viewerIsOwner, options) => {
	let dispatch = options?.dispatch;
	let userId = options?.userId;
	let sender = message?.user;
	let locationPermission = options?.locationPermission;
	const role = getRoleLabel();
	const isDispatcher = authRoles.dispatcher.includes(role);

	if (message?.type === "LOAD_SHARED") {
		return (
			<MessageViewLoadShared
				message={message}
				incoming={incoming}
				userId={userId}
				viewerIsOwner={viewerIsOwner}
				sender={sender}
				nativeMobile={nativeMobile}
			/>
		);
	} else if (message?.type === "BID_BOOK_TRANSACTION") {
		return (
			<TransactionBidBookView
				message={message}
				incoming={incoming}
				dispatch={dispatch}
				userId={userId}
				viewerIsOwner={viewerIsOwner}
			/>
		);
	} else if (message?.type === "REQUEST_GPS_TRACKING") {
		return (
			<MessageViewRequestLocation
				message={message}
				incoming={incoming}
				dispatch={dispatch}
				userId={userId}
				isDispatcher={isDispatcher}
				viewerIsOwner={viewerIsOwner}
				locationPermission={locationPermission}
			/>
		);
	} else if (message?.type === "TRUCK_REMINDER") {
		return (
			<MessageViewTruckReminder
				message={message}
				incoming={incoming}
				dispatch={dispatch}
				userId={userId}
				viewerIsOwner={viewerIsOwner}
			/>
		);
	} else if (message?.type === "GEO_FENCE_CONFIRM") {
		return <MessageGeoFence message={message} />;
	} else if (message?.type === "MARKET_ANALYTICS_WARNING") {
		return <MessageViewMarketAnalysisWarning message={message} nativeMobile={nativeMobile} />;
	} else if (message?.type === "COMMAND_HELP") {
		return <MessageViewCommandHelp message={message} />;
	} else if (message?.type === "TEXT") {
		return <MessageBasic message={message} />;
	} else if (
		["DRIVER_ITINERARY", "DRIVER_BOL_NOTIFICATION", "DRIVER_REUPLOAD_BOL_NOTIFICATION"].includes(message?.type)
	) {
		return <MessageBasicSms message={message} />;
	}

	//Provitional meanwhile the old messages dissapear from UI
	if (
		message?.type === "LOAD_CANCELED" ||
		message?.type === "LOAD_ACCEPTED" ||
		message?.type === "LOAD_SUGGESTED" ||
		message?.type === "LOAD_DISPATCHED" ||
		message?.type === "ITINERARY_SENT" ||
		message?.type === "TRUCK_PLAN_REMINDED" ||
		message?.type === "EVENT_TRUCK_PLAN_OUTDATED"
	) {
		return (
			<Typography
				color={"inherit"}
				className="font-normal break-all text-12 max-w-sm md:text-14 italic text-gray-400"
				component="div"
			>
				Message removed
			</Typography>
		);
	}

	return (
		<Typography color={"inherit"} className="font-normal break-all text-12 max-w-sm md:text-14" component="div">
			{buildMessageContentPreview({ message })}
		</Typography>
	);
};

const capitalizeFirstLetter = (word) => {
	if (!word) return "";
	const wordArray = word.split(" ");
	for (var i = 0; i < wordArray.length; i++) {
		wordArray[i] = wordArray[i].charAt(0).toUpperCase() + wordArray[i].slice(1);
	}
	const wordCapitalize = wordArray.join(" ");
	return wordCapitalize;
};

const buildChannelId = (carrier, type) => {
	let channel = "CHANNEL_" + carrier;
	if (type) channel = channel + "_" + type;
	return channel;
};

export {
	buildChatWarningAvatar,
	buildChatAvatar,
	buildChatTypeTitle,
	buildChatTitle,
	buildChatSubtitle,
	buildChatLabels,
	buildChatPreview,
	buildSenderName,
	buildMessageAvatar,
	buildMessagePreview,
	buildMessageView,
	capitalizeFirstLetter,
	buildChannelId,
};
