import { createHeaders, createRequest, throwResErrors } from "./requestUtil";

export const getUploadedFiles = () => {
	return createRequest()
		.get(`api/multi-upload/list`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const startQueueFiles = () =>
	createRequest()
		.get(`api/multi-upload/queue`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const getParsedRC = (fileId) => {
	return createRequest()
		.get(`api/multi-upload/file/${fileId}/transcribe`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const clearFiles = () =>
	createRequest()
		.delete(`api/multi-upload/files`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const deleteFile = (fileId) =>
	createRequest()
		.delete(`api/multi-upload/file/${fileId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const updateFileTruckMetadata = (fileId, data) =>
	createRequest()
		.put(`api/multi-upload/file/${fileId}/metadata`, { data })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const retryFileUpload = (fileId) =>
	createRequest()
		.put(`api/multi-upload/file/${fileId}/retry`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const saveTripTestData = (data) =>
	createRequest()
		.post(`api/multi-upload/test-data`, data)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const getTripTestDataPreview = (tripId, regenerate) =>
	createRequest()
		.get(`api/multi-upload/test-data/trips/${tripId}/preview${regenerate ? "?regenerate=true" : ""}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));

export const saveFileOnTrip = (fileId, tripId) =>
	createRequest()
		.put(`api/multi-upload/file/${fileId}/trip/${tripId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
