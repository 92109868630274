// Dependencies
import { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { openLoadedFormDialog, refreshDialog } from "app/store/tools/formDialogSlice";
import clsx from "clsx";
import { SmarthopConfirmDialog } from "@smarthop/form";

// Material-UI components
import { Button, CardMedia, CircularProgress, Typography } from "@material-ui/core";

// Utils
import { readURLParameters, rewriteURLParameters } from "app/main/utils/urlUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { downloadFile } from "app/main/utils/fileUtils";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";

// Services
import {
	approvePayroll,
	closePayroll,
	generateDriverPayroll,
	getPayrollById,
	removeTripsFromPayroll as removeTripsFromPayrollService,
	rollbackPayroll,
} from "app/services/billingServices";
import { getCarrierId, getRoleLabel, isCarrierPermission, isInternalPermission } from "app/services/LoginService";

// Components
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import EnterprisePayrollDetailsView from "./EnterprisePayrollDetailsView";
import EnterprisePayrollSummaryView from "./enterprisePayrollSummaryView";

// Lists
import { payeePayrollLedgerList, payrollTripsList } from "@smarthop/list";
import { global } from "app/services/requestUtil";
import { getUserDetails, getUserDriverDetails } from "app/services/usersServices";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";

// Features
import { isEnabled } from "app/services/featureStorageService";

const EnterprisePayrollView = forwardRef(({ nativeMobile, dataIds, setTitle }, ref) => {
	useEffect(() => {
		setTitle?.(`Payroll Details`, dataIds?.label);
		// eslint-disable-next-line
	}, [dataIds?.label]);
	const user = useSelector(({ auth }) => auth.user);

	const [processing, setProcessing] = useState(false);
	const [selectedLedger, setSelectedLedger] = useState([]);
	const [unselectedLedger, setUnselectedLedger] = useState([]);
	const [selectedTrips, setSelectedTrips] = useState([]);
	const [unselectedTrips, setUnselectedTrips] = useState([]);
	const [tabKey, setTabKey] = useState("SUMMARY");
	const [fileLoading, setFileLoading] = useState(false);
	const [openDialogAlert, setDialogAlert] = useState({
		flag: false,
		message: null,
		type: null,
		optionsText: {},
	});
	const [payrollDetails, setPayrollDetails] = useState();
	const [userDetails, setUserDetails] = useState();
	const [payrollStatus, setPayrollStatus] = useState(dataIds?.payrollStatus);
	const [carrierId, setCarrierId] = useState(dataIds?.carrierId ?? getCarrierId());
	const [driverId, setDriverId] = useState(dataIds?.driverId ?? dataIds?.historyData?.payee?.driver);
	const [userId, setUserId] = useState(dataIds?.userId ?? dataIds?.historyData?.payee?.user);
	const [filters, setFilters] = useState();
	const [error, setError] = useState();

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	const role = useMemo(() => getRoleLabel(), []);
	const isCarrier = useMemo(() => isCarrierPermission(role), [role]);
	const isInternal = useMemo(() => isInternalPermission(role), [role]);
	const hasPayrollEditAccess = hasRequiredGateKeepers(user, { permission_payroll_access: "editor" });
	const hasPayrollAdminAccess = hasRequiredGateKeepers(user, { permission_payroll_access: "admin" });

	let sendReportByEmail = false;

	const isHistoryItem = !!dataIds.historyData;

	const isInvestor = !!dataIds.isInvestor;
	const payrollId = dataIds.payrollId ?? dataIds?.historyData?._id;
	const readOnly = dataIds.readOnly;

	const payrollRevision = useSelector(({ tools }) => tools.revision["payrollRevision"] ?? 0);
	const dataRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);
	const tripsRevision = useSelector(({ tools }) => tools.revision["tripsRevision"] ?? 0);

	const isCloseButtonDisabled =
		isEnabled("CLOSE_SINGLE_PAYROLL_V2") &&
		(!payrollDetails?.entries?.length ||
			(!selectedTrips.length && tabKey === "TRIPS") ||
			(!selectedLedger.length && tabKey === "LEDGER"));

	const downloadDriverPayroll = async ({ selected, status, snackbar }) => {
		try {
			const report = await generateDriverPayroll({ data: selected, payrollStatus: status });
			if (report) {
				downloadFile(report, `${status === "closed" ? "[Draft] " : ""}Payroll Report`, "application/pdf");
			}
		} catch (e) {
			showSnackbar(snackbar, e.errors[0]?.message, "error", 500);
		}
	};

	const setSendReportByEmail = (value) => {
		sendReportByEmail = value;
	};

	useEffect(() => {
		const params = readURLParameters();
		rewriteURLParameters({ tab: 0 });
		setProcessing(false);
		return () => {
			rewriteURLParameters(params);
		};
	}, []);

	const fetchPayrollData = async () => {
		try {
			const payrollData = await getPayrollById(payrollId);
			const payrollUserId = payrollData?.payee.user;
			const payrollDriverId = payrollData?.payee.driver;
			const userData = await (payrollUserId
				? getUserDetails(payrollUserId, carrierId, { version: 2 })
				: getUserDriverDetails(payrollDriverId, { version: 2 }));

			if (!userId) setUserId(userData.user._id);
			if (!carrierId && userData.user.roleType === "EXTERNAL") setCarrierId(userData.user.carrier);
			if (!driverId && userData.user.roleType === "EXTERNAL") setDriverId(userData.user.driver);
			setPayrollDetails({ ...payrollData });
			setPayrollStatus(payrollData?.state);
			setUserDetails({ ...userData });
		} catch (err) {
			setError(err.errors[0]?.message);
		} finally {
			setProcessing(false);
		}
	};

	useImperativeHandle(
		ref,
		() => {
			const btns = {};

			if (["open", "closed"].includes(payrollStatus)) {
				btns.edit = {
					onClick: () => {
						dispatch(openLoadedFormDialog({ formId: "PAYROLL_EDIT_FORM", mode: "EDIT", dataIds: { payrollId } }));
					},
					title: "Edit Period",
				};
			}
			dispatch(refreshDialog());
			return btns;
		},
		// eslint-disable-next-line
		[payrollStatus, payrollRevision, payrollId, dataRevision, tripsRevision]
	);

	useEffect(() => {
		setProcessing(true);
		fetchPayrollData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payrollRevision, payrollId, dataRevision, tripsRevision]);

	useEffect(() => {
		if (tabKey === "LEDGER") {
			setUnselectedTrips([]);
		} else if (tabKey === "TRIPS") {
			setUnselectedLedger([]);
		}
	}, [tabKey]);

	const removeTripsFromPayroll = async (carrierId, payrollId, selected) => {
		try {
			await removeTripsFromPayrollService(carrierId, payrollId, { data: selected });
			showSnackbar(snackbar, "Trips were successfully removed", "success");
		} catch (err) {
			showSnackbar(snackbar, err.errors[0]?.message, "error", 5000);
		} finally {
			setSelectedTrips([]);
			dispatch(incrementDataRevision({ event: "payrollRevision" }));
			dispatch(incrementDataRevision({ event: "tripsRevision" }));
		}
	};

	const handleSelectLedgerItems = (type, ids) => {
		if (type === "CLEAR") {
			setSelectedLedger([]);
		} else if (type === "ADD") {
			setSelectedLedger([...selectedLedger, ...ids]);
			setUnselectedLedger(unselectedLedger.filter((unselectedId) => !ids.includes(unselectedId)));
		} else if (type === "REMOVE") {
			setSelectedLedger(selectedLedger.filter((selectedId) => !ids.includes(selectedId)));
			setUnselectedLedger([...unselectedLedger, ...ids]);
		} else if (type === "ALL") {
			const allSelectedIds = ids.filter((id) => !unselectedLedger.includes(id));
			setSelectedLedger([...allSelectedIds]);
		}
	};

	const handleSelectTripsItems = (type, ids) => {
		if (type === "CLEAR") {
			setSelectedTrips([]);
		} else if (type === "ADD") {
			setSelectedTrips([...selectedTrips, ...ids]);
			setUnselectedTrips(unselectedTrips.filter((unselectedId) => !ids.includes(unselectedId)));
		} else if (type === "REMOVE") {
			setSelectedTrips(selectedTrips.filter((selectedId) => !ids.includes(selectedId)));
			setUnselectedTrips([...unselectedTrips, ...ids]);
		} else if (type === "ALL") {
			const allSelectedIds = ids.filter((id) => !unselectedTrips.includes(id));
			setSelectedTrips([...allSelectedIds]);
		}
	};

	const onListLoadedLedger = (data) => {
		const itemPayrollStatus = data.items?.[0]?.payroll__view?.metadata?.state;
		handleSelectLedgerItems("CLEAR");
		if (data?.items?.length && itemPayrollStatus === "open" && isEnabled("CLOSE_SINGLE_PAYROLL_V2")) {
			handleSelectLedgerItems(
				"ALL",
				data.items.map((item) => item._id)
			);
		}
	};

	const onListLoadedTrips = (data) => {
		const itemPayrollStatus = data.items?.[0]?.payroll__view?.metadata?.state;
		handleSelectTripsItems("CLEAR");
		if (data?.items?.length && itemPayrollStatus === "open" && isEnabled("CLOSE_SINGLE_PAYROLL_V2")) {
			handleSelectTripsItems(
				"ALL",
				data.items.map((item) => item._id)
			);
		}
	};

	const payrollPDFURI = useMemo(() => {
		if (carrierId) {
			setFileLoading(true);
			const baseUrl = `${global.SERVER_NAME}/billing/v3/payroll/${payrollId}/status/${payrollStatus}/pdf`;
			const token = localStorage.getItem("tokenSmarthop");
			const url = `${baseUrl}?token=${token}`;
			setTimeout(() => {
				setFileLoading?.(false);
			}, 1000);
			return url;
		}
		// eslint-disable-next-line
	}, [carrierId, payrollStatus, payrollRevision, tripsRevision, dataRevision]);

	if (!payrollDetails || !userDetails || error) {
		return (
			<SmarthopDialogViewLoadingStub
				nativeMobile={nativeMobile}
				loading={!payrollDetails || !userDetails}
				error={error}
			/>
		);
	}

	const payPeriod = userDetails?.payroll?.settings?.pay_period;

	const buildClosePayrollMessage = () => {
		const unselectedItems = tabKey === "TRIPS" ? unselectedTrips : unselectedLedger;
		if (unselectedItems.length) {
			return `You have unselected ${
				tabKey === "TRIPS" ? "some trips" : "some ledger items"
			}, only selected items will be closed, do you want to continue?`;
		}
		return `All ${["LEDGER", "TRIPS"].includes(tabKey) ? "selected" : ""} ${
			tabKey === "TRIPS" ? "trips" : "ledger items"
		} will be closed, do you want to continue?`;
	};

	const handleFiltersChanged = (tableFilters) => setFilters(tableFilters);

	return isHistoryItem ? (
		<EnterprisePayrollDetailsView params={dataIds} isHistoryItem={isHistoryItem} />
	) : (
		<SmarthopDialogViewContainer
			nativeMobile={nativeMobile}
			processing={processing}
			forceRefresh={true}
			onTabChange={(tab) => {
				setTabKey(tab);
			}}
			sideComponents={[
				{
					key: "PAYROLL_PREVIEW" + payrollId,
					type: "PREVIEW",
					tabKeys: ["SUMMARY"],
					hideOnMobile: true,
					ratio: "5/12",
					component: (
						<div className={`flex flex-col h-full w-full  bg-grey-100 items-center `}>
							{fileLoading ? (
								<div className={clsx("flex w-full h-full items-center justify-center border-1")}>
									<CircularProgress className="text-grey-400" />
								</div>
							) : payrollStatus === "open" ? (
								<div className={"flex w-full h-full items-center justify-center border-1"}>
									<Typography color="textSecondary">
										Payroll PDF only available for In Review and Approved states
									</Typography>
								</div>
							) : (
								<CardMedia
									component="iframe"
									className={"flex h-full relative w-full bg-grey-200 "}
									image={payrollPDFURI}
									onLoad={() => {
										setFileLoading(false);
									}}
								/>
								// null
							)}
						</div>
					),
				},
			]}
			tabComponents={[
				{
					key: "SUMMARY",
					title: "Summary",
					component: (
						<div className="flex flex-col w-full">
							<EnterprisePayrollSummaryView
								dataIds={{ ...dataIds, payrollStatus, isInvestor, isCarrier }}
								isView={true}
								payrollDetails={payrollDetails}
								userDetails={userDetails}
								processing={processing}
							/>
						</div>
					),
				},
				{
					key: "LEDGER",
					title: "Ledger",
					type: "LIST",
					...(["open", "closed"].includes(payrollStatus) && !readOnly && hasPayrollEditAccess
						? {
								header: (
									<div className="flex flex-row w-full items-center justify-end border-b-1 broger-grey mb-4">
										<div className="flex flex-col pb-6">
											<Button
												className={"mx-12 text-12 py-6"}
												variant="contained"
												color="secondary"
												aria-label="Follow"
												onClick={() =>
													dispatch(
														openLoadedFormDialog({
															formId:
																dataIds?.payeeType === "USER"
																	? isInvestor
																		? "PAYROLL_INVESTOR_ADDITIONS_DEDUCTIONS_FORM"
																		: "PAYROLL_USER_ADDITIONS_DEDUCTIONS_FORM"
																	: "PAYROLL_USER_ADDITIONS_DEDUCTIONS_FORM",
															mode: "CREATE",
															dataIds: { ...dataIds, carrierId, payrollId, userId },
														})
													)
												}
											>
												Add Addition/Deduction
											</Button>
										</div>
									</div>
								),
						  }
						: {}),
					params: {
						config: payeePayrollLedgerList({
							filterByPayroll: true,
							isActionsEnable: isCarrier && payrollStatus !== "approved",
							isView: true,
							isInvestor,
							payeeType: dataIds?.payeeType,
							payrollStatus,
							payPeriod,
							showPeriodFilter: isEnabled("CLOSE_SINGLE_PAYROLL_V2"),
						}),
						dataIds: { ...dataIds, isInvestor, carrierId, userId },
						...(payrollStatus === "open" &&
							isEnabled("CLOSE_SINGLE_PAYROLL_V2") && {
								onListFiltersChanged: handleFiltersChanged,
								multiselect: true,
								selectedItems: selectedLedger,
								onSelectItems: handleSelectLedgerItems,
								onListLoaded: onListLoadedLedger,
							}),
					},
				},
				{
					key: "TRIPS",
					title: "Trips",
					type: "LIST",
					...(payrollStatus === "closed" && !readOnly && hasPayrollEditAccess
						? {
								header: (
									<div className="flex flex-row w-full items-center justify-end border-b-1 broger-grey mb-4">
										<div className="flex flex-col pb-6">
											<Button
												className={"mx-12 text-12 py-6"}
												variant="contained"
												color="secondary"
												aria-label="Follow"
												disabled={!selectedTrips.length}
												onClick={() =>
													setDialogAlert({
														flag: true,
														message:
															"You are going to remove these trips from this payroll, you cannot undo this operation, do you want to continue?",
														optionsText: { accept: "Continue" },
														onAccept: () => removeTripsFromPayroll(carrierId, payrollId, selectedTrips),
													})
												}
											>
												Remove Trips
											</Button>
										</div>
									</div>
								),
						  }
						: {}),
					params: {
						config: payrollTripsList({
							payrollStatus,
							isInvestor,
							payPeriod,
							showPeriodFilter: isEnabled("CLOSE_SINGLE_PAYROLL_V2"),
						}),
						dataIds: { ...dataIds, isInvestor },
						...(payrollStatus === "open" &&
							isEnabled("CLOSE_SINGLE_PAYROLL_V2") && {
								onListFiltersChanged: handleFiltersChanged,
								multiselect: true,
								selectedItems: selectedTrips,
								onSelectItems: handleSelectTripsItems,
								onListLoaded: onListLoadedTrips,
							}),
						...(payrollStatus === "closed" && {
							multiselect: true,
							selectedItems: selectedTrips,
							onSelectItems: handleSelectTripsItems,
							onListLoaded: onListLoadedTrips,
						}),
					},
				},
			]}
			footerActions={[
				{
					key: "ROLL_BACK_PAYROLL",
					title: "Roll Back Payroll",
					style: { align: "RIGHT", notCollapsible: true, icon: "undo", classes: "bg-grey-600 text-white" },
					hideOnDesktop:
						readOnly ||
						!hasPayrollEditAccess ||
						(!isInternal && !isCarrier) ||
						!["closed", "approved"].includes(payrollStatus),
					hideOnMobile:
						readOnly ||
						!hasPayrollEditAccess ||
						(!isInternal && !isCarrier) ||
						!["closed", "approved"].includes(payrollStatus),
					onClick: () => {
						setDialogAlert({
							flag: true,
							message: "You are going to roll back this payroll, do you want to continue?",
							optionsText: { accept: "Continue" },
							onAccept: async () => {
								setProcessing(true);
								rollbackPayroll({ data: [payrollId], payrollStatus })
									.then(
										() => {
											dispatch(incrementDataRevision({ event: "payrollRevision" }));
											setProcessing(false);
											showSnackbar(snackbar, "Payroll rolled back.", "success");
										},
										(err) => {
											setProcessing(false);
											showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the payroll status", "error", {
												duration: 8000,
											});
										}
									)
									.catch((err) => {
										setProcessing(false);
										showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the payroll status", "error", {
											duration: 8000,
										});
									});
							},
						});
					},
				},
				{
					key: "CLOSE_PAYROLL",
					title: "Close Payroll",
					style: { align: "RIGHT", notCollapsible: true, icon: "done", primary: true },
					disabled: isCloseButtonDisabled,
					hideOnDesktop:
						readOnly ||
						!hasPayrollEditAccess ||
						(!isInternal && !isCarrier) ||
						["closed", "approved"].includes(payrollStatus),
					hideOnMobile:
						readOnly ||
						!hasPayrollEditAccess ||
						(!isInternal && !isCarrier) ||
						["closed", "approved"].includes(payrollStatus),
					onClick: () => {
						setDialogAlert({
							flag: true,
							message: buildClosePayrollMessage(),
							optionsText: { accept: "Continue" },
							onAccept: async () => {
								if (isCloseButtonDisabled) return;
								setProcessing(true);
								closePayroll(carrierId, {
									payrollId,
									filters,
									entityUnselectedItemsType: tabKey,
									entityUnselectedItems: tabKey === "TRIPS" ? unselectedTrips : unselectedLedger,
								})
									.then(
										() => {
											dispatch(incrementDataRevision({ event: "payrollRevision" }));
											setProcessing(false);
											showSnackbar(snackbar, "Payroll closed.", "success");
										},
										(err) => {
											setProcessing(false);
											showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the payroll status", "error", {
												duration: 8000,
											});
										}
									)
									.catch((err) => {
										setProcessing(false);
										showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating the payroll status", "error", {
											duration: 8000,
										});
									});
							},
						});
					},
				},
				{
					key: "APPROVE_PAYROLL",
					title: "Approve Payroll",
					style: {
						align: "RIGHT",
						notCollapsible: true,
						icon: "done_all",
						primary: true,
					},
					hideOnDesktop:
						readOnly ||
						!hasPayrollAdminAccess ||
						(!isInternal && !isCarrier) ||
						["approved", "open"].includes(payrollStatus),
					hideOnMobile:
						readOnly ||
						!hasPayrollAdminAccess ||
						(!isInternal && !isCarrier) ||
						["approved", "open"].includes(payrollStatus),
					onClick: () => {
						setDialogAlert({
							flag: true,
							message: "You are going to approve this payroll, do you want to continue",
							optionsText: { accept: "Continue" },
							checkboxLabel: "Email approved report to payee (Optional)",
							checkboxValue: sendReportByEmail,
							setCheckboxValue: (value) => {
								setSendReportByEmail(value);
							},
							onAccept: async () => {
								setProcessing(true);
								approvePayroll({ data: [payrollId], payrollStatus, sendReportByEmail })
									.then(
										() => {
											dispatch(incrementDataRevision({ event: "payrollRevision" }));
											setProcessing(false);
											showSnackbar(snackbar, "Payroll approved.", "success");
										},
										(err) => {
											setProcessing(false);
											showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating payroll status", "error", {
												duration: 8000,
											});
										}
									)
									.catch((err) => {
										setProcessing(false);
										showSnackbar(snackbar, err.errors?.[0]?.message ?? "Error updating payroll status", "error", {
											duration: 8000,
										});
									});
							},
						});
					},
				},
				{
					key: "EXPORT_PDF",
					title: "Export PDF",
					style: { align: "LEFT", icon: "download" },
					hideOnDesktop: readOnly || (!isInternal && !isCarrier) || payrollStatus === "open",
					hideOnMobile: readOnly || (!isInternal && !isCarrier) || payrollStatus === "open",
					onClick: () => {
						downloadDriverPayroll({ selected: [payrollId], status: payrollStatus, carrierId, snackbar });
					},
				},
			]}
		>
			<SmarthopConfirmDialog
				open={!!openDialogAlert.flag}
				title={openDialogAlert?.message}
				checkboxLabel={openDialogAlert?.checkboxLabel}
				checkboxValue={openDialogAlert?.checkboxValue}
				setCheckboxValue={(value) => {
					typeof openDialogAlert?.setCheckboxValue === "function" && openDialogAlert?.setCheckboxValue(value);
				}}
				onAccept={() => {
					openDialogAlert?.onAccept();
					setDialogAlert({ flag: false, message: null, type: null, optionsText: {} });
				}}
				handleClose={() => setDialogAlert({ flag: false, message: null, type: null, optionsText: {} })}
				acceptMsg={openDialogAlert?.optionsText?.accept}
			/>
		</SmarthopDialogViewContainer>
	);
});

export default EnterprisePayrollView;
