import moment from "moment";
import { Icon, Typography } from "@material-ui/core";

import CellFirstRow from "../views/CellFirstRow";
import CellSecondRow from "../views/CellSecondRow";
import CellContainerRow from "../views/CellContainerRow";

// Utils
import { capitalizaFirstLetter, capitalizaEveryWordFirstLetter } from "helpers/Formatter";
import { getRoleLabel } from "app/services/LoginService";
import { formatDate } from "app/main/utils/dateUtils";
import AddonsCellView from "../views/AddonsCellView";
import { centsToDollars } from "app/main/utils/financeUtils";

//Gatekeeper
import { isEnabled } from "app/services/featureStorageService";

const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const createAlertMessage = (item, owner) => {
	let icon, color;
	const isGeneric = item?.integrations?.[owner]?.isGeneric;
	if (!item?.integrations?.[owner]) {
		icon = "power_off";
	} else if (isGeneric) {
		icon = "power";
	} else {
		icon = "power";
	}

	if (!item?.integrations?.[owner] || item?.integrations?.[owner]?.status === "EXPIRED") {
		color = "text-red";
	} else if (isGeneric) {
		color = "text-orange";
	} else {
		color = "text-green";
	}

	let style = { icon, color };
	return (
		<div className="flex flex-row items-center py-2">
			<Icon className={`${style.color} m-0 p-0`}>{style.icon}</Icon>
			{isGeneric && <Typography>Trial</Typography>}
		</div>
	);
};

const getCarriers = ({ accountSubset = false, isView = false } = {}) => {
	const role = getRoleLabel();
	const isAdmin = role === "administrator";
	return {
		urlGET: accountSubset ? "api/profile/accounts/:accountId/carriers" : "api/profile/carriers",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "INTERNAL_CARRIERS",
		cache: {
			key: accountSubset ? "account_carriers_:accountId" : null,
		},
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: ["carrier_company_name", "user_email", "activation_date", "createdAt"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: "createdAt",
					dir: "desc",
				},
			},
			...(isAdmin && !accountSubset
				? {
						export: {
							fileName: "carriers_export.csv",
						},
				  }
				: {}),
			defaults: accountSubset
				? {}
				: {
						// Initial value of fields
						initial: {
							filters: {
								accountStatus: "ACTIVE",
								platformPlan: "__NOT_EMPTY__",
								onboardingStatus: "__NOT_SELECTED__",
								...(PROD_DB ? { carriers_type: "REAL_CARRIERS" } : { carriers_type: "__NOT_SELECTED__" }),
							},
						},
						// Additional quick filters
						items: [
							{
								label: "Active",
								filters: {
									onboardingStatus: "__NOT_SELECTED__",
									accountStatus: "ACTIVE",
									platformPlan: "__NOT_EMPTY__",
								},
							},
							{
								label: "Getting Started",
								filters: {
									onboardingStatus: "IN_REVIEW",
									accountStatus: "__NOT_SELECTED__",
									platformPlan: "__NOT_SELECTED__",
								},
							},
							{
								label: "Onboarding",
								filters: {
									onboardingStatus: "[INCOMPLETE,CHANGES_REQUESTED]",
									accountStatus: "__NOT_SELECTED__",
									platformPlan: "__NOT_SELECTED__",
								},
							},
							{
								label: "No Access",
								filters: {
									onboardingStatus: "__NOT_SELECTED__",
									accountStatus: "ACTIVE",
									platformPlan: "__EMPTY__",
								},
							},
						],
				  },
			filters: {
				search: true,
				items: accountSubset
					? []
					: [
							{
								key: "onboardingStatus",
								type: "select",
								label: "Onboarding Status",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "INCOMPLETE",
										label: "Incomplete",
									},
									{
										value: "IN_REVIEW",
										label: "Getting Started",
									},
									{
										value: "CHANGES_REQUESTED",
										label: "Changes Requested",
									},
									{
										value: "ACCEPTED",
										label: "Accepted",
									},
									{
										value: "REJECTED",
										label: "Rejected",
									},
									{
										value: "[INCOMPLETE,CHANGES_REQUESTED]",
										label: "In Progress",
									},
								],
							},
							{
								key: "accountStatus",
								type: "select",
								label: "Account Status",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "ACTIVE",
										label: "Active",
									},
									{
										value: "DEACTIVATED",
										label: "Suspended",
									},
								],
							},
							{
								key: "platformPlan",
								type: "select",
								label: "Platform Plan",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "__NOT_EMPTY__",
										label: "Has Platform Plan",
									},
									{
										value: "__EMPTY__",
										label: "No Platform Plan",
									},
								],
							},
							{
								key: "platformPlanId",
								type: "autocomplete",
								label: `Platform Plan`,
								autocomplete: {
									provider: "smarthop",
									url: "billing/platform-plans",
									params: {
										sortOrder: "asc",
										sortBy: "nickname",
									},
									preloadDefault: true,
								},
							},
							{
								key: "platformPlanTier",
								type: "select",
								label: `Platform Plan Tier`,
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "TIER_ANY_PAID",
										label: "Any Paid Tier",
									},
									{
										value: "TIER_LIMITED",
										label: "Limited",
									},
									{
										value: "TIER_STARTER",
										label: "Starter",
									},
									{
										value: "TIER_BASIC",
										label: "Basic",
									},
									{
										value: "TIER_BASIC_PLUS",
										label: "Basic Plus Tier",
									},
									{
										value: "TIER_PROFESSIONAL",
										label: "Professional",
									},
									{
										value: "TIER_GROWTH",
										label: "Growth Tier",
									},
									{
										value: "TIER_ENTERPRISE",
										label: "Enterprise",
									},
								],
							},
							{
								key: "carrierAccountType",
								type: "select",
								label: `Carrier Account Type`,
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "MAIN_ACCOUNT",
										label: "Main Account",
									},
									{
										value: "SUB_ACCOUNT",
										label: "Sub Account",
									},
								],
							},
							{
								key: "typeOfCompany",
								type: "select",
								label: "Type of Company",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "FLEET",
										label: "Fleet",
									},
									{
										value: "OWNER_OPERATOR",
										label: "Owner Operator",
									},
									{
										value: "DISPATCH_SERVICE",
										label: "Dispatching Service",
									},
								],
							},
							{
								key: "eldCarrierStatus",
								type: "select",
								label: "ELD Configured",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "INCOMPLETE",
										label: "ELD errors",
									},
									{
										value: "COMPLETED",
										label: "ELD Completed",
									},
								],
							},
							{
								key: "onboardingInformationStatus",
								type: "select",
								label: "MC Validated",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "__VALIDATED__",
										label: "Validated",
									},
									{
										value: "__NOT_VALIDATED__",
										label: "Not Validated",
									},
								],
							},
							{
								key: "totalSpend",
								type: "text",
								label: `Total Spend`,
								description: "You can preceed a value with < (less than) or > (greater than). Ex. >1000",
							},
							{
								key: "unsubscriptionDate",
								type: "select",
								label: `Scheduled for deletion`,
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "__NOT_EMPTY__",
										label: "Has un-subscription date",
									},
									{
										value: "__EMPTY__",
										label: "No un-subscription date",
									},
								],
							},
							{
								key: "defaultPayment",
								type: "select",
								label: "Payment Method",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "__NOT_EMPTY__",
										label: "Has Default Payment Method",
									},
									{
										value: "__EMPTY__",
										label: "No Default Payment Method",
									},
								],
							},
							{
								key: "payrollStatus",
								type: "select",
								label: "Payroll Module",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "ENABLED",
										label: "Enabled",
									},
									{
										value: "DISABLED",
										label: "Disabled",
									},
								],
							},
							{
								key: "invoicesStatus",
								type: "select",
								label: "Invoice Module",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "ENABLED",
										label: "Enabled",
									},
									{
										value: "DISABLED",
										label: "Disabled",
									},
								],
							},
							{
								key: "smartPayStatus",
								type: "select",
								label: "SmartPay Module",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "ENABLED",
										label: "Enabled",
									},
									{
										value: "DISABLED",
										label: "Disabled",
									},
								],
							},
							{
								key: "smartFuelStatus",
								type: "select",
								label: "SmartFuel Module",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "ENABLED",
										label: "Enabled",
									},
									{
										value: "DISABLED",
										label: "Disabled",
									},
								],
							},
							{
								key: "carriers_type",
								type: "select",
								label: "Internal Carrier Type",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "REAL_CARRIERS",
										label: "Real Carriers",
									},
									{
										value: "TEST_CARRIERS",
										label: "Test Carriers",
									},
								],
							},
							{
								key: "utm_campaign",
								type: "select",
								label: "Marketing Campaign",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "__NOT_EMPTY__",
										label: "Not Empty",
									},
								],
							},
							...(isAdmin && isEnabled("ENABLE_ARCHIVE_CARRIER")
								? [
										{
											key: "archive_carrier",
											type: "select",
											label: "Carrier Archive",
											defaultValue: "__NOT_SELECTED__",
											options: [
												{
													value: "__NOT_SELECTED__",
													label: "Not Selected",
												},
												{
													value: "_CAN_ARCHIVE_",
													label: "Can be removed",
												},
											],
										},
								  ]
								: []),
							{
								key: "date_range",
								type: "dateRange",
								label: "Activity Period",
							},
							{
								key: "requestedBrokerConnection",
								type: "select",
								label: "Requested Broker Connections",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										value: "_REQUESTED_",
										label: "Requested",
									},
								],
							},
					  ],
			},
			groups: {
				items: accountSubset
					? []
					: [
							{
								key: "internal",
								type: "checkbox",
								label: "Show Internal Fields",
							},
							{
								key: "payments",
								type: "checkbox",
								label: "Show Plans and Payments",
							},
							{
								key: "driver_truks",
								type: "checkbox",
								label: "Show Count of Trucks and Drivers",
							},
							{
								key: "onboarded_integrations",
								type: "checkbox",
								label: "Show Broker and ELD integrations",
							},
							{
								key: "trips",
								type: "checkbox",
								label: "Show Carrier Trips",
							},
							{
								key: "smartfuel",
								type: "checkbox",
								label: "Show Fuel Module Status",
							},
							{
								key: "activity",
								type: "checkbox",
								label: "Show Activity Fields",
							},
							{
								key: "utm",
								type: "checkbox",
								label: "Show UTM Data",
							},
					  ],
			},
			items: [
				{ key: "_id", type: "id", label: "Carrier ID", group: "internal" },
				{
					key: "carrier_company_name",
					builder: (item) => item.carrier__view,
					type: "carrier__view",
					label: "Carrier",
					viewConfig: {
						showTestCarrier: true,
						showMcNumber: true,
						showCarrierAccountType: true,
					},
					export: {
						extra: [
							{
								label: "MC Number",
								value: "carrier_mc_number",
							},
						],
					},
				},
				{
					key: "ownerUser__view",
					type: "user__view",
					label: "Owner",
					viewConfig: {
						showEmail: true,
					},
					export: {
						extra: [
							{
								label: "Email",
								value: (item) => item["user_email"],
							},
						],
					},
				},
				{
					key: "typeCompany",
					type: "text",
					label: "Type Of Company",
					builder: (item) => {
						const type = item.sfCustomerCategorization ?? item.typeCompany;
						if (type) return capitalizaEveryWordFirstLetter(type);
						return " - ";
					},
				},
				{
					key: "onboardingStatus",
					label: "Onboarding",
					type: "text",
					builder: (item) => {
						if (item.onboardingStatus) {
							return capitalizaFirstLetter(item.onboardingStatus?.toLowerCase().replace("_", " "));
						}
						return "-";
					},
				},
				{
					key: "accountStatus",
					label: "Account",
					type: "text",
					builder: (item) => {
						if (item.accountStatus) {
							if (item.accountStatus === "DEACTIVATED") {
								return "Suspended";
							} else {
								return capitalizaFirstLetter(item.accountStatus?.toLowerCase().replace("_", " "));
							}
						}
						return "-";
					},
				},
				{ key: "createdAt", type: "date", label: "Registration Date" },
				{
					key: "activation_date",
					type: "date",
					label: "Onboarding Date",
				},
				{
					key: "validated_date",
					type: "date",
					label: "Validated Date",
				},
				{
					group: "payments",
					key: "deactivation_date",
					type: "date",
					label: "Deactivation Date",
				},
				{
					key: "tier",
					label: "Tier",
					type: "text",
					builder: (item) => {
						const tier = item?.customer?.payment_plans?.platform?.tier;
						if (tier === "TIER_BASIC") {
							return "Basic";
						} else if (tier === "TIER_BASIC_PLUS") {
							return "Basic+";
						} else if (tier === "TIER_GROWTH") {
							return "Growth";
						} else if (tier === "TIER_PROFESSIONAL") {
							return "Professional";
						} else if (tier === "TIER_ENTERPRISE") {
							return "Enterprise";
						} else if (tier === "TIER_LIMITED") {
							return "Limited";
						} else if (tier === "TIER_STARTER") {
							return "Starter";
						} else {
							return !tier ? "-" : tier;
						}
					},
					export: {
						builder: (item) => item?.customer?.payment_plans?.platform?.tier ?? "-",
					},
				},
				{
					key: "platform_plan",
					type: "group",
					label: "Plan Subscription",
					group: accountSubset ? "hidden" : null,
					items: [
						{
							key: "platform_plan_nickname",
							label: "Local Plan Name",
							type: "text",
							builder: (item) => item?.platformPlan?.nickname,
						},
						{
							key: "platform_plan_meta",
							label: "Local Plan Name",
							type: "text",
							builder: (item) => (item?.platformPlan?.public ? "Public" : "Internal"),
						},
					],
					export: {
						builder: (item) =>
							item?.platformPlan?.nickname
								? item?.platformPlan?.nickname + " (" + (item?.platformPlan?.public ? "Public" : "Internal") + ")"
								: "",
					},
				},
				{
					key: "subscription",
					label: "Stripe Subscription",
					type: "component",
					classes: { cell: "min-w-240" },
					GET: (item) => `/billing/carriers/${item._id}/platform-plans`,
					builder: (item, dispatch, snackbar, data) => {
						if (!data?.active) {
							return "-";
						}

						const name = data?.nickname ?? data?.metadata?.price?.nickname;
						const price = data?.subscription?.items?.data?.[0]?.price;
						const value = data?.subscription?.items?.data?.[0]?.price?.unit_amount ?? data?.value;
						const unsubscribed = !!data?.subscription?.cancel_at;
						const unsubscriptionScheduled = !!item.customer.unsubscription_date;
						const unsubscriptionDate = item.customer.unsubscription_date
							? formatDate(
									item.customer.unsubscription_date,
									item.customer.unsubscription_date,
									false,
									false,
									true,
									false
							  )
							: "";
						const cancelAt = unsubscribed ? moment.unix(data?.subscription?.cancel_at).local().format("MMM Do") : null;

						const valueNext = data?.upcomingInvoice?.amount_due ?? 0;
						const nextPaymentAt =
							valueNext > 0 ? moment.unix(data?.subscription?.current_period_end).local().format("MMM Do") : "";
						return (
							<CellContainerRow
								variant={"skinny"}
								first={
									<CellFirstRow
										variant={"skinny"}
										textLimit={40}
										label={
											name +
											(value > 0
												? " ($" + price?.unit_amount / 100 + " " + price?.recurring?.interval + ")"
												: price?.nickname
												? " (" + price?.nickname + ")"
												: "")
										}
									/>
								}
								second={
									<div className="flex flex-wrap">
										<CellSecondRow
											textLimit={25}
											icon={unsubscriptionScheduled || unsubscribed ? "report_problem" : "done"}
											label={
												unsubscriptionScheduled
													? "Deletes " + unsubscriptionDate
													: unsubscribed
													? "Cancels " + cancelAt
													: "Active"
											}
											color={
												unsubscriptionScheduled ? "text-red-800" : unsubscribed ? "text-orange-800" : "text-green-800"
											}
											variant={"skinny"}
										/>
										{valueNext > 0 && !unsubscriptionScheduled && (
											<CellSecondRow
												textLimit={30}
												label={"Next Payment $" + valueNext / 100 + (nextPaymentAt ? " (" + nextPaymentAt + ")" : "")}
												color={"text-blue-800"}
												variant={"skinny"}
											/>
										)}
									</div>
								}
							/>
						);
					},
					group: "payments",
				},
				{
					key: "addons",
					label: "Addons",
					type: "component",
					GET: (item) => `/billing/carriers/${item._id}/platform-addons-plans-totals`,
					builder: (item, dispatch, snackbar, data) => {
						return <AddonsCellView item={data} />;
					},
					group: "payments",
				},
				{
					key: "total_spend",
					label: "Total Spend",
					type: "currency",
					builder: (item) => {
						const amount = item.customer?.total_spend;
						return centsToDollars(amount);
					},
					group: "payments",
				},
				{
					key: "payment",
					label: "Default Payment",
					type: "component",
					classes: { cell: "min-w-240" },
					GET: (item) => `/billing/carriers/${item._id}/stripe-default-source`,
					builder: (item, dispatch, snackbar, data) => {
						if (!data?.payment_method__view?.label) {
							return "-";
						}
						return (
							<CellContainerRow
								variant={"skinny"}
								first={<CellFirstRow textLimit={40} variant={"skinny"} label={data?.payment_method__view?.label} />}
								second={
									<div className="flex flex-wrap">
										<CellSecondRow
											label={data?.payment_method__view?.description}
											variant={"skinny"}
											color={"text-grey-600"}
										/>
									</div>
								}
							/>
						);
					},
					group: "payments",
				},
				{
					key: "onboardingFuelModule",
					group: "smartfuel",
					type: "text",
					label: "Fuel Module",
					builder: (item) => item.onboardingFuelModule ?? "DISABLED",
				},
				{
					key: "axleConnection",
					label: "ELD Connection",
					type: "text",
					group: "onboarded_integrations",
					builder: (item) => {
						if (item.axleConnection) {
							return (
								<CellContainerRow
									variant={"skinny"}
									first={
										<CellFirstRow
											textLimit={40}
											variant={"skinny"}
											label={item.axleConnection.fleet_id ?? item.axleConnection.status_code}
										/>
									}
									second={
										<div className="flex flex-wrap">
											<CellSecondRow
												label={item.axleConnection.tsp_source}
												variant={"skinny"}
												color={"text-grey-600"}
											/>
										</div>
									}
								/>
							);
						}
						return "-";
					},
				},
				{
					builder: (item) => item.drivers?.length + " driver(s)",
					group: "driver_truks",
					type: "text",
					label: "Drivers",
				},
				{
					builder: (item) => item.trucks?.length + " truck(s)",
					group: "driver_truks",
					type: "text",
					label: "Trucks",
				},
				{
					key: "active_trips__view",
					builder: (item) => item.active_trips__view,
					type: "trips__view",
					label: "Active Trips",
					viewConfig: {
						showStatus: true,
					},
					group: "trips",
				},
				{
					key: "delivered_trips__view",
					builder: (item) => item.delivered_trips__view,
					type: "trips__view",
					label: "Delivered Trips (60 days)",
					viewConfig: {
						showStatus: true,
						showDeliveryDs: true,
					},
					group: "trips",
				},
				{
					key: "integrations_dat",
					type: "component",
					label: "DAT V2",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "dat");
					},
				},
				{
					key: "integrations_dat",
					type: "component",
					label: "DAT V3",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "dat-rest");
					},
				},
				{
					key: "integrations_echo",
					type: "component",
					label: "Echo",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "echo");
					},
				},
				{
					key: "integrations_coyote",
					type: "component",
					label: "Coyote",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "coyote");
					},
				},
				{
					key: "integrations_chrobinson",
					type: "component",
					label: "CH R.",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "chrobinson");
					},
				},
				{
					key: "integrations_loadsmart",
					type: "component",
					label: "LoadSm.",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "loadsmart");
					},
				},
				{
					key: "integrations_newtrul",
					type: "component",
					label: "Newtrul",
					group: "onboarded_integrations",
					builder: (item) => {
						return createAlertMessage(item, "newtrul");
					},
				},
				{
					key: "counters.users",
					label: "Users",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.trucks",
					label: "Trucks",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.drivers",
					label: "Drivers",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.costs",
					label: "Costs",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.goals",
					label: "Goals",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.searches",
					label: "Searches",
					type: "number",
					group: "activity",
				},
				{
					key: "counters.trips",
					label: "Trips",
					type: "number",
					group: "activity",
				},

				// Marketing
				{ key: "utm_campaign", type: "text", label: "UTM Campaign", maxLengthStart: 30, group: "utm" },
				{ key: "utm_source", type: "text", label: "UTM Source", group: "utm" },
				{
					key: "utm_params",
					builder: (item) => (item.utm_params ? JSON.stringify(item.utm_params) : null),
					type: "text",
					label: "Registration Params",
					maxLengthStart: 30,
					group: "utm",
				},
			],
		},
	};
};

export default getCarriers;
