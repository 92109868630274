import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

// Form
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { trailerFormConfig, TRUCK_DEFAULTS } from "@smarthop/form/configs/truckForm";

// Service
import { signInWithToken } from "app/services/LoginService";
import { getTrailer, createTrailer, updateTrailer } from "app/services/truckServices";

// Utils
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { setUserData } from "app/store/auth/userSlice";

function TrailerView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const native = window?.ReactNativeWebView ?? false;

	const dataIds = props?.dataIds;
	const onDone = props?.onDone;

	const mode = dataIds?.mode;
	const trailerId = dataIds?.id;
	const historyData = dataIds?.historyData;
	const fromCalendar = dataIds?.fromCalendar;
	const carrierOption = hasSubaccounts && dataIds?.carrierOption;

	const isCreate = mode === "CREATE";
	const carrierId = carrierOption ? null : dataIds?.carrierId;

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		props?.setTitle?.(historyData ? "View Trailer" : isCreate ? "Add New Trailer" : "Edit Trailer");
		props?.setSize?.("max-w-xl");
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (historyData) {
			setData(historyData);
			return;
		}

		if (!carrierId || !trailerId || isCreate) {
			return;
		}

		setLoading(true);

		getTrailer(carrierId, trailerId).then(
			(response) => {
				setLoading(false);
				setData({
					...response,
					equipment:
						response.equipment === "SPECIAL" ? response.equipment + "_" + response.subtype : response.equipment,
				});
			},
			(err) => {
				setLoading(false);
				setError(err.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);

		// eslint-disable-next-line
	}, [carrierId, trailerId, isCreate]);

	const form = useMemo(() => trailerFormConfig({ mode: isCreate ? "VIEW" : "EDIT" }), [isCreate]);

	const handledSave = (action = null) => {
		setProcessing(true);

		if (action) data.action = action;

		//Logic for special equipments
		const equipment = data?.equipment?.includes("SPECIAL") ? "SPECIAL" : data?.equipment;
		const subtype = equipment === "SPECIAL" ? data?.equipment.replace("SPECIAL_", "") : undefined;

		const dataToSend = { ...data, equipment, subtype };
		if (dataIds?.truckId && isCreate) {
			dataToSend.truckId = dataIds.truckId;
		}
		const promise = isCreate ? createTrailer(carrierId, dataToSend) : updateTrailer(carrierId, trailerId, dataToSend);

		promise.then(
			async () => {
				if (isCreate) {
					const userData = await signInWithToken();
					if (userData) dispatch(setUserData(userData, { skip_settings_set: true }));
				}

				setProcessing(false);
				showSnackbar(snackbar, "Successfully Saved Information", "success");

				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "dataRevision" }));
				if (fromCalendar) dispatch(incrementDataRevision({ event: "dashboardRevision" }));
				if (native) window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "REFRESH_TRAILER_LIST" }));
				onDone?.();
			},
			(err) => {
				setProcessing(false);
				showSnackbar(snackbar, err.errors?.[0]?.message ?? "Opps, operation failed...", "error");
			}
		);
	};

	if (loading) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography color="primary" className="text-13 font-light mx-20 py-6 md:pb-48">
					Loading...
				</Typography>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex w-full h-512 flex-col items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
			</div>
		);
	}

	return (
		<div className="mt-15">
			<SmarthopFormView
				key={"trailer-view"}
				mode={historyData ? "COMPARE" : mode}
				content={form}
				data={data}
				noInitValidation={true}
				dataIds={{ ...dataIds, carrierId }}
				trackChangedFields={["ALL"]}
				onChangeCommitted={(model, key) => {
					if (key === "equipment") {
						const subtype = model?.equipment.split("_")?.[1] ?? model?.equipment;
						if (data?.subtype !== subtype)
							setData({
								...data,
								equipment: model?.equipment,
								subtype,
								...TRUCK_DEFAULTS[subtype],
							});
						return;
					}
					setData({ ...data, ...model });
				}}
				onSubmit={() => {
					handledSave();
				}}
				processing={processing}
			/>
		</div>
	);
}

export default TrailerView;
