import moment from "moment";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FuseUtils from "@fuse/utils";
import ChatFile from "./ChatFile";
import { addMessage } from "app/store/messenger/messageSlice";
import { getUserId, getRoleLabel } from "app/services/LoginService";
import * as fileService from "app/services/fileServices";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmartMessageButton from "./SmartMessageButton";
import MobileDetect from "mobile-detect";
import fetch from "cross-fetch";

import { isEnabled } from "app/services/featureStorageService";

const _MAX_CHARACTERS = 600;
const mobileDetect = new MobileDetect(window.navigator.userAgent);

function MessageSendPanel(props) {
	const dispatch = useDispatch();
	const userId = getUserId();
	const roleLabel = getRoleLabel();
	const onMessageSent = props.onMessageSent;

	const nativeMobile = props.nativeMobile;
	const onEvent = props.onEvent;
	const scanFileNameId = "sendPanelScanner";

	const [messageText, setMessageText] = useState("");
	const [fileList, setFileList] = useState([]);
	const [localId, setLocalId] = useState("");

	const socket = useSelector(({ socket }) => socket.connection.socket);
	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const activeChat = useSelector(({ messenger }) => messenger.messages.activeChat);
	const user = useSelector(({ auth }) => auth.user);
	const chatTruckView = props.chatTruckView;
	const snackbar = useSnackbar();

	const isNewUIEnabledMessages = isEnabled("CHAT_NEW_UI_MESSAGES");

	const enableSmartMsgBtn =
		(roleLabel === "administrator" || roleLabel === "dispatcher") && activeChat.type === "TRUCK_EXTERNAL";

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);

		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};

		// eslint-disable-next-line
	}, [fileList]);

	const dataURLtoFile = async (dataurl, filename, mime) => {
		const response = await fetch(dataurl);
		const blob = await response.blob();
		return new File([blob], filename, { type: mime });
	};

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "ScannedDocument":
					return OnScannedDocument(dataParsed.data);
				default:
					break;
			}
		} catch (e) {}
	};

	const OnScannedDocument = async (msg) => {
		if (msg.fileName !== scanFileNameId) return;

		let content = msg?.imgData;
		if (msg?.imageList) {
			content = await fileService.convert(msg.imageList);
		}

		const genFilename = `${Date.now()}.${msg?.contentType.split("/")[1]}`;
		const file = await dataURLtoFile(content, genFilename, msg?.contentType);
		handleFilesUpload([file]);
	};

	const scanBtn = useMemo(() => {
		const reactNative = window?.ReactNativeWebView ?? false;
		if (!reactNative) return;

		return (
			<IconButton
				onClick={() =>
					reactNative.postMessage(JSON.stringify({ type: "DOCUMENT_SCAN", data: { fileName: scanFileNameId } }))
				}
				aria-label="upload picture"
				component="span"
				disabled={!isAuthenticated}
				className="-mt-1 p-6"
			>
				<Icon className="text-24" color="action">
					scanner
				</Icon>
			</IconButton>
		);

		// eslint-disable-next-line
	}, []);

	const isLoading = useMemo(() => {
		const stillLoading = fileList.filter((f) => f.isLoading);
		return stillLoading.length !== 0;
	}, [fileList]);

	const onInputChange = (event) => {
		let input = event.target.value;
		if (input.length > _MAX_CHARACTERS) {
			input = input.substring(0, _MAX_CHARACTERS);
			showSnackbar(snackbar, `Max ${_MAX_CHARACTERS} Characters`, "error", 5000);
		}

		setMessageText(input);
		setLocalId(FuseUtils.generateGUID());
	};

	const onKeyDown = (event) => {
		if (event.ctrlKey && event.key === "Enter") {
			event.preventDefault();
			setMessageText((val) => val + "\n");
		} else if (event.key === "Enter") {
			onMessageSubmit(event);
		}
	};

	const onMessageSubmit = (event) => {
		event.preventDefault();
		if (!activeChat || isLoading || !isAuthenticated || (!messageText && fileList.length === 0)) {
			return;
		}

		let chatId = activeChat._id;
		let type = "TEXT";
		let content = { text: messageText, files: fileList };
		let sentUnix = moment().unix();

		let metadata = {
			status: { incoming: false, sending: true },
			user: { first_name: user?.first_name, last_name: user?.last_name, roleLabel: roleLabel },
		};
		let messageView = { chat: chatId, user: userId, sentUnix, localId, type, content, metadata };

		//Don't add if it is a system message
		if (!(type === "TEXT" && content.text?.startsWith("/"))) dispatch(addMessage(messageView));

		let data = { chatId, localId, type, content, options: { queueTruckInformation: chatTruckView } };
		socket.emit("message", { type: "MESSAGE_SENT", data });

		if (onMessageSent) onMessageSent(messageText);
		if (onEvent) onEvent("MESSAGE_SENT", { type: "TEXT", text: messageText, files: fileList });
		setMessageText("");
		setFileList([]);
		setLocalId("");
	};

	const onFileUpload = (e) => {
		handleFilesUpload(e.target.files);
		e.target.value = "";
	};

	const handleFilesUpload = (filesToUpload) => {
		if (filesToUpload.length + fileList.length > 5) {
			return showSnackbar(snackbar, `Max 5 Files`, "error", 5000);
		}

		// make sure this gets generated in case the message just has files with no text so onInputChange doesnt get triggered
		setLocalId(FuseUtils.generateGUID());
		const files = [...fileList];
		for (let file of filesToUpload) {
			const newFile = { name: file.name, id: null, type: file.type, isLoading: true };
			files.push(newFile);
			uploadOne(file);
		}
		setFileList(files);
	};

	const uploadOne = async (newFile) => {
		let fileRecord;
		try {
			fileRecord = await fileService.upload({
				userId,
				chatId: activeChat._id,
				file: newFile,
			});
		} catch (e) {
			showSnackbar(snackbar, `There was an error uploading file`, "error", 5000);
			return removeFile(newFile.name);
		}

		// takes a few seconds for google bucket to make the image available
		setTimeout(
			() =>
				setFileList((currentList) => {
					return currentList.map((f) => {
						if (f.name === newFile.name) {
							f.id = fileRecord._id;
							f.isLoading = false;
						}
						return f;
					});
				}),
			3000
		);
	};

	const onDelete = (e, file) => {
		e.stopPropagation();
		fileService.remove(file.id);
		removeFile(file.name);
	};

	const removeFile = (filename) => {
		setFileList((files) => files.filter((f) => f.name !== filename));
	};

	return (
		<form
			onSubmit={onMessageSubmit}
			className={"absolute bottom-0 right-0 left-0 py-16 px-20" + (nativeMobile ? " mb-10 " : " bg-white ")}
		>
			<Paper
				className={
					"relative rounded-24 shadow " +
					(isAuthenticated ? "" : " opacity-50 ") +
					(isNewUIEnabledMessages ? "border border-solid" : "")
				}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{
						<div style={{ display: "inline-block" }}>
							<label htmlFor="icon-button-file">
								<input onChange={onFileUpload} id="icon-button-file" type="file" className="hidden" multiple />
								<IconButton
									className="ml-2 -mt-1 p-6"
									aria-label="upload picture"
									component="span"
									disabled={!isAuthenticated}
								>
									<Icon className="text-24" color="action">
										image
									</Icon>
								</IconButton>
							</label>
							{scanBtn}
						</div>
					}
					{enableSmartMsgBtn && <SmartMessageButton disabled={!isAuthenticated || isLoading} />}
					<TextField
						multiline={true}
						id="message-input"
						className="flex-1"
						value={messageText}
						onChange={onInputChange}
						onKeyDown={onKeyDown}
						autoFocus={false}
						InputProps={{
							disableUnderline: true,
							classes: {
								root: "flex flex-grow flex-shrink-0 my-8 mx-8 leading-snug",
								input: "",
							},
							placeholder: "Type your message",
						}}
						InputLabelProps={{
							shrink: false,
							className: "form-label",
							autoComplete: "off",
						}}
					/>
					<div style={{ display: "inline-block" }}>
						<IconButton className="" type="submit" disabled={!isAuthenticated || isLoading}>
							{isLoading ? (
								<CircularProgress size={24} color="secondary" />
							) : (
								<Icon className="text-24" color="action">
									send
								</Icon>
							)}
						</IconButton>
					</div>
				</Box>
				{fileList.length > 0 && (
					<Box sx={{ display: "flex", flex: 1, flexWrap: "wrap" }} className="px-7 pb-7 pt-4">
						{fileList.map((f, index) => (
							<ChatFile key={f.name} name={f.name} fileId={f.id} type={f.type} onDelete={(e) => onDelete(e, f)} />
						))}
					</Box>
				)}
			</Paper>
		</form>
	);
}

export default MessageSendPanel;
