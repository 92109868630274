import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

/**
 * Request an invoice for a given tripId
 * @param {*} carrierId
 * @param {*} tripId
 * @returns
 */
export const getInvoiceForTrip = (carrierId, tripId) => {
	return createRequest()
		.get(`api/trip-invoicing/carriers/${carrierId}/trips/${tripId}`)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Requests the update of an invoice status
 * @param {string} carrierId
 * @param {string} invoiceId
 * @param {string} newStatus
 * @param {Object} data
 * @param {Object} params
 * @returns
 */
export const updateInvoiceStatus = (carrierId, invoiceId, newStatus, data, params) => {
	return createRequest()
		.put(`api/trip-invoicing/carriers/${carrierId}/invoices/${invoiceId}/${newStatus}`, data, {
			headers: createHeaders(),
			params,
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Requests the update of an invoice
 * @param {*} carrierId
 * @param {*} invoiceId
 * @param {*} data to update invoice
 * @returns
 */
export const updateInvoice = (carrierId, tripId, data, isAdmin) => {
	return createRequest()
		.put(
			isAdmin ? `api/trip-invoicing/trips/${tripId}` : `api/trip-invoicing/carriers/${carrierId}/trips/${tripId}`,
			{ data },
			{
				headers: createHeaders(),
			}
		)
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Requests the update of an invoice combine documents
 * @param {*} carrierId
 * @param {*} tripId
 * @param {*} combinedDocs to change to
 * @returns
 */
export const updateInvoiceCombineDocs = (carrierId, tripId, data) => {
	return createRequest()
		.put(`api/trip-invoicing/carriers/${carrierId}/trips/${tripId}/combine-pdf`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Requests to update an invoice to include factoring information
 * @param {*} carrierId
 * @param {*} tripId
 * @param {*} combinedDocs to change to
 * @returns
 */
export const updateInvoicePDFSettings = (carrierId, tripId, data) => {
	return createRequest()
		.put(`api/trip-invoicing/carriers/${carrierId}/trips/${tripId}/pdf-settings`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Requests send trip invoice to recipients
 * @param {*} carrierId
 * @param {*} invoiceId
 * @param {*} data to change to
 * @returns
 */
export const sendTripInvoiceEmail = (carrierId, invoiceId, data) => {
	return createRequest()
		.post(`api/trip-invoicing/carriers/${carrierId}/invoices/${invoiceId}/send-invoice`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * @description generate invoice pdf content
 * @param {*} carrierId
 * @param {*} invoiceId
 * @returns
 */
export const generateTripInvoice = (carrierId, invoiceId, combine) => {
	return createRequest()
		.get(`/api/trip-invoicing/carriers/${carrierId}/invoices/${invoiceId}/pdf`, {
			responseType: "arraybuffer",
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Fetches a Payout by id
 * @param {*} carrierId
 * @param {*} payoutId
 * @returns
 */
export const getPayout = (carrierId, payoutId) => {
	return createRequest()
		.get(`/api/trip-invoicing/carriers/${carrierId}/payouts/${payoutId}`, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Updates a Payout by id
 * @param {*} carrierId
 * @param {*} payoutId
 * @returns
 */
export const updatePayout = (carrierId, payoutId, data) => {
	return createRequest()
		.put(`/api/trip-invoicing/carriers/${carrierId}/payouts/${payoutId}`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

/**
 * Get Invoices and trips files
 * @returns
 */
export const getInvoicesAndTripsFiles = (carrierId, data) => {
	return createRequest()
		.post(`/api/trip-invoicing/carriers/${carrierId}/invoices/reports`, data, {
			headers: createHeaders(),
		})
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
