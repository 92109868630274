import { payrollTimeZoneSection } from "app/main/profile/drivers/EnterprisePayrollSection";

export const carrierPayrollSettingsForm = {
	urlGET: "api/profile/carriers/:carrierId/details/payroll-settings",
	urlPUT: "api/profile/carriers/:carrierId/details/payroll-settings",
	idKey: "_id",
	triggerEvent: ["profileRevision", "payrollOnboardingRevision"],
	listenEvent: ["profileRevision", "payrollOnboardingRevision"],
	header: {
		edit: { label: "Payroll Configuration" },
	},
	content: {
		view: { sameAs: "edit" },
		create: { sameAs: "edit" },
		edit: {
			form: { noInitValidation: true },
			items: [
				{
					type: "group",
					content: {
						items: [
							...payrollTimeZoneSection,
							{
								key: "eligibility_condition",
								type: "select",
								required: true,
								label: "Eligibility Condition",
								resource: "ELIGIBILITY_CONDITION",
								options: [
									{ value: "DELIVERED_TRIP_STATUS", label: "Only Delivered Trip Status" },
									{ value: "DELIVERED_TRIP_STATUS_AND_BOL_FILE", label: "Delivered Trip Status & BOL file" },
									{
										value: "DELIVERED_TRIP_STATUS_AND_TRIP_INVOICING",
										label: "Delivered Trip Status & Trip Invoicing",
									},
								],
							},
						],
					},
				},
				{ type: "action", action: "SUBMIT", label: "Save" },
			],
		},
	},
};
