// Utils
import { Typography } from "antd";
import { formatDate } from "app/main/utils/dateUtils";
import { getDisplayPickupAddress, getDisplayDeliveryAddress } from "app/main/utils/tripsUtils";

const CARRIER = () => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	field: {
		noErrorMessage: true,
		classes: {},
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: {
			options_active_only: true,
			carrier_id: ":carrierId",
			filters: { payrollStatus: "ENABLED" },
		},
	},
});

const PAYEE = (carrierSelectionEnabled = false, selectedCarrierId) => {
	const rules = carrierSelectionEnabled
		? {
				params: {
					valueRef: "carrier",
					paramKey: "options_carrier_id_search",
				},
		  }
		: null;

	return {
		key: "payee",
		type: "autocomplete",
		label: "Payee",
		translate: "",
		field: {
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			preloadDefault: true,
			preloadImmedately: true,
			showDescription: true,
			listenEvent: "profileRevision",
			// TODO incorrect way of fetching payees, carrieId should be part of the url params
			url: "api/profile/payees",
			params: {
				options_metadata_driver: true,
				label_format_show_driver: true,
				// TODO incorrect way of fintering carriers
				options_carrier_id_search: selectedCarrierId,
			},
		},
		rules,
	};
};

const enterprisePayrollTripsErrors = ({ showCarrier, selectedCarrierId }) => ({
	urlGET: "billing/v3/payroll/carriers/:carrierId/trips-elegible/required-actions",
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "ENTERPRISE_TRIPS_PAYROLL",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["data.delivery_date", "data.pickup_date"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
			defaut: {
				size: 200,
			},
		},
		order: {
			defaut: {
				key: "data.delivery_date",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			searchDescription: "Search payroll entries by load ID, broker name or payee",
			items: [
				...(showCarrier ? [CARRIER()] : []),
				PAYEE(showCarrier, selectedCarrierId),
				{
					key: "payee_category",
					type: "select",
					label: "Payee Type",
					defaultValue: "__NOT_SELECTED__",
					translate: "",
					options: [
						{
							value: "COMPANY_DRIVER",
							label: "Company Driver",
						},
						{
							value: "OWNER_OPERATOR",
							label: "Owner Operator",
						},
						{
							value: "DISPATCHER",
							label: "Dispatcher",
						},
						{
							value: "INVESTOR",
							label: "Investor",
						},
						{
							value: "__NOT_SELECTED__",
							label: "All",
						},
					],
				},
			],
		},
		items: [
			{
				key: "status",
				type: "trip_status_payroll__view",
				label: "Status",
				table: { minWidth: 240, width: 320 },
			},
			{
				type: "separator",
			},
			...(showCarrier
				? [
						{
							key: "carrier__view.label",
							builder: (item) => item.carrier__view,
							type: "carrier__view",
							label: "Carrier",
							viewConfig: {
								showMcNumber: true,
								showTestCarrier: true,
							},
							table: {
								width: 200,
							},
						},
				  ]
				: []),
			{
				key: "payee_name",
				type: "payee__view",
				builder: (item) => ({
					...item.payee__view,
					metadata: {
						...item.payee__view?.metadata,
						payee_type: item.payee_type,
					},
				}),
				label: "Payee",
				viewConfig: {
					showPayeeType: true,
					showStatus: true,
				},
			},
			{
				type: "separator",
			},
			{
				key: "load_id",
				type: "trips__view",
				builder: (item) => item?.trip__view,
				label: "Trip",
				viewConfig: {
					showBroker: true,
				},
				table: { width: 200 },
			},
			{
				type: "separator",
			},
			{
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.data?.pickup_date ? formatDate(item.data.pickup_date, item.data.pickup_window_date) : "-"}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{getDisplayPickupAddress(item)}
							</Typography>
						</div>
					);
				},
				key: "pickup_address",
				type: "component",
				label: "Pickup",
				table: { minWidth: 160, width: 180 },
			},
			{
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13 ">
								{item.data?.delivery_date ? formatDate(item.data.delivery_date, item.data.delivery_window_date) : "-"}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-3">
								{getDisplayDeliveryAddress(item)}
							</Typography>
						</div>
					);
				},
				key: "delivery_address",
				type: "component",
				label: "Delivery",
				table: { minWidth: 160, width: 180 },
			},
		],
	},
});

export default enterprisePayrollTripsErrors;
