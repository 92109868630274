import moment from "moment";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ResourceView from "app/main/tools/ResourceView";
import { resourcesKeys } from "app/main/resource/resourcesFiles";
import { formatAge, formatDate } from "app/main/utils/dateUtils";
import { formatNumber, formatCurrency } from "app/main/utils/tableUtils";
import LoadSummary from "./LoadSummary";
import LoadSearchEmptyContent from "./LoadSearchEmptyContent";
import {
	addSearchStatistic,
	setSearchParams,
	saveCheckAvailability,
	acknowledgeNew,
} from "app/store/search/searchV3Slice";
import { getCarrierStatus, getTruckSearchData } from "app/services/searchServices";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { updateLoad } from "app/main/search/result/LoadSearchViewHelper";
import { getUserTier } from "app/services/LoginService";
import { saveViewedSearchVersions } from "app/services/searchServices";

// Column Components
import TypeComponent from "../table-components/TypeComponent";
import FeatureComponent from "../table-components/FeatureComponent";
import MPIComponent from "../table-components/MPIComponent";
import BrokerComponent from "../table-components/BrokerComponent";
import ContactComponent from "../table-components/ContactComponent";
import RowSeparatorComponent from "../table-components/RowSeparatorComponent";
import LoadCardView from "../table-components/LoadCardView";
import WeightComponent from "../table-components/WeightComponent";

import { applyFilter } from "../utils/filtersUtils";

import { flexRender, getCoreRowModel, useReactTable, getFilteredRowModel } from "@tanstack/react-table";

import { getMemoOptions, memo } from "@tanstack/table-core";

import { RECOMMENDED_SEPARATOR, RECOMMENDED_MESSAGE, NEW_LOADS_SEPARATOR } from "../utils/separatorsUtils";

const _ROW_HEIGHT = 35;
const _ROW_HEIGHT_SMALL_SCREEN = 25;
const _ROW_HEIGHT_MOBILE = 205;
const _PREFIX_RESOURCE = "SEARCH_RESULT_DISPATCHER";
const _DEFAULT_SORT = [{ id: "updated" }];
const getResourceView = (info) => {
	return (
		<ResourceView
			type={"icon"}
			description={info?.description}
			link={info?.link}
			classes={{ icon: " space-x-1 text-blue mt-0 ml:mt-4 ml:ml-3", iconSize: "text-12 ml:text-13" }}
		/>
	);
};

const useStyles = makeStyles((theme) => ({
	hover: {
		"&:hover:not(.active)": {
			backgroundColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.06)" : "rgba(0,0,0,.05)",
		},
	},
	strikethrough: {
		backgroundImage:
			"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAAXNSR0IArs4c6QAAABJJREFUGFdjDHcO/88ABYw4OQCF+wXU04vWpgAAAABJRU5ErkJggg==')",
		backgroundRepeat: "repeat-x",
		backgroundPosition: "50% 50%",
		backgroundSize: "100% 1px",
		opacity: "0.3",
	},
}));

//Override table sorting function and apply recommended section logic
function getSortedRowModel() {
	return (table) =>
		memo(
			() => [table.getState().sorting, table.getPreSortedRowModel()],
			(sorting, rowModel) => {
				if (!rowModel.rows.length || !sorting?.length) {
					return rowModel;
				}
				const sortingState = table.getState().sorting;
				const sortedFlatRows = [];

				// Filter out sortings that correspond to non existing columns
				const availableSorting = sortingState.filter((sort) => table.getColumn(sort.id)?.getCanSort());
				const columnInfoById = {};
				availableSorting.forEach((sortEntry) => {
					const column = table.getColumn(sortEntry.id);
					if (!column) return;
					columnInfoById[sortEntry.id] = {
						sortUndefined: column.columnDef.sortUndefined,
						invertSorting: column.columnDef.invertSorting,
						sortingFn: column.getSortingFn(),
					};
				});

				const sortData = (rows) => {
					// This will also perform a stable sorting using the row index
					// if needed.
					const sortedData = rows.map((row) => ({ ...row }));
					sortedData.sort((rowA, rowB) => {
						for (let i = 0; i < availableSorting.length; i += 1) {
							const sortEntry = availableSorting[i];
							const columnInfo = columnInfoById[sortEntry.id];
							const isDesc = sortEntry?.desc ?? false;

							let sortInt = 0;
							// All sorting ints should always return in ascending order
							if (columnInfo.sortUndefined) {
								const aValue = rowA.getValue(sortEntry.id);
								const bValue = rowB.getValue(sortEntry.id);
								const aUndefined = aValue === undefined;
								const bUndefined = bValue === undefined;

								if (aUndefined || bUndefined) {
									sortInt =
										aUndefined && bUndefined ? 0 : aUndefined ? columnInfo.sortUndefined : -columnInfo.sortUndefined;
								}
							}
							if (sortInt === 0) {
								sortInt = columnInfo.sortingFn(rowA, rowB, sortEntry.id);
							}
							// If sorting is non-zero, take care of desc and inversion
							if (sortInt !== 0) {
								if (isDesc) {
									sortInt *= -1;
								}
								if (columnInfo.invertSorting) {
									sortInt *= -1;
								}
								return sortInt;
							}
						}
						return rowA.index - rowB.index;
					});

					// If there are sub-rows, sort them
					sortedData.forEach((row) => {
						sortedFlatRows.push(row);
						if (row.subRows?.length) {
							row.subRows = sortData(row.subRows);
						}
					});
					return sortedData;
				};

				const searchState = table.getState().searchState;
				const isMobile = table.getState().isMobile;
				const userTier = table.getState().userTier;
				const preloadingInProgress = searchState === "FINISHED_PRELOADED" || searchState === "QUEUEING";

				let finalLoads = [];

				//Disable recommended loads for mobile and Starter tier
				const disableRecommendedSection = isMobile || userTier === "TIER_STARTER";

				if (disableRecommendedSection) {
					finalLoads = sortData(rowModel.rows);
				} else if (preloadingInProgress) {
					const allLoads = sortData(rowModel.rows);
					//Add dividers
					finalLoads = [
						...RECOMMENDED_SEPARATOR(),
						...RECOMMENDED_MESSAGE(true, 0),
						...NEW_LOADS_SEPARATOR(),
						...allLoads,
					];
				} else {
					const { best, noBest } = rowModel.rows.reduce(
						(acc, row) => {
							if (row.original.best) {
								acc.best.push(row);
							} else {
								acc.noBest.push(row);
							}
							return acc;
						},
						{ best: [], noBest: [] }
					);
					const bestSorted = sortData(best);
					const topBest = bestSorted.splice(0, 5);

					//Append the rest of loads
					const allLoads = sortData(noBest.concat(bestSorted));

					//Add dividers
					finalLoads = [
						...RECOMMENDED_SEPARATOR(),
						...RECOMMENDED_MESSAGE(false, topBest.length),
						...topBest,
						...NEW_LOADS_SEPARATOR(),
						...allLoads,
					];
				}

				return {
					rows: finalLoads,
					flatRows: sortedFlatRows,
					rowsById: rowModel.rowsById,
				};
			},
			getMemoOptions(table.options, "debugTable", "getSortedRowModel", () => table._autoResetPageIndex())
		);
}

function SearchResults(props) {
	const classes = useStyles();

	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1600 && window.innerWidth > 960);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);
	const [isScrolled, setIsScrolled] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const userTier = getUserTier();
	const searches = useSelector(({ search }) => search.searchV3Slice.searches);
	const currentSearchId = useSelector(({ search }) => search.searchV3Slice.currentSearch);
	const socket = useSelector(({ socket }) => socket.connection.socket);

	const mobile = props?.mobile;
	const onScroll = props?.onScroll;
	const availabilityOffset = mobile ? 0 : smallScreen ? 3 : 5;
	const _SEPARATOR_WEIGHT = smallScreen ? 10 : 20;

	const scrollHandler = (state) => {
		setIsScrolled(state);
		onScroll?.(state);
	};

	const handleResize = useCallback(() => {
		const newSmallScreen = window.innerWidth < 1600 && window.innerWidth > 960;
		setSmallScreen(newSmallScreen);

		const newInnerHeight = parseInt(window.innerHeight / 10) * 10;
		if (newInnerHeight !== screenHeight) {
			setScreenHeight(newInnerHeight);
		}
	}, [screenHeight]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [handleResize]);

	const currentSearch = useMemo(() => searches[currentSearchId], [searches, currentSearchId]);
	const stats = useMemo(() => currentSearch?.stats, [currentSearch]);

	const [sorting, setSorting] = useState(_DEFAULT_SORT);
	const [globalFilter, setGlobalFilter] = useState(currentSearch?.softFilters);
	const [carrierStatus, setCarrierStatus] = useState(null);
	const cachedTruckSearchId = useRef(null);
	const scrollAnchor = useRef(null);
	const availabilityAnchor = useRef(null);
	const [truckSearchData, setTruckSearchData] = useState(null);
	const rowHeight = useRef(mobile ? _ROW_HEIGHT_MOBILE : smallScreen ? _ROW_HEIGHT_SMALL_SCREEN : _ROW_HEIGHT);
	const loadsWindowsSize = useRef(mobile ? 5 : smallScreen ? 15 : 30);
	const lastSearchCheckRef = useRef(currentSearch?.lastCheck);
	const checkedLoadsRef = useRef(currentSearch?.loadsChecked ?? {});
	const currentSearchIdRef = useRef(currentSearchId);
	const currentSearchFiltersRef = useRef(currentSearch?.filters);
	const currentSearchStatsRef = useRef(currentSearch?.stats);
	const lastAckNews = useRef(moment().unix());

	const isInternalUser = user.roleType === "INTERNAL";

	//Handling filters saved on redux
	useEffect(() => {
		setGlobalFilter(currentSearch?.softFilters);
	}, [currentSearch?.softFilters]);

	//Update table sort when the search changed
	useEffect(() => {
		if (currentSearch?.sortConfig) {
			setSorting(currentSearch?.sortConfig);
		} else {
			setSorting(_DEFAULT_SORT);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearchId]);

	// Checking carrier permission
	useEffect(() => {
		const carrier = currentSearch?.filters?.carrier;
		const truck = currentSearch?.filters?.truck;
		const userId = user?._id;
		if (!userId || !carrier) {
			return;
		}

		async function fetchCarrierStatus() {
			await getCarrierStatus(userId, carrier, { truckId: truck }).then(
				(data) => {
					setCarrierStatus(data);
				},
				(err) => {
					console.log("[SearchResult]: Error get carrier status");
				}
			);
		}

		async function fetchTruckSearchData() {
			let { carrier, truck } = currentSearch?.filters;

			if (cachedTruckSearchId.current === truck || !truck || !carrier) return;
			cachedTruckSearchId.current = truck;

			let equipment = truck.includes("ANY_")
				? truck.replace("ANY_", "")
				: props?.initModel?.truck__view?.metadata?.truck?.equipment ?? "VAN";

			await getTruckSearchData(carrier, truck, equipment, {
				location: currentSearch?.filters?.location_origin,
				dh: currentSearch?.filters?.dh_origin,
			}).then(
				(data) => {
					setTruckSearchData(data);
				},
				(err) => {
					console.log("[LoadSearchResult]: Error getting profit  status");
				}
			);
		}

		fetchTruckSearchData();
		fetchCarrierStatus();
		// eslint-disable-next-line
	}, [user?._id, currentSearch?.filters]);

	useEffect(() => {
		rowHeight.current = mobile ? _ROW_HEIGHT_MOBILE : smallScreen ? _ROW_HEIGHT_SMALL_SCREEN : _ROW_HEIGHT;
		loadsWindowsSize.current = mobile ? 5 : smallScreen ? 15 : 30;
	}, [mobile, smallScreen]);

	const columns = useMemo(
		() =>
			mobile
				? [
						{
							accessorKey: "mobile_card",
							size: 900,
							cell: (item) => {
								return (
									<div className="flex flex-col w-full p8 rounded-12">
										<LoadCardView id={item.id} item={item.row.original} />
									</div>
								);
							},
						},
						{
							accessorKey: "updated",
							invertSorting: true,
							sortDescFirst: false,
						},
						{
							accessorKey: "distance_to_origin",
							invertSorting: true,
							sortDescFirst: false,
						},
						{
							accessorKey: "price",
						},
						{
							accessorKey: "rpm",
						},
						{
							accessorKey: "profit_trip_carrier",
						},
						{
							accessorKey: "rank_mci",
						},
				  ]
				: [
						{
							accessorKey: "updated",
							header: "Age",
							size: smallScreen ? 45 : 70,
							cell: (item) => {
								return (
									<>
										<div className={"w-6 h-6 rounded-full mr-4" + (item.row.original.new ? " bg-cyan-700 " : "")} />
										{formatAge(item.getValue())}
									</>
								);
							},
							invertSorting: true,
							sortDescFirst: false,
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						...(isInternalUser
							? [
									{
										accessorKey: "last_check",
										header: "Check",
										size: smallScreen ? 45 : 70,
										cell: (item) => {
											return item.row.original.hasAvailabilityCheck ? (
												<>
													{checkedLoadsRef.current?.[item.row.original.tripid]
														? formatAge(checkedLoadsRef.current?.[item.row.original.tripid]?.lastCheck)
														: "-"}
												</>
											) : (
												"N/A"
											);
										},
										invertSorting: true,
										sortDescFirst: false,
									},
									{
										accessorKey: "separator",
										type: "separator",
									},
							  ]
							: []),
						{
							accessorKey: "type",
							header: "Type",
							size: smallScreen ? 35 : 75,
							style: {
								align: "center",
							},
							cell: (item) => TypeComponent({ item: item.row.original, smallScreen }),
							enableSorting: false,
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						{
							accessorKey: "profit_trip_carrier",
							header: "Profit",
							showDescription: true,
							size: smallScreen ? 70 : 80,
							cell: (item) => {
								const profitValue = item.getValue();
								if (!profitValue || profitValue === 0) return "-";

								const color = profitValue > 0 ? "text-green" : "text-red";
								const icon = profitValue > 0 ? "arrow_drop_up" : "arrow_drop_down";
								return (
									<>
										{formatCurrency(profitValue, 0)}
										<Icon className={`-mr-7 ml:-mr-7 -ml-2 text-20 ml:text-24 ${color}`}>{icon}</Icon>
									</>
								);
							},
						},
						{
							accessorKey: "price",
							header: "Rate",
							size: smallScreen ? 50 : 80,
							sortingFn: "alphanumeric",
							cell: (item) => (item.getValue() ? formatCurrency(item.getValue(), 0) : "-"),
						},
						{
							accessorKey: "rpm",
							header: "RPM",
							size: smallScreen ? 50 : 70,
							cell: (item) => {
								const rpmValue = item.getValue();
								const rowItem = item.row.original;

								let rpmResult;
								if (rpmValue > 0) {
									rpmResult = formatCurrency(rpmValue, 2);
								} else if (rowItem.est_rpm > 0) {
									rpmResult = formatCurrency(rowItem.est_rpm, 2);
								} else {
									rpmResult = "-";
								}
								return rpmResult;
							},
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						{
							accessorKey: "miles",
							header: "Miles",
							size: smallScreen ? 50 : 70,
							cell: (item) => (item.getValue() > 0 ? formatNumber(item.getValue(), 0) : "-"),
						},
						{
							accessorKey: "distance_to_origin",
							header: "DH-O",
							size: smallScreen ? 50 : 70,
							sortDescFirst: false,
							cell: (item) => (item.getValue() > 0 ? item.getValue() : "-"),
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						{
							accessorKey: "ready",
							header: "Pickup",
							size: smallScreen ? 65 : 100,
							cell: (item) => {
								const from = item.getValue();
								const to = item.row.original.pickupby;

								const timeString = formatDate(from, to, true, false, true, false, true);
								return timeString;
							},
						},
						{
							accessorKey: "origin",
							header: "Origin",
							size: smallScreen ? 140 : 150,
							style: {
								align: "left",
							},
						},
						{
							accessorKey: "separator",
							type: "separator",
						},

						...(currentSearch?.filters?.location_destination__view
							? [
									{
										accessorKey: "distance_to_dest",
										header: "DH-D",
										sortDescFirst: false,
										size: smallScreen ? 50 : 70,
										cell: (item) => (item.getValue() > 0 ? item.getValue() : "-"),
									},
							  ]
							: []),

						{
							accessorKey: "rank_mci",
							header: "MPI",
							showDescription: true,
							size: smallScreen ? 60 : 68,
							cell: (item) => {
								const mci = item.getValue();
								const mvi = item.row.original.rank_mvi;
								if (!mci) return "-";

								return MPIComponent({ mci: mci, mvi: mvi });
							},
						},
						{
							accessorKey: "destination",
							header: "Destination",
							size: 150,
							style: {
								align: "left",
							},
							cell: (item) => {
								return (
									<Typography className={item.row.original.destBlacklisted ? "line-through" : ""}>
										{item.row.original.destination}
									</Typography>
								);
							},
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						{
							accessorKey: "smartpayElegible",
							header: user?.hasSmartPayProgram && !smallScreen ? "SmartPay" : "Factor",
							showDescription: user?.hasSmartPayProgram,
							enableSorting: false,
							size: smallScreen ? 60 : 90,
							cell: (item) => {
								if (user?.hasSmartPayProgram) {
									return item.getValue() ? <Icon className={"text-18 text-grey-500"}>check</Icon> : null;
								} else {
									return item.row.original.factoring ? <Icon className={"text-18 text-grey-500"}>check</Icon> : null;
								}
							},
							style: {
								align: "center",
							},
						},
						{
							accessorKey: "separator",
							type: "separator",
						},
						{
							accessorKey: "weight",
							header: "Weight",
							size: smallScreen ? 55 : 70,
							cell: (item) => WeightComponent({ item: item.row.original }),
						},
						{
							accessorKey: "feature",
							header: "",
							size: smallScreen ? 45 : 80,
							cell: (item) => FeatureComponent({ item: item.row.original, smallScreen }),
							style: {
								align: "left",
							},
						},
						{
							accessorKey: "clientid",
							header: "Broker",
							size: smallScreen ? 180 : 290,
							cell: (item) => BrokerComponent({ item: item.row.original, smallScreen }),
							style: {
								align: "left",
							},
						},
						{
							header: "",
							accessorKey: "contact",
							size: smallScreen ? 90 : 210,
							cell: (item) =>
								ContactComponent({ item: item.row.original, userId: user._id, searchId: currentSearchId, smallScreen }),
							style: {
								align: "left",
							},
						},
				  ],
		[
			mobile,
			smallScreen,
			isInternalUser,
			currentSearch?.filters?.location_destination__view,
			user?.hasSmartPayProgram,
			user._id,
			currentSearchId,
		]
	);

	const columnVisibility = useMemo(
		() =>
			mobile
				? {
						updated: false,
						distance_to_origin: false,
						price: false,
						rpm: false,
						profit_trip_carrier: false,
						rank_mci: false,
				  }
				: {},
		[mobile]
	);

	const data = useMemo(() => [...(currentSearch?.results ?? [])], [currentSearch?.results]);

	const globalFilterFunction = (row, columnId, filterValue) => {
		return applyFilter(filterValue, row.original);
	};

	useEffect(() => {
		if (currentSearch?.lastCheck !== lastSearchCheckRef.current) {
			console.log("lastSearchCheck changed:", currentSearch?.lastCheck);
			lastSearchCheckRef.current = currentSearch?.lastCheck;
		}
	}, [currentSearch?.lastCheck]);

	useEffect(() => {
		const newCheckedLoadsCount = currentSearch?.loadsChecked ? Object.keys(currentSearch?.loadsChecked).length : 0;
		const currentCheckedLoadsCount = Object.keys(checkedLoadsRef.current).length;

		if (newCheckedLoadsCount !== currentCheckedLoadsCount) {
			console.log("checkedLoads changed:", newCheckedLoadsCount);
			checkedLoadsRef.current = currentSearch?.loadsChecked ?? {};
		}
	}, [currentSearch?.loadsChecked]);

	useEffect(() => {
		currentSearchIdRef.current = currentSearchId;
	}, [currentSearchId]);

	useEffect(() => {
		if (JSON.stringify(currentSearch?.filters) !== JSON.stringify(currentSearchFiltersRef.current)) {
			console.log("Search filters changed:", currentSearch?.filters);
			currentSearchFiltersRef.current = currentSearch?.filters;
		}
	}, [currentSearch?.filters]);

	useEffect(() => {
		if (JSON.stringify(currentSearch?.stats) !== JSON.stringify(currentSearchFiltersRef.stats)) {
			currentSearchStatsRef.current = currentSearch?.stats;
		}
	}, [currentSearch?.stats]);

	//Update sort only for mobile
	useEffect(() => {
		if (!mobile) return null;
		if (mobile) {
			setSorting(currentSearch?.sortConfig);
		}
	}, [currentSearch?.sortConfig, mobile]);

	const onSortingChange = (sort) => {
		setSorting(sort());
		dispatch(
			setSearchParams({
				searchId: currentSearchId,
				sortConfig: sort(),
			})
		);
	};

	const table = useReactTable({
		data,
		columns,
		initialState: {
			columnVisibility,
		},
		state: {
			sorting,
			globalFilter,
			searchState: currentSearch?.state,
			isMobile: mobile,
			userTier,
		},
		onSortingChange: (sort) => onSortingChange(sort),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getRowId: (row) => row.tripid,
		enableRowSelection: true,
		enableMultiRowSelection: false,
		enableSortingRemoval: false,
		enableColumnResizing: false,
		enableGlobalFilter: true,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: globalFilterFunction,
		getFilteredRowModel: getFilteredRowModel(),
	});

	const parentRef = useRef(null);

	const sendSocketMessage = (sendingLoads) => {
		if (sendingLoads.length > 0) {
			console.log(
				"[SEARCH][checkLoadAvailability] Loads to send ",
				currentSearchIdRef.current,
				" - ",
				sendingLoads.length
			);
			//Send WS message search id and array of IDS
			socket.emit("message", {
				type: "SEARCH_CHECK_AVAILABILITY",
				data: {
					userId: user?._id,
					searchId: currentSearchIdRef.current,
					loads: sendingLoads,
					carrierId: currentSearchFiltersRef.current?.carrier,
				},
			});
			dispatch(saveCheckAvailability({ searchId: currentSearchIdRef.current, loads: sendingLoads }));
		}
	};

	const checkLoadAvailability = useCallback(
		(newScrollAnchor) => {
			//Send ACK for new loads if past more than 2 minutes (ignore on scroll availability check)
			if (!newScrollAnchor && currentSearchIdRef.current !== "EMPTY") {
				const currentTimestamp = moment().unix();
				if (currentTimestamp - lastAckNews.current >= 120) {
					lastAckNews.current = currentTimestamp;
					//Call ack for current new loads
					if (currentSearchStatsRef.current?.versions?.last) {
						saveViewedSearchVersions(
							user?._id,
							currentSearchIdRef.current,
							currentSearchStatsRef.current?.versions?.last
						).then(
							(data) => {
								dispatch(
									addSearchStatistic({
										searchId: currentSearchIdRef.current,
										statName: "versions",
										value: data.versions,
									})
								);
								dispatch(
									acknowledgeNew({
										searchId: currentSearchIdRef.current,
									})
								);
							},
							(errors) => {
								console.error(`[SearchStatusPanel] POST: failed to acknoledged viewed loads`, errors);
							}
						);
					}
				}
			}
			//console.log("[checkLoadAvailability] Check Availability called ", lastSearchCheckRef.current?.toISOString() , " - Total Checked:", Object.keys(checkedLoadsRef.current).length);

			//if (currentSearch?.state === "EXPIRED" || currentSearch?.state === "EMPTY") return;
			let visibleLoads;
			let scrollReachedWindow = false;
			//TODO Change when screen size is screen resize
			const realVisibleLoads = scrollAnchor?.current?.range
				? (scrollAnchor?.current?.range?.endIndex - scrollAnchor?.current?.range?.startIndex) / 3
				: loadsWindowsSize.current - availabilityOffset * 2; //Add Offset
			const lastCheckMinutes = moment().diff(moment(lastSearchCheckRef.current), "seconds");
			console.log("[checkLoadAvailability] Last Check Seconds", lastCheckMinutes);
			if (newScrollAnchor) {
				const currentScrollEnd = availabilityAnchor?.current?.range?.endIndex ?? 0;
				const newScrollEnd = newScrollAnchor.range?.endIndex ?? 0;
				const scrollDelta = Math.abs(newScrollEnd - currentScrollEnd);
				const scrollDeltaSeconds = moment(newScrollAnchor?.date).diff(
					moment(availabilityAnchor?.current?.date),
					"seconds"
				);
				scrollReachedWindow = scrollDelta >= realVisibleLoads; // && scrollDeltaSeconds >= 30;
				// console.log(
				// 	"[checkLoadAvailability] Scroll changed",
				// 	availabilityAnchor?.current?.range,
				// 	"vs",
				// 	newScrollAnchor.range,
				// 	" - Loads:",
				// 	scrollDelta,
				// 	 " - Visible:",
				// 	realVisibleLoads,
				// 	" - Seconds",
				// 	scrollDeltaSeconds
				// );

				if (scrollReachedWindow && lastCheckMinutes >= 60) {
					const startIndexToAsk = newScrollAnchor?.range?.startIndex;
					const lastIndexToAsk = newScrollAnchor?.range?.endIndex;
					console.log(
						"[checkLoadAvailability] Scroll reached - Range",
						startIndexToAsk,
						"-",
						lastIndexToAsk,
						" - Date: ",
						newScrollAnchor?.date,
						" - Seconds: ",
						scrollDeltaSeconds,
						"seconds"
					);
					visibleLoads = table.getRowModel().rows.slice(startIndexToAsk, lastIndexToAsk);
					console.log("[checkLoadAvailability] Visible loads", visibleLoads.length);
				}
			} else {
				const currentScroll = availabilityAnchor?.current?.range ?? 0;
				console.log(
					"[checkLoadAvailability] Check Availability without scrolling",
					currentScroll.startIndex,
					"-",
					currentScroll.endIndex
				);
				const startIndexToAsk = availabilityAnchor?.current?.range?.startIndex ?? 0;
				const lastIndexToAsk = availabilityAnchor?.current?.range?.endIndex ?? realVisibleLoads;
				console.log("[checkLoadAvailability] No scrolling - Range", startIndexToAsk, "-", lastIndexToAsk);
				visibleLoads = table.getRowModel().rows.slice(startIndexToAsk, lastIndexToAsk);
				console.log("[checkLoadAvailability] No Scrolling - Visible loads", visibleLoads.length);
			}

			//Set the new anchor for availability
			if (newScrollAnchor && scrollReachedWindow) {
				availabilityAnchor.current = newScrollAnchor;
			}

			if (visibleLoads?.length > 0) {
				const currentDate = moment();
				//Filer out:
				// * Already covered loads
				// * Loads without check availability
				// * NO Load rows
				// * Loads we already checked availability in the past 2 minutes
				let sendingLoads = visibleLoads
					.filter(
						(load) =>
							!load.original.deleted &&
							load.original.hasAvailabilityCheck &&
							load.id.startsWith("SH") &&
							currentDate.diff(load.original.updated, "seconds") > 120 &&
							(checkedLoadsRef.current?.[load.id]?.lastCheck
								? currentDate.diff(moment(checkedLoadsRef.current?.[load.id]?.lastCheck), "minutes") >= 2
								: true)
					)
					.map((load) => {
						return {
							tripid: load.id,
							owner: load.original.owner,
							datDetailKey: load.original.datDetailKey,
							equipment: load.original.equipment,
						};
					});
				console.log(
					"[checkLoadAvailability] Sending",
					sendingLoads.length,
					"loads, since: ",
					lastSearchCheckRef.current?.toISOString()
				);
				sendSocketMessage(sendingLoads);
			} else {
				//console.log("[checkLoadAvailability] No loads to send...");
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line
		[currentSearchIdRef, availabilityAnchor]
	);

	const sidePanel = useMemo(
		() => {
			if (!table?.getSelectedRowModel()?.rows?.[0]) return null;
			const loadSelected = table?.getSelectedRowModel()?.rows?.[0];
			//Call fire and forget for availability if was not checked before
			const lastCheck = currentSearch?.loadsChecked?.[loadSelected.id]?.lastCheck;
			if (!lastCheck) {
				sendSocketMessage([loadSelected]);
			}
			//Call fire and forget for seen load
			if (!loadSelected.original.seen) {
				updateLoad(currentSearchId, loadSelected.original.tripid, { seen: true });
				socket.emit("message", {
					type: "SEARCH_SEEN_LOAD",
					data: { userId: user._id, searchId: currentSearchId, tripid: loadSelected.id },
				});
			}
			if (!mobile) {
				return (
					<LoadSummary
						loadId={loadSelected.id}
						load={{
							...loadSelected.original,
							...{ loadId: loadSelected.id },
						}}
						onClose={() => table?.resetRowSelection()}
						truckSearchData={truckSearchData}
						carrierStatus={carrierStatus}
						smallScreen={smallScreen}
					/>
				);
			} else {
				dispatch(
					openFormDialog({
						viewId: "LOAD_SUGGEST_VIEW",
						dataIds: {
							load: {
								...table?.getSelectedRowModel()?.rows?.[0]?.original,
								...{ loadId: table?.getSelectedRowModel()?.rows?.[0]?.id },
							},
							loadId: table?.getSelectedRowModel()?.rows?.[0]?.id,
							userId: user?._id,
							searchId: currentSearchId,
							carrierId: currentSearchFiltersRef.current?.carrier,
							carrier__view: currentSearchFiltersRef.current?.carrier__view,
							truckId: currentSearchFiltersRef.current?.truck,
							truck__view: currentSearchFiltersRef.current?.truck__view,
							driverId: currentSearchFiltersRef.current?.driver,
							driver__view: currentSearchFiltersRef.current?.driver__view,
							isFromSearch: true,
						},
					})
				);
			}
		},
		// eslint-disable-next-line
		[table?.getSelectedRowModel()?.rows?.[0]?.id, carrierStatus, smallScreen]
	);

	useEffect(() => {
		dispatch(
			addSearchStatistic({
				searchId: currentSearchId,
				statName: "filtered",
				value: table.getFilteredRowModel().rows.length,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearchId, table.getFilteredRowModel().rows.length, currentSearch?.results]);

	const { rows } = table?.getRowModel() ?? {};

	const virtualizer = useVirtualizer({
		count: rows?.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => rowHeight.current,
		overscan: loadsWindowsSize.current,
		getItemKey: (index) => rows[index]?.tripid,
	});

	//Check availability after a tab change
	useEffect(() => {
		if (currentSearchId === "EMPTY") return;
		lastAckNews.current = moment().unix();
		const timeoutId = setTimeout(() => {
			checkLoadAvailability();
		}, 5000);
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearchId]);

	//Check availability for current window every 2 minutes
	useEffect(() => {
		//if (!isEnabled("QA_SEARCH_AUTO_AVAILABILITY")) return;

		let intervalId;

		const handleVisibilityChange = () => {
			if (document.hidden) {
				console.log("[checkLoadAvailability] Visibility changed to hidden");
				clearInterval(intervalId);
			} else {
				console.log("[checkLoadAvailability] Visibility changed to visible");
				//Check availability every 2 minutes
				intervalId = setInterval(checkLoadAvailability, 60000);
			}
		};

		// Initial setup
		if (!document.hidden) {
			console.log("[checkLoadAvailability] Interval set");
			intervalId = setInterval(checkLoadAvailability, 60000);
		}

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			clearInterval(intervalId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//If data changes we need to recalculate scroll offset to avoid scroll jumping
	useEffect(() => {
		if (!scrollAnchor.current) return;
		const previousAnchor = scrollAnchor.current;
		const newIndex = table.getRowModel().rows.findIndex((row) => row.id === previousAnchor.itemId);

		//Calculate how much the first item index changed
		const indexDelta = newIndex - previousAnchor.itemIndex;
		const newOffset = previousAnchor.offset + indexDelta * rowHeight.current;

		virtualizer.scrollToOffset(newOffset);
		if (newOffset === 0) scrollHandler(false);
		virtualizer.measure();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, virtualizer, table]);

	useEffect(() => {
		if (currentSearchId === "EMPTY") {
			virtualizer.scrollToOffset(0);
		} else {
			virtualizer.scrollToOffset(currentSearch.scrollOffset);
		}
	}, [currentSearch, virtualizer, currentSearchId]);

	//Keep virtualizer scroll anchor
	useEffect(() => {
		if (currentSearchId === "EMPTY") return;
		const itemIndex = table.getRowModel().rows.findIndex((row) => row.id === virtualizer.getVirtualItems()?.[0]?.key);

		const newScrollAnchor = {
			itemId: virtualizer.getVirtualItems()?.[0]?.key,
			itemIndex,
			offset: virtualizer.scrollOffset,
			date: moment().toISOString(),
			range: virtualizer.range,
		};

		dispatch(
			setSearchParams({
				searchId: currentSearchId,
				scrollOffset: virtualizer.scrollOffset,
			})
		);

		//Check availability process
		checkLoadAvailability(newScrollAnchor);

		//Save information about the first load of virtualizer window and current scroll offset
		scrollAnchor.current = newScrollAnchor;

		//Hide the request bar on mobile
		if (virtualizer.scrollOffset > rowHeight.current) {
			scrollHandler(true);
		} else {
			scrollHandler(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [virtualizer.scrollOffset]);

	if (currentSearch?.error) {
		return (
			<Typography className="text-red flex flex-col flex-auto items-center justify-center">
				{currentSearch.error}
			</Typography>
		);
	}
	if (!stats?.filtered || stats?.filtered === 0) {
		const processingSearch =
			(!currentSearch?.state && currentSearchId !== "EMPTY") ||
			currentSearch?.state === "QUEUEING" ||
			currentSearch?.state === "FINISHED_PRELOADED" ||
			currentSearch?.processing;
		return (
			<div className={`flex flex-col w-full items-center flex-1 ${processingSearch ? "opacity-60" : ""}`}>
				<LoadSearchEmptyContent processingSearch={processingSearch} />
			</div>
		);
	}
	const tableHeightOffset = !mobile ? 256 : isScrolled ? 0 : 350;

	return (
		<div
			ref={parentRef}
			className={`bg-transparent`}
			style={{
				overflow: "auto", //our scrollable table container
				position: "relative", //needed for sticky header
				height: screenHeight - tableHeightOffset + "px",
			}}
		>
			{sidePanel}
			<table style={{ width: "100%" }} className="w-full">
				{!mobile && (
					<thead
						style={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							width: "100%",
							boxShadow: "0 0px 0.5px 1px #E0E0E0",
						}}
						className={"bg-gray-100 h-40 border-b-2 text-12 ml:text-13 font-semibold"}
					>
						{table?.getHeaderGroups().map((headerGroup, indexHeaderGroups) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header, indexHeader) => {
									const columnDef = header.column.columnDef;
									const hValue = columnDef.type === "separator" ? "" : columnDef.header;
									const isLeftAligned = columnDef.style?.align === "left";
									const sortedApplied = header.column.getIsSorted();
									const sortIcon = sortedApplied === "asc" ? "arrow_upward" : "arrow_downward";
									const infoIcon = columnDef.showDescription
										? getResourceView(resourcesKeys[_PREFIX_RESOURCE + "_" + header.id])
										: null;

									const align =
										columnDef.style?.align === "left"
											? ` justify-start ${smallScreen ? "pl-5" : "pl-20"} `
											: columnDef.style?.align === "center"
											? " justify-center "
											: " justify-end ";

									return (
										<th
											key={header.id + "_" + indexHeaderGroups + "_" + indexHeader}
											colSpan={header.colSpan}
											style={{
												minWidth: columnDef.type === "separator" ? _SEPARATOR_WEIGHT : header.getSize(),
											}}
											className={`overflow-hidden font-semibold  `}
										>
											{header.isPlaceholder ? null : (
												<div
													{...{
														className:
															`${align} flex flex-row ` +
															(header.column.getCanSort()
																? "cursor-pointer select-none overflow-hidden "
																: "overflow-hidden"),
														onClick: header.column.getToggleSortingHandler(),
													}}
												>
													{isLeftAligned && infoIcon}
													{!isLeftAligned && !!header.column.getIsSorted() && (
														<Icon className="mr-0 ml:mr-2 pt-2 text-12 ml:text-15" style={{ color: "#6b7280" }}>
															{sortIcon}
														</Icon>
													)}
													{flexRender(hValue, header.getContext())}
													{isLeftAligned && !!header.column.getIsSorted() && (
														<Icon className="mr-0 ml:mr-2 pt-2 text-12 ml:text-15" style={{ color: "#6b7280" }}>
															{sortIcon}
														</Icon>
													)}
													{!isLeftAligned && infoIcon}
												</div>
											)}
										</th>
									);
								})}
							</tr>
						))}
					</thead>
				)}
				<tbody
					style={{
						height: `${virtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
						position: "relative", //needed for absolute positioning of rows
						width: "100%",
					}}
				>
					{virtualizer.getVirtualItems().map((virtualRow, indexVirtualItem) => {
						const row = rows[virtualRow.index];
						return (
							<tr
								data-index={virtualRow.index} //needed for dynamic row height measurement
								ref={(node) => virtualizer.measureElement(node)} //measure dynamic row height
								key={row.id}
								className={
									(row.original.type !== "separator" ? classes.hover + " cursor-pointer " : "") +
									(!mobile && row.getIsSelected()
										? " bg-blue-100 "
										: !mobile && row.original.seen && !row.original?.deleted
										? " bg-orange-50 "
										: "") +
									(row.original.deleted && !mobile ? classes.strikethrough : "")
								}
								style={{
									display: "flex",
									position: "absolute",
									height: `${virtualRow.size}px`,
									transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
									width: "100%",
								}}
								onClick={row.getToggleSelectedHandler()}
							>
								{row.original?.type === "separator" ? (
									<RowSeparatorComponent row={row} classes={classes} />
								) : (
									row.getVisibleCells().map((cell, indexRow) => {
										const columnDef = cell.column.columnDef;
										const align =
											columnDef.style?.align === "left"
												? ` text-left ${smallScreen ? "pl-5" : "pl-20"} `
												: columnDef.style?.align === "center"
												? " justify-center "
												: " justify-end ";

										let child;
										if (columnDef.type === "separator") {
											child = <Divider orientation="vertical" className="h-16" />;
										} else {
											child = flexRender(columnDef.cell, cell.getContext());
										}

										return (
											<td
												key={cell.id + "_" + indexVirtualItem + "_" + indexRow}
												style={{
													display: "flex",
													width: columnDef.type === "separator" ? _SEPARATOR_WEIGHT : cell.column.getSize() + "px",
													minWidth: columnDef.type === "separator" ? _SEPARATOR_WEIGHT : cell.column.getSize() + "px",
													overflow: "hidden",
													color: "#111827",
												}}
												className={`items-center  text-11 ml:text-12 font-normal ${align} ${
													!mobile ? "border-b" : "p-15"
												} ${columnDef.type === "separator" ? " justify-center " : ""}`}
											>
												{child ?? <></>}
											</td>
										);
									})
								)}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default SearchResults;
