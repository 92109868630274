import { Typography, Icon } from "@material-ui/core";

// Tools
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";

const _MCI_STYLE = (mci) => {
	let icon;
	let color;
	let opacity;

	if (mci >= 60) {
		icon = "local_fire_department";
		color = mci >= 90 ? "text-red-500" : mci >= 80 ? "text-orange-700" : "text-yellow-800";
		opacity = mci >= 90 ? "opacity-100" : mci >= 80 ? "opacity-90" : mci >= 70 ? "opacity-70" : "opacity-30";
	} else if (mci > 0) {
		icon = "ac_unit";
		color = "text-blue-500";
		opacity = mci < 30 ? "opacity-100" : mci < 40 ? "opacity-80" : mci < 50 ? "opacity-60" : "opacity-30";
	}

	return { icon, color, opacity };
};

const _MVI_STYLE = (mvi) => {
	let text = "";
	let colorBar = {};
	if (mvi < 33 && mvi >= 0) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-gray-400", thirdBar: "bg-gray-400" };
		text = "Fewer options for getting a reload";
	} else if (mvi < 66 && mvi >= 33) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-blue-900", thirdBar: "bg-gray-300" };
		text = "Average options for getting a reload";
	} else if (mvi >= 66) {
		colorBar = { firstBar: "bg-blue-900", secondBar: "bg-blue-900", thirdBar: "bg-blue-900" };
		text = "Many of options for getting a reload";
	}

	return { text, colorBar };
};

function MPIComponent(props) {
	const mci = props.mci;
	const mvi = props.mvi;
	if (!mci) return null;

	const rankMCI = parseInt(mci * 100);
	const textMCI =
		rankMCI >= 90
			? "Best"
			: rankMCI >= 80
			? "Great"
			: rankMCI >= 70
			? "Good"
			: rankMCI >= 60
			? "Medium"
			: rankMCI
			? "Bad"
			: "-";

	const rankMVI = mvi && mvi > 0 ? mvi * 100 : null;
	const textMVI = rankMVI > 0 ? _MVI_STYLE(rankMVI).text : null;

	const TitleView = (
		<Typography className={"text-11 md:text-12 p-6 font-semibold text-11 md:text-12"} component={"div"}>
			<p>{`Market Profitability Index: ${textMCI}`}</p>
			<p className={"mt-10 -mb-2"}>{`Reload Score: ${textMVI}`}</p>
		</Typography>
	);

	const mciRankView = () => {
		const mciStyle = _MCI_STYLE(rankMCI);

		return (
			<div className="whitespace-nowrap flex flex-row justify-end items-end">
				<Icon className={"text-13 ml:text-14 mr-2 mb-1 " + (mciStyle.color ?? "") + " " + (mciStyle.opacity ?? "")}>
					{mciStyle.icon}
				</Icon>
				<Typography component={"div"} className={"text-10 ml:text-11"}>
					{rankMCI}
				</Typography>
			</div>
		);
	};

	const mviRankVIew = () => {
		const colorBar = _MVI_STYLE(rankMVI).colorBar;
		return (
			<div className={"flex flex-row mt-2 mb-2 justify-end items-end"}>
				<span className={`h-1 w-8 mr-2 ${colorBar.firstBar}`}></span>
				<span className={`h-1 w-8 mr-2 ${colorBar.secondBar}`}></span>
				<span className={`h-1 w-8 ${colorBar.thirdBar}`}></span>
			</div>
		);
	};

	return (
		<TooltipClickAwayView classes={{ tooltip: "drop-shadow-md " }} title={TitleView}>
			<div className="flex flex-col justify-end items-end">
				{rankMCI && mciRankView()}
				{rankMCI && rankMVI && mviRankVIew()}
			</div>
		</TooltipClickAwayView>
	);
}

export default MPIComponent;
