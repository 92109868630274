import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

import { Typography, Link } from "@material-ui/core";

function HelpRequestDialog(props) {
	const open = props.open;
	const setClose = props.setClose;

	return (
		<SmarthopConfirmDialog open={!!open} title={"Support"} handleClose={() => setClose()}>
			<div className="flex flex-col min-w-360">
				<Typography className="text-14">Any questions about the platform, reach out by phone or email.</Typography>
				<Typography className={"mt-20 text-14 text-grey-700 max-w-450"}>
					Visit our resource portal and watch training videos to learn more about our platform.
				</Typography>
				<Link
					className="text-14 mt-2"
					to={"https://sites.google.com/smarthop.co/customer-resources"}
					onClick={() => {
						window.open(`https://sites.google.com/smarthop.co/customer-resources`, "_blank");
					}}
				>
					Open Resource Portal
				</Link>
				<Typography className="mt-20 text-14 text-grey-700">Office Hours</Typography>
				<Typography className="text-14 mt-2">8am to 5pm eastern time, Monday to Friday</Typography>
				<Typography className="mt-16 text-14 text-grey-700">Phone</Typography>
				<div className="flex flex-row">
					<Link
						className={"cursor-pointer tracking-wide text-16 tracking-wide text-blue-800 text-start justify-start"}
						component="button"
						underline="always"
						onClick={(event) => {
							event.stopPropagation();
							window.location.href = `tel:${"(844)467-1669"}`;
						}}
					>
						(844)467-1669
					</Link>
				</div>
				<Typography className="mt-16 text-14 text-grey-700">Email</Typography>
				<div className="flex flex-row">
					<Link
						className={"cursor-pointer tracking-wide text-16 tracking-wide text-blue-800 text-start justify-start"}
						component="button"
						underline="always"
						onClick={(event) => {
							event.stopPropagation();
							window.open(`mailto:${"support@smarthop.com"}`, "_blank");
						}}
					>
						support@smarthop.com
					</Link>
				</div>
				<Typography className="mt-5 text-12 text-grey-500">Response Time: 1h - 24h</Typography>
			</div>
		</SmarthopConfirmDialog>
	);
}

export default HelpRequestDialog;
