import _ from "@lodash";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo, useRef } from "react";

import SmarthopGridView from "./SmarthopGridView";
import SmarthopTableView from "./SmarthopTableView";
import SmarthopErrorView from "./SmarthopErrorView";
import SmarthopPaginationView from "./SmarthopPaginationView";
import SmarthopFiltersView from "./SmarthopFiltersView";
import SmarthopQuickActionView from "./SmarthopQuickActionView";
import { TABLE_OFFSET_DEFAULT, TABLE_OFFSET_VIEW_MODE } from "./consts";

import { setCache } from "app/store/tools/tableSlice";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

import { axios, createHeaders } from "app/services/requestUtil";
import {
	readURLParameters,
	replaceURLParameters,
	convertURLParamsToModel,
	convertModelToURLParams,
} from "app/main/utils/urlUtils";
import { setSwitchCache, getSwitchCache } from "app/main/utils/tableUtils";
import { createTrackOrPage } from "app/main/segment/segmentEvent";
import { FORM_LIST_EVENT } from "app/main/segment/segmentType";

// Utils
import {
	renderOtpRequiredMessage,
	OTP_ERROR_MESSAGE_REQUIRED,
	SMARTHOP_WALLET_SECTION_LABEL,
	UNAUTHORIZED_WALLET_ACCESS,
	WALLET_ACCESS,
	ONBOARDING_REQUIRED,
} from "app/main/utils/financeUtils";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

const HEADER_HEIGHT_PX = 52;
const FOOTER_HEIGHT_PX = 52;
const QUICK_ACTIONS_HEIGHT_PX = 36;
const FILTERS_SECTION_HEIGHT_PX = 64;
const PAGINATION_SECTION_HEIGHT_PX = 54;

export const useDebouncedEffect = (effect, deps, delay) => {
	useEffect(() => {
		let effectCallback;
		const handler = setTimeout(() => {
			effectCallback = effect();
		}, delay);
		return () => {
			if (effectCallback) effectCallback();
			clearTimeout(handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...(deps || []), delay]);
};

/**
 * SmarthopList component allows to load any type of data in a grid or list by using config files.
 */
function SmarthopList(props) {
	const dispatch = useDispatch();

	const urlKey = props.urlKey ?? "p";
	// eslint-disable-next-line
	const config = props.config ?? {};
	const content = config?.content;
	const mode = props.mode?.toUpperCase();
	const dataIds = props.dataIds;
	const isView = props.isView;
	const isSection = props.isSection;
	const size = props.size;
	const header = props.header;
	const headerHeight = props.headerHeight;
	const footer = props.footer;
	// Additional static filters
	const staticFilters = props.filters ?? {};
	const staticParams = props.params ?? {};
	const selectedItems = props.selectedItems;
	const multiselect = props.multiselect;
	const disableHeaderSelectAll = props.disableHeaderSelectAll;
	const selectionDisabled = props.selectionDisabled;
	const onSelectItems = props.onSelectItems;
	const onListLoaded = props.onListLoaded;
	const onListLoading = props.onListLoading;
	const onListLoadError = props.onListLoadError;
	const onListFiltersChanged = props.onListFiltersChanged;
	const onListOrderChanged = props.onListOrderChanged;
	const renderEmptyListView = props.renderEmptyListView;

	const [mobile, setMobile] = useState(window.innerWidth < 960);
	const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1600);
	const [screenHeight, setScreenHeight] = useState(parseInt(window.innerHeight / 10) * 10);
	const [loading, setLoading] = useState(false);
	const [init, setInit] = useState(false);

	const segmentKey = config?.segmentKey;
	useEffect(() => {
		console.log("[SmarthopList] Tracking list", segmentKey);
		createTrackOrPage(FORM_LIST_EVENT(segmentKey), { type: type }, "page", null);
		//eslint-disable-next-line
	}, [segmentKey]);

	// * Smarthop Wallet: OTP (2FA verification)
	const otp = Array.isArray(props.config?.otp) ? props.config?.otp : [];
	const hasSmarthopWalletToken = useSelector(({ wallet }) => !!wallet.smarthopWallet?.token);
	const [shouldRefresh, setShouldRefresh] = useState(null);

	const { urlGET, notUrlDataIds, missingUrlParams } = useMemo(() => {
		let urlGET = config.urlGET;
		let notUrlDataIds = {};
		let missingUrlParams = [];

		if (dataIds) {
			Object.keys(dataIds).forEach((key) => {
				if (urlGET?.includes(":" + key)) {
					urlGET = urlGET.replace(":" + key, dataIds[key]);
				} else {
					notUrlDataIds[key] = dataIds[key];
				}
			});
		}

		urlGET?.split("/").forEach((section) => {
			if (section.startsWith(":")) {
				missingUrlParams.push(section.substring(1));
			}
		});

		console.log(`[SmarthopList] genereted final URL`);
		return { urlGET, notUrlDataIds, missingUrlParams };
	}, [dataIds, config.urlGET]);

	// List type switcher
	const [typeOverride, setTypeOverride] = useState(
		config?.content?.type?.enabled ? getSwitchCache(segmentKey, config?.content?.type?.default) : null
	);
	const type = mobile ? "GRID" : typeOverride ?? mode;

	// Check if allowed to use cache
	const cacheDisableInit = config?.cache?.disableInit;

	// Check if pagination allowed
	const paginationEnabled = config?.content?.pagination?.enabled;

	// Grid supports only auto pagination
	const paginationAuto = paginationEnabled && type === "GRID" ? true : config?.content?.pagination?.auto?.enabled;

	// Grid supports only auto pagination on specific event
	const paginationAutoWhen = paginationEnabled ? config?.content?.pagination?.auto?.when : null;

	// Check if need to show pagination settings view
	const paginationViewVisible = paginationEnabled && !paginationAuto;
	const disableRowsPerPage = !!config?.content?.pagination?.disableRowsPerPage;

	// Check if need to show pagination settings view
	const filtersViewVisible = config?.content?.filters?.search || config?.content?.filters?.items?.length > 0;

	// Check if need to show filters groups
	const groupsViewVisible = config?.content?.groups?.items?.length > 0;

	// Check if need to show perion comparison
	const settingsViewVisible = config?.content?.settings?.items.length > 0 && !mobile;

	// Check if need to show presets
	const quickActionsViewVisible =
		!!config.content?.defaults?.items && config?.content?.defaults?.items?.length > 0 && !mobile;

	// Check if need to show export view
	const exportViewVisible = config?.content?.export?.fileName && !mobile;

	// Check if need to show export view
	const switchActionViewVisible = config?.content?.type?.enabled && !mobile;

	// Reading default configs
	const { defaultSort, defaultPage, defaultFiltres, defaultGroups, defaultActiveSort } = useMemo(() => {
		const defaultActiveSort = content?.order?.defaut?.key;
		let defaultSort = {};
		//Workaround to show the correct arrow on hover at the begining
		content?.order?.desc?.forEach((col) => (defaultSort[col] = "default-desc"));
		if (content?.order?.defaut?.key) defaultSort[content?.order?.defaut?.key] = content?.order?.defaut?.dir;
		const defaultPage = {
			size: mobile && !config?.alwaysTable ? 10 : content?.pagination?.defaut?.size ?? (paginationEnabled ? 10 : 999),
			offset: 0,
		};
		const defaultFiltres = content?.defaults?.initial?.filters ?? content?.filters?.default;
		const defaultGroups = content?.defaults?.initial?.groups ?? content?.groups?.defaut ?? {};

		return { defaultSort, defaultPage, defaultFiltres, defaultGroups, defaultActiveSort };
	}, [config, content, mobile, paginationEnabled]);

	// If bar is visible we need to decrease size of the table when it's opened full screen
	const barOffsetOn = useSelector(({ tools }) => tools.table.barOffsetOn);

	// Data revision can trigger reload of the date externally
	const dataRevision =
		useSelector(({ tools }) => {
			// If there are multiple listen events, they are all added together.
			if (Array.isArray(config?.listenEvent)) {
				const eventRevisions = config?.listenEvent?.map((listenEvent) => tools.revision[listenEvent] ?? 0);
				return eventRevisions.reduce((a, b) => a + b, 0);
			} else {
				return tools.revision[config?.listenEvent ?? "none"];
			}
		}) ?? 0;
	const urlRevision = useSelector(({ tools }) => tools.revision?.urlRevision);

	// Reading default settings config
	const settingsConfig = content?.settings;
	const defaultSettings = settingsConfig?.defaut ?? {};

	const { cacheKey } = useMemo(() => {
		let cacheKey = config?.cache?.key;
		if (dataIds && cacheKey) {
			Object.keys(dataIds).forEach((key) => {
				if (cacheKey?.includes(":" + key)) {
					cacheKey = cacheKey.replace(":" + key, dataIds[key]);
				}
			});
		}

		return { cacheKey };
	}, [dataIds, config?.cache?.key]);

	// Initializing using cache when allowed
	const cache = useSelector(({ tools }) => tools.table[cacheKey ?? urlGET]);
	const cacheResult = cache?.result;
	const cacheRequest = cache?.request;

	// Loaded search params from urlKey
	const urlRequest = useMemo(() => {
		return !isView ? convertURLParamsToModel(readURLParameters(), {})?.[urlKey] : {};
		// eslint-disable-next-line
	}, [urlRevision]);

	// Validate that saved cached matches to data saved into URL params
	const validaCache = useMemo(() => {
		return (
			!cacheDisableInit &&
			cacheRequest &&
			(_.isEqual(cacheRequest?.settings, urlRequest?.settings) || _.isEqual(cacheRequest?.settings, defaultSettings)) &&
			(_.isEqual(cacheRequest?.filters, urlRequest?.filters) || _.isEqual(cacheRequest?.filters, defaultFiltres)) &&
			(_.isEqual(cacheRequest?.staticFilters, urlRequest?.staticFilters) ||
				_.isEqual(cacheRequest?.staticFilters, staticFilters)) &&
			(_.isEqual(cacheRequest?.staticParams, urlRequest?.staticParams) ||
				_.isEqual(cacheRequest?.staticParams, staticParams)) &&
			(_.isEqual(cacheRequest?.sort, urlRequest?.sort) || _.isEqual(cacheRequest?.sort, defaultSort)) &&
			(_.isEqual(cacheRequest?.activeSort, urlRequest?.activeSort) ||
				_.isEqual(cacheRequest?.activeSort, defaultActiveSort)) &&
			(_.isEqual(cacheRequest?.page, urlRequest?.page) || _.isEqual(cacheRequest?.page, defaultPage))
		);
		// eslint-disable-next-line
	}, [urlRequest]);

	const getURLParams = () => {
		return {
			settings: urlRequest?.settings ?? defaultSettings,
			filters: urlRequest?.filters ?? defaultFiltres,
			sort: urlRequest?.sort ?? defaultSort,
			activeSort: urlRequest?.activeSort ?? defaultActiveSort,
			groups: urlRequest?.groups ?? defaultGroups,
			page: type === "GRID" ? defaultPage : urlRequest?.page ?? defaultPage,
		};
	};

	// Request data
	let [requestData, setRequestData] = useState(
		validaCache
			? cacheRequest
			: {
					staticFilters: staticFilters,
					staticParams: staticParams,
					revision: 0,
					mobile: window.innerWidth < 960,
					typeOverride: typeOverride,
					...getURLParams(),
			  }
	);

	// Result data
	const [resultData, setResultData] = useState(
		validaCache
			? cacheResult
			: {
					// Marked as init on first load
					initialized: false,
					// Error present when reques fails
					errors: null,
					// Returned data
					items: null,
					page: { total: 0 },
					metadata: {},
					// Set of all items by key, needed to avoid duplicates
					entities: {},
					// Keeping group to know if data contails new visilbe columns
					groups: {},
					// Keeping dynamic filters that were used to get search result
					filters: {},
					// Keeping dynamic filters that were used to get search result
					settings: {},
					// Keeping external filters that were used to get search result
					staticFilters: {},
					// Keeping external params that were used to get search result
					staticParams: {},
					// Keeping revisons to know if we need to reload data when navigating back
					dataRevision: -1,
			  }
	);
	const resultWarning = resultData?.warning;
	const resetScrollRequired = !resultData.items && !switchActionViewVisible;

	const [initFilters, setInitFilters] = useState(requestData.filters);
	const [initSettings, setInitSettings] = useState(requestData.settings);
	const [initGroups, setInitGroups] = useState(requestData.groups);

	const errorHandler = (error) => {
		let response = error?.response;
		let errors = response?.data?.errors?.map
			? response?.data?.errors
			: [
					{
						type: "generic",
						message: `Something went wrong, please try again... (Internal error: ${error.message})`,
					},
			  ];

		onListLoadError?.(errors);
		setLoading(false);
		setResultData({ ...resultData, errors: errors, items: null });
	};

	const onReloadClick = () => {
		console.log("[SmarthopList] clearing error, need to reload data...");
		setResultData({
			...resultData,
			initialized: false,
			errors: null,
			items: null,
		});
		setRequestData({
			...requestData,
			revision: requestData.revision + 1,
		});
	};

	// Checing if screen size change to dynamically adjust size of teh table
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 960 && mobile) {
				setMobile(false);
			}

			if (window.innerWidth < 1600 && !smallScreen) {
				setSmallScreen(true);
			} else if (window.innerWidth > 1600 && smallScreen) {
				setSmallScreen(false);
			}

			let innerHeight = window.innerHeight;
			innerHeight = parseInt(innerHeight / 10) * 10;
			if (innerHeight !== screenHeight) setScreenHeight(innerHeight);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	// Refresh page if page size is changed
	useEffect(() => {
		if (requestData.mobile === mobile && (requestData.typeOverride ?? "") === (typeOverride ?? "")) {
			return;
		}

		console.log(`[SmarthopList] screen size mode changed, mobile=${mobile}, need to reload data...`);
		setLoading(true);
		setResultData({
			...resultData,
			items: null,
			initialized: false,
		});
		setRequestData({
			...requestData,
			mobile: mobile,
			typeOverride: typeOverride,
			page: {
				...requestData.page,
				size: mobile && !config?.alwaysTable ? 10 : config?.content?.pagination?.defaut?.size ?? 10,
				offset: 0,
			},
			groups: {},
		});
		// eslint-disable-next-line
	}, [mobile, typeOverride]);

	// Refresh page if static filters changes
	useEffect(() => {
		if (_.isEmpty(staticFilters) || _.isEqual(staticFilters, requestData.staticFilters)) {
			return;
		}

		console.log(`[SmarthopList] exteranl filter changed, need to reload data...`);
		setLoading(true);
		setRequestData({
			...requestData,
			// Overriding all filter provided externally
			staticFilters: staticFilters,
			page: { ...requestData.page, offset: 0 },
		});
		// eslint-disable-next-line
	}, [staticFilters]);

	// Refresh page if static filters changes
	useEffect(() => {
		if (_.isEmpty(staticParams) || _.isEqual(staticParams, requestData.staticParams)) {
			return;
		}

		console.log(`[SmarthopList] external params changed, need to reload data...`);
		setLoading(true);
		setRequestData({
			...requestData,
			// Overriding all params provided externally
			staticParams: staticParams,
			page: { ...requestData.page, offset: 0 },
		});
		// eslint-disable-next-line
	}, [staticParams]);

	// Refresh page if smarthop wallet token changed
	useEffect(() => {
		if (otp?.includes(WALLET_ACCESS) && !!shouldRefresh !== hasSmarthopWalletToken) {
			console.log(`[SmarthopList] ${SMARTHOP_WALLET_SECTION_LABEL} token changed, need to reload data...`);
			onReloadClick();
			setShouldRefresh(hasSmarthopWalletToken);
		}
		// eslint-disable-next-line
	}, [hasSmarthopWalletToken, otp, shouldRefresh]);

	// only trigger if URL changes, not on init
	const firstRun = useRef(false);
	useEffect(() => {
		if (!firstRun.current) {
			firstRun.current = true;
			return;
		}

		const updatedParams = getURLParams();
		setRequestData((curRequestData) => {
			return {
				...curRequestData,
				...updatedParams,
			};
		});
		setInitFilters(updatedParams.filters);
		setInitGroups(updatedParams.groups);
		setInitSettings(updatedParams.settings);
		// eslint-disable-next-line
	}, [urlRequest]);

	// Reload data in the beginning and in case if errors are set to null, when errors are set
	// to null it means there was a re-try button click
	useDebouncedEffect(
		() => {
			// * Smarthop Wallet: OTP (2FA verification)
			if (otp?.includes(WALLET_ACCESS) && !hasSmarthopWalletToken) {
				return errorHandler(OTP_ERROR_MESSAGE_REQUIRED);
			}

			let unregistered = false;
			if (resultData.errors || missingUrlParams?.length > 0) {
				setLoading(false);
				return;
			}

			setInit(true);
			if (!init && resultData?.dataRevision === dataRevision && resultData?.items?.length > 0) {
				console.log(`[SmarthopList] ignor init load, data already loaded`);
				return;
			}

			const headers = createHeaders();

			let processedRequestFilters = {};
			Object.keys(requestData?.filters ?? {}).forEach((key) => {
				if (!key.includes("__view") && key !== "search") {
					processedRequestFilters[key] = requestData.filters[key];
				}
			});

			const revChanged = resultData.dataRevision !== dataRevision;
			const needToReloadAll =
				paginationAuto && (revChanged || (requestData.page.offset > 0 && !resultData?.items?.length));

			let request = {
				...notUrlDataIds,
				sortOrder: requestData.sort[requestData.activeSort],
				sortBy: requestData.activeSort,
				offset: needToReloadAll ? 0 : requestData.page.offset,
				limit: needToReloadAll
					? resultData?.items?.length > 0
						? resultData?.items?.length + 1
						: requestData.page.size
					: requestData.page.size,
				search: requestData.filters?.search ?? "",
				filters: { ...(processedRequestFilters ?? {}), ...(requestData.staticFilters ?? {}) },
				groups: { ...(requestData.groups ?? {}) },
				settings: requestData.settings ?? {},
				...(requestData.staticParams ?? {}),
			};

			// Protection to not load more in case if scrolling up and no changes in data revision
			if (
				paginationAuto &&
				request.offset < resultData?.items?.length &&
				resultData?.items?.length > 0 &&
				request.offset !== 0 &&
				resultData.dataRevision === dataRevision
			) {
				setLoading(false);
				return;
			}

			// Scrolled to the very end and no changes in data revision
			if (
				paginationAuto &&
				resultData.page.total !== 0 &&
				resultData.page.total !== -1 &&
				request.offset >= resultData.page.total &&
				resultData.dataRevision === dataRevision
			) {
				setLoading(false);
				return;
			}

			const __COMPARISON = request.settings?.__COMPARISON;
			const comparisonItem = config?.content?.settings?.items?.find((item) => item.key === "__COMPARISON");

			console.log(
				`[SmarthopList] GET: loading data... params: sortBy=${request.sortBy}, sortOrder=${request.sortOrder}, ` +
					`offset=${request.offset}, limit=${request.limit}, comparions=${__COMPARISON ? "ON" : "OFF"}`,
				request
			);

			onListLoading?.();
			(async () => {
				try {
					const res = await axios.get(urlGET, { params: request, headers });
					if (unregistered) return;

					console.log(`[SmarthopList] GET: loaded data, url=${urlGET} length=${res.data?.items?.length}`);
					let newResultData = { ...resultData };

					// Marking as initialized
					newResultData.initialized = true;
					// Save loaded data revision
					newResultData.dataRevision = dataRevision;
					// If total is not provided assuming pagination is
					// not supported by backend, assuming returned all items
					newResultData.page = res?.data?.page ?? { total: res.data.items?.length ?? 0 };
					// Saving result metadata
					newResultData.metadata = res?.data?.metadata ?? {};
					// Saving request settings
					newResultData.settings = request.settings;
					// Saving request groups
					newResultData.groups = request.groups;
					// Saving request filters
					newResultData.filters = request.filters;
					newResultData.staticFilters = request.staticFilters;
					newResultData.staticParams = request.staticParams;
					// Process data

					let needScrollReset = false;
					if (paginationAuto && newResultData?.items?.length > 0 && request.offset !== 0) {
						newResultData.items = [...(newResultData?.items ?? [])];
						newResultData.entities = { ...(newResultData?.entities ?? {}) };

						res.data.items?.forEach((item) => {
							let key = item[config?.idKey];
							if (!newResultData.entities[key]) {
								newResultData.entities[key] = item;
								newResultData.items.push(item);
							} else {
								console.warn(`[SmarthopList] GET: load entity which already existis, key=${key}`);
							}
						});
					} else {
						const paginationResetScroll = paginationEnabled ? config?.content?.pagination?.resetScroll : false;
						if (
							!revChanged &&
							paginationResetScroll &&
							((config?.content.table?.variant === "extra-skinny" && request.limit > 20) ||
								(config?.content.table?.variant !== "extra-skinny" && request.limit > 10))
						) {
							// Workaround to reset scroll to original position,
							// applying to only cases when large pages is shown
							console.log(`[SmarthopList] Scrolling to top...`, config?.content.table?.variant);
							needScrollReset = true;
						}
						// Save Items
						newResultData.items = res.data.items;
						newResultData.entities = {};
						// Process items and save entities by ids
						res.data.items?.forEach((item) => {
							let key = item[config?.idKey];
							newResultData.entities[key] = item;
						});
						newResultData.period = res?.data?.period;
						newResultData.warning = res?.data?.warning;
					}

					newResultData.comparedEntities = {};
					if (__COMPARISON) {
						// Reformating filters to fetch data from similar period
						const entityIds = Object.keys(newResultData.entities);
						const comparisonFilters = comparisonItem?.filterBuilder?.(newResultData.filters, entityIds);
						if (!comparisonFilters) {
							throw new Error("Comparison setting did not generate new filters");
						}
						// Using new generated filters to fetch data for comparable period
						const comparisonRequest = { ...request };
						comparisonRequest.filters = comparisonFilters;
						// Fetching data
						const compariosnRes = await axios.get(urlGET, { params: comparisonRequest, headers });
						if (unregistered) return;
						// Saving fetched entities
						compariosnRes.data.items?.forEach((item) => {
							let key = item[config?.idKey];
							newResultData.comparedEntities[key] = item;
						});
						entityIds.forEach((id) => {
							if (!newResultData.comparedEntities[id]) newResultData.comparedEntities[id] = {};
						});
						newResultData.comparablePeriod = compariosnRes?.data?.period;
					}

					if (needScrollReset) {
						setResultData({ ...resultData, items: null });
					}

					onListLoaded?.(newResultData);
					setLoading(false);
					setResultData(newResultData);

					// Even if cache is disabled we need to save values of each table to cache,
					// that would allow us to access metadate of that tabke in other componetns
					dispatch(setCache({ key: cacheKey ?? urlGET, url: urlGET, result: newResultData, request: requestData }));
				} catch (error) {
					if (unregistered) return;
					console.warn(`[SmarthopList] GET: failed to load data, url=${urlGET}`, error);

					errorHandler(error);
				}
			})();

			// Updating URL after we requested data, no need to way to response to update URL
			// Cache initialization is not going to work if URL params are empty so disabling URL
			if (!cacheDisableInit) {
				let urlParams = {};
				const rd = requestData;
				if (!_.isEqual(rd.settings, defaultSettings) && !_.isEmpty(rd.settings)) urlParams.settings = rd.settings;
				if (!_.isEqual(rd.filters, defaultFiltres) && !_.isEmpty(rd.filters)) urlParams.filters = rd.filters;
				if (!_.isEqual(rd.sort, defaultSort) && !_.isEmpty(rd.sort)) urlParams.sort = rd.sort;
				if (!_.isEqual(rd.activeSort, defaultActiveSort) && !_.isEmpty(rd.activeSort))
					urlParams.activeSort = rd.activeSort;
				if (!_.isEqual(rd.page, defaultPage) && !_.isEmpty(rd.page)) urlParams.page = rd.page;
				if (!_.isEqual(rd.groups, defaultGroups) && !_.isEmpty(rd.groups)) urlParams.groups = rd.groups;
				if (!isView) {
					replaceURLParameters(convertModelToURLParams({ [urlKey]: _.isEmpty(urlParams) ? null : urlParams }, {}));
				}
			}

			return () => {
				unregistered = true;
			};
		},
		[requestData, dataRevision],
		100
	);

	const unselectAllView = props.multiselect && (
		<div className={"flex pr-32 pt-1"}>
			<Typography color="inherit" className="py-6 font-normal text-13 whitespace-nowrap">
				Selected {selectedItems?.length ?? 0} items
			</Typography>
			<Link
				to="#"
				className="cursor-pointer ml-10"
				color="primary"
				onClick={() => {
					if (onSelectItems) onSelectItems("CLEAR", []);
				}}
			>
				<Typography className="-ml-4 py-6 font-normal text-13 whitespace-nowrap">Clear All</Typography>
			</Link>
		</div>
	);

	if (!props?.config) {
		return null;
	}

	if (!segmentKey) {
		let message = "You need to pass it a segmentKey in order to create a list or grid";
		return <SmarthopErrorView message={message} />;
	}

	// Check if there is more items to load
	const hasMore =
		paginationAuto &&
		resultData.page?.total > 0 &&
		resultData?.items?.length > 0 &&
		resultData?.items?.length < resultData.page?.total;

	if (missingUrlParams.length > 0) {
		const paramsMissing = missingUrlParams.join(", ");
		const message = `Component GET url '${config.urlGET}' required mandatory params [${paramsMissing}] which are missing in 'dataIds'`;
		return <SmarthopErrorView message={message} />;
	}

	const hasActionsSection = quickActionsViewVisible && !mobile;
	const hasFilterSection =
		filtersViewVisible || groupsViewVisible || settingsViewVisible || exportViewVisible || switchActionViewVisible;
	const hasBottomSection = paginationViewVisible;

	const fixHeight = config?.content?.table?.fixHeight;
	const diffHeight =
		config?.content?.table?.diffHeight ??
		(isView ? TABLE_OFFSET_VIEW_MODE + (isView === 2 ? 48 : 0) : TABLE_OFFSET_DEFAULT);
	const diffWidth = content?.table?.diffWidth;
	const staticHeight = content?.table?.staticHeight;

	let contentStyleWidth = diffWidth > 0 ? window.innerWidth - diffWidth : null;
	let contentStyleMax = props.isSection
		? undefined
		: diffHeight > 0
		? screenHeight - diffHeight
		: fixHeight > 1
		? parseInt(fixHeight)
		: fixHeight > 0
		? parseInt(screenHeight * fixHeight)
		: staticHeight
		? staticHeight
		: undefined;

	let contentTopOffset =
		(header ? headerHeight ?? HEADER_HEIGHT_PX : 0) +
		(hasActionsSection ? QUICK_ACTIONS_HEIGHT_PX : 0) +
		(hasFilterSection ? FILTERS_SECTION_HEIGHT_PX : 0);

	let contentBottomOffset = (hasBottomSection ? PAGINATION_SECTION_HEIGHT_PX : 0) + (footer ? FOOTER_HEIGHT_PX : 0);

	if (type === "TABLE" && barOffsetOn && !isSection && !isView) {
		contentStyleMax = contentStyleMax - 32;
	}

	let contentStyleHeight = type !== "TABLE" || isSection ? 0 : contentStyleMax - contentTopOffset - contentBottomOffset;

	let contentStyleHeightValue;
	if (size === "FULL") {
		contentStyleHeightValue = `calc(100% - ${contentTopOffset}px - ${contentBottomOffset}px - 4px)`;
	} else {
		contentStyleHeightValue = contentStyleHeight ? contentStyleHeight + "px" : undefined;
	}

	if (resultData.errors || !resultData.initialized) {
		return (
			<motion.div
				className="flex w-full"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.5 } }}
				style={{
					paddingTop: contentTopOffset,
					height: contentStyleMax,
					paddingBottom: contentTopOffset + contentBottomOffset,
				}}
			>
				<div className="flex-col h-full w-full flex items-center justify-center">
					{resultData?.errors ? (
						<SmarthopErrorView
							message={resultData.errors[0]?.message ?? "Unknown Error"}
							otpButtonComponent={resultData.errors[0]?.code === UNAUTHORIZED_WALLET_ACCESS && renderOtpRequiredMessage}
							onboardingButtonComponent={resultData.errors[0]?.code === ONBOARDING_REQUIRED}
							onReloadClick={onReloadClick}
						/>
					) : (
						<Typography color="primary" className="text-12 ml:text-13 font-light mx-20">
							Loading...
						</Typography>
					)}
				</div>
			</motion.div>
		);
	}

	let quickActionsView;
	let settingsView;
	let filtersView;
	let groupsView;
	let contentView;
	let paginationView;
	let exportView;
	let switchActionView;

	if (filtersViewVisible) {
		filtersView = (
			<SmarthopFiltersView
				isView={isView}
				mobile={mobile}
				dataIds={dataIds}
				config={config?.content?.filters}
				initValues={initFilters}
				defaultValues={defaultFiltres}
				onChange={(filters) => {
					console.log("[SmarthopList] filters changes, need to reload data...");
					onListFiltersChanged?.(filters);
					setLoading(true);
					setRequestData({
						...requestData,
						filters: filters,
						page: { ...requestData.page, offset: 0 },
					});
				}}
			/>
		);
	}

	if (groupsViewVisible) {
		groupsView = (
			<SmarthopFiltersView
				isView={isView}
				mobile={mobile}
				dataIds={dataIds}
				config={config?.content?.groups}
				initValues={initGroups}
				defaultValues={defaultGroups}
				groupsView={true}
				onChange={(groups) => {
					console.log("[SmarthopList] groups changes, need to reload data...");
					setLoading(true);
					setRequestData({
						...requestData,
						groups: groups,
					});
				}}
			/>
		);
	}

	if (quickActionsViewVisible) {
		quickActionsView = (
			<SmarthopQuickActionView
				isView={isView}
				requestData={requestData}
				config={config.content?.defaults}
				onChange={(config) => {
					console.log("[SmarthopList] preset tab filter clicked, need to change filters...", config);
					onListFiltersChanged?.(config.filters);
					setLoading(true);
					setRequestData({
						...requestData,
						filters: config.filters ?? {},
						groups: config.groups ?? {},
						sort: config.sort ?? {},
						page: config.page ?? {},
					});

					setInitFilters(
						config.filters
							? { ...config.filters, revison: initFilters?.revison > 0 ? initFilters?.revison + 1 : 1 }
							: {}
					);
					setInitGroups(
						config.groups ? { ...config.groups, revison: initGroups?.revison > 0 ? initGroups?.revison + 1 : 1 } : {}
					);
				}}
			/>
		);
	}

	if (settingsViewVisible) {
		settingsView = (
			<SmarthopFiltersView
				isView={isView}
				config={config?.content?.settings}
				initValues={initSettings}
				defaultValues={defaultSettings}
				settingsView={true}
				onChange={(config) => {
					console.log("[SmarthopList] comparison clicked, need to change filters...", config);
					setLoading(true);
					setRequestData({
						...requestData,
						settings: { ...requestData.settings, ...config },
					});
				}}
			/>
		);
	}

	if (exportViewVisible) {
		exportView = (
			<SmarthopFiltersView
				isView={isView}
				config={config?.content?.export}
				exportView={true}
				onExport={() => {
					dispatch(
						openLoadedFormDialog({
							viewId: "EXPORT_LIST",
							dataIds: {
								notUrlDataIds,
								resultData,
								requestData,
								urlGET,
								content: _.cloneDeep(config?.content),
							},
						})
					);
				}}
			/>
		);
	}

	if (switchActionViewVisible) {
		switchActionView = (
			<SmarthopFiltersView
				isView={isView}
				config={config?.content?.type}
				initValues={{ current: typeOverride }}
				defaultValues={{ current: typeOverride }}
				switchView={true}
				onSwitch={() => {
					console.log("[SmarthopList] Switch changes, change view...");
					const newType = type === "GRID" ? "TABLE" : "GRID";
					setTypeOverride(newType);
					setSwitchCache(segmentKey, newType);
				}}
			/>
		);
	}

	const handleAutoPageLoad = () => {
		if (paginationAuto && resultData?.items?.length > 0 && resultData?.items?.length >= requestData?.page?.size) {
			console.log("[SmarthopList] scrolled to the end, auto pagination ON, need to reload data...");
			let oldPage = requestData.page;
			let newPage = { ...oldPage, offset: resultData?.items?.length };
			setRequestData({ ...requestData, page: newPage });
		}
	};

	if (resetScrollRequired) {
		// Workaround to reset scroll to original position
		contentView = null;
	} else if (type === "TABLE") {
		contentView = (
			<SmarthopTableView
				isView={isView}
				segmentKey={segmentKey}
				isSection={isSection}
				hasActionsSection={hasActionsSection}
				hasFilterSection={hasFilterSection}
				content={config?.content}
				contentIdKey={config?.idKey}
				data={resultData}
				dataIds={dataIds}
				sorting={requestData.sort}
				activeSort={requestData.activeSort}
				groups={resultData.groups}
				loading={loading}
				hasMore={hasMore}
				mobile={mobile}
				smallScreen={smallScreen}
				multiselect={multiselect}
				disableHeaderSelectAll={disableHeaderSelectAll}
				selectedItems={selectedItems}
				onSelectItems={onSelectItems}
				renderEmptyListView={renderEmptyListView}
				showSummary={resultData.settings?.__SUMMARY}
				isSelectionAllowed={selectionDisabled}
				onOrderChange={(key) => {
					console.log(`[SmarthopList] sort orded changed, column=${key} need to reload data...`);
					let currentSort = requestData.sort[key];
					//Reset other columns sorting to default
					let newSort = { ...defaultSort };
					delete newSort[content?.order?.defaut?.key];
					const sortDirection = currentSort === "default-desc" ? "desc" : currentSort === "asc" ? "desc" : "asc";
					newSort[key] = sortDirection;

					if (onListOrderChanged) onListOrderChanged(newSort);
					setLoading(true);
					setRequestData({
						...requestData,
						activeSort: key,
						sort: newSort,
						page: { ...requestData.page, offset: 0 },
					});
				}}
				onLoadMore={() => {
					handleAutoPageLoad();
				}}
				onCellChange={() => {
					setRequestData({ ...requestData, revision: requestData.revision + 1 });
				}}
			/>
		);
	} else if (type === "GRID") {
		contentView = (
			<SmarthopGridView
				isView={isView}
				isSection={isSection}
				hasActionsSection={hasActionsSection}
				hasFilterSection={hasFilterSection}
				content={config.content}
				contentIdKey={config?.idKey}
				data={resultData}
				loading={loading}
				hasMore={hasMore}
				moreWhen={paginationAutoWhen}
				dataIds={dataIds}
				mobile={mobile}
				renderEmptyListView={renderEmptyListView}
				onLoadMore={() => {
					handleAutoPageLoad();
				}}
			/>
		);
	} else {
		let message = "List view mode not supported";
		return <SmarthopErrorView message={message} />;
	}

	if (!!resultData && !resultData.items?.length) {
		const emptyView =
			!!resultData.initialized && renderEmptyListView ? renderEmptyListView?.(resultData, dataIds) : "No Data Found";

		contentView = (
			<motion.div
				className="flex w-full h-full items-center justify-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.5 } }}
				style={{ paddingBottom: contentTopOffset + "px" }}
			>
				<div className="flex-col w-full flex items-center justify-center">
					<Typography color="primary" className="text-12 ml:text-13 font-light mx-20" component={"div"}>
						{emptyView}
					</Typography>
				</div>
			</motion.div>
		);
	}

	paginationView = (
		<SmarthopPaginationView
			disableRowsPerPage={disableRowsPerPage}
			isView={isView}
			period={resultData.period}
			countTotal={resultData.page.total}
			countPerPage={requestData.page.size}
			pageNumber={parseInt(requestData.page.offset / requestData.page.size)}
			onChangePage={(pageNumber) => {
				console.log(`[SmarthopList] page number changed, number=${pageNumber} need to reload data...`);
				let oldPage = requestData.page;
				let newPage = { ...oldPage, offset: pageNumber * oldPage.size };
				setLoading(true);
				setRequestData({ ...requestData, page: newPage });
			}}
			onChangeRowsPerPage={(countPerPage) => {
				console.log(`[SmarthopList] count per page changed, count=${countPerPage} need to reload data...`);
				let oldPage = requestData.page;
				let newPage = { ...oldPage, offset: 0, size: countPerPage };
				setLoading(true);
				setRequestData({ ...requestData, page: newPage });
			}}
		/>
	);

	let filteredTime;
	let period = resultData?.period;
	if (period?.start && resultData?.period?.end) {
		filteredTime = "From " + period.start + " Till " + period.end + (period.timezone ? ` (${period.timezone})` : "");
	} else if (period?.start) {
		filteredTime = "From " + period.start + (period.timezone ? ` (${period.timezone})` : "");
	} else if (period?.end) {
		filteredTime = "Till " + period.end + (period.timezone ? ` (${period.timezone})` : "");
		if (period.timezone) {
		}
	}

	return (
		<div className="w-full flex flex-col items-center justify-start">
			{header && (
				<div
					key="header"
					className="flex flex-col w-full items-start justify-center overflow-hidden"
					style={{ height: headerHeight ?? HEADER_HEIGHT_PX + "px" }}
				>
					{header}
				</div>
			)}
			{quickActionsViewVisible && !mobile && (
				<div
					key="quickActionsView"
					className="w-full flex flex-col items-center mb-4"
					style={{ height: QUICK_ACTIONS_HEIGHT_PX + "px" }}
				>
					{quickActionsView}
				</div>
			)}
			{filtersViewVisible ||
			groupsViewVisible ||
			quickActionsViewVisible ||
			settingsViewVisible ||
			exportViewVisible ||
			switchActionViewVisible ? (
				<div
					key="groupsFiltersViewVisible"
					className={"w-full flex justify-start flex-row" + (type === "GRID" ? " pl-0 md:pl-2 pr-0 md:pr-2 " : "")}
					style={{ height: FILTERS_SECTION_HEIGHT_PX + "px" }}
				>
					{settingsViewVisible && (
						<div key="settingsView" className="flex-col pt-2 md:pt-8">
							{settingsView}
						</div>
					)}
					{switchActionViewVisible && (
						<div key="switchActionView" className="flex-col pt-2 -mt-7 mb-4 md:mt-0 md:pt-8">
							{switchActionView}
						</div>
					)}
					{exportViewVisible && (
						<div key="exportView" className="flex-col pt-2 -mt-7 mb-4 md:mt-0 md:pt-8">
							{exportView}
						</div>
					)}
					{filtersViewVisible && (
						<div key="filtersView" className="w-full flex flex-col pr-2 -mt-8 mb-4 md:mt-0 md:mb-0">
							{filtersView}
						</div>
					)}
					{groupsViewVisible && !mobile && (
						<div key="groupsView" className="flex-col pt-2 md:pt-8 -ml-3">
							{groupsView}
						</div>
					)}
				</div>
			) : null}
			<div
				className={
					"w-full flex flex-col " +
					(loading ? " opacity-50 " : "") +
					(size === "FULL" ? "" : contentStyleWidth ? " overflow-scroll " : "")
				}
				style={{
					height: contentStyleHeightValue,
					width: contentStyleWidth ? contentStyleWidth + "px" : "100%",
				}}
			>
				{contentView}
			</div>
			{paginationViewVisible && (
				<div
					key={"pages"}
					className="w-full flex flex-row items-center justify-end"
					style={{ height: PAGINATION_SECTION_HEIGHT_PX + "px" }}
				>
					{resultData?.items?.length > 0 && (
						<>
							{resultWarning && (
								<Typography color="primary" className="text-orange-800 text-13 mx-20 mt-3">
									<Icon className="text-16  -mb-3 mr-4">warning</Icon>
									{resultWarning}
								</Typography>
							)}
							{filteredTime && (
								<Typography color="primary" className="text-grey text-13 mx-20 mt-3">
									Period: {filteredTime}
								</Typography>
							)}
							{unselectAllView}
							{paginationView}
						</>
					)}
				</div>
			)}
			{footer && (
				<div
					key="header"
					className="w-full flex flex-col items-start justify-center"
					style={{ height: FOOTER_HEIGHT_PX + "px" }}
				>
					{footer}
				</div>
			)}
		</div>
	);
}

export default SmarthopList;
