import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { capitalizeFirstLetter } from "app/main/utils/tableUtils";

const buildTooltip = (enabled, icon, description, classes) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
			title={<Typography className={"text-13 text-black py-10 px-10"}>{description}</Typography>}
		>
			<Icon className={"text-20 min-w-28 " + (classes?.icon ?? "") + (enabled ? "text-primary" : "text-grey")}>
				{icon}
			</Icon>
		</Tooltip>
	);
};

const filterEquipment = {
	key: "equipment",
	type: "select",
	label: "Equipment",
	defaultValue: "__NOT_SELECTED__",
	options: [
		{
			value: "__NOT_SELECTED__",
			label: "Not Selected",
		},
		{
			value: "VAN",
			label: "Van",
			translate: "VAN",
		},
		{
			value: "REEFER",
			label: "Reefer",
			translate: "REEFER",
		},
		{
			value: "FLATBED",
			label: "Flatbed",
		},
		{
			value: "PO",
			label: "Power Only",
		},
	],
};

const carrierTrucksListBuilder = ({ source, isSubaccount } = {}) => ({
	urlGET:
		source === "USER"
			? "api/profile/carriers/:carrierId/investor/:userId/trucks"
			: source === "DRIVER"
			? "api/profile/carriers/:carrierId/drivers/:driverId/trucks"
			: source === "DISPATCHER"
			? "api/profile/carriers/:carrierId/users/:userId/trucks"
			: "api/profile/carriers/:carrierId/trucks",
	idKey: "_id",
	listenEvent: "profileRevision",
	segmentKey: "CARRIER_TRUCKS",
	content: {
		grid: {
			tilesPerRow: 2,
			itemsPerRow: 2,
			layout: [["truck__view"], ["carrier"], ["trailer", "driver__view.label"], ["equipment", "access"]],
		},
		order: {
			defaut: {
				key: "_id",
				dir: "desc",
			},
		},
		table: {
			variant: "skinny",
			orderBy: ["driver__view.label", "equipment"],
			layout: ["table_avatar", "carrier", "truck__view", "truck_trailer", "driver", "access"],
		},
		isActive: (item) => item.active,
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		defaults:
			source === "DISPATCHER"
				? {}
				: {
						initial: {
							filters: {
								truck_active: "ACTIVE",
								equipment: "__NOT_SELECTED__",
							},
						},
						items: [
							{
								label: "Active",
								description: "Active",
								filters: {
									truck_active: "ACTIVE",
								},
							},
							{
								label: "Archived",
								description: "Archived",
								filters: {
									truck_active: "INACTIVE",
								},
							},
						],
				  },

		filters:
			source !== "CARRIER"
				? { search: true, countVisible: 0, items: [filterEquipment] }
				: {
						search: true,
						countVisible: 0,
						items: [
							{
								key: "truck_active",
								type: "select",
								label: "Status",
								defaultValue: "__NOT_SELECTED__",
								options: [
									{
										value: "__NOT_SELECTED__",
										label: "Not Selected",
									},
									{
										label: "Active",
										value: "ACTIVE",
									},
									{
										label: "Archived",
										value: "INACTIVE",
									},
								],
							},
							filterEquipment,
						],
				  },
		items: [
			{
				key: "table_avatar",
				type: "component",
				table: { width: "30px" },
				builder: (item) => {
					return (
						<Avatar src={null} alt={""} className={"bg-white border-1 border-grey-400"}>
							<Icon className="text-20 text-primary">local_shipping</Icon>
						</Avatar>
					);
				},
			},

			...(!isSubaccount && source === "DISPATCHER"
				? [
						{
							key: "carrier",
							type: "Text",
							label: "Carrier",
							table: { width: "23%" },
							builder: (item) => item.carrier__view.label,
						},
				  ]
				: [{}]),

			{
				key: "truck__view",
				type: "truck__view",
				label: "Truck",
				grid: { headerTitle: true, valuePefix: "ID " },
				viewConfig: {
					showTrailerSize: true,
					variant: "spacious",
				},
			},
			{
				key: "truck_trailer",
				label: "Trailer",
				required: true,
				table: { width: "28%" },
				edit: {
					urlPUT: "api/profile/carriers/:carrierId/trucks/:truckId/field",
					triggerEvent: "profileRevision",
					field: {
						key: "truck_trailer",
						required: true,
						type: "autocomplete",
						classes: { cell: "pr-16" },
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers/:carrierId/trailers",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: {
								options_active_only: true,
							},
							disableClearable: (val) => !val || val === "None" || val === "Power Only",
							actionOptions: [
								{
									value: null,
									label: "Power Only",
									metadata: {
										equipment: "PO",
									},
									selectable: true,
								},
								{
									value: "ADD_NEW_TRAILER",
									label: "Add New Trailer",
									icon: "add",
									dialog: {
										viewId: "TRAILER_VIEW",
										mode: "CREATE",
										dataIds: { mode: "CREATE" },
									},
								},
							],
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: {
								truck_trailer: item.truck_trailer ? item.truck_trailer : null,
								truck_trailer__view: {
									label: item.truck_trailer__view?.label ? item.truck_trailer__view?.label : "Power Only",
									value: item.truck_trailer ? item.truck_trailer : null,
								},
							},
							dataIds: {
								carrierId: dataIds.carrierId,
								truckId: item._id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "driver",
				type: "text",
				label: "Driver",
				table: { width: "28%" },
				edit: {
					urlPUT: "api/profile/carriers/:carrierId/trucks/:truckId/field",
					triggerEvent: "profileRevision",
					field: {
						key: "driver",
						required: true,
						type: "autocomplete",
						autocomplete: {
							provider: "smarthop",
							url: "api/profile/carriers/:carrierId/drivers",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: { options_active_only: true },
							disableClearable: (val) => !val || val === "None",
							actionOptions: [
								{
									value: null,
									label: "None",
									selectable: true,
								},
								{
									value: "ADD_NEW_DRIVER",
									label: "Add New Driver",
									icon: "add",
									dialog: {
										viewId: "CARRIER_USER_EDIT_VIEW",
										mode: "CREATE",
										dataIds: { origin: "DRIVER", mode: "CREATE", driverType: "PRIMARY" },
									},
								},
							],
						},
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: {
								driver: item.driver ? item.driver : null,
								driver__view: {
									...(item?.driver__view ?? {}),
									label: item.driver__view?.label ?? "None",
									value: item.driver ? item.driver : null,
								},
							},
							dataIds: {
								carrierId: dataIds.carrierId,
								truckId: item._id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "trailer",
				type: "text",
				label: "Trailer",
				table: { width: "14%" },
				builder: (item) => {
					return item.truck_trailer__view?.metadata?.trailerid
						? "#" + item.truck_trailer__view?.metadata?.trailerid
						: null;
				},
			},
			{
				key: "driver__view.label",
				type: "text",
				label: "Driver(s)",
				table: { width: "60%" },
				builder: (item) => {
					const dPrimary = item.driver__view?.label;
					const dSecondary = item.driver_secondary__view?.label;

					let text = dPrimary ?? "";
					if (text && dSecondary) text += ` & `;
					if (dSecondary) text += `${item.driver_secondary__view?.label}`;

					return text;
				},
			},
			{
				key: "equipment",
				type: "text",
				label: "Equipment",
				table: { align: "right", width: "20%" },
				builder: (item) =>
					item.equipment === "PO"
						? "Power Only"
						: item.equipment === "SPECIAL"
						? item.subtype
						: capitalizeFirstLetter(item.equipment),
			},
			{
				key: "access",
				type: "component",
				label: "Status",
				table: { width: "8%" },
				builder: (item) => {
					const truck = item?.truck__view?.metadata;
					return (
						<div className={"flex flex-row"} onClick={(e) => e.stopPropagation()}>
							{buildTooltip(
								item.active,
								item.active ? "directions_bus" : "no_transfer",
								item.active ? "This truck is active" : "This truck has been archived",
								{ icon: " ml-2 " }
							)}
							{buildTooltip(
								!!item.truck_trailer__view?.label,
								!!item.truck_trailer__view?.label ? "luggage" : "no_luggage",
								!!item.truck_trailer__view?.label
									? capitalizeFirstLetter(item.truck_trailer__view?.metadata?.equipment) +
											" trailer connected" +
											(!!truck?.trailer ? ", " + truck?.trailer + "' Wedge" : "") +
											(!!truck?.max_weight ? ", max " + truck?.max_weight + "lb" : "")
									: "Trailer not connected (Power Only)",
								{ icon: " ml-4 " }
							)}
							{buildTooltip(
								!!item.driver__view?.label,
								!!item.driver_secondary__view?.label
									? "people_outline"
									: !!item.driver__view?.label
									? "perm_identity"
									: "person_add_disabled",
								!!item.driver_secondary__view?.label
									? "This truck has multiple drivers"
									: !!item.driver__view?.label
									? "This truck has a single driver"
									: "Driver is not configured for this truck",
								{ icon: " ml-4 " }
							)}
						</div>
					);
				},
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							viewId: "TRUCK_VIEW",
							mode: "EDIT",
							dataIds: { ...dataIds, id: item._id, truckId: item._id, mode: "EDIT" },
						}),
					},
					{
						key: "divider",
						variant: "divider",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
					},
					{
						key: "activate",
						label: "Activate",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRUCK_STATUS_FORM",
							mode: "EDIT",
							dataIds: { ...dataIds, id: item._id, status: "active" },
						}),
						isVisible: (item) => !item.active,
					},
					{
						key: "inactivate",
						label: "Archive",
						auth: ["carrier", "carrier_manager", "carrier_dispatcher", "administrator"],
						dialogBuilder: (item, dataIds) => ({
							formId: "TRUCK_STATUS_FORM",
							mode: "EDIT",
							dataIds: { ...dataIds, id: item._id, status: "inactive", item: item },
						}),
						isVisible: (item) => item.active,
					},
				],
			},
		],
	},
});

const carrierTrucksList = carrierTrucksListBuilder({ source: "CARRIER", isSubaccount: false });

export { carrierTrucksList, carrierTrucksListBuilder };
