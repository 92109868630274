import { fetchChats } from "app/store/messenger/chatSlice";

// Services
import { getUserId, getRoleLabel, getCarrierId, isCarrier } from "app/services/LoginService";
import { fetchActions } from "app/store/actions/actionsUserSlice";

// Form
import { TRAILER_FIELDS, EQUIPMENT_TRAILER_FIELDS } from "./trailerForm";
import driverForm from "./driverForm";
import truckPlanForm from "@smarthop/form/configs/truckPlanForm";
import { costStructureFields } from "app/main/profile/cost-structure/calculator/CostStructureCalculatorSettings";
import carrierGoalsContent from "./carrierGoalsContent";

const TRUCK_DEFAULTS = {
	PO: {
		max_weight: undefined,
		load_type: "FULL",
	},
	REEFER: {
		max_weight: 44000,
		load_type: "FULL",
		trailer: 53,
	},
	VAN: {
		max_weight: 45000,
		load_type: "FULL",
		trailer: 53,
	},
	FLATBED: {
		max_weight: 48000,
		load_type: "FULL",
		trailer: 53,
	},
	Tanker: {
		max_weight: 70000,
		load_type: "FULL",
		trailer: 53,
	},
	Deck: {
		max_weight: 55000,
		load_type: "FULL",
		trailer: 53,
	},
	HotShot: {
		max_weight: 30000,
		load_type: "FULL",
		trailer: 53,
	},
	Conestoga: {
		max_weight: 48000,
		load_type: "FULL",
		trailer: 53,
	},
	"Cargo Van": {
		max_weight: 7500,
		load_type: "BOTH",
		trailer: 15,
	},
	"Box Truck": {
		max_weight: 26000,
		load_type: "BOTH",
		trailer: 26,
	},
};

const getRules = ({ carrierOption }) => {
	if (!carrierOption) return {};
	return {
		rules: {
			params: {
				valueRef: "carrier",
				paramKey: "options_carrier_id_search",
			},
		},
	};
};

const infoCarrierSection = () => {
	return [
		{
			key: "carrier",
			type: "autocomplete",
			label: "Carrier",
			required: true,
			field: {
				noErrorMessage: true,
				classes: {},
				variant: undefined,
			},
			autocomplete: {
				provider: "smarthop",
				url: "api/profile/carriers",
				preloadDefault: true,
				preloadImmedately: true,
				listenEvent: "profileRevision",
				params: {
					options_active_only: true,
					options_onboarding_completed: true,
				},
			},
		},
	];
};

const infoSection = ({ mode, carrierOption, carrierId, isCreateMode }) => {
	return [
		...(mode === "EDIT"
			? [
					{
						type: "group",
						content: {
							items: [
								{
									key: "driver",
									type: "autocomplete",
									label: "Primary Driver",
									autocomplete: {
										provider: "smarthop",
										url: carrierOption ? "api/profile/drivers" : "api/profile/carriers/:carrierId/drivers",
										preloadDefault: true,
										listenEvent: "profileRevision",
										params: { options_active_only: true },
										actionOptions: [
											{
												value: "ADD_NEW_DRIVER",
												label: "Add New Driver",
												icon: "add",
												dialog: {
													viewId: "CARRIER_USER_EDIT_VIEW",
													mode: "CREATE",
													dataIds: { origin: "DRIVER" },
												},
											},
										],
									},
									...getRules({ carrierOption }),
								},
								{
									key: "driver_secondary",
									type: "autocomplete",
									label: "Secondary Driver",
									autocomplete: {
										provider: "smarthop",
										url: carrierOption ? "api/profile/drivers" : "api/profile/carriers/:carrierId/drivers",
										preloadDefault: true,
										listenEvent: "profileRevision",
										params: { options_active_only: true },
										actionOptions: [
											{
												value: "ADD_NEW_DRIVER",
												label: "Add New Driver",
												icon: "add",
												dialog: {
													viewId: "CARRIER_USER_EDIT_VIEW",
													mode: "CREATE",
													dataIds: { origin: "DRIVER" },
												},
											},
										],
									},
									...getRules({ carrierOption }),
								},
							],
						},
					},
			  ]
			: []),
		{
			type: "group",
			content: {
				items: [
					{
						key: "investor_profile",
						type: "autocomplete",
						label: "Investor",
						autocomplete: {
							provider: "smarthop",
							url: carrierOption ? "api/profile/investors" : "api/profile/carriers/:carrierId/investors",
							preloadDefault: true,
							listenEvent: "profileRevision",
							params: { options_investor: true },
						},
						...getRules({ carrierOption }),
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "truckid",
						type: "text",
						label: "ID",
						required: true,
						preventCharacter: true,
						minCharacter: 1,
						maxCharacter: 15,
					},
					{
						key: "vin",
						type: "text",
						label: "VIN #",
						field: { maxLength: 30 },
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "miles_per_gallon", type: "number", label: "Miles Per Gallon", required: true, defaultValue: 5 },
					{ key: "licensePlate", type: "text", label: "License Plate" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "truck_model", type: "text", label: "Model" },
					{ key: "truck_year", type: "number", label: "Year" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "goals",
						type: "autocomplete",
						label: "Goals",
						autocomplete: {
							provider: "smarthop",
							url: carrierOption ? "api/goals" : "api/goals/carriers/:carrierId",
							preloadDefault: true,
							listenEvent: "goalsRevision",
							actionOptions: [
								{
									value: "CREATE_NEW_GOALS",
									label: "Create New Goals",
									icon: "add",
									dialog: {
										viewId: "CREATE_NEW_GOALS_VIEW",
										mode: "CREATE",
									},
								},
							],
						},
						...getRules({ carrierOption }),
					},
					{
						key: "cost_structure",
						type: "autocomplete",
						label: "Cost Structure",
						required: false,
						autocomplete: {
							provider: "smarthop",
							url: carrierOption ? `api/cost-structures` : `api/cost-structures/carriers/:carrierId`,
							preloadDefault: true,
							listenEvent: "costStructureRevision",
							params: { options_active_only: true },
							actionOptions: [
								{
									value: "ADD_NEW_COSTS_STRUCTURE",
									label: "New Cost Structure",
									icon: "add",
									disabled: getRoleLabel() === "dispatcher" ? true : false,
									dialog: {
										viewId: "COST_STRUCTURE",
										mode: "CREATE",
										dataIds: { id: false },
									},
								},
							],
						},
						...getRules({ carrierOption }),
					},
				],
			},
		},
		{
			key: "cab_card",
			type: "upload",
			label: "CAB Card",
			upload: {
				url: "api/profile/carriers/:carrierId/files",
				documentType: "driver",
				fileName: "cab_card",
			},
			disabled: carrierId ? false : true,
		},
		{
			key: "truck_registration",
			type: "upload",
			label: "Truck Registration",
			upload: {
				url: "api/profile/carriers/:carrierId/files",
				documentType: "driver",
				fileName: "truck_registration",
			},
			disabled: carrierId ? false : true,
		},
	];
};

const trailerSection = ({ mode, trailer, carrierOption, subtype }) => {
	return [
		...(mode !== "VIEW"
			? [
					{
						key: "truck_trailer",
						type: "autocomplete",
						label: "Trailer",
						required: true,
						autocomplete: {
							provider: "smarthop",
							url: carrierOption ? "api/profile/trailers" : "api/profile/carriers/:carrierId/trailers",
							preloadDefault: true,
							listenEvent: "trailerRevision",
							params: {
								options_active_only: true,
							},
							actionOptions: [
								{
									value: "PO",
									label: "Power Only",
									metadata: {
										equipment: "PO",
									},
									selectable: true,
								},
								{
									value: "CREATE_NEW_TRAILER",
									label: "New Trailer",
									icon: "add",
									selectable: true,
								},
							],
						},
						...getRules({ carrierOption }),
					},
			  ]
			: []),
		...(trailer === "CREATE_NEW_TRAILER" || mode === "VIEW"
			? TRAILER_FIELDS()
			: EQUIPMENT_TRAILER_FIELDS(trailer, subtype)),
	];
};

const prefSection = ({ mode }) => {
	return [
		{
			key: "blackListCommodities",
			type: "autocomplete",
			label: "Blacklist commodities",
			autocomplete: {
				preloadDefault: true,
				multiple: true,
				provider: "smarthop",
				url: "api/consts/blacklist/commodities",
			},
		},
	];
};

const trackingSection = ({ mode, carrierOption }) => {
	return [
		{
			key: "axleId",
			type: "autocomplete",
			label: "Axle Truck ID",
			autocomplete: {
				provider: "smarthop",
				url: "api/profile/carriers/:carrierId/axle/trucks",
				multiple: false,
				preloadDefault: true,
				anyValue: true,
				listenEvent: "profileRevision",
			},
		},
	];
};

const truckFormConfig = (
	isCreateMode = false,
	{ mode, carrierOption, carrierId } = {},
	trailer = null,
	subtype = null
) => {
	if (!mode || mode === "EDIT") {
		return {
			items: [
				...(isCreateMode && carrierOption
					? [
							{
								type: "section",
								label: "Carrier Information",
								section: { classes: { label: " -mt-1 " } },
							},
							...infoCarrierSection(),
					  ]
					: []),
				{
					type: "section",
					label: "Truck Information",
					section: { classes: { label: " -mt-1 " } },
				},
				...infoSection({ isCreateMode, mode: "EDIT", carrierOption, carrierId }),
				{
					type: "section",
					label: "Trailer Information",
				},
				...trailerSection({ mode, trailer, carrierOption, subtype }),
				{
					type: "section",
					label: "Preferences",
				},
				...prefSection({ mode }),
				{
					type: "section",
					label: "Tracking",
				},
				...trackingSection({ mode, carrierOption }),
				{ type: "action", action: "SUBMIT" },
			],
		};
	} else if (mode === "VIEW") {
		return {
			info: {
				items: [...infoSection({ isCreateMode, mode }), ...trackingSection({ mode })],
			},
			trailer: {
				items: [...trailerSection({ mode })],
			},
			driver: {
				items: [...driverForm(true, false).content?.edit?.items],
			},
			driver_secondary: {
				items: [...driverForm(true, true).content?.edit?.items],
			},
			plan: {
				items: [...truckPlanForm?.content?.view?.items],
			},
			cost: {
				items: [
					...Object.keys(costStructureFields)
						.map((f) => costStructureFields[f])
						.filter((item) => item.key !== "name_cost_structure"),
				],
			},
			goal: {
				items: [...carrierGoalsContent().items.filter((item) => item.key !== "name")],
			},
		};
	}
};

const trailerFormConfig = ({ mode } = {}) => {
	return {
		items: [...TRAILER_FIELDS(), { type: "action", action: "SUBMIT", label: "Save" }],
	};
};

const truckForm = ({ user }, trailer = null) => {
	const isPayrollEnabled = user?.enterpriseFeatures?.payroll_module_flag;

	return {
		urlPOST: "api/profile/carriers/:carrierId/trucks",
		urlGET: "api/profile/carriers/:carrierId/trucks/:id",
		urlPUT: "api/profile/carriers/:carrierId/trucks/:id",
		idKey: "_id",
		triggerEvent: ["fuelRevision", "profileRevision", "goalsCarrierRevision", "trailerRevision", "dataRevision"],
		listenEvent: "profileRevision",
		header: {
			create: { label: "Add New Truck" },
			edit: { label: "Edit Truck" },
			delete: { label: "Delete Truck" },
			view: { label: "View Truck" },
		},
		messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
		content: {
			delete: {
				items: [
					{
						type: "message",
						builder: (item) => `Would you like to permanently delete truck with ID '${item.truckid}'?`,
					},
					{
						type: "group",
						content: {
							items: [
								{ type: "action", action: "CANCEL" },
								{ type: "action", action: "DELETE" },
							],
						},
					},
				],
				onFinished: (data, dispatch) => {
					dispatch(fetchChats({ userId: getUserId() }));
				},
			},
			view: {
				sameAs: "edit",
			},
			create: {
				...truckFormConfig(true, { isPayrollEnabled }, trailer),
				onFinished: (data, dispatch) => {
					dispatch(fetchChats({ userId: getUserId() }));
					if (isCarrier()) {
						dispatch(fetchActions({ carrierId: getCarrierId() }));
					}
				},
			},
			edit: truckFormConfig(false, { isPayrollEnabled }, trailer),
		},
	};
};

export { truckForm, truckFormConfig, trailerFormConfig, TRUCK_DEFAULTS };
