import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";

import { formatDate } from "app/main/utils/dateUtils";

import { getUserId } from "app/services/LoginService";
import { getInviteLink } from "app/services/usersServices";
import copy from "copy-to-clipboard";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// TODO: this is a prevent (At the moment)
const _USER_CAN_BE_PARENT = ["CARRIER_OWNER", "CARRIER_MANAGER", "CARRIER_DISPATCHER"];

const buildTooltip = (enabled, icon, description, classes) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
			title={<Typography className={"text-13 text-black py-10 px-10"}>{description}</Typography>}
		>
			<Icon className={"text-20 min-w-28 " + (classes?.icon ?? "") + (enabled ? "text-primary" : "text-grey")}>
				{icon}
			</Icon>
		</Tooltip>
	);
};

const isActive = (item) => {
	return !item.deactivated || item.driver_profile?.deleted === false || item.payroll_profile?.payroll_enabled === true;
};

const getRoleLabel = (role, parent, parentType, driverProfile, gks) => {
	const roleLabel =
		role === "CARRIER_OWNER"
			? "Owner" + (driverProfile?.deleted === false ? ", Driver" : "")
			: role === "CARRIER_MANAGER"
			? "Manager" + (driverProfile?.deleted === false ? ", Driver" : "")
			: role === "CARRIER_DISPATCHER"
			? "Dispatcher"
			: role === "CARRIER_DRIVER"
			? "Driver"
			: role === "CARRIER_GENERIC"
			? gks?.permission_investor_on
				? "Investor"
				: undefined
			: undefined;

	return (
		<div>
			{roleLabel}
			{!!parent && parentType === "PARENT" && (
				<Tooltip
					classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
					title={
						<Typography className={"text-13 text-black py-10 px-10"}>
							{"This user was created by the parent carrier which controlls your account (you can not edit this user)"}
						</Typography>
					}
				>
					<Chip className="ml-5 text-10 h-16" label="Parent" color="secondary" size="small" />
				</Tooltip>
			)}
			{!!parent && parentType === "CONNECTED" && (
				<Tooltip
					classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
					title={
						<Typography className={"text-13 text-black py-10 px-10"}>
							{"This user was created by the connected carrier (you can not edit this user)"}
						</Typography>
					}
				>
					<Chip className="ml-5 text-10 h-16 bg-green text-white" label="Connected" size="small" />
				</Tooltip>
			)}
		</div>
	);
};

const filters = [
	{
		key: "accountStatus",
		type: "select",
		label: "Account Status",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				label: "Active",
				value: "active",
			},
			{
				label: "Inactive",
				value: "inactive",
			},
		],
	},
	{
		key: "role",
		type: "select",
		label: "Role",
		defaultValue: "__NOT_SELECTED__",
		options: [
			{
				value: "__NOT_SELECTED__",
				label: "Not Selected",
			},
			{
				value: "CARRIER_OWNER",
				label: "Owner",
			},
			{
				value: "__PROFILE_DRIVER__",
				label: "Driver",
			},
			{
				value: "CARRIER_MANAGER",
				label: "Manager",
			},
			{
				value: "CARRIER_DISPATCHER",
				label: "Dispatcher",
			},
			{
				value: "__PROFILE_INVESTOR__",
				label: "Investor",
			},
		],
	},
];

const users = ({ showPayrollFields }) => {
	const userId = getUserId();

	return {
		urlGET: "api/profile/carriers/:carrierId/users",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "CARRIER_MANAGER",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
				layout: [["email"], ["access"]],
			},
			table: {
				variant: "skinny",
				orderBy: ["first_name", "email", "roleLabel"],
				layout: ["table_avatar", "first_name", "email", "roleLabel", "access"],
			},
			pagination: {
				enabled: true,
				resetScroll: true,
			},
			defaults: {
				initial: {
					filters: {
						accountStatus: "active",
						includeDriver: true,
					},
				},
				items: [
					{
						label: "Active",
						description: "Active users",
						filters: {
							accountStatus: "active",
						},
					},
					{
						label: "Inactive",
						description: "Inactive users",
						filters: {
							accountStatus: "inactive",
						},
					},
				],
			},
			filters: {
				search: true,
				countVisible: 1,
				items: filters,
			},
			click: {
				grid: { variant: "menu" },
				table: { addToMenu: true },
				dialogBuilder: (item, dataIds) => ({
					viewId: "USER_DETAILS_VIEW",
					dataIds: {
						...dataIds,
						userId: item._id,
						driverId: item.driver,
					},
				}),
			},
			items: [
				{
					key: "table_avatar",
					type: "component",
					table: { width: "30px" },
					builder: (item) => {
						return (
							<Avatar src={null} alt={""} className={"bg-white border-1 border-grey-400"}>
								{item.role === "CARRIER_OWNER" ? (
									<Icon className="text-20 text-primary">star</Icon>
								) : item.role === "CARRIER_DRIVER" ? (
									<Icon className="text-20 text-primary">airline_seat_recline_normal</Icon>
								) : item.role === "CARRIER_DISPATCHER" ? (
									<Icon className="text-20 text-primary">support_agent</Icon>
								) : (
									<Icon className="text-20 text-primary">account_circle</Icon>
								)}
							</Avatar>
						);
					},
				},
				{
					key: "first_name",
					type: "text",
					label: "Name",
					grid: { headerTitle: true },
					builder: (item) => (item.first_name ?? "") + " " + (item.last_name ?? "").trim(),
				},
				{
					key: "email",
					type: "text",
					label: "Email",
					table: { width: "38%" },
					grid: { longRow: true },
				},
				{
					key: "roleLabel",
					builder: (item) =>
						getRoleLabel(item.role, item.parent, item.parentType, item.driver_profile, item.gatekeepers),
					type: "text",
					label: "Role",
					table: { align: "right", width: "20%" },
					grid: { headerSubtitle: true },
				},
				{
					key: "access",
					type: "component",
					label: "Access",
					table: { width: "8%" },
					builder: (item) => {
						return (
							<div className={"flex flex-row"} onClick={(e) => e.stopPropagation()}>
								{buildTooltip(
									!item.deactivated,
									!item.deactivated
										? item.parent
											? "settings_ethernet"
											: item.accessAt
											? "phonelink"
											: "mark_email_unread"
										: "phonelink_off",
									!item.deactivated
										? item.parent
											? "This external user has access to your account"
											: item.accessAt
											? "This user can access web portal (Last online '" + formatDate(item.accessAt) + "')"
											: " Invitation has been sent, but user not yet logged in"
										: " This user does not have access to web portal",
									{ icon: " ml-4 " }
								)}
								{(item.driver_profile?.deleted === false || item.role === "CARRIER_DRIVER") &&
									buildTooltip(
										item.driver_profile?.deleted === false,
										item.driver_profile?.deleted === false ? "directions_bus" : "no_transfer",
										item.driver_profile?.deleted === false
											? "This user has active driver profile"
											: " This user driver profile is not active",
										{ icon: " ml-2 " }
									)}
								{item.role === "CARRIER_DISPATCHER" &&
									buildTooltip(
										item.accessSettings?.accessType === "FULL",
										"all_inclusive",
										item.accessSettings?.accessType === "FULL"
											? "This user has access to all trucks"
											: "This user has access to some trucks",
										{ icon: " ml-2 " }
									)}
								{showPayrollFields &&
									(item.payroll_profile?.payroll_enabled === true ||
										item.driver_profile?.deleted === false ||
										item.role === "CARRIER_DRIVER" ||
										item.role === "CARRIER_USER" ||
										item.role === "CARRIER_DISPATCHER") &&
									buildTooltip(
										item.payroll_profile?.payroll_enabled === true,
										item.payroll_profile?.payroll_enabled === true ? "attach_money" : "money_off",
										item.payroll_profile?.payroll_enabled === true
											? "This user has been enrolled in payroll"
											: "This user has not been enrolled in payroll",
										{ icon: " -ml-2 " }
									)}
							</div>
						);
					},
				},
				{
					key: "menu",
					type: "menu",
					label: "Menu",
					items: [
						{
							key: "edit",
							label: "Edit",
							auth: ["carrier", "carrier_manager", "administrator"],
							dialogBuilder: (item, dataIds) => ({
								viewId: "CARRIER_USER_EDIT_VIEW",
								mode: "EDIT",
								dataIds: { userId: item._id, carrierId: dataIds.carrierId },
							}),
							isVisible: (item) => {
								// Owner can edit himsel
								if (item.role === "CARRIER_OWNER" && item._id === userId && !item.parent) return true;
								// Other Users can editt all the users less OWNER and HIMSELF
								if (item.role !== "CARRIER_OWNER" && item._id !== userId && !item.parent) return true;
								// workaroung: Has parent and is a users with not posibilities has parent
								if (item.parent && !_USER_CAN_BE_PARENT.includes(item.role)) return true;
								return false;
							},
						},
						{
							variant: "divider",
							auth: ["carrier", "carrier_manager", "administrator"],
							isVisible: (item) => {
								if (item.parent && _USER_CAN_BE_PARENT.includes(item.role)) return false;
								return item.role !== "CARRIER_OWNER" && item._id !== userId;
							},
						},
						{
							key: "activate",
							label: "Activate",
							auth: ["carrier", "carrier_manager", "administrator"],
							dialogBuilder: (item, dataIds) => ({
								viewId: "CARRIER_USER_EDIT_VIEW",
								mode: "EDIT",
								dataIds: { userId: item._id, carrierId: dataIds.carrierId },
							}),
							isVisible: (item) => {
								if (item.parent && _USER_CAN_BE_PARENT.includes(item.role)) return false;
								return item.role !== "CARRIER_OWNER" && item._id !== userId && !isActive(item);
							},
						},
						{
							key: "deactivate",
							label: "Deactivate",
							auth: ["carrier", "carrier_manager", "administrator"],
							dialogBuilder: (item, dataIds) => ({
								viewId: "CARRIER_USER_EDIT_VIEW",
								mode: "DELETE",
								dataIds: { userId: item._id, carrierId: dataIds.carrierId },
							}),
							isVisible: (item) => {
								if (item.parent && _USER_CAN_BE_PARENT.includes(item.role)) return false;
								return item.role !== "CARRIER_OWNER" && item._id !== userId && isActive(item);
							},
						},
						{
							key: "send",
							label: "Re-send invitation",
							auth: ["carrier", "carrier_manager", "administrator"],
							dialogBuilder: (item, dataIds) => ({
								formId: "INVITATION_FORM",
								mode: "CREATE",
								dataIds: { userId: item._id, carrierId: dataIds.carrierId },
							}),
							isVisible: (item) => !item.parent && !item.deactivated && !item.accessAt,
						},
						{
							key: "copy-invite",
							label: "Copy invitation link",
							auth: ["carrier", "carrier_manager", "administrator"],
							dispatchAction: (item, _, dispatch, history, snackbar) => {
								getInviteLink(item.carrier, item._id).then((url) => {
									copy(url);
									showSnackbar(snackbar, `Link copied to clipboard`, "success");
								});
							},
							isVisible: (item) => !item.parent && !item.deactivated && !item.accessAt,
						},
					],
				},
			],
		},
	};
};

export default users;
